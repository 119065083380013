import axios from 'axios';
import https from 'https';



const getOvApiEndpoint = () => {

  let apiendpoint = process.env.OV_NODE_API;
  console.log('ov api endpoint:', apiendpoint);
  if (!apiendpoint || apiendpoint==='LOCAL_FILE') {
    // console.log('Getting local api endpoint');
    apiendpoint = 'https://openvidu-scoreit-api.herokuapp.com';
    apiendpoint = 'http://127.0.0.1:5000';// hmmm which to choose.
  }
  return apiendpoint;

};

const ovApiEndpoint = getOvApiEndpoint();



export async function openViduLogin (emmaUser: any, sessionName: string)  {
  const ovApiEndpoint = getOvApiEndpoint();
  console.log('calling OV with:', emmaUser);
  console.log('session name:', sessionName);
  const url = `${ovApiEndpoint}/api-login/scoreitlogin`;
  console.log('url:', url);
  const {uid, bearerToken } = emmaUser.firebaseUser;
  axios.defaults.headers.common = {
    'Authorization': `Bearer ${bearerToken}`,
    'uid': uid,
    'Access-Control-Allow-Origin': '*'
  };
  
  if (!sessionName) {
    sessionName = 'OT-SESSION';
  }
  try {
    const responseData = await axios.post(url, {
      emmauser: emmaUser,
      sessionName: sessionName 
    });
    console.log('Response from OVendpoint:', responseData);
    return responseData;
  }catch(error){
    console.log('401 ERROR on /openViduLogin');
  }
};

export async function getOVToken (sessionName: string) {
  const url = `${ovApiEndpoint}/api-sessions/get-token`;
  console.log('Getting OV token from:', url);
  try{
    const response = await axios.post (url, {
      sessionName: sessionName
    });
    console.log('getOvToken response:', response.data);
    return response.data;
  
  } catch(error){
    console.log('getOvToken error:', error);
  }

};

export async function closeSession(sessionName: string){

  const url = `${ovApiEndpoint}/api-sessions/close-session`;
  try{
    const response = await axios.delete (url, {
      data:{sessionName: sessionName}
    });
    console.log('close-session response:', response.data);
    return response.data;
  
  } catch(error){
    console.log('close-session error:', error);
  }
}

export async function leaveSession (sessionName: string, token: any) {

  const url = `${ovApiEndpoint}/api-sessions/remove-user`;
  if (!sessionName) { return;}

  console.log('Leave session: ', sessionName);
  try{
    const response = await axios.post (url, {
      sessionName: sessionName,
      token: token
    });
    console.log('remove-user response:', response.data);
    return response.data;
  
  } catch(error){
    console.log('remove-user error:', error);
  }
}

export async function recordingStart (sessionId: string) {

  const url = `${ovApiEndpoint}/api/recording/start`;
  if (!sessionId) { return;}

  try{
    const response = await axios.post (url, {
      session: sessionId,
      outputMode: 'COMPOSED',
      hasAudio: true,
      hasVideo: true
    });
    console.log('api/recording/start response:', response.data);
    return response.data;
  
  } catch(error){
    console.log('recording/start error:', error);
  }
}

export async function recordingStop (recordingId: string) {

  const url = `${ovApiEndpoint}/api/recording/stop`;
  if (!recordingId) { return;}

  try{
    const response = await axios.post (url, {
      recording: recordingId
    });
    console.log('api/recording/stop response:', response);
    return response;
  
  } catch(error){
    console.log('recording/stop error:', error);
  }
}
