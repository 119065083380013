import React, { useContext, useEffect, useState, useRef } from 'react';

import {Redirect} from 'react-router-dom';
import {
  IconButton,
  Button,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Checkbox
} from '@material-ui/core';

import PropTypes from 'prop-types';

import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Delete as DeleteIcon
} from '@material-ui/icons';


import { createStyles, makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';

import { stableSort, getSorting } from '../../../common/tableUtilities';
import { useUserState } from '../../../context/UserContext';
import { 
  VideoTagTableContext,
  createEmpty,
  VideoTagTableTypes, 
  initialVideoTabTable
} from '../../../context/VideoTagTableContext';

import { 
  AssessmentInfoContext,
  indexOfLibraryName
} from '../../../context/AssessmentInfoContext';

import { SimplifiedVideoTagTableHead } from './SimplifiedVideoTagTableHead';

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Paper = styled(MuiPaper)(spacing);
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - 12px);
  height: 500px
`;
//   
// max-width: calc(100vw - ${props => props.theme.spacing(12)}px);


const useStyles = makeStyles((theme) =>
  createStyles({
    tableRow: {
      backgroundColor: 'lightgrey'
    },
    tableRowDefault: {
      backgroundColor: 'white'
    },
  }),
);

export const VideoTagTable = (props) => {

  const classes = useStyles();

  const user = useUserState();
  const newlyAddedItemRef = useRef();
  const {tagTableState, tagTableDispatch} = React.useContext(VideoTagTableContext);
  const {annotationSetMap, currentAnnotSetOwner, annotationFilter, forceVisualUpdateHack} = tagTableState;
  const {assessmentInfoState, assessmentInfoDispatch} = React.useContext(AssessmentInfoContext);
  const { activeInstrumentName, instrumentLibraries} = assessmentInfoState;
  const [tagsToDisplay, setTagsToDisplay] = useState([]);
  const [redirect, setRedirect] = useState(null);
  const [tagOptionStrings, setTagOptionsString] = useState([]);

  useEffect(() => {
    const currentInstrumentItems = instrumentLibraries.filter((lib)=>lib.name === activeInstrumentName);
    if(currentInstrumentItems && currentInstrumentItems.length >0){
      setTagOptionsString(currentInstrumentItems[0].instrumentItems.map(x => x.name));
    }

  }, [activeInstrumentName]);

  useEffect(()=>{
    // I believe it would be much much cleaner to do this in the TagTableContext.
    // do to time dlp is putting this here, but please don't keep adding to this.
    if(currentAnnotSetOwner === undefined){
      setTagsToDisplay([]);
      return;
    }
    const currentIndex = indexOfLibraryName(assessmentInfoState);
    // Brute force filter on the annotation set that is currently being displayed
    if(annotationSetMap?.get(currentAnnotSetOwner)?.find(annotSet=>annotSet.instrumentIndex ===currentIndex)?.annotations){
      // Thes are of type AnnotationSchemaProps
      const instrumentDetails = instrumentLibraries[currentIndex];
      let filtered = annotationSetMap.get(currentAnnotSetOwner).
        find(annotSet=>annotSet.instrumentIndex ===currentIndex)?.annotations;
      // the type of props.instrumentDetails comes from the API
      // and is type Instrument (with InstrumentItems). 
      // the instrumentDb.json has an example
      
      const subscales = new Set(instrumentDetails.subscales);
      if(annotationFilter){
        // Semi hack for filtering on things that are not related. At the very
        // least it is not pretty. The biggest limitation of this is that
        // it needs the full dataset. ie if the dataset is too big memory/speed issues.
        // have i mentioned that tests would be great for this type of thing?
        if(annotationFilter === 'Flagged'){
          filtered = filtered.filter(tag=>tag.verifyLabel === true);
        }else if(subscales.has(annotationFilter)){
          // brute force this. 
          //get all the instrument names that match the subscale
          const subscaleItems = instrumentDetails
            .instrumentItems.filter((item)=>item.subscale?.name === annotationFilter)
            // interesting return item.name didnt work in filter.
            .map((item)=>item.name);
          const subscaleItemSet = new Set(subscaleItems);
          filtered = filtered.filter(tag=>subscaleItemSet.has(tag.label));
        }else{
          filtered = filtered.filter(tag=>tag.label === annotationFilter);
        }        
      }
      const theSorted = stableSort(filtered, getSorting('desc', 'startTime'));
      setTagsToDisplay(theSorted);
    }else{
      setTagsToDisplay([]);
    }
                  
  },[annotationFilter, annotationSetMap, currentAnnotSetOwner, forceVisualUpdateHack, activeInstrumentName]);


  useEffect( () => {
    scrollToRecentlyAddedItem();
  }, [tagsToDisplay.length]);

  const scrollToRecentlyAddedItem = () => {
    if (newlyAddedItemRef.current == null) return;    
    newlyAddedItemRef.current.scrollIntoView();    
  };

  const timeDifference = (time1, time2) => {
    return Math.abs(time1-time2)*1000.0;
  };

  const handleAddLabel = () => {
    // // A way of getting the current time from the playing video
    const currentTimeStamp = props.handleAddLabel();
    console.log('blah, should be addign a label now');
    const toAdd = createEmpty(currentTimeStamp, indexOfLibraryName(assessmentInfoState));
    tagTableDispatch({type:VideoTagTableTypes.ADDITEM, ...toAdd});
    // hmm to save or not to save??
    // props.handleSaveLabels();
  };

  const handleDeleteLabel = (data) => {
    // it would be way better to send this to the dispatch, 
    // but this hack seems to work ok.
    const currentIndex = indexOfLibraryName(assessmentInfoState);
    const annotations = annotationSetMap.get(currentAnnotSetOwner).
      find(annotSet=>annotSet.instrumentIndex ===currentIndex)?.annotations;
    console.log('deleting', annotations);
    const tagIndex = annotations.findIndex(tag => tag.startTime === data.startTime);
    annotations.splice(tagIndex, 1);
    tagTableDispatch({type:VideoTagTableTypes.REMOVEITEM, ...initialVideoTabTable});
    props.handleSaveLabels();
  };

  const handleSeekToLocal = (amt) => {
    console.log('handleSeekTo LOCAL');
    props.handleSeekTo(amt);
  };

  const isModifyTagDisabled = () =>{
    return tagTableState.currentAnnotSetOwner !== user.localEmail;
  };
  const zeroPad = (num, places) => String(num).padStart(places, '0');

  if (redirect) {
    return <Redirect to={redirect} />; //Back? 
  }
  return (
    <Card  variant="outlined">
      <Paper elevation={2}>
        <CardContent pb={0}>
          <SimplifiedVideoTagTableHead
            handleAddLabel = {handleAddLabel}
          />
        </CardContent>
      </Paper>   
      <Paper>        
        <TableWrapper>
          <Table 
            aria-labelledby="tableTitle"
            size="small"
          >
            <TableBody>
              {
                tagsToDisplay.map( (tag, index) => {
                  const idToUse = `${tag.startTime}`;
                  const textId = `${idToUse}-text`;
                  if ( timeDifference(tag.startTime,props.returnCurrentTimeStamp()) < 200 /*ms*/){
                    console.log('Time match:',tag.startTime);
                    console.log('current timestamp:', props.returnCurrentTimeStamp());
                  }
                  return (
                    <TableRow
                      className = {timeDifference(tag.startTime,props.returnCurrentTimeStamp()) < 200 /*ms*/ ? classes.tableRow : classes.tableRowDefault}
                      hover
                      key={idToUse}
                      ref = {timeDifference(tag.startTime,props.returnCurrentTimeStamp()) < 200 /*ms*/ ? newlyAddedItemRef : null}
                      tabIndex={-1}
                    >
                      <TableCell 
                        align="center"
                        style={{ width: '15px' }}
                      >
                        <Button
                          onClick={()=>{handleSeekToLocal(tag.startTime);}}
                          
                        >
                          {zeroPad(Math.floor(tag.startTime/60), 2)}:{zeroPad(Math.floor(tag.startTime%60), 2)}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Autocomplete
                          blurOnSelect
                          disabled= {isModifyTagDisabled()}
                          id={textId}
                          onChange={(event, newValue) => {
                            tag.label = newValue;
                            props.handleSaveLabels();
                          }}
                          options = {tagOptionStrings}
                          renderInput={(params) => (
                            <TextField 
                              {...params}
                              margin="normal" 
                              variant="outlined"
                            />
                          )}
                          value= {tag.label}                          
                        />
                      </TableCell>
                      <TableCell 
                        align="center"
                        style={{ width: '15px' }}
                      >
                        <Tooltip title="Flag for verification">
                          <Checkbox
                            checked={tag.verifyLabel}

                            disabled= {isModifyTagDisabled()}
                            onChange={(event)=>{
                            //Hack clean up, we don't want to save
                            // false, so delete the verifyLabel if false.
                              const isChecked = event.target.checked;
                              if(isChecked){
                                tag.verifyLabel = event.target.checked;
                              }else{
                                // the delete works because the whole
                                // entity is re-written on the save.
                                delete tag.verifyLabel; 
                              }                        
                              props.handleSaveLabels();
                            }}
                            tool
                          />
                        </Tooltip>

                      </TableCell>
                      <TableCell 
                        align="center"
                        style={{ width: '15px' }}
                      >
                        <IconButton
                          aria-label="Delete"
                          disabled= {isModifyTagDisabled()}
                          onClick={()=>handleDeleteLabel(tag)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableWrapper>
      </Paper>
    </Card>
  );
};

VideoTagTable.propTypes = {
  handleAddLabel: PropTypes.func.isRequired,
  handleSaveLabels: PropTypes.func.isRequired,
  handleSeekTo: PropTypes.func.isRequired,
  // history: PropTypes.number.isRequired,
  // instrumentDetails: PropTypes.object.isRequired,
  videoId: PropTypes.string.isRequired
};