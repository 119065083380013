import firebase from 'firebase';
import * as Schema from './mongoSchemas';
import { 
  EmmaUserSchemaProps,
  AnnotationSetSchemaProps

} from './mongoSchemas';

export enum SaveStatusTypes {
  INITIALIZE = 'INITIALIZE',
  SAVING = 'SAVING',
  DONE = 'DONE'
  // CANCELLING = 'CANCELLING'
}


export  async function ReadJsonFromUrl(url: string) {

  await fetch(url)
    .then(res => res.json())
    .then( (out) => {
      console.log('Json output:', out);
      return out;
    })
    .catch(err => {throw err;});
    
}
/// Hodge podge of properties/class/types that are used throught out the app/////

export type SignInError = {
  code: string,
  message: string
}

export type OTPlanProps = {
  //   parentemail: payload.parentemail,
  //   plannotes: payload.plannotes,
  //   createdby: authUser.uid,
  //   videos: this.state.selected
}

export type ErrorInformation = {
  misc?:any,// Misc info, design is lacking
  message:string,
  // Property used for error check, hack. the value doesn't matter
  // just the presence 
  isErrorType:boolean
}
export type FirebaseUserProps = {
  // From the authentication service (firebase)
  displayName: string;
  email: string;
  uid: string;
  photoURL: string;
  bearerToken: string;
  firebaseUserObject: any;
}

// I think this is what gets passed around the UI
export type UserProps = {
  // firebaseUser will be undefined if the user is not
  // the one that is current logged in.
  firebaseUser?:FirebaseUserProps;
  // UI related property, can probably get factored out.
  isAuthenticated: boolean;
  studyid: string,
  phoneNumber:string,
  authenticationInProcess: boolean;
  // Properties that match database names
  // I would expect these to be replaced by EmmaUserSchemaProps
  // for consistancy
  emmauserId?: string;
  created?: Date; // hmm should this be undefined?
  firstname: string;
  lastname: string;
  authLevel: number;
  sharedEmails: string;
  deviceIds:string[];
  localDisplayName?: string;
  localEmail?:string;
  //hack storage of firebase userId, a quick way of getting the firebase id
  // but not really sure when it is important/used
  storedUid?:string; 
  notes: string; // not directly in db: comes from additionalProp1:{comments:'ddsf'}
  signature?:string;
  // The subject associated with the user, meh, hackish for sure
  subject?:Schema.SubjectSchemaProps;
};

export const emptyFirebaseUserState:FirebaseUserProps = {
  displayName:'',
  email:'',
  uid:'',
  photoURL:'',
  bearerToken:'',
  firebaseUserObject:null
};

// This is largely as a hack way of avoiding storing downloaded data
// The mostly bad plan is to load everything at the start of the app
// A better plan would be to load data as needed (base on the displayed page)
// and store the data to session/local storage or cache for fast retrieval.
// Should only use this for compare, if you need the values use {...emptyUserState}
export const emptyUserState:UserProps = {
  firebaseUser:emptyFirebaseUserState,
  firstname:'', 
  lastname:'', 
  emmauserId:undefined,
  authLevel:-1, //-1== unregistered, 0== normal user, 10===admin
  studyid: '',
  phoneNumber:'',
  created:undefined,
  notes:'',
  sharedEmails:'',
  deviceIds:[],
  isAuthenticated:false,
  authenticationInProcess:false
  // signature:''//remove this
  // subject:undefined
};

export interface AssessmentCreationFeedBackUIProps{
  bytesTransferred:number,
  totalSize:number,
  fileName: string,
  videoTotal:number
  currentVideoCount:number,
  genericMessage?:string
}

export interface AnnotationSetFromApi extends AnnotationSetSchemaProps {
  emmaUser?:EmmaUserSchemaProps// why does/did this need user props? //clean up laters
}
export interface AssessmentPayloadProps extends Schema.AssessmentSchemaProps {
  ownerId:string;
  notes:string;
  existingVideoIdsToUse:string[];
  videosForUpload:any[];
  videosThumbnailsForUpload?:any[];
  subjectDob:Date,
  parentEmail:string,
  assessmentId?: string;
}

export type ObservationTypeProps = {
  assessmentId: string,
  observationId: string,
  videoId: string
};
// For the assessment table
export type AssessmentListUIProps = {
  assessmentId:string,
  parent:string, // This is the parent email.
  parentId:string, // needed for updating
  date:Date, // not sure what this date is for
  assessor?:string,
  dob:Date, // dob of subject
  notes:string,
  observationCount:number,
  observations:ObservationForAsmtListUIProps[],
  reports?:Schema.ReportSchemaProps[],
  // dlp got tired of ticky tacky props 
  subject?:Schema.SubjectSchemaProps
  //observations:ObservationSchemaProps[]
};

// This is basically a video...actually it is a video
// It would be better if it was an observation but based
// on current usage it is just a video
export type ObservationForAsmtListUIProps = {
  observationId:string,
  timestamp:Date,
  url:string,
  thumbnailurl:string,
  videoId:string,
  duration:number,
  tagCount:number,
  hasBeenViewed:boolean,
};

export const emptyObservationUIState:ObservationForAsmtListUIProps = {
  observationId:'',
  timestamp:new Date(),
  url:'',
  thumbnailurl:'',
  videoId:'',
  duration:0,
  tagCount:0,
  hasBeenViewed:false,
};

export function createFirebaseUser(firebaseUser:any, bearerToken=''):FirebaseUserProps{
  // TS cares, but it doesn't care THAT much about types
  // so ugly dlp, why are you doing this???
  const initialUser:FirebaseUserProps = {...emptyFirebaseUserState};
  initialUser.photoURL = firebaseUser.photoURL;
  initialUser.uid = firebaseUser.uid;
  initialUser.email = firebaseUser.email;
  initialUser.displayName = firebaseUser.displayName;
  initialUser.bearerToken = bearerToken;
  initialUser.firebaseUserObject = firebaseUser;
  // // There is also a refreshToken...
  return initialUser;
}

// Could use a good old fashioned test. 
// The rawUserData is expected to come from mongodb and have 
// the type that comes from the api. types are a bit messy
// since the api can get related types it could have other types
// and it could also be a partial
export function convertUserDataToUserPropType(rawUserData:any):Partial<UserProps>{
  if(!rawUserData) return {};
  // Minor house keeping, not sure why any more though
  let userData = rawUserData;
  // Really don't know why this is here or when this would even happen. dlp
  if(typeof rawUserData === 'string') {
    // userData =JSON.parse(rawUserData);
    userData = JSON.parse(rawUserData); 
  }
  // In case of empty data, don't panic, just return empty
  if(Object.keys(userData).length === 0) return {...emptyUserState};

  //Clear out the props (or possible props) from the db that don't have a direct match
  // Since the type props and properties returned from the db match
  // not much is needed. but some work does need to be done to get them to match
  const {additionalProp1, email, subject, studyid, phoneNumber, ...matchingData} = userData;
  // console.log('Arge', email, 'split', matchingData);
  const userProp:Partial<UserProps> = {...matchingData };
  userProp.studyid = studyid;
  userProp.phoneNumber = phoneNumber;
  if(additionalProp1 && additionalProp1.comments){
    const notes = additionalProp1.comments;
    userProp.notes = notes;
  }
  if(email){
    userProp.localEmail = email;
  }
  // But there could be a subject in the mix.
  if(subject){
    const {additionalProp1, ...subjectData} = subject;
    if(additionalProp1 && additionalProp1.notes){
      subjectData.notes = additionalProp1.notes;
    }
    userProp.subject = subjectData;
  }

 
  // Fairly sure this is note needed any more. if we need a whole new user
  // that should be done in a different function, this just converts property names
  // from MongoSchema to UserProp 
  // // The user is in the db, but not registered, not even sure 
  // // if this happens ... tests anyone? This could happen
  // // when an admin user creates a non-registered user
  // if(userData.authLevel !== undefined && userData.authLevel < 0 ){
    
  //   const newUserProp:UserProps = {...emptyUserState, ...userProp };
  //   return newUserProp;
  // }
  return userProp;
}



////// Information about the mongodb schemas

// Observation mongo schema
// "timestamp": "2020-09-29T09:56:31.015Z",
// "duration": 0,
// "notes": "string",
// "needs_review": true,
// "subjectId": "string",
// "videoId": "string",
// "assessmentId": "string",
// "additionalProp1": {}

// Schema from mongodb
// {
//   "emmauserId": "string",
//   "email": "string", //must match firebase email
//   "uid": "string", // From firebase
//   "authLevel": 0,
//   "pushSubscription": "string",
//   "firstname": "string",
//   "lastname": "string",
//   "created": "2020-09-28T05:08:53.636Z",
//   "deviceIds": [
//     "string"
//   ],
//   "sharedEmails": "string",
//   "additionalProp1": {} //additionalProp1:{notes:'ddsf'}
// }

// Mongo schema for subject.
// {
//   subjectId	string
//   name*	string
//   gender	string
//   dob*	string($date-time)
//   duedate*	string($date-time)
//   emmauserid*	string
//     additionalProp1: {notes:notes},
//   }