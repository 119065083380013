import React, { useRef, useState, useEffect } from 'react';

import styled from 'styled-components';
import {Redirect} from 'react-router-dom';

import { spacing } from '@material-ui/system';
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  IconButton,
  Menu,
  MenuItem,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  Snackbar
} from '@material-ui/core';

import {
  Delete,
  VideocamTwoTone,
  CloudDownload,
  AddCircleOutlined
} from '@material-ui/icons';

import AssessmentTableHead from './AssessmentTableHead';
import AssessmentTableToolbar from './AssessmentTableToolbar';
import {AssessmentDetails} from '../../../dialogs/AssessmentDetails';
import {AssessmentDeleteDialog} from '../../../dialogs/AssessmenteDeleteDialog';
import {stableSort, getSorting} from '../../../common/tableUtilities';
import {AssessmentRecordDialog} from '../../../dialogs/AssessmentRecordDialog/AssessmentRecordDialog';

import * as RouteNames from '../../../common/routeNames';
import { useUserState } from '../../../context/UserContext';
import {
  useAsmtTableDispatch,
  useAsmtTableState,
  deleteAssessmentAndUpdate,
  AssessmentListActionTypes,
  adjustAvailableAsmts
} from '../../../context/AssessmentListContext';

import { ReportInfoActionTypes, 
  ReportInfoContext,
  convertReportSchemaToContext 
} from '../../../context/ReportContext';

import { 
  GeneralControlContext,
  GeneralControlActionTypes
} from '../../../context/GeneralControlContext';

import {
  AssessmentInfoActionTypes,
  AssessmentInfoContext
} from '../../../context/AssessmentInfoContext';

// import { getInstruments } from '../../../common/apiGetUtilities';
import {createEmailText} from '../../../common/reportUtilities';

import {ConfirmationDialog} from '../../../dialogs/AssessmentRecordDialog/ConfirmationDialog';

const CardContent = styled(MuiCardContent)(spacing);
const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);
const TableWrapper = styled.div`
overflow-y: auto;
max-width: calc(100vw - 12px);
`;
// max-width: calc(100vw - ${props => props.theme.spacing(12)}px);

const AssessmentInfoTable = (props) => {
  // Global
  const user = useUserState();
  const asmtTableDispatch = useAsmtTableDispatch();
  const {reportInfoDispatch} = React.useContext(ReportInfoContext);
  const {generalControlState, generalControlDispatch} = React.useContext(GeneralControlContext);
  const tableSearchState = useAsmtTableState();
  const {allAssessments, statusMessage, subjectIdForAsmt } = tableSearchState;
  const {updateAssessmentWithDialog } = props;

  const {assessmentInfoState, assessmentInfoDispatch} = React.useContext(AssessmentInfoContext);
  const {instrumentLibraries} = assessmentInfoState;
  // Local
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [openRecord, setOpenRecord] = React.useState(false);

  const [selectedAssessment, setSelectedAssessment] = useState(undefined);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('parent');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [redirect, setRedirect] = useState(null);
  const [toasterOpen, setToasterOpen] = useState(false);
  const [toasterMessage, setToasterMessage] = useState('No videos found in that date range');

  // const [assessmentDetailsOpen, setAssessmentDetailsOpen] = useState(false);
  // const [instruments, setInstruments] = useState(null);
  const [anchorElement, setAnchorEl] = useState(null);
  const [currentAssessment, setCurrentAssessment] = useState(null);
  const [recordConfirm, setRecordConfirm] = useState(false);
  const [recordConfirmationMessage, setRecordConfirmationMessage] = useState('');
  const [recordConfirmationTitle, setRecordConfirmationTitle] = useState ('');
  const reportMenuOpen = Boolean(anchorElement);
  const detailsPageElement = useRef();  

  useEffect(()=>{
    console.log('Search needs refresh:', tableSearchState.needListRefresh);
    if(tableSearchState.needListRefresh){
      adjustAvailableAsmts(AssessmentListActionTypes.UPDATE, tableSearchState, user.firebaseUser, asmtTableDispatch);
    }
  }, [tableSearchState.needListRefresh]);

  useEffect(()=>{
    setToasterMessage(statusMessage);
    setToasterOpen(true);
  },[statusMessage]);
  
  const handleRequestSort = (event, property) => {
    const orderByProp = property;
    let orderProp = 'desc';

    if (orderBy === property && order === 'desc') {
      orderProp = 'asc';
    }
    setOrder(orderProp);
    setOrderBy(orderByProp);
  };

  // Move this function to the main context
  const handleSelectAllClick = event => {
    if (event.target.checked) {
      // emmaVideosDispatch({type:VideoListActionTypes.SELECT_VIDEOS});
    }else{
      // emmaVideosDispatch({type:VideoListActionTypes.UNSELECT_VIDEOS});
    }
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  const handleAssessmentDetails = async (assessment) =>  {

    // if (instruments == null) {
    //   setInstruments(await getInstruments(user));
    // }
    // The data is a single AssessmentListUIProps

    console.log('Selected asmt: ', assessment);
    setSelectedAssessment(assessment);
    // the new way of doing things? maybe? hopefully?
    assessmentInfoDispatch({type:AssessmentInfoActionTypes.ACTIVE_ASMTID, activeAsmtId:assessment.assessmentId});
    // setAssessmentDetailsOpen(true);
    generalControlDispatch({type:GeneralControlActionTypes.ASMTINFO_DIALOG, 
      isAsmtDialogVisible:true}); 

  };

  useEffect( ()=>{
    // console.log('allAssessments modified');
    let index =  allAssessments.indexOf(selectedAssessment);
    if (index >= 0){
      setSelectedAssessment(allAssessments[index]);
    }
  }, [allAssessments]
  );

  // // why was this in here???? -dlp
  // const handleAssessmentDetailsSave = (value) => {
  //   patchAssessment(user.firebaseUser, value); // the parent may need to call adjustAvailableAsmts
  // };

  // moved
  // const handleAssessmentDetailsClose = (value) => {
  //   // setAssessmentDetailsOpen(false);
  //   generalControlDispatch({type:GeneralControlActionTypes.ASMTINFO_DIALOG, 
  //     isUserInfoDialogVisible:false}); 

  //   //kind of lame but oh well. -jo
  //   // agreed on the lame, but needed since all actions based off the table depend
  //   // on the info stored in the table instead of getting it fresh
  //   adjustAvailableAsmts(AssessmentListActionTypes.UPDATE, startDate, endDate,
  //     user.firebaseUser, asmtTableDispatch);
  // };

  const handleAssessmentsClick = event => {
    console.log('Items selected for assessment creation:',this.state.selected);
    setRedirect(RouteNames.ASSESSMENTS);
  };

  const handleEditVideos = event => {
    setRedirect(RouteNames.VIDEO_TASKS);
  };

  const handleAssessmentEdit = (data, event) =>{
    if(updateAssessmentWithDialog){
      console.log('update assessment with: ', data);
      updateAssessmentWithDialog(data);
    }
    if(event) {
      event.stopPropagation();
    }
  };
  const handleAssessmentDelete = (data, event) =>{
    setOpenConfirm(true);
    console.log('Delete asmt? ', data);
    setSelectedAssessment(data);
    event.stopPropagation();
  };

  const handleAssessmentRecord = (data, event) =>{
    setOpenRecord(true);
    console.log('Record asmt: ', data);
    event.stopPropagation();
  };

  const handleRecordingConfirmation = (data, event) => {
    console.log('Raising confirmation dialog:', data);
    if (data) {
      setRecordConfirmationMessage('Child ('+ data.subject?.name + ') with DOB:' + new Date(data.dob).toDateString() + ' ?');
      setRecordConfirmationTitle('Record for parent: ' + data.parent );
    }
    console.log('Conf message: ', recordConfirmationMessage);
    setRecordConfirm(true);
    setSelectedAssessment(data);
    event.stopPropagation();
  };

  const onRecordCancel = () =>{
    console.log('yeah, no on the record');
    setRecordConfirm(false);
  };

  const onRecordConfirm = () => {
    console.log('Record asmt confirmed:', selectedAssessment);
    handleAssessmentRecord(selectedAssessment);
    setRecordConfirm(false);
  };

  const handleMenuClose = (event) => {
    setAnchorEl(null);
    if (event) {event.stopPropagation();}
  };

  const handleDownloadReport = (event) => {
    console.log('handle download report:', currentAssessment);
    if (currentAssessment){
      if(currentAssessment.reports && currentAssessment.reports.length >0){
        const reports = currentAssessment.reports;
        reports.sort((a,b) =>  new Date(b.creationDate) - new Date(a.creationDate));
        // Set the current report to the most recent one of the current assessment
        convertReportSchemaToContext(currentAssessment.reports[0], reportInfoDispatch);
        reportInfoDispatch({type:ReportInfoActionTypes.UPDATE_REPORTDIALOG, 
          isReportPreviewVisible:true}); 

      // for(const pdfReport of currentAssessment.reports){
      //   if(pdfReport.reportFileURL){        
      //     const w = window.open(pdfReport.reportFileURL,  '_blank');// open in new tab
      //   }       
      // }
      }
    }
    handleMenuClose();
    event.stopPropagation();
  };

  // const handleEmailReport = (event) => {
  //   console.log('handle email report:', currentAssessment);
  //   if (currentAssessment){
  //     if(currentAssessment.reports && currentAssessment.reports.length >0){
  //       for(const pdfReport of currentAssessment.reports){

  //         const emailToSend = createEmailText(pdfReport);
  //         if(emailToSend){
  //           const w = window.open(emailToSend, '_blank');
  //         }
  //         // if(pdfReport.reportFileURL && pdfReport.reportData){       
  //         //   const parentName =  pdfReport.reportData.parentName;
  //         //   const asmtDateString = new Date(pdfReport.reportData.assessmentDate).toDateString();
  //         //   const asmtSubjectName = pdfReport.reportData.asmtSubjectName;
  //         //   const mailHeading = 'Motor Assessment Report: ' + asmtDateString + ' visit';
  //         //   const body = '<p>Dear <strong>' + parentName + '</strong>:</p> Thank you for helping us with our project! \
  //         //   Attached is the report from the visit on:  &nbsp;<strong>' + asmtDateString + '</strong>' +
  //         //    '. If you have any questions, let me know.\
  //         //    Thanks again.\
  //         //    <p>Here is the link to <strong>' + asmtSubjectName+ '\'s </strong> motor evaluation report (in PDF): <a href="' + pdfReport.reportFileURL + '"> <b>report link</b></a></p>';
  //         //   console.log('Body:', body);
  //         //   const w = window.open('mailto:' + currentAssessment.parent + '?subject=' + mailHeading + '&body=' + encodeURIComponent(body),  '_blank');// open in new tab
  //         // }       
  //       }
  //     }
  //   }
  //   handleMenuClose();
  //   event.stopPropagation();
  // };

  const handleAssessmentDownload = (assessment, event) =>{
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setCurrentAssessment(assessment);
    console.log('Stop event propagation');
  };  

  const handleConfirmationClose = (confirmationValue, videoToDelete) =>{
    setOpenConfirm(false);
    if(confirmationValue === true){
      console.log('Delete asmt:', videoToDelete);

      deleteAssessmentAndUpdate(asmtTableDispatch,user.firebaseUser, videoToDelete)
        .then( (result)=> {
          if (!result) {
            console.log('Asmt Delete failed');
            return;
          }
          adjustAvailableAsmts(AssessmentListActionTypes.UPDATE,tableSearchState,user.firebaseUser,asmtTableDispatch);

        });
    }   
  };

  const handleRecordClose = (confirmationValue, assessmentToRecord, blob) =>{
    console.log('handle record close');
    setOpenRecord(false);
  };


  const handleToasterClose = (event,reason) => {
    if (reason === 'clickaway') {
      return;
    }
    console.log('toaster close reason:', reason);
    setToasterOpen(false);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, allAssessments.length - page * rowsPerPage);

  // Is this ever used?
  if (redirect) {
    return <Redirect to={redirect} />; // Back to?
  }
  if (user.authLevel < 0){
    return (
      <Typography variant="h5">
        Hmmm...you may not yet have content here.
      </Typography>
    );
  }
  return (
    <Card mb={6}>
      <CardContent pb={0}>
        <Typography
          gutterBottom
          variant="h5"
        >
          Motor Assessments Gallery
        </Typography>
        {user.authLevel > 0 &&
          <Typography
            gutterBottom
            variant="body2"
          >
            Assessments displayed below. Start by filtering assessments using <b>start date</b> and <b>end date</b> pickers below 
            and clicking the <b>Show</b> button.
          </Typography>
        }

        {user.authLevel == 0 &&
          <Typography
            gutterBottom
            variant="body2"
          >
            Your assessments are displayed below. If you want to filter by date, uncheck <b>Include all dates.</b>
          </Typography>
        }
      </CardContent>
      <Paper>
        <AssessmentTableToolbar
          user = {user}
          daysBack = {props.daysBack}
          handleAssessments = {handleAssessmentsClick}
          handleEditVideos = {handleEditVideos}
          // handleTagVideo = {handleTagVideo}
        />
        <TableWrapper>
          <Table aria-labelledby="tableTitle">
            <AssessmentTableHead
              // numSelected={selectedVideoIds.length}
              onRequestSort={handleRequestSort} 
              onSelectAllClick={handleSelectAllClick}
              order={order}
              orderBy={orderBy}
              rowCount={allAssessments.length}
            />
            <TableBody>
              {allAssessments && 
                stableSort(allAssessments, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(assessment => {
                    const idToUse = assessment.assessmentId ? assessment.assessmentId: assessment.id;
                    
                    return (
                      <TableRow
                        // aria-checked={isSelected}
                        hover
                        key={idToUse}
                        onClick = {()=>{user.authLevel > 0 && handleAssessmentDetails(assessment);}}
                        // selected={isSelected}
                        tabIndex={-1}
                      >
                        <TableCell
                          align="center"
                          style={{width:'10%'}}
                        >
                          {assessment.reports !== undefined && 
                          (
                            <div>
                              <Tooltip title="Display/Email reports">
                                <IconButton onClick={(event)=>handleAssessmentDownload(assessment, event)}>
                                  <CloudDownload />
                                </IconButton>
                              </Tooltip>
                              <Menu
                                anchorEl={anchorElement}
                                id="fade-menu"
                                keepMounted
                                onClose={handleMenuClose}
                                open={reportMenuOpen}
                              >
                                <MenuItem onClick={handleDownloadReport}>View Report</MenuItem>
                                {/* <MenuItem onClick={handleEmailReport}>Email Report</MenuItem> */}
                                <MenuItem onClick={handleMenuClose}>Close</MenuItem>
                              </Menu>
                            </div>                            
                          )
                          }
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{width:'20%'}}
                        >{new Date(assessment.date).toLocaleDateString() }</TableCell>
                        <TableCell
                          align="right"
                          style={{width:'20%'}}
                        >{new Date(assessment.dob).toLocaleDateString() }</TableCell>
                        
                        <TableCell style={{width:'15%'}} >{assessment.parent}</TableCell>
                        {/* <TableCell
                          align="center"
                          style={{width:'10%'}}
                        >
                          <IconButton
                            disabled = {user.authLevel === 0}
                            color="primary"
                            onClick={(event)=>handleRecordingConfirmation(assessment, event)}
                          >
                            <VideocamTwoTone/>
                          </IconButton>
                        </TableCell> */}

                        <TableCell
                          align="center"
                          style={{width:'10%'}}
                        ><IconButton onClick={(event)=>handleAssessmentEdit(assessment, event)}><AddCircleOutlined/></IconButton></TableCell>
                    
                        <TableCell
                          align="center"
                          style={{width:'10%'}}
                        >
                          <IconButton 
                          // disabled = {user.authLevel === 0} 
                          onClick={(event)=>handleAssessmentDelete(assessment, event)}>
                            <Delete/>
                          </IconButton>
                        </TableCell>
                        
                      </TableRow>
                    );
                  })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableWrapper>
        <TablePagination
          backIconButtonProps={{
            'aria-label': 'Previous Page'
          }}
          component="div"
          count={allAssessments.length}
          nextIconButtonProps={{
            'aria-label': 'Next Page'
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Paper>
      <Snackbar
        autoHideDuration={4000}
        message = {toasterMessage} 
        onClose={handleToasterClose}
        open={toasterOpen}
      />
      <AssessmentDeleteDialog
        assessmentProp={selectedAssessment}
        onClose={handleConfirmationClose}
        open={openConfirm}
      />
      <AssessmentRecordDialog 
        assessmentProp={selectedAssessment}
        onClose={handleRecordClose}
        open={openRecord}
      />
      { generalControlState.isAsmtDialogVisible &&
      // Without the assessmentDetailsOpen the dialog gets created
      // and initialized, but usually without the selected assessment
      // so there are issues.
        <AssessmentDetails 
          asmtData={selectedAssessment}
          handleAssessmentEdit={handleAssessmentEdit}
          // instruments={instrumentLibraries}
          // isCreateAsmtDialogOpen = {isCreateAsmtDialogOpen}
          // onClose={handleAssessmentDetailsClose} 
          // onRefresh = {handleAsmtRefresh} 
          // onSave={handleAssessmentDetailsSave}
          
          // open={generalControlState.isAsmtDialogVisible}
        />
      }

      <ConfirmationDialog
        cancelText = "Cancel"
        confirmText = "Proceed"
        message = {recordConfirmationMessage}
        onCancel = {onRecordCancel}
        onConfirm = {onRecordConfirm}
        open={recordConfirm}
        title = {recordConfirmationTitle}
      />
    </Card>
  );
};
export default AssessmentInfoTable;