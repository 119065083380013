import React, {useState} from 'react';

import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  Divider,
  Paper,
  Tabs,
  Tab
} from '@material-ui/core';

import { TagTabPanel } from './components/TagTabPanel';
import { useUserState } from '../../context/UserContext';

// dlp wonders what this is?
export const VideoTagDetails = (props) => {
  
  const { videoData, onClose, open } = props;

  const user = useUserState();
  //mutable state
  const [selected, setSelected] = useState(0);

  //constants
  const fullWidth = true;
  const maxWidth = 'lg';
  const timestamp = new Date(videoData ? videoData.timestamp : 0).toLocaleString();

  const handleTabChange = (event, newValue) => {
    setSelected(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `tagtabpanel-${index}`
    };
  };
  
  return (
    <Paper mt={4}>
      <React.Fragment>             
        <Dialog
          aria-labelledby="max-width-dialog-title"
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          onClose={onClose}
          open={open}
        >
          <Paper>
            <Tabs 
              aria-label="Video"
              centered
              indicatorColor="primary" 
              onChange={handleTabChange} 
              value={selected}
            >
              <Tab
                label="Video"
                {...a11yProps(0)}
              />
              <Tab
                label="Notes"
                {...a11yProps(1)}
              />              
            </Tabs>
          </Paper>
          <Divider my={6} />
          <TagTabPanel
            index={0}
            timestamp = {timestamp}
            user={user}
            value={selected}
            videoData={videoData}
          />
          <TagTabPanel
            index={1}
            user={user} 
            value={selected}
            videoData={videoData}
          />
          <DialogActions>
            <Button
              color="primary"
              onClick={onClose}
            >
              Close
            </Button>
          </DialogActions>
          <Card>
            <CardContent />
          </Card>
        </Dialog>
      </React.Fragment>
    </Paper>
  );
};
