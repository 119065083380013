
import * as Schemas from './mongoSchemas';

export const simpleNonAuthUser:Schemas.EmmaUserSchemaProps[] = [
  {
    emmauserId: '5ec864134e22ef484edee6b8',
    email: 'ajheckathorn@gmail.com',
    uid: 'psIdSm6v93XqnH09TZlBuYHDn8C3',
    authLevel: 0,
    firstname: 'Anna',
    lastname: 'Heckathorn',
    created: new Date('2020-06-17T03:23:37.000Z'),
    deviceIds: [
      '57K28BSU82047',
      '568285SB641AD'
    ],
    sharedEmails: '',
    additionalProp1: {
      comments: ''
    },
    subjects: [
      {
        subjectId: '5eead3bc8b1b63f899828683',
        name: 'Em',
        gender: 'F',
        dob: new Date('2020-06-08T07:00:00.000Z'),
        duedate: new Date('2020-06-07T07:00:00.000Z'),
        emmauserid: '5ec864134e22ef484edee6b8',
        additionalProp1: {},
        assessments: [
          {
            assessmentId: '5fbd8900c7d6cb00035fddca',
            date: new Date('2020-09-29T21:27:00.000Z'),
            assessor: 'study@earlymarkers.com',
            assessmentName: 'New Assessment',
            notes: 'home videos from 1 week prior to 6 month assessment',
            subjectId: '5eead3bc8b1b63f899828683'
          }
        ]
      }
    ]
  },
  {
    emmauserId: '5ec864134e22ef48feedee2b8',
    email: 'randome@gmail.com',
    uid: 'psIdSm6v93XqnH09TZlBuYHDn8C3',
    authLevel: 0,
    firstname: 'Other',
    lastname: 'Name',
    created: new Date('2020-06-17T03:23:37.000Z'),
    deviceIds: [
      '57K28BSU82047',
      '568285SB641AD'
    ],
    sharedEmails: '',
    additionalProp1: {
      comments: ''
    },
    subjects: [
      {
        subjectId: '5eead3bc8b1b63f899828683',
        name: 'Em',
        gender: 'F',
        dob: new Date('2020-06-08T07:00:00.000Z'),
        duedate: new Date('2020-06-07T07:00:00.000Z'),
        emmauserid: '5ec864134e22ef484edee6b8',
        additionalProp1: {},
        assessments: [
          {
            assessmentId: '5fbd8900c7d6cb00035fddca',
            date: new Date('2020-09-29T21:27:00.000Z'),
            assessor: 'study@earlymarkers.com',
            assessmentName: 'New Assessment',
            notes: 'home videos from 1 week prior to 6 month assessment',
            subjectId: '5eead3bc8b1b63f899828683'
          }
        ]
      }
    ]
  }
];