// import * as Utils from './services/utils';
// import axios from 'axios';
// import AuthStore from './stores/AuthStore';
import { setSubscriptionInfo } from './apiSetUtilities';

const pushServerPublicKey = 'BMreQ6qzUP4g_Tsoxm67jMFTSv8suilkdKplyiWNu3hYVeXksAUmIG-7m4J2fPekhixaM3pzh0op8cCi3RswzdU';

/**
 * checks if Push notification and service workers are supported by your browser
 */
function isPushNotificationSupported() {
  return 'serviceWorker' in navigator && 'PushManager' in window;
}

/**
 * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
 */
async function askUserPermission() {
  return await Notification.requestPermission();
}

/**
 * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
 */
function initializePushNotifications() {
  // request user grant to show notification
  return Notification.requestPermission(function(result) {
    return result;
  });
}
/**
 * shows a notification
 */
function sendNotification() {
  const img = '/images/jason-leung-HM6TMmevbZQ-unsplash.jpg';
  const text = 'Take a look at this brand new t-shirt!';
  const title = 'New Product Available';
  const options = {
    body: text,
    icon: '/images/jason-leung-HM6TMmevbZQ-unsplash.jpg',
    vibrate: [200, 100, 200],
    tag: 'new-product',
    image: img,
    badge: 'https://spyna.it/icons/android-icon-192x192.png',
    actions: [{ action: 'Detail', title: 'View', icon: 'https://via.placeholder.com/128/ff0000' }]
  };
  navigator.serviceWorker.ready.then(function(serviceWorker) {
    serviceWorker.showNotification(title, options);
  });
}
/**
 * 
 */
function registerServiceWorker() {
  navigator.serviceWorker.register('/sw.js').then(function(swRegistration) {
    // console.log('registered ' + swRegistration);
    //you can do something with the service wrker registration (swRegistration)
  });
  // return navigator.serviceWorker.register('/sw.js')
}

/**
 *
 * using the registered service worker creates a push notification subscription and returns it
 *
 */
async function createNotificationSubscription() {
  //wait for service worker installation to be ready
  const serviceWorker = await navigator.serviceWorker.ready;
  // subscribe and return the subscription
  return await serviceWorker.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: pushServerPublicKey
  });
}

/**
 * returns the subscription if present or nothing
 */
function getUserSubscription() {
  //wait for service worker installation to be ready, and then
  return navigator.serviceWorker.ready
    .then(function(serviceWorker) {
      return serviceWorker.pushManager.getSubscription();
    })
    .then(function(pushSubscription) {
      return pushSubscription;
    });
}

/**
 * define a click handler that sends the push susbcription to the push server.
 * Once the subscription is created on the server, it saves the id using the hook setPushServerSubscriptionId
 */
async function sendSubscriptionToPushServer(userSubscription, bearerToken, uid) {
  const userSubscriptionValue = await userSubscription;
  const rawInformation = JSON.stringify(userSubscriptionValue);
  setSubscriptionInfo(rawInformation, uid, bearerToken);
  // let authUser = AuthStore.getUser();
  // console.log('Auth user:', authUser);


  // let token = AuthStore.getBearerToken();
  // let bearertoken = 'Bearer ' + token;

  // if (authUser == null) {
  //   console.log('No authenticated user...no subscription sent');
  //   return;
  // }

  // axios.defaults.headers.common = {
  //   'Authorization': bearertoken,
  //   'uid': authUser.uid
  // };

  // let userSubscriptionValue = await userSubscription;
  // let apiendpoint = Utils.getScoreitApiEndpoint() + '/subscription';
  // var raw = JSON.stringify(userSubscriptionValue);
  // axios
  //   .request({ method: 'POST', url: apiendpoint, headers: { 'Content-Type': 'application/json;charset=UTF-8' }, data: raw })
  //   .then(function (response) {
  //     console.log(response.id);
  //     //setPushServerSubscriptionId(response.id);
  //   })
  //   .catch(err => {
  //     console.log(err);
  //     //setError(err);
  //   });
}
  
export {
  isPushNotificationSupported,
  askUserPermission,
  registerServiceWorker,
  // sendNotification,
  createNotificationSubscription,
  getUserSubscription,
  sendSubscriptionToPushServer
};