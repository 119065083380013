import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Redirect} from 'react-router-dom';

import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';

import Loading from './components/Loading';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { spacing } from '@material-ui/system';

import { makeStyles } from '@material-ui/styles';

import { green, blue, orange, yellow, blueGrey, lightBlue } from '@material-ui/core/colors';
import {
  Activity,
  Info,
  Smile,
  Video,
  UserPlus,
  Film
} from 'react-feather';
import Actions from './components/Actions';
import LineChart from './components/LineChart';
import DashboardDoughnutChart from './components/DashboardDoughnutChart';
import Stats from './components/Stats';
import { RecentAsmtCard} from './components/cards/RecentAsmtCard';
import DashboardTable from './components/DashboardTable';
import { AsmtAndUserControlComponent } from '../assessments/components/AsmtAndUserControlComponent';

import OTPlanCard from './components/OTPlanCard';
import { useUserState } from '../../context/UserContext';
import * as RouteNames from '../../common/routeNames';
import {getDevicesHealth, getIndividualOTPlans} from '../../common/apiGetUtilities';

import * as AppRoutes from '../../common/routeNames';
import useStyles from './styles';

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const currentDate = new Date();



function Dashboard(props)  {


  const classes = useStyles();
  //This is terrible usage of the useUserState()...find some way
  //to use the built in tracker for its state
  const [emmaUser] = useState(useUserState());
  const [redirect, setRedirect] = useState(null);
  // const [authUser] = useState('');
  // individual otplans, not the lot of them
  const [otplans, setOtplans] = useState([]);
  const [devicesHealth, setDevicesHealth] = useState([]);
  const [authLevel, setAuthLevel] = useState(0);

  const registerPage = AppRoutes.EMMA_USER_SETINGS;

  // Once all is settle 
  useEffect(()=>{
    async function updateWait(){
      const otPlans = await getIndividualOTPlans(emmaUser.firebaseUser, emmaUser);
      setOtplans(otPlans);
      const devices = await getDevicesHealth(emmaUser);
      setDevicesHealth(devices);
    }
    setAuthLevel(emmaUser.authLevel);
    updateWait();
  },[]);


  const daysBackHandler = (daysBack) => {

    setRedirect({pathname:RouteNames.EMMA_VIDEOS, state:{backpage:RouteNames.DASHBOARD, daysBack:daysBack}});
  };

  const familyHandler = () => {
    setRedirect({pathname:RouteNames.EMMA_USER_SETINGS, state:{backpage:RouteNames.DASHBOARD}});
  };

  const toFamilyPageHandler = () =>{
    setRedirect({pathname:RouteNames.ADMIN_TASKS, state:{backpage:RouteNames.DASHBOARD, initialAuthFilter:0}});
  };
  const devicesHealthList = devicesHealth.map((deviceHealth) => <li key={deviceHealth.deviceName}>{deviceHealth.deviceName} last uploaded a video at {new Date(deviceHealth.lastSeen._seconds*1000).toString()}</li>);
  if (redirect) {
    return <Redirect to={redirect}  />;
  }
  if (authLevel == 0){
    return (
        <React.Fragment>
        <Loading/>
          <Grid
            container
            justify="flex-start"
            spacing={4}
            className = {classes.paper}
          >
            <Grid
              item
              md={6}
              sm={6}
              xs={6}
            >
              <Typography
                display="inline"
                variant="h5"
                color='secondary'
              >
                  Welcome back, {emmaUser.localDisplayName}
              </Typography>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <Typography
                display="inline"
                ml={2}
                variant="body2"
              >
                {new Date(currentDate).toDateString()}
              </Typography>
            </Grid>
          
            <Divider my={6} />

            <Grid container spacing={4}>
              <Grid
                item
                lg={3}
                md={6}
                sm={6}
                xl
                xs={12}
              >
                <RecentAsmtCard
                  color={orange[500]}
                  daysBack={30}
                  handleRedirect={setRedirect}
                  iconName={Activity}
                />
              </Grid>
              <Grid item xl lg={3} md={6} sm={6} xs={12}>

                <Stats
                  color={blue[500]}
                  daysBack = {30}
                  handler = {daysBackHandler}
                  icon={Video}
                  title="Previous videos"
                />
              </Grid>

              <Grid item xl lg={3} md={6} sm={6} xs={12}>

                <Stats
                  color={green[500]}
                  handler = {familyHandler}
                  icon={UserPlus}
                  title="Family details"
                />
              </Grid>

              <Grid item xl lg={3} md={6} sm={6} xs={12}>

                <Stats
                  color={orange[500]}
                  handler = {()=>{setRedirect(RouteNames.OT_VIDEOS);}}
                  icon={Film}
                  title="Activity plans"
                  amount={otplans.length}
                />
              </Grid>
            </Grid>

            <Divider my={8} />


            <Grid item xs={12} sm={12}>
            
            </Grid>
          </Grid>
        </React.Fragment>
    );
  }
  else if (authLevel > 0) {
    return (
      <React.Fragment>
        <Grid
          container
          justify="space-between"
          spacing={4}
        >
          <Grid
            item
            md={6}
            sm={6}
            xs={12}
          >
            <Typography
              display="inline"
              variant="h4"
            >
                Welcome back, {emmaUser.localDisplayName}
            </Typography>
            <Typography
              display="inline"
              ml={2}
              variant="body2"
            >
              {new Date(currentDate).toDateString()}
            </Typography>
          </Grid>
          <Grid 
            item 
            lg={3}
            md={6}
            sm={6}
            xl
            xs={12}
          >
            {authLevel >= 5 &&
              <Actions />
            }
          </Grid>
        </Grid>
        <Divider my={2} />
        <Typography
          align="center"
          color="secondary"
          onClick={()=>{setRedirect({pathname:RouteNames.ASSESSMENTS, state:{backpage:RouteNames.DASHBOARD}});}}
          variant="h5"
        >
          Assessments
        </Typography>
        <Divider my={2} />
        <Grid
          container
          spacing={6}
        > 
          {authLevel>=5  &&
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <Accordion>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  expandIcon={<ExpandMoreIcon />}
                  id="update-assessment-header"
                >
                  <Typography>Add Assessment/Family</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xl={12}
                    xs={12}
                  >
                    <AsmtAndUserControlComponent
                      assessmentToUpdate={null}
                    />
                  </Grid>

                </AccordionDetails>
              </Accordion>

            </Grid>
          }
          <Grid
            item
            lg={3}
            md={6}
            sm={6}
            xl
            xs={6}
          >
            <RecentAsmtCard
              daysBack={1}
              handleRedirect={setRedirect}
              // amount="2"
              // color={yellow[500]}
              // icon={Info}
              // title="Pending Notifications"
              // handler = {()=>{console.log("Notification handler");}}
            />
          </Grid>
          <Grid
            item
            lg={3}
            md={6}
            sm={6}
            xl
            xs={6}
          >
            <RecentAsmtCard
              daysBack={7}
              handleRedirect={setRedirect}
            />
          </Grid>
          <Grid
            item
            lg={3}
            md={6}
            sm={6}
            xl
            xs={6}
          >
            <RecentAsmtCard            
              handleRedirect={setRedirect}
              text="With no tags"
              withItems
            />
          </Grid>
          <Grid
            item
            lg={3}
            md={6}
            sm={6}
            xl
            xs={6}
          >
            <RecentAsmtCard
              handleRedirect={setRedirect}
              text="With no reports"
              withOther
            />
          </Grid>
          <Grid
            item
            lg={3}
            md={6}
            sm={12}
            xl
            xs={12}
          >
            <Stats
              amount="12"
              color={blue[500]}
              daysBack = {1}
              handler = {toFamilyPageHandler}
              icon={SupervisedUserCircleIcon}
              title="Family Details"
              // onClick={setRedirect({pathname:RouteNames.ADMIN_TASKS, state:{backpage:RouteNames.DASHBOARD}})}
            />
          </Grid>
        </Grid>
        
        <Divider my={6} />
        <Typography
          align="center"
          color="secondary"
          onClick={()=>{daysBackHandler(7);}}
          variant="h5"
          // onClick={()=>{this.setState({redirect:'/video7days'}); }}
        >
              Video Feeds
        </Typography>
        <Divider my={6} />
        <Grid
          container
          spacing={6}
        >
          <Grid
            item
            lg={3}
            md={6}
            sm={12}
            xl
            xs={12}
          >
            <Stats
              amount="12"
              color={blue[500]}
              daysBack = {1}
              handler = {daysBackHandler}
              icon={Video}
              title="Videos Today"
            />
          </Grid>
          <Grid
            item
            lg={3}
            md={6}
            sm={12}
            xl
            xs={12}
          >
            <Stats
              amount="520"
              color={orange[500]}
              daysBack = {7}
              // iconclickhandler={()=>{console.log("Icon clicked")}}
              handler = {daysBackHandler}
              icon={Video}
              title="Past 7 days"
            />
          </Grid>
          {authLevel===10  &&
                      <Grid
                        item
                        lg={3}
                        md={6}
                        sm={12}
                        xl
                        xs={12}
                      >
                        <Stats
                          amount="7"
                          color={green[500]}
                          icon={Smile}
                          title="Milestones"
                        />
                      </Grid>
          }
          <Grid
            item
            lg={3}
            md={6}
            sm={12}
            xl
            xs={12}
          >
            <Stats
              amount="2"
              color={yellow[500]}
              icon={Info}
              title="Pending Notifications"
              // handler = {()=>{console.log("Notification handler");}}
            />
          </Grid>
        </Grid>

        <Divider my={6} />
        <Typography
          align="center"
          color="secondary"
          onClick={()=>{setRedirect(RouteNames.OT_VIDEOS); }}

          variant="h5"
        >
              Activity Plans
        </Typography>
        <Divider my={6} />
        {
          otplans.length === 0 &&
            <Typography
              align="center"
              // color="black"//Gives warning, it is better to use theme
              variant="h6"
            >
            No custom activity plans yet
            </Typography>
        }
        {
          otplans.length > 0 && authLevel === 10 &&
            <Grid
              container
              spacing={6}
            >
              <Grid
                item
                md={4}
                sm={6}
                xs={12}
              >
                <OTPlanCard otplan={otplans.length > 0 ? (otplans[0]):([])}/>
              </Grid>
              <Grid
                item
                md={4}
                sm={6}
                xs={12}
              >
                <OTPlanCard otplan={otplans.length > 0 ? (otplans[0]):([])}/>
              </Grid>
              <Grid
                item
                md={4}
                sm={6}
                xs={12}
              >
                <OTPlanCard otplan={otplans.length > 0 ? (otplans[0]):([])}/>
              </Grid>
            </Grid>
        }
        {
          otplans.length > 0 && authLevel !== 10 &&
              <Grid
                container
                spacing={6}
              >
                <Grid 
                  item
                  sm={3}
                  xs={12}
                >
                  <Stats
                    amount={otplans.length}
                    color={blue[500]}
                    handler={()=>{setRedirect(RouteNames.OT_VIDEOS); }}
                    icon={Video}
                    title="Play Activity Plans"
                    // handler = {()=>{ this.setState({redirect:'/tables/otvideos-table'});}}
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  sm={6}
                  xs={6}
                >
                  <OTPlanCard otplan={otplans.length > 0 ? (otplans[0]):([])}/>
                </Grid>
              </Grid>
        }
        <Divider my={6} />
        <Typography
          align="center"
          color="secondary"
          variant="h5"
        >
            Development Metrics (Sample)
        </Typography>
        <Grid
          container
          spacing={6}
        >
          <Grid
            item
            lg={8}
            xs={12}
          >
            <LineChart />
          </Grid>
          {authLevel===10  &&
            <Grid
              item
              lg={4}
              xs={12}
            >
              <DashboardDoughnutChart />
            </Grid>
          }
        </Grid>
        <Divider my={6} />
        {authLevel===10  &&
          <div>
            <Typography
              align="center"
              color="secondary"
              variant="h5"
            >
            Milestones
            </Typography>
            <Grid
              container
              spacing={6}
            >
              <Grid
                item
                lg={8}
                xs={12}
              >
                <DashboardTable />
              </Grid>
            </Grid>
          </div>
        }
        {devicesHealthList.length > 0 && 
          <div>
            <Divider my={6} />
            <Typography
              align="center"
              color="secondary"
              variant="h5"
            >
              Device health status
            </Typography>
            <Grid
              container
              spacing={6}
            >
              <Grid
                item
                lg={8}
                xs={12}
              >
                <ul>
                  {devicesHealthList}
                </ul>
              </Grid>
            </Grid>
          </div>
        }
      </React.Fragment>
    );
  }
  else{
    return <Redirect to={registerPage} />;
  }
}
export default Dashboard;
// export default withTheme(withRouter(Dashboard));
