import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import {
  AppBar,
  Button,
  Toolbar,
  Typography,
  Grid, 
  Dialog,
  DialogTitle,
  DialogContent,
  Divider as MuiDivider
} from '@material-ui/core';

import { FlipCameraAndroidTwoTone, VideocamTwoTone, RadioButtonCheckedTwoTone, StopTwoTone} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';

import { CameraPreview} from '@awesome-cordova-plugins/camera-preview';
import {Toast} from '@capacitor/toast';
import { isAndroid, isIOS } from 'react-device-detect';

import { Filesystem } from '@capacitor/filesystem';
import {decode} from 'base64-arraybuffer';

import {
  useEmmaVideosState,
  useEmmaVideosDispatch,
  VideoListActionTypes
} from '../../context/EmmaVideosContext';

import {useUserState} from '../../context/UserContext';
import { AssessmentListUIProps, AssessmentPayloadProps, SaveStatusTypes, UserProps } from '../../common/entityUtilities';
import  './styles.css';


type CameraPreviewDialogProps = {
  isOpen:boolean,
  dialogComplete:()=>void,
  parentInformation:UserProps|null,
  setVideo:(x:any)=>void,
  asmtToEdit?:AssessmentListUIProps
}

type CameraPreviewDialogValues = {
  email:string,
  dob: Date,
  asmtdate:Date, //default asmt date is CURRENT DATE
  notes:string,
  parentemail:string,
  parentId:string,
  gestAgeWeeks:number
};

const defaultState:CameraPreviewDialogValues = {
  email:'',
  dob: new Date('2020-08-20T00:00:00.000Z'),
  asmtdate:new Date(), //default asmt date is CURRENT DATE
  notes:'',
  parentemail:'',
  parentId:'',
  gestAgeWeeks:0
};

// what a mess...
export default function CameraPreviewDialog(props:CameraPreviewDialogProps):JSX.Element {
  
  const emmaVideosDispatch = useEmmaVideosDispatch();
  const {asmtToEdit, parentInformation, isOpen, dialogComplete, setVideo} =props;

  const [videos, setVideos] = React.useState<File[]>([]);
  // This is probably duplicating something, but i don't want to spend the
  // time to unravel things for now dlp
  const [dialogValue, setDialogValue] = React.useState(defaultState);
  const [savingStatus, setSavingStatus] = useState(SaveStatusTypes.INITIALIZE);
  const [isRecording, setIsRecording] = useState(false);
  const [cameraOrientation, flipCameraOrientation] = useState('front');
  const [startCamera, setStartCamera] = useState(false);
  const [videoFilePath, setVideoFilePath] = useState<any>();
  
  const videoFileReader = new FileReader();

  // Interesting, seems like this gets called when the containing component
  // gets opened
  useEffect(()=>{
    if(asmtToEdit){
      console.log('asmtToEdit data:', asmtToEdit);

      const updatedDialogValues:CameraPreviewDialogValues = {
        ...defaultState,
        parentemail: asmtToEdit.parent,
        notes: asmtToEdit.notes,
        asmtdate: asmtToEdit.date,
        dob: asmtToEdit.dob,
        parentId: asmtToEdit.parentId
      };
      setDialogValue({...dialogValue, ...updatedDialogValues});
      return;
    }

    // the parentinfo comes for the parent selectin/creation dialog
    // they are of type UserProps and comes for userListContext
    if(!parentInformation) return;
    const updatedDialogValues:CameraPreviewDialogValues = {
      ...defaultState,
      parentemail: parentInformation.localEmail!,
      parentId: parentInformation.emmauserId!
    };
    setDialogValue({...dialogValue, ...updatedDialogValues});

  },[isOpen, asmtToEdit]);



  // using the savingStatus state could be a way to 
  // get a cancel control in.
  const handleClose = () => {
    if(savingStatus==SaveStatusTypes.SAVING) return;
    console.log('Clearing data', defaultState);
    setDialogValue(defaultState);
    setSavingStatus(SaveStatusTypes.INITIALIZE);
    dialogComplete();
    CameraPreview.stopCamera();
  };

  const handleStartRecording = () => {
    setIsRecording(true);
    var opts = {
      width: (window.screen.width / 2),
      height: (window.screen.height / 2),
      quality: 60,
      withFlash: false,
      storeToFile: false,
      disableExifHeaderStripping: true
    }
    
    CameraPreview.startRecordVideo(opts).then( (res)=>{
      console.log('Started recording video:', res);
    }, (err) => {
      console.log('Error starting recording video:', JSON.stringify(err));
    });

  };

  const readFilePath = async (filepath:any) => {
    // Here's an example of reading a file with a full file path. Use this to
    // read binary data (base64 encoded) from plugins that return File URIs, such as
    // the Camera.
    const contents = await Filesystem.readFile({
      path: 'file://' + filepath
    });
  
    if (!contents) {
      console.log('Read from local device file');
    }
    return contents;
  };

  const handleStopRecording = async() => {
    setIsRecording(false);
    CameraPreview.stopRecordVideo().then ( (filepath:any) => {
      console.log('Stopped recording...returned:', filepath);
     
  
      readFilePath(filepath).then(
        (filecontent) => { 

          // the following works
          // but seems to invert the image top to bottom

          const blob = new Blob([new Uint8Array(decode(filecontent.data))], {
            type: 'video/mp4',
          });

          // another way to create blob

          // const rawBinaryData = atob(filecontent.data);
          // const bytes = new Array(rawBinaryData.length);
          // console.log('Video blob data length:', rawBinaryData.length);
          // for (var x=0; x<rawBinaryData.length; x++){
          //   bytes[x] = rawBinaryData.charCodeAt(x);
          // }
          // const arr = new Uint8Array(bytes);
          // const blob = new Blob([arr], {type:'video/mp4'});

        console.log('Created blob');
        setVideo(blob);
        setVideoFilePath(blob);}
      );

    }, (err:any) => {
      console.log('Error stopping video:', err);
    });

  };



  const handleCameraFlip = () => {
    flipCameraOrientation(cameraOrientation === 'front' ? 'rear' : 'front');
    console.log('Camera orientation:', cameraOrientation);
    if (isAndroid || isIOS){
      CameraPreview.switchCamera();
    }
  };

  useEffect( ()=> {

    if(!startCamera) return;

    console.log('Android? ', isAndroid);
    console.log('iOS?', isIOS);
    if (isAndroid || isIOS) {

      const opts = {
        x: 0,
        y: 50,
        cameraDirection: CameraPreview.CAMERA_DIRECTION.BACK,
        storeToFile: false,
        toBack:false,
        tapPhoto:false,
        tapFocus:true,
        disableExifHeaderStripping: true
      };
      CameraPreview.startCamera( opts).then( (res:any)=> {
        console.log('Starting camera');
        console.log(res);
        CameraPreview.getCameraCharacteristics().then((c) => {
          console.log(c);
        });
      }, 
        (err:any) => { 
          console.log('Error starting camera');
          console.log(err);});
        // {
//        parent: 'camera-content',
        // toBack: true,
        // position:'front'
     // });
      //}
    }
    else{
      showToast('Camera functions only on Android or iOS devices');
    }
  },[startCamera]);

  const handleStartCamera = () => {
    setStartCamera(true);
  };

  useEffect(()=>{
    // console.log('Saving change', savingStatus);
    if(savingStatus === SaveStatusTypes.DONE){
      //To avoid oddities with users having assessments with videos
      //that don't belong to them clear out the selected video ids.
      emmaVideosDispatch({type:VideoListActionTypes.UNSELECT_VIDEOS});
      handleClose();
    }
  },[savingStatus]);

  // const handleDropzoneUpload = async () => {
  //   // save all the files
  //   console.log('Save all files', videos);
  // };

  const showToast = async (msg:any) => {
    await Toast.show({
      text: msg
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
   
  };

  return (     
    <Dialog
      aria-labelledby="form-dialog-title"
      onClose={handleClose} //closes on click away
      open={isOpen}
      fullScreen
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-dialog-title">Create/update assessment</DialogTitle>
        <AppBar color='secondary'>
          <Toolbar>
            <IconButton
              edge="start"
              disabled={savingStatus===SaveStatusTypes.SAVING}
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Grid container justify='flex-end'>
              <Button variant='outlined' onClick={handleStartCamera}
                style={{color:'white'}}>OPEN</Button>
              {/* <IconButton onClick={handleStartCamera} >
                  <VideocamTwoTone style={{color:'white'}}/>
                  <Typography variant='h5' style={{color:'white'}}>Start</Typography>
              </IconButton> */}
              <IconButton onClick={handleStartRecording}>
                {isRecording &&
                  <RadioButtonCheckedTwoTone style={{color:'red'}}/>
                }
                {!isRecording && <RadioButtonCheckedTwoTone style={{color:'white'}}/>}
              </IconButton>
              <IconButton onClick={handleStopRecording} >
                  <StopTwoTone style={{color:'white'}}/>
              </IconButton>
              <IconButton onClick={handleCameraFlip}>
                <FlipCameraAndroidTwoTone style={{color:'white'}}/>
              </IconButton>
            </Grid>
          </Toolbar>
        </AppBar>
        <DialogContent> 

          <div id='camera-content'>
        
          </div>
        </DialogContent>
        
      </form>
    </Dialog>
  );
}
