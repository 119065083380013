import React from 'react';
import styled from 'styled-components';

import { useTheme } from '@material-ui/styles';
import { CardContent, Card as MuiCard, Typography } from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Line } from 'react-chartjs-2';

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 378px;
`;

function LineChart(){
  const theme = useTheme();

  const data = canvas => {
    const ctx = canvas.getContext('2d');

    const gradient = ctx.createLinearGradient(0, 0, 0, 300);
    gradient.addColorStop(
      0,
      fade(theme.palette.secondary.main, 0.0875)
    );
    gradient.addColorStop(1, 'rgba(0, 0, 0, 0)');

    return {
      labels: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ],
      datasets: [
        {
          label: 'Score',
          fill: true,
          backgroundColor: gradient,
          borderColor: theme.palette.secondary.main,
          data: [
            6,
            11,
            11,
            15,
            20,
            26,
            32,
            37,
            45,
            50,
            53,
            58            ]
        },
        {
          label: '50th Percentile',
          fill: true,
          backgroundColor: 'transparent',
          borderColor: theme.palette.grey['500'],
          borderDash: [4, 4],
          data: [
            4.5,
            7.3,
            9.8,
            12.6,
            17.9,
            23.2,
            28.3,
            32.3,
            39.8,
            45.5,
            49.3,
            51.3            ]
        }
      ]
    };
  };
  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: {
      intersect: false
    },
    hover: {
      intersect: true
    },
    plugins: {
      filler: {
        propagate: false
      }
    },
    scales: {
      xAxes: [
        {
          reverse: true,
          gridLines: {
            color: 'rgba(0,0,0,0.0)'
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            stepSize: 10
          },
          display: true,
          borderDash: [5, 5],
          gridLines: {
            color: 'rgba(0,0,0,0.0375)',
            fontColor: '#fff'
          }
        }
      ]
    }
  };

  return (
    <Card mb={3}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
            Overall Motor Development
        </Typography>

        <Spacer mb={6} />

        <ChartWrapper>
          <Line data={data} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
}

export default LineChart;