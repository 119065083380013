/// Schema section
// A push to create admin users and parent users needs to be made
// in addition to storing unneeded information, it create an unneeded
// overlap. Giving the user a type (user admin parent) might work too
// but it would not clean up things
// another options is to connected entities needed for a parent via
// the database instead of explicitly putting them into the database (relation style)/*  */
export interface EmmaUserSchemaProps {
  emmauserId?:string,//the mongoId
  email:string,
  uid?:string,//the firebase id, needed in order to create on mongo
  authLevel:number,
  pushSubscription?:string,
  firstname:string,
  lastname:string,
  created:Date,
  deviceIds:string[],
  sharedEmails:string,
  // oops the additional props was not used correctly
  additionalProp1?:Record<string,unknown>, //{comments:'the notes'},
  // The above is an incorrect representation of
  // [key:string]:unknown;
  signature?:string,
  subjects?:SubjectSchemaProps[] // returned via relation filter call
}
export const emptyUserMongoSchema:EmmaUserSchemaProps = {
  email:'',
  authLevel:	-1,
  firstname:	'',
  lastname:	'',
  created:new Date(),
  deviceIds: [],
  sharedEmails:'',
  uid:'unauthenticatedId'
};
export interface SubjectSchemaProps{
  subjectId?:	string; // not needed for new
  name:	string;
  gender:	string;
  dob:Date;
  // not an optional property in the api
  // for now the non-used field will be
  // stored as new Date(0)
  duedate:Date;
  // an optional property in the api
  gestationAgeDays?:number;
  // notes:string //not really a property
  emmauserid?:	string, // A connector property for the parent/guardian of subject
  additionalProp1?:Record<string,unknown> //The thing for notes //{notes:'ddsf'}
  // The above is an incorrect representation of
  // [key:string]:unknown;
  notes?: string;
  assessments?:AssessmentSchemaProps[];
}

export const emptySubjectState:SubjectSchemaProps = {
  subjectId:'',
  name:	'',
  gender:	'F',
  dob:new Date(),
  duedate: new Date(),
  gestationAgeDays:0,
  additionalProp1:{notes:''}//The old way it was done, dlp unsure when things went south
};

export interface AssessmentSchemaProps  {
  assessmentId?:string, //Not needed for new
  date:Date; // i assume that is creation date.
  assessor?:string; //i believe this is an email address
  assessmentName:string;
  subjectId?:string;
  additionalProp1?:Record<string,unknown>;//legeacy error with API usage
  notes:string;
  // a proper phase out would involve a database update.
  // additionalProp1?:object;
  // The next bit come from api when using the
  // relation filter (needs a lot more testing on api front)
  // not used for putting, at least dlp thinks.
  observations?:ObservationSchemaProps[];
  subject?:SubjectSchemaProps;
  reports?:ReportSchemaProps[];
}

export interface ObservationSchemaProps {
  observationId?:string,
  timestamp:Date,
  duration:number,
  notes:string,
  need_review:boolean,
  subjectId?:string,
  videoId?:string,
  video?:VideoSchemaProps,
  assessmentId?:string,

  additionalProp1?:any,//ug, this prop needs to be removed ... and it uses quashed
  // The above is an incorrect representation of
  // [key:string]:unknown;
  assessment?:AssessmentSchemaProps
}

// Copied from arlo-video-igestor ie used there too
export type VideoSchemaProps = {
  videoId?:string, //not needed for new
  timestamp:Date,
  duration:number,
  tagCount:number,
  hasBeenViewed:boolean,
  deviceId:string, // device this video comes from camara source
  // The ownerId refers to the id given by arlo to the owner of
  // the machine that creates the video, used by avi {ownerId:'M8HA5WG-336-53075198'}
  ownerId?:string, // Owner of the video. 
  bucketName:string, // firebase bucket
  url:string, // accessable url for video
  thumbnailurl?:string, //accessable url for thumb
  videoStoragePath:string, //bucM8HA5WG-336-53075198ketPath, ownerId, deviceId, videoFilename
  //bucketPath, ownerId, deviceId, thumbnailFilename
  thumbnailStoragePath?:string, 
  //not sure what this is bucketPath, ownerId, deviceId, jsonFilename
  jsonStoragePath?:string
}

export type AnnotationSetSchemaProps = {
  annotationSetId?:string,
  instrumentIndex:number,
  emmaUserId:string, // User that created the codings
  observationId:string, // Observation the coding is for.
  annotations:AnnotationSchemaProps[],
  //legacy but it is unknown how many only have this for id
  videoId:string,
}

export type AnnotationSchemaProps = {
  startTime?:number,
  label: string,
  labelIndex?:number,
  // Some of the additional property hacks
  verifyLabel?:boolean
  // [key:string]:unknown;
}
////// Scoring schemas? probably should be in a different
// file since these are not part of the mongo db, but knowing
// what that have does make life easier.
export type GenericScoreSchemaProps = {
  totalScore:number,
  // ick this is only for aims, hacky :(
  // subscaleScores?: Record<string,unknown>
  [key:string]:unknown;
  // the scoring results that come back for aims type score
  // aimsScore:{prone:{score:12, maxItemName:name, notes:'df'},
  // sitting:{score:12, maxItemName:name, notes:'df'},
  // supine:{score:12, maxItemName:name, notes:'df'},
  // standing:{score:12, maxItemName:name, notes:'df'}}
}
// turns out this thing is just a bunch of records, for now.
// export type PercentileScoreSchemaProps = {
//   percentileData: Record<string,number>,
//   //message, 90, 75, 50,25,10,5, total, below, above are the options, i think
// }

export type InstrumentSchemaProps = {
  instrumentId?:string,
  name:string,
  subscales?:string[],
  instrumentItems:InstrumentItemSchemaProps[],
  // Addition properties added post creation.
  //scoringMethodNames:string[]

  [key:string]:unknown
}

export type InstrumentItemSchemaProps = {
  name:string,
  index?:number,
  // additional properties that are
  // yet to be defined
  [key:string]:unknown
}

export interface ReportSchemaProps {
  reportId?:string, //Not needed for new
  creationDate:Date,
  reportFileURL?:string, // optional?
  reportFileStoragePath?:string,//optional?
  assessmentId:string,
  emmauserId: string, //assessor id, api keeps it from emmaUserId
  subjectId: string,
  subjectParentId?:string, // this prop is meh
  reportData:ReportDataSchemaProps,
  // The email status should only be temporary, 
  // techinically it is a long process call so the email status
  // should go into what ever part of hte db will eventually deal with that.
  emailData?:EmailStatusSchemaProps
}

export interface ReportDataSchemaProps {
  reportCreationDate:Date,
  reportTypeName:string, //Not needed for new
  reportCreatorName?: string,
  reportCreatorEmail?: string,
  reportCreatorSignature?:string,
  assessmentDate?:Date,
  asmtSubjectName?:string,
  asmtSubjectBirthDate?:Date,
  subjectGender?:string,
  subjectAgeMonthDaysForAsmt?: string,//month:days
  subjectCorrectedAgeMonthDaysForAsmt?:string,//months:days
  parentEmail?:string,
  parentName?:string,
  reportConclusion?: string,

//something like score['aimsScore'] or report['aimsScore']
// and report['emailStatus']
// the idea is that what ever gets this can decide
// the type of scoring based on the extra props
  [key:string]:unknown
}
export interface EmailStatusSchemaProps {
  sendVerified:boolean,
  sendVerifierId?:string,
  sendRequesterId:string,
  sendRequested:boolean
}
// order of the table should be...
// prone/supine/sitting/standing
export interface AimsScoreSchemaProps {
   total:Record<string, unknown>,
   prone:Record<string, unknown>,
   supine:Record<string, unknown>,
   sitting:Record<string, unknown>,
   standing:Record<string, unknown>,
   percentile:Record<string, unknown>,
  // total :{score:10, notes:'fdd'},
  //sitting: {score:10, maxItemName:'fd', notes:'fdd'}, 
  //supine: {score:10, maxItemName:'fd', notes:'fdd'} ,
  //standing: {score:10, maxItemName:'fd', notes:'fdd'}, 
  //prone: {score:10, maxItemName:'fd', notes:'fdd'},
  //percentile:{below?:34.5, above?:10 information:'fe'}, actionToTake?};
}