/* eslint-disable react/jsx-key */
/* eslint-disable react/no-multi-comp */

import React from 'react';

import {
  // Avatar,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Paper,
  Tabs,
  Tab
} from '@material-ui/core';

export class TabPanel extends React.Component {
  state = {
    isPlaying: false,
    volume: 0.7
  };

  constructor(props) {
    super(props);
  }

  handlePlay = () => {
    this.setState({isPlaying: true});
  };
  
  handlePause = () => {
    this.setState({isPlaying: false});
  };
  
  handleVolume = value => {
    this.setState({volume: value});
  };      

  render() {
    const { children, value, index, otvideo, ...other } = this.props;
    let { isPlaying, volume } = this.state;
   
    return (
      
      <div hidden={value !== index}>
      
        <DialogTitle id="max-width-dialog-title">
          {this.props.otvideo.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.props.otvideo.instruction}
          </DialogContentText>
          <CardMedia 
            component="video"
            controls
            height="256"
            image={this.props.otvideo && this.props.otvideo.url}
            title={this.props.otvideo.title}
          />
        </DialogContent>
      </div>
    );      
  }
}

export class OTVideoDetails extends React.Component {
    state = {
      open: false,
      fullWidth: true,
      maxWidth: 'sm',
      thumbnailUrl: {},
      tabIndexValue: 0,
      isPlaying: false,
      selected:0
    };
  
    constructor(props){
      super(props);
    }

    handleClickOpen = () => {
      this.setState({ open: true }, function(){
        console.log('dialog open state',this.state.open);
      });
    };
  
    handleClose = () => {
      this.setState({ open: false });
    };
  
    handleMaxWidthChange = event => {
      this.setState({ maxWidth: event.target.value });
    };
  
    handleFullWidthChange = event => {
      this.setState({ fullWidth: event.target.checked });
    };

    handleTabChange = (event, newValue) => {
      this.setState({selected:newValue}, function(){
        console.log('New tab value:',this.state.selected);
      });
    };

    a11yProps(index) {
      return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`
      };
    }
      
    render() {
      return (  
        <Paper mt={4}>
          <React.Fragment>               
            <Dialog
              aria-labelledby="max-width-dialog-title"
              fullWidth={this.state.fullWidth}
              maxWidth={this.state.maxWidth}
              onClose={this.handleClose}
              open={this.state.open}
            >
              <Paper>
                <Tabs 
                  aria-label="Video"
                  centered
                  indicatorColor="primary" 
                  onChange={this.handleTabChange} 
                  value={this.state.selected}
                >
                  {this.props.otplan && this.props.otplan.otvideos && this.props.otplan.otvideos.map( function(video,index){
                    return(
                      <Tab
                        label={video.title}
                        {...this.a11yProps(index)}
                      />
                    );
                  }.bind(this))}
                </Tabs>
              </Paper>
                   
              <Divider my={6} />
              {this.props.otplan && this.props.otplan.otvideos && this.props.otplan.otvideos.map( (video,index) => {
                return(
                  <TabPanel
                    index={index}
                    otvideo={video}
                    value={this.state.selected}
                  />
                );
              }
              )}
              <DialogActions>
                <Button
                  color="primary"
                  onClick={this.handleClose}
                >
                      Close
                </Button>
              </DialogActions>
              <Card>
                <CardContent />
              </Card>
            </Dialog>
          </React.Fragment>
        </Paper>
      );
    }
}