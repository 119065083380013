/* eslint-disable react/no-multi-comp */
import React, {useEffect, useState} from 'react';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Button, IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { 
  AddCircleOutlined, 
  Delete,
  VideocamTwoTone,
  CloudDownload
} from '@material-ui/icons';

import MUIDataTable, { SelectableRows, Responsive, MUIDataTableMeta } from 'mui-datatables';

import {simpleNonAuthUser} from '../../common/nonauthuser-data';
import { EmmaUserSchemaProps } from '../../common/mongoSchemas';
import { GeneralControlActionTypes, GeneralControlContext } from '../../context/GeneralControlContext';

// https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/mui-datatables/index.d.ts
const nestedTablesTheme = createMuiTheme({
  overrides: {
    MUIDataTableSelectCell: {
      expandDisabled: {
        visibility: 'hidden'
      }
    },
    MUIDataTableToolbar: {
      root: {
        display: 'none'
      }
    },
    MUIDataTableSearch: {
      clearIcon: {
        display: 'none'
      }
    },
    MUIDataTableHeadRow: {
      root: {
        display: 'none'
      }
    }
  }
});

type UserWithSubjectAndAsesssement = {
  email:string,
  firstname:string,
  lastname:string,

  assessments:SubjectAndAssessment[]
  reports:string[]
};
type SubjectAndAssessment = {
  name:string,
  asmtDate:string,//Date,
  dob:string,//Date
};
export function UserAndAssessmentTable():JSX.Element {


  const {generalControlState, generalControlDispatch} = React.useContext(GeneralControlContext);

  useEffect(() => {
    console.log();
    const blah = simpleNonAuthUser.map((user)=>{
      const asmtAndSubj:SubjectAndAssessment[] = [];
      if(user.subjects){
        for(const subj of user.subjects){
          if(subj.assessments){

            for(const asmt of subj.assessments){
              const tasmt = {
                name:subj.name,
                dob:subj.dob.toLocaleString(),
                asmtDate:asmt.date.toLocaleString()
              };
              asmtAndSubj.push(tasmt);
            }
          }
        }
      }

      return {
        email:user.email,
        firstname:user.firstname,
        lastname:user.lastname,
        assessments:asmtAndSubj,
        reports:[]
      } as UserWithSubjectAndAsesssement;
    });
    console.log(blah);
    setUserTableData(blah);
  }, []);
  const [userTableData, setUserTableData] = useState<UserWithSubjectAndAsesssement[]>([]);
  const userColumns = [
    {
      name:'firstname',
      label:'First Name',
      options: { }
    },
    {
      name:'lastname',
      label:'Last Name',
      options: { }
    },
    {
      name:'email',
      label:'Email',
      options:{}
    },
    {
      name:'assessments',
      options: { display: false, viewColumns: false, filter: false }
    },
    {
      name: 'reports',
      label:'Report',
      options: {
        // empty: true,
        // display: true,
        // filter: false,
        // sort: false,
        // // empty: true,display: false, viewColumns: false, 
        customBodyRender: (value:any, tableMeta:MUIDataTableMeta, updateValue:(value: string)=>void) => {
          return (
            <IconButton
              onClick={(event:React.MouseEvent<HTMLButtonElement>)=>{
                event.stopPropagation();
                console.log('Trying to open something');
              }}
            >
              <CloudDownload/>
            </IconButton>
          );
        }
      }
    },
  ];

  const asmtColumns = [
    {
      name: 'name',
    },
    {
      name: 'asmtDate'
    },
    {
      name: 'dob'
    },
    {
      name: 'report',
      label:'Report',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value:any, tableMeta:MUIDataTableMeta, updateValue:(value: string)=>void) => {
          return (
            <IconButton onClick={(event:React.MouseEvent<HTMLButtonElement>)=>{console.log(tableMeta);}}>
              <CloudDownload/>
            </IconButton>
          );
        }
      }
    },
    {
      name: 'record',
      label:'Record',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value:any, tableMeta:MUIDataTableMeta, updateValue:(value: string)=>void) => {
          return (
            <IconButton onClick={(event:React.MouseEvent<HTMLButtonElement>)=>{console.log(tableMeta);}}>
              <VideocamTwoTone/>
            </IconButton>                    
          );
        }
      }
    },
    {
      name: 'update',
      label:'Update',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value:any, tableMeta:MUIDataTableMeta, updateValue:(value: string)=>void) => {
          return (
            <IconButton onClick={(event:React.MouseEvent<HTMLButtonElement>)=>{console.log(tableMeta);}}>
              <AddCircleOutlined/>
            </IconButton>                    
          );
        }
      }
    },
    {
      name: 'delete',
      label:'Delete',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value:any, tableMeta:MUIDataTableMeta, updateValue:(value: string)=>void) => {
          return (
            <Tooltip title="Delete Assessment">
              <IconButton onClick={(event:React.MouseEvent<HTMLButtonElement>)=>{console.log(tableMeta);}}>
                <Delete />
              </IconButton>
            </Tooltip>
          );
        }
      }
    },
  ];

  const nestedTableOptions = ():any => { //expandMethod:(()=>void)|undefined
    // const hasExpandableRows = expandMethod !== undefined;
    return {

      filter: true,
      pagination: false,
      responsive: 'standard' as Responsive,
      selectableRows: 'none' as SelectableRows,
      elevation: 0,
      expandableRows: false, //hasExpandableRows
      expandableRowsOnClick: true, //hasExpandableRows
      renderExpandableRow: expRowAssessments,
      searchOpen: true,
      search: false,
      download: false,
      print: false,
    };
  };

  const expRowAssessments = (rowData:any, rowMeta:{ dataIndex: number, rowIndex: number}) => {
    const colSpan = rowData.length + 1;
    rowData = rowData.slice(-1)[0]; //try to always keep the nested data item at the end of the array
    return (
      <TableRow>
        <TableCell
          // class="nestedTable"
          colSpan={colSpan}
        >
          <MuiThemeProvider theme={nestedTablesTheme}>
            <MUIDataTable
              // class="noPadding"
              columns={asmtColumns}
              data={rowData}
              options={nestedTableOptions()}//expRowEmployees)}

              title={'Assessments'}
            />
          </MuiThemeProvider>
        </TableCell>
      </TableRow>
    );
  };

  const options = {
    filter: true,
    expandableRows: true,
    responsive: 'standard' as Responsive,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    renderExpandableRow: expRowAssessments,
    selectableRows: 'none' as SelectableRows,
    searchOpen: true,
    search: false,
    download: false,
    print: false,

    // eslint-disable-next-line react/no-multi-comp
    customToolbar: () => {
      return (
        <Button 
          color="primary"
          onClick={()=> {
            if(generalControlDispatch){      
              generalControlDispatch({type:GeneralControlActionTypes.USERINFO_DIALOG, 
                isUserInfoDialogVisible:true}); 
            }
          }}
          variant="outlined"
        >
          Add user
        </Button>
        // <CustomToolbar />
      );
    }
  };
  return(
    <TableRow>
      <TableCell
        // class="nestedTable"
        colSpan={2}
      >
        <MUIDataTable
          columns={userColumns}
          data={userTableData}
          options={options}

          title={'Users and Assessments'}
        />
      </TableCell>
    </TableRow>
  );
}