import React from 'react';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  TextField
} from '@material-ui/core';
import { 
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core/styles';

import { 
  reportInfoReducer,
  ReportInfoContext,
  ReportInfoContextType,
  defaultReportInfo
} from '../../context/ReportContext';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    card: {
      maxWidth: '100%',
      minWidth: '0',
      width:'100%',
      margin: 'auto',
      transition: '0.3s',
      boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
      '&:hover': {
        boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)'
      }
    },
    cardcontent: {
      textAlign: 'left',
      // padding: theme.spacing.unit * 3
    },    
  }),
);

export const ParentInfo= ():JSX.Element=>{

  const classes = useStyles();

  const {reportInfoState, reportInfoDispatch} = React.useContext(ReportInfoContext);
  const {parentEmail, parentName } = reportInfoState?.reportInformation;

  return (          
    <Card className={classes.card}>
      <CardContent className={classes.cardcontent}>
        <Typography
          color="secondary"
          gutterBottom
          variant={'h6'}
        >
        Parent Information
        </Typography>

        <form
          autoComplete="off"
          className={classes.root}
          noValidate
        >
          <div>
            <TextField
              autoFocus
              fullWidth
              id="parentFullName"
              label="Name"
              margin="dense"
              // onChange={(event) => setDialogValue({ ...dialogValue, lastname: event.target.value })}
              type="text"
              value={parentName}
            />
            {/* <TextField
              autoFocus
              fullWidth
              id="parentlastname"
              label="Last Name"
              margin="dense"
              // onChange={(event) => setDialogValue({ ...dialogValue, lastname: event.target.value })}
              type="text"
            /> */}
            <TextField
              autoFocus
              fullWidth
              id="parentemail"
              label="Parent Email"
              margin="dense"
              // onChange={(event) => setDialogValue({ ...dialogValue, lastname: event.target.value })}
              type="text"
              value={parentEmail}
            />
          </div>
        </form>
      </CardContent>
    </Card>
  );
};