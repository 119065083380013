import React, { useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import firebase, {firebaseAuth} from '../firebaseConfig';


import * as AppRoutes from '../common/routeNames';
import { SignInError } from '../common/entityUtilities';
import { getUserDataWithEmail, getUserDataWithUid } from '../common/apiGetUtilities';
import { 
  UserProps,
  emptyUserState, 
  convertUserDataToUserPropType,
  createFirebaseUser
} from '../common/entityUtilities';
import { AnyRecord } from 'dns';
import {Toast} from '@capacitor/toast';
import {showDialog, showPrompt, showCapacitorAlert} from '../common/utilities';
import { PromptResult } from '@capacitor/dialog';
import { deleteAllData , deleteUserAccount} from '../common/apiDelUtilities';


type Props = {
  children:React.ReactNode;
};

export enum UserActionTypes {
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',
  USER_FAILURE = 'USER_FAILURE',
  UPDATE = 'UPDATE',
  AUTH_INPROCESS = 'AUTH_INPROCESS'
}

interface UserActionProps extends Partial<UserProps>{
  type:UserActionTypes;
}

const tokenConst = 'id_token';
const UserStateContext = React.createContext<UserProps>(null!);
const UserDispatchContext = React.createContext<React.Dispatch<any>>(null!);



//Bad david...so poorly used
// Make sure that this user matches the user that in the userlist, not sure how that
// is supposed to work, but it is something import to look out for because of updates.
function userReducer(state:UserProps, action:Partial<UserActionProps>) {

  const {type, ...noType} = action;
  switch (action.type) {
    case UserActionTypes.LOGIN_SUCCESS:
      console.log('LOGIN_SUCCESS');
      return { ...noType, isAuthenticated: true, authenticationInProcess:false };
    case UserActionTypes.SIGN_OUT_SUCCESS:
      console.log('SIGNOUT_SUCCESS');
      return { ...noType, isAuthenticated: false , authenticationInProcess:false};
    case UserActionTypes.LOGIN_FAILURE:
      console.log('LOGIN_FAILURE');
      return { ...noType, isAuthenticated: false , authenticationInProcess:false};
    case UserActionTypes.USER_FAILURE:
      console.log('USER_FAILURE');
      return { ...noType, isAuthenticated: true , authenticationInProcess:false};
    case UserActionTypes.AUTH_INPROCESS:
      console.log('AUTH_INPROCESS');
      return { ...noType, isAuthenticated: false, authenticationInProcess: true};
    // Specific updates might be good for the future
    case UserActionTypes.UPDATE: {
      console.log(type,'AUTH UPDATE', noType);
      const changeSet:any = {...noType};

      console.log('the state', state);
      // // really can't remember what these are for
      // if(state.emmauserId !== action.emmauserId){
      //   // console.log(state.emmauserId , 'Changing id', action.emmauserId);
      //   changeSet['emmauserId'] = action.emmauserId;
      // }
      // Firebase user is a guarantee 
      // // also the odd case where user was added by and admin and is just now
      // // updating after authentication
      // if(state.storedUid !== action.firebaseUser?.uid){
      //   console.log(state.storedUid , 'Changing id', action.firebaseUser?.uid);
      //   changeSet['storedUid'] = action.firebaseUser?.uid;
      // }
      return { ...state, ...changeSet};
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
// This method gets the firebase user info from the api with the firebasetoken
// that is provieded by the firebase auth method.
// For now no ui updates when loading is occuring, maybe in a refactor
// Ick, i feel dirty writing this. Hopefully a good refactor isn't hard.
// hmm what is up with the bearer token. I guess you can only check the api with
// the bearertoken, ...but you can set a use with just the firebase auth...
async function loginAuthenticatedUser(firebaseUser:any, bearerToken:string, dispatch:any){//}, history:any){
  let emailFromApi = '';
  if(!firebaseUser){
  // Not really sure what to do in this situation
    dispatch({ type: UserActionTypes.LOGIN_FAILURE });
    return;
  }
  const convertedFirebaseUser = createFirebaseUser(firebaseUser, bearerToken);
  // Bearer token should not be set here
  try{ 
    let userDataFromApi = await getUserDataWithEmail(firebaseUser.email, bearerToken, firebaseUser.uid);
    // No user could be found, create a blank webui user
    let userForWebPage = {...emptyUserState};
    console.log('No user in database with email', firebaseUser.email);

    if (!userDataFromApi){
      // try it with uid
      console.log('Searching for user with uid:', firebaseUser.uid);
      userDataFromApi = await getUserDataWithUid(firebaseUser.uid, bearerToken);
    }

    if(!userDataFromApi){
      // A user that matched the email was not found. Which means this is a brand new user that
      // nobody has added to the user list and has not logged in before. So create a new entity
      // and fill it with userful info. This entity will get passed around in the webapp and 
      // will be used to fill forms and eventually used to store in the database for future logins.
      userForWebPage.firebaseUser = convertedFirebaseUser;
      console.log('user not found in database');
    }else{
      console.log('user found in emma database', userDataFromApi);
      emailFromApi = userDataFromApi.email;
      //User has been put in the database either by registering or be being created by an admin
      console.log('userdatafromapi:',userDataFromApi);
      const convertedProps = convertUserDataToUserPropType(userDataFromApi);
      console.log('converted props:', convertedProps);
      userForWebPage = {...emptyUserState, ...convertedProps};
      userForWebPage.firebaseUser = convertedFirebaseUser;
      userForWebPage.storedUid = userDataFromApi.uid;
    } 
  
    // Set the display name normally we want to use the display name from the authentication
    userForWebPage.localDisplayName = convertedFirebaseUser.displayName;
    // This displayname setting logic is pretty nasty...dlp
    if(!userForWebPage.localDisplayName || userForWebPage.localDisplayName === ''){
      if(userForWebPage.authLevel < 0){
        userForWebPage.localDisplayName  = convertedFirebaseUser.email;
      }else{
        const displayName = `${userForWebPage.firstname} ${userForWebPage.lastname}`;
        if(displayName.trim() === ''){
          userForWebPage.localDisplayName  = convertedFirebaseUser.email;
        }else {
          userForWebPage.localDisplayName = displayName;
        }
      }
    }
    if(!convertedFirebaseUser.email){
      userForWebPage.localEmail = emailFromApi;
    }
    else {
      userForWebPage.localEmail = convertedFirebaseUser.email;
    }
    // Legacy, but keep it around just for fun
    localStorage.setItem(tokenConst, convertedFirebaseUser.email);
    console.log('Dispatching with data:', userForWebPage);
    dispatch({ type: UserActionTypes.LOGIN_SUCCESS, ...userForWebPage });
    // Routing is odd, the login page shows while firebase is checking
    // that is not desirable, fix in later versions.
  }catch(err: any){
    // How to deal with errors from 
    //https://www.intricatecloud.io/2020/03/how-to-handle-api-errors-in-your-web-app-using-axios/
    const {message} = err as Error;
    console.log('Something here', err);
    if (message) {
      // client received an error response (5xx, 4xx)
      dispatch({ type: UserActionTypes.LOGIN_FAILURE });
    } 
  }
}

// This feels bad, please refactor dlp
function UserProvider({ children }:Props) {
  // const history = useHistory(); // this should provide history...maybe context is not set right?
  const [state, dispatch] = React.useReducer(userReducer, 
    emptyUserState);//, initLoginReducer);// TODO bring this back? probably not firebase deals here
  const [firebaseUser, setFirebaseUser] = useState<any>(null);
  
  useEffect(() =>{
    // Have firebase take care of its own authenication and send back the result
    // when it is done
    firebaseAuth.onAuthStateChanged(setFirebaseUser);
  },[]);

    
  useEffect(() =>{
    // Have firebase take care of its own authenication and send back the result
    // when it is done
    firebaseAuth.onIdTokenChanged(async function(user){
      console.log('FIREBASE TOKEN CHANGED for user:',user);
      if (user){
        setFirebaseUser(user);
      }
    });
  },[]);

  useEffect(() =>{
    async function getFirebaseUserToken() {
      if(!firebaseUser) return;
      let token:any = undefined;
      console.log('LOGGING in authenticated firebaseuser: ', firebaseUser);
      if (firebaseUser?.email !== null && !firebaseUser?.emailVerified){
        //the firebaseuser has an email field, but hasn't been verified yet
        console.log('firebase user email has not been verified yet');
        const message = 'We can send a verification email to: ' + firebaseUser?.email + '. We need to make sure you have access to this email.';
        const value = await showDialog('Verify email',message,'Send','Cancel');
        if (value){
          console.log('User wants a confirmation email sent');
          firebaseUser.sendEmailVerification().then( (result:any)=> {
            showToast('Check your email and click on the verification link sent. Then SIGN IN again. Be sure to check your SPAM or NOTIFICATIONS email folders.');
            console.log('Sent email verification link:', result);

          }).catch( (error:any)=> {
            console.log('Error sending email link:', error);
          });
        }
        else{
          console.log('User cancelled email verification sending');
        }

        firebaseAuth.signOut().then(()=>{console.log('SIGNED OUT USER');});
        return; //return without logging the unverified user in
      }

      showToast('Please wait...logging you in');
      dispatch({type:UserActionTypes.AUTH_INPROCESS});
      token = await firebaseUser.getIdToken(/*force refresh*/ true);
      loginAuthenticatedUser(firebaseUser, token, dispatch);//, history);
    }
    getFirebaseUserToken();
  },[firebaseUser]);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

async function refreshToken(user: any){
  console.log('REFRESHING TOKEN');
  console.log('Firebaseuser with uid:', user.uid);

  let token:any = undefined;
  if(!user) return;

  if (user.firebaseUserObject) {
    token = await user.firebaseUserObject.getIdToken(true);
  }
  else if (user.firebaseUser){
    token = await user.firebaseUser.firebaseUserObject.getIdToken(true);
    user.firebaseUser.bearerToken = token;
  }
  user.bearerToken = token;
  console.log('New refreshed token:'); //, token);
  
  const convertedFirebaseUser = createFirebaseUser(user, token);
  console.log('Finished REFRESHING user');
}

function useUserState() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}

// ###########################################################
function externalUserVerification(setIsLoading:any, setError:any, setAuthMessage:any){
  const providers = {
    googleProvider: new firebase.auth.GoogleAuthProvider(),
    emailAuthProvider: new firebase.auth.EmailAuthProvider()
  };
  setAuthMessage('Attempting a Google Authentication');
  setIsLoading(true);
  // The signInWithPopup triggers the firebaseAuth.onAuthStateChanged
  // which is listened toin the usercontext
  firebaseAuth.signInWithPopup(providers.googleProvider)
    .then((result)=>{
      console.log('Google Authentication: Completed with result:', result);
      setAuthMessage('Google Authentication complete: Verifying User');
      setIsLoading(false);
    })
    .catch(error =>{
      setIsLoading(false);
      console.log('Login error', error);
      setAuthMessage('There was an error');
    });
}

/**
 * This will login a user to firebase authentication source or add the user
 * if it does not exists. 
 * @param dispatch 
 * @param loginEmail 
 * @param password 
 * @param history 
 * @param setIsLoading 
 * @param setError 
 * @param setAuthMessage 
 */
async function loginUser(dispatch:any, loginEmail:any, password:any, 
  history:any, setIsLoading:any, setError:any, setAuthMessage:any) {
  setError(false);
  setIsLoading(true);
  setAuthMessage(`Authenticating ${loginEmail}`);
  let signedUser:any = null;

  if (!!loginEmail && !!password) {
    // The login details is handled by authstate changed, so need to call the dispatch
       
    firebaseAuth.signInWithEmailAndPassword(loginEmail, password).then( (firebaseUser:any)=>{

      signedUser = firebaseUser;
      setError(null);
      setIsLoading(false);

    }).catch( (signInError: any)=> {

      //This does catch an error, but the same error seems to get through else where in the code
      // ugh soooo ugly, i feel very dirty doing this
      console.log("SIGNIN ERROR:", signInError);
      if(signInError.code && signInError.code === 'auth/user-not-found'){
        setAuthMessage('User not found. You can SIGNUP if you are new.');
        console.log('User not found. Try SIGNING UP');
        setIsLoading(false);
       
       
      }else{

        setAuthMessage(signInError.message);
        setError(true);
        setIsLoading(false);
      }
    }).finally ( ()=> {
      console.log('Logged in user:', signedUser?.user);
      signedUser = signedUser?.user;
      //
      // we can leave it here and not check for email verification
      // when firebaseauth authenticates user, our logic will respond
      // firebase's onidtokenchanged() and onauthstatechanged() events will trigger
      // and set the firebaseUser state variable
      // this should trigger the loginAuthenticatedUser function here
      // which check for email verification and sends link when needed


      // if (signedUser != null){
      //   try{
      //     if(!signedUser?.emailVerified){
      //       console.log('Send user email verification link');
      //       signedUser.sendEmailVerification();
      //       console.log('Email verification link sent');
      //       setAuthMessage('Check your email. Click on verification link.');
      //     }
      //   }
      //   catch(error:any) {
      //     console.log('Error:', error);
      //     console.log('Signing user out...');
      //     signOut(dispatch, history);
      //   }}

      });

    

    
   
  } else {
    dispatch({ type: UserActionTypes.LOGIN_FAILURE });
    setError(true);
    setIsLoading(false);
  }
}


async function registerUser(dispatch:any, loginEmail:any, password:any, confirmPassword:any,
  history:any, setIsLoading:any, setError:any, setAuthMessage:any, setRegister:any) {

    setError(false);
    setIsLoading(true);
    if (password !== confirmPassword) {
      setAuthMessage("Passwords don't match!");
      setIsLoading(false);
      setError(true);
      return;
    }
    setAuthMessage(`Registering ${loginEmail}`);

    try{
      // This triggers a base firebaseapp subscription started on init of userPRovider
      await firebaseAuth.createUserWithEmailAndPassword(loginEmail, password);
      setError(null);
      setIsLoading(false);
      setRegister(false);
      setAuthMessage('User created. You still have to verify email. Then SIGN IN again.');
    } catch (createError){
      console.log('Bigger error', createError);
      setError(true);
      setIsLoading(false);
      setAuthMessage(createError);
    }
}

function signOut(dispatch:any, history:any) {
  firebaseAuth.signOut().then(()=>{
    localStorage.removeItem(tokenConst);
    dispatch({ type: UserActionTypes.SIGN_OUT_SUCCESS, ...emptyUserState });
    history.push(`${AppRoutes.SIGN_IN}`);
  });
}

async function deleteAccount(dispatch: any, history: any, userState: any):Promise<boolean> {
  //warn the user
  let message:string = 'Are you sure you want to delete your account? This will permanently delete all your data.';
  const confirmResult = await showDialog('Account Deletion!', message, 'OK', 'Cancel');

  if (confirmResult) {
    //next stage
    message = 'Type in your email address. If it matches your account, we can proceed';
    const result: PromptResult = await showPrompt('Delete Account', message);
    console.log('Result:', result);
    console.log('Userstate:', userState);
    if (!result.cancelled){
      if (result.value == userState?.localEmail){
        //email match...ask for one final confirmation
        const finalConfirmation = await showDialog('Account Deletion', 'Final check. Are you ready to delete your account?','Proceed', 'Cancel');
        if (finalConfirmation){
          Toast.show({text:'Deleting user data'});
          console.log('Deleting user account');
          //UNCOMMENT NEXT LINE IF YOU REALLY WANT TO LOSE ALL THE DATA!
          //await deleteAllData(userState?.firebaseUser, userState, dispatch);
          
          //after deleting all data, delete the firebase account
          Toast.show({text:'Deleting user account credentials'});
          await deleteUserAccount(userState?.firebaseUser);
          await showCapacitorAlert('Goodbye', 'We are sorry to see you go. Come back anytime.');
          return true;
        }
      }
      else {
        Toast.show({text:'Email did not match what we have on record...Account will not be deleted'});
        console.log('Email did not match');
        return false;
      }
    }
    else {
      console.log('Account will not be deleted');
      Toast.show({text:'Account will not be deleted'});
      return false;
    }

  }
  return false;

}

async function resetPassword(email: any, setIsLoading: any, setAuthMessage:any){
  if (!email || email == ''){
    setAuthMessage('Type in your email address');
    return;
  }
  setIsLoading(true);
  firebaseAuth.sendPasswordResetEmail(email).then((result)=> {
    console.log('reset status:', result);
    setAuthMessage('Check your email for information');
    setIsLoading(false);
  }).catch( (err) => {
    console.log('reset password error:', err);
    setAuthMessage('Reset error:' + err);
    setIsLoading(false);
  }
  ).finally( setIsLoading(false));

}



const showToast = async (msg: string) => {

  console.log('Showing toast');
  await Toast.show({
    text: msg
  });
};


export { 
  refreshToken,
  externalUserVerification,
  UserProvider, 
  useUserState, 
  useUserDispatch, 
  loginUser, 
  resetPassword,
  registerUser,
  signOut,
  deleteAccount,
  showToast};
