import React, { ReactFragment, useEffect, useState} from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Tab,
  Tabs,
  Box,
} from '@material-ui/core';

import {firebaseStorage} from '../../firebaseConfig';
import path from 'path';

import { createAimReportPdfUrl } from '../../common/reportGeneration';
import { ReportInfoActionTypes, ReportInfoContext, ReportInfoContextType } from '../../context/ReportContext';
import { singleReport } from '../../common/staticdata';
import { AimsScoreSchemaProps, EmailStatusSchemaProps, ReportDataSchemaProps, ReportSchemaProps } from '../../common/mongoSchemas';
import { setReportFileInfo, updateReportFileInfo } from '../../common/apiSetUtilities';
import { refreshToken, useUserState } from '../../context/UserContext';
import { generateDocument } from '../../common/reportDocxGeneration';
import { createEmailText, makeRawReportDataDisplayable } from '../../common/reportUtilities';

import { makeStyles, Theme } from '@material-ui/core/styles';
import {DropzoneArea} from 'material-ui-dropzone';
import { ReportSummary } from './components/ReportSummary';
import { EmailConfirmationDialog } from './EmailConfirmationDialog';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  previewChip: {
    minWidth: 160,
    // maxWidth: 410
  },
}));

// export type ReportPdfPreviewProps = {
//   isOpen:boolean,
//   setIsOpen:(value:boolean)=>void
// }

// First pass at the report dialog. The data is preloaded in this class so 
// not many async fetch calls are made in the base components.
// dlp is not sure if that is the correct way to do things...
// The assessor for the report should only be the person that is logged in.
// dlp is undecided about making this dialog/component rougly independent ie it
// will regather all the data needed from the assessment

// It is unclear how/where in the ui the instrument is to be selected.
//props:ReportPdfPreviewProps
export const ReportPdfPreviewDialog = ():JSX.Element=> {
  const classes = useStyles();
  // userInformation is UserProps
  // const {isOpen, setIsOpen}  = props;
  const {reportInfoState, reportInfoDispatch} = React.useContext(ReportInfoContext);
  // const [detailsTabValue, setDetailsTabValue] = React.useState(0);
  const [submitStatus, setSubmitStatus] = useState('');
  const [confirmEmailSentDialogOpen, setConfirmEmailSentDialogOpen] = useState(false);
  const [loadedPdfFile, setLoadedPdfFile] = React.useState<File[]>([]);
  // const [savedReportPdfName, setSavedReportPdfName] = React.useState<string|undefined>();
  const loggedInUser = useUserState();
  const [reportData, setReportData] = useState<ReportDataSchemaProps|undefined>();
  const {dbInformation, reportInformation, isReportPreviewVisible } = reportInfoState;
  const {firebaseUser} = loggedInUser;
  const [instructionsMessage, setInstructionsMessage] = React.useState('');
  const [pdfSource, setPdfSource] = useState<string|File|undefined>();

  // const {reportFileURL} = dbInformation??{};

  useEffect(()=>{
    const fetchData = async () => {
      const reportData = reportInfoState.reportInformation;
      reportData['aimsScore'] = reportInfoState.scoreInformation;
      setReportData(reportData);
      // why isnt dbInformation used?? dlp
      const reportDbData = reportInfoState.dbInformation;
      // Note that having a reportFileURL should be the same thing
      // as having a reportid.
      if(reportDbData){
        setPdfSource(reportDbData.reportFileURL);
      }
      // setDetailsTabValue(0);
      console.log('Reportinfo state on open', reportInfoState);
      const instructionMessageHTML = '';
      setInstructionsMessage(instructionMessageHTML);
      if(dbInformation?.emailStatus && loggedInUser.authLevel >= 5){
        if(dbInformation.emailStatus.sendRequested &&
           !dbInformation.emailStatus.sendVerified){
          setConfirmEmailSentDialogOpen(true);
        }        
      }
    };

    if(isReportPreviewVisible){
      fetchData();
    }    
  },[isReportPreviewVisible]);

  //looking at the pdf data before finalizing
  useEffect(()=>{
    // console.log('details', detailsTabValue, reportPdfFile);
    if(loadedPdfFile && loadedPdfFile.length === 1){
      setPdfSource(loadedPdfFile[0]);
    }else{
      setPdfSource(undefined);
    }
  }, [loadedPdfFile]);

  useEffect(() =>{
    if(pdfSource){
      if(pdfSource instanceof File){
        const reader = new FileReader();
        reader.onloadend=(e)=>{
          // console.log(e);
          const img = document.getElementById('reportFrame');
          if(img && e !== null && e.target?.result){
            img.setAttribute('src', e.target.result as string);
          }
        };
        reader.readAsDataURL(pdfSource);
      }else if(typeof pdfSource === 'string'){
        console.log('String');
        const img = document.getElementById('reportFrame');
        if(img){
          img.setAttribute('src', pdfSource as string);
        }
      }
    }
  }, [pdfSource]);

  const handleClose = () => {
  
    if(reportInfoDispatch){
      // setIsOpen(false);
      reportInfoDispatch({type:ReportInfoActionTypes.UPDATE_REPORTDIALOG, 
        isReportPreviewVisible:false}); 
      setLoadedPdfFile([]);
      setSubmitStatus('');
    }
  };

  const handleOpenBrowserEmail = async () =>{
    const fullReportToSave = createFullReportFromContextInfo(reportInfoState);
    if(!fullReportToSave) return;
    const emailToSend = createEmailText(fullReportToSave);
    if(emailToSend){
      const w = window.open(emailToSend, '_blank');
      // There is a report id so it is a finialized report ie
      // it can be sent.
      if(dbInformation?.reportId){
        const emailStatus:EmailStatusSchemaProps = {
          sendVerified:false,
          sendRequesterId:loggedInUser.emmauserId?loggedInUser.emmauserId:'',
          sendRequested:true,
        };
        const res = await updateReportFileInfo(firebaseUser, {reportId:dbInformation.reportId, emailData:emailStatus});
        if(!res){
          console.log('Emeila logging failed');
        }
      }
    }
  };

  const canReportBeFinalized = ():boolean =>{
    if(dbInformation?.reportId) return false;
    if(loadedPdfFile.length !== 1) return false;
    return true;
  };

  // there is some hacky circular logic going on between the context and report schemaprops
  const createFullReportFromContextInfo=(reportInfoState:ReportInfoContextType)
    :ReportSchemaProps|undefined => {
    if(!dbInformation) {
      return undefined;
    }
    // store the report into the database
    //Update the report data with stuff, replacing info from an existing
    // report doesn't seem like the best idea, but it is needed for debuging
    const reportData = reportInfoState.reportInformation;
    reportData['aimsScore'] = reportInfoState.scoreInformation;
    // a {...dbInformation}
    const fullReportToSave:ReportSchemaProps = {
      ...dbInformation,
      creationDate:new Date(),
      reportData:reportData
    };
    // since updating a report is illegal, only allow upload if
    // the report is fresh
    return fullReportToSave;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const fullReportToSave = createFullReportFromContextInfo(reportInfoState);
    if(!fullReportToSave){
      setSubmitStatus('Incorrectly generate report');
      return;
    }
    // since updating a report is illegal, only allow upload if
    // the report is fresh
    if(fullReportToSave.reportId) {
      setSubmitStatus('Report was previously saved');
      return;
    }
    const reportFinalizeTime = new Date();
    // In case the same file gets used, add hour, minute differences
    const identifyingTimeString = `_${reportFinalizeTime.getHours()}_${reportFinalizeTime.getMinutes()}.pdf`;
    // Can a report only be saved with a file? Limits/expected actions for report finalizing
    // need to be sorted out
    if(!fullReportToSave.reportId && loadedPdfFile.length===1){
      refreshToken(firebaseUser);
      setSubmitStatus(`Saving ${loadedPdfFile[0].name}`);
      const bucketPath = '__reports/';
      const baseBucketPath = path.join(bucketPath, fullReportToSave.assessmentId);
      const fullFileToSave = loadedPdfFile[0].name.replace('.pdf', `${identifyingTimeString}`);
      const bucketLocation =path.join(baseBucketPath, fullFileToSave);
      const uploadTaskSnapshot = await firebaseStorage.ref(bucketLocation).put(loadedPdfFile[0]);
      if(!uploadTaskSnapshot){
        setSubmitStatus(`Finalize failed: Could not save ${loadedPdfFile[0].name}`);
        return;
      }
      const fullUrl = await firebaseStorage.ref(uploadTaskSnapshot.metadata.fullPath).getDownloadURL();
      fullReportToSave.creationDate = reportFinalizeTime;
      fullReportToSave.reportFileStoragePath = uploadTaskSnapshot.metadata.fullPath;
      fullReportToSave.reportFileURL = fullUrl;
      
      // Set all the needed UI stuff now that save is finished
      // setSavedReportPdfName(loadedPdfFile[0].name);
      setLoadedPdfFile([]);//clear out all saved values, why? good questions
    }
    // should the report be saved if the file didn't go through????
    const res = await setReportFileInfo(firebaseUser, fullReportToSave);
    if(res && res.reportId){
      fullReportToSave.reportId = res.reportId;
      //inform context that report has been saved (so ugly)
      reportInfoDispatch({type:ReportInfoActionTypes.UPDATE_DBINFO, 
        dbInformation:{...dbInformation, reportId:res.reportId, 
          reportFileURL:res.reportFileURL, reportFileStoragePath:res.reportFileStoragePath}});
      const nowTime = (new Date(Date.now())).toLocaleTimeString('en-US');
      console.log('Report that was saved', res);
      setSubmitStatus(`Saved at: ${nowTime}`);
    }else{
      setSubmitStatus('Report was previously saved');
    }
  };

  // this can be turned into its own component
  const handleCreateDocx = ()=>{
    if(!reportData){
      setSubmitStatus('Could not save');
      return;
    } 
    const timeString = new Date().toLocaleString();
    const baseFileName = `${reportData.asmtSubjectName} by ${loggedInUser.firstname} on ${timeString}`;
    const cleanReportData = makeRawReportDataDisplayable(reportData);
    console.log('The clean data', cleanReportData);
    generateDocument(cleanReportData, baseFileName);
  };

  return (
    <React.Fragment>

      <Dialog
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'md'} // 'sm' || 'md' || 'lg' || 'xl'
        onClose={handleClose}
        open={isReportPreviewVisible}
      >
        <form onSubmit={handleSubmit}>
          <DialogContent>          
            <div className={classes.root}>
              {!(dbInformation?.reportId)&&(
                <div>                
                  <p>Do the following steps to create a final report:</p>
                  <ol>
                    <li>Click on the 
                      <Button
                        color="primary"
                        onClick={handleCreateDocx}
                        variant="contained"
                      >
                    Edit Final Report
                      </Button> button below to download a Word document (docx) of the final report.</li>
                    <li>Edit the downloaded file, as needed, in Word or Google docs and save it as PDF.</li>
                    <li><strong>Drag/Drop the PDF file</strong> in the box below.</li>
                    <li>You will automatically be moved to the next tab <span style={{color: '#3366ff'}}><strong>Preview Final Report</strong></span></li>
                    <li>Make sure it is all good and click on <span style={{color: '#ff6600'}}><strong>Finalize Report</strong></span></li>
                  </ol>                
                </div>
              ) }

              <div>
                <ReportSummary/>
                
                {(dbInformation?.reportId)?                  
                  ( 
                    <Typography
                      color="textPrimary"
                      variant="subtitle1"
                    >                      
                      {//a cute way of getting the first value without worrying about length issues
                      //[...reportPdfFile].shift()?.name
                      // `Report pdf is: ${savedReportPdfName}`
                        'Report pdf is: Present'
                      }
                    </Typography>
                  ):
                  (
                    <div>
                      <DropzoneArea
                        acceptedFiles={['application/pdf']}
                        // clearOnUnmount={false}//does not really work
                        dropzoneText="Drag/drop or click to preview report pdf"
                        filesLimit={1}
                        initialFiles = {loadedPdfFile}
                        maxFileSize={50000000}
                        // a hacky way to
                        onChange={(files) => {
                          if(files.length === 1 && loadedPdfFile.length ===1){
                            if(files[0].name !== loadedPdfFile[0].name){
                              setLoadedPdfFile(files);
                            }
                          }else{
                            setLoadedPdfFile(files);
                          }
                        }}
                  
                        // previewChipProps={{classes: { root: classes.previewChip } }}
                        // previewGridProps={{container: { spacing: 1, direction: 'row' }}}
                        previewText="Selected Report pdf"
                        showFileNames
                      />
                      <Button
                        color="primary"
                        onClick={handleCreateDocx}
                        style={{margin:5}}
                        variant="contained"
                      >
                        Edit Final Report
                      </Button>
                    </div>
                  )}                
              </div>              
              {pdfSource && <div>
                <iframe
                  height={600}
                  id="reportFrame"
                  width="100%"
                />
              </div>
              }
            </div>
          </DialogContent>
          <DialogActions>
            <Typography>
              {submitStatus}
            </Typography>
            {/* {(dbInformation?.reportId===undefined)??(
            <Button
              color="primary"
              onClick={handleCreateDocx}
            >
            Edit Final Report
            </Button>
          )} */}
            <Button
              color="primary"
              onClick={handleOpenBrowserEmail}
              size="large"
            >            
            Email   
            </Button>
            <Button
              color="primary"
              onClick={handleClose}
              size="large"
            >            
              {(dbInformation?.reportId)?'Done':'Cancel'}   
            </Button>
            <Button
              aria-disabled={!canReportBeFinalized()}
              color="primary"
              disabled = {!canReportBeFinalized()}
              size="large"
              type="submit"
            >
              {(dbInformation?.reportId)?'Report Complete':'Finalize Report'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <EmailConfirmationDialog
        isOpen={confirmEmailSentDialogOpen}
        setIsOpen={setConfirmEmailSentDialogOpen}
      />
    </React.Fragment>
  );
};

///LEgacy deletable

// useEffect(() => {
//   // console.log('the reprp',dbInformation);
//   const fetch = async(url:string|undefined)=>{
//     if(!url) return;
//     // const xhr = new XMLHttpRequest();
//     // xhr.responseType = 'blob';
//     // xhr.onload = (event) => {
//     //   const blob = xhr.response as Blob;
//     //   setPdfBlob(blob);
//     //   // const blob = new Blob([pdf.buffer], { type: 'application/pdf' });
//     // };
//     // xhr.open('GET', url);
//     // xhr.send();
  
//     // Or inserted into an <img> element
//     const img = document.getElementById('reportFrame');
//     if(img){
//       img.setAttribute('src', url);
//     }
//   };
//   fetch(dbInformation?.reportFileURL);
  
// }, [dbInformation]);


// const fillAndDispayPdf = async (reportToView:ReportDataSchemaProps) =>{
//   console.log('Report input', reportToView);
//   const ret = await createAimReportPdfUrl(reportToView);
  
//   const attachTarget = document.getElementById('reportFrame') as HTMLImageElement;
//   if(attachTarget){
//     attachTarget.src = ret;
//   } 
// };

// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: any;
//   value: any;
// }

// function TabPanel(props: TabPanelProps) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       aria-labelledby={`simple-tab-${index}`}
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       role="tabpanel"
//       {...other}
//     >
//       {value === index && (
//         <Box p={3}>
//           {children}
//         </Box>
//       )}
//     </div>
//   );
// }


// function a11yProps(index: any) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }
// eslint-disable-next-line @typescript-eslint/ban-types
// const handleDetailsTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
//   setDetailsTabValue(newValue);
// };
