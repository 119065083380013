import axios from 'axios';
import https from 'https';

import { 
  UserProps, 
  FirebaseUserProps,
  AnnotationSetFromApi,
  ErrorInformation
} from './entityUtilities';
import { 
  getScoreitApiEndpoint, 
  setAxiosDefaultHeaders 
} from './apiBaseUtilities';

import {
  AssessmentSchemaProps,
  EmmaUserSchemaProps,
  emptyUserMongoSchema,
  GenericScoreSchemaProps,
  InstrumentSchemaProps,
  ObservationSchemaProps,
  VideoSchemaProps,
  // PercentileScoreSchemaProps
} from './mongoSchemas';

import { 
  userData,
  deviceData, 
  videoData, 
  subjectData, 
  individualPlanData, 
  instructionalVideoData,
  derivedDataData,
  derivedDataTypeData,
  observationData,
  annotationSetsData,
  notificationData,
  asmtsTableUIData,
  // parentUIData
} from './staticdata';
import {

  allInstrumentData,
} from './instrumentLibraryData';
import { boolean } from 'yargs';

const scoreitApiEndpoint = getScoreitApiEndpoint();

// The getting functions

export async function getEmmaUserWithFilter(firebaseUser:FirebaseUserProps|undefined, filter:any)
:Promise<EmmaUserSchemaProps|undefined>{
  if(!scoreitApiEndpoint || !firebaseUser){
    return emptyUserMongoSchema;
  }

  const {uid, bearerToken } = firebaseUser;
  const apiendpoint = `${scoreitApiEndpoint}/emma-users?filter=${JSON.stringify(filter)}`;
  setAxiosDefaultHeaders(uid, bearerToken);
  // try{
  const responseData = await axios.get(apiendpoint);
  if(!responseData.data) return undefined;
  if(responseData.data.length === 0) return undefined;
  return responseData.data[0];
  // }catch(error){
  //   console.log('401 ERROR on /emma-users');
  //   return undefined;
  // }
}

export async function sendCloudMessageFromBackend(firebaseUser:FirebaseUserProps|undefined,  data: string, tokens:string[])
{

  if(!scoreitApiEndpoint || !firebaseUser){
    return false;
  }

  const {uid, bearerToken } = firebaseUser;
  console.log('Tokens passed:', tokens);
  const tokenString = {'tokens': tokens}
  const apiendpoint = `${scoreitApiEndpoint}/admin/sendCloudMessage/`  + data + `/` + JSON.stringify(tokenString);

  console.log('Sending to api endpoint:', apiendpoint);
  setAxiosDefaultHeaders(uid, bearerToken);
  // try{
  const responseData = await axios.get(apiendpoint);
  console.log('sendMessage response:', responseData);

}

export async function checkIfStudyidValid(firebaseUser:FirebaseUserProps|undefined, email:String, studyid: String) : 
Promise<boolean> {

  if(!scoreitApiEndpoint || !firebaseUser){
    return false;
  }
  if(email === undefined) { return false};

  let emailLowerCase = email.toLowerCase();
  const {uid, bearerToken } = firebaseUser;

  if (studyid === undefined) {return false};

  let studyidLowerCase = studyid.toLowerCase();

  //const filter = { 'where': { 'and': [{'email': emailLowerCase } , {'studyid': studyidLowerCase }] } };

  const filter = {'where':{'email':emailLowerCase } };
  const apiendpoint = `${scoreitApiEndpoint}/study-participants?filter=${JSON.stringify(filter)}`;
  setAxiosDefaultHeaders(uid, bearerToken);

  const responseData = await axios.get(apiendpoint);

  if(!responseData.data) return false;
  if(responseData.data.length === 0) return false;

  let participantData =  responseData.data[0];

  if (participantData?.studyid?.toLowerCase() === studyidLowerCase){
    return true;
  }


  return false;
}

export async function checkIfEmailIsTaken(firebaseUser:FirebaseUserProps|undefined, email:String) : Promise<boolean>{

  if(!scoreitApiEndpoint || !firebaseUser){
    return false;
  }

  console.log('Checking if email is taken:', email);

  let emailLowerCase = email.toLowerCase();
  const {uid, bearerToken } = firebaseUser;
  const filter = {'where':{'email':emailLowerCase } };

  const apiendpoint = `${scoreitApiEndpoint}/emma-users?filter=${JSON.stringify(filter)}`;
  setAxiosDefaultHeaders(uid, bearerToken);
  // try{
  const responseData = await axios.get(apiendpoint);

  console.log('Is email taken:', responseData);
  if(!responseData.data) return false;
  if(responseData.data.length === 0) return false;

  //this email is taken, meaning there's some emmauser entry that has this email
  //
  //what if the user has retyped their own email back?
  if (firebaseUser.uid == responseData.data[0].uid){
    console.log('firebaseuser uid:', firebaseUser.uid);
    console.log('found emmauser:', responseData.data[0]);
    return false;
  }

  return true;

}
// Used to set up the user that is logged in, so don't the subject is needed.
export async function getUserDataWithEmail(email:string, bearerToken:string, uid:string){
  if(!scoreitApiEndpoint){
    return userData.find(user=>user.email===email);
  }
  const filter = {'where':{'email':email } };
  const firebaseUser:FirebaseUserProps = {
    uid:uid,
    bearerToken:bearerToken,
    email:email,
    photoURL:'',
    displayName:email,
    firebaseUserObject:null
  };
  return getEmmaUserWithFilter(firebaseUser, filter);
}

// when users register with PHONE auth, firebaseUser object might not have email
// but the user might still be in the database under emmauser collection
export async function getUserDataWithUid (uid: string, bearerToken: string) {

  if(!scoreitApiEndpoint){
    return userData.find(user=>user.uid===uid);
  }
  const filter = {'where':{'uid':uid } };
  const firebaseUser:FirebaseUserProps = {
    uid:uid,
    bearerToken:bearerToken,
    email:'',
    photoURL:'',
    displayName:'',
    firebaseUserObject:null
  };
  return getEmmaUserWithFilter(firebaseUser, filter);
}

// Unsure if authLevel is needed for this. The results of this fuction
// are used for creating subjects, tags, assessments, etc.
// authlevel is now needed since this is used for admin purposes.
// dlp is still confused about what function should be taking responsibility for making the
// the gets usable by the UI
export async function getAllEmmaUsers(user:UserProps):Promise<any[]>{
  if(!scoreitApiEndpoint){
    // might be good to have some data for this
    return [];
  }

  let rawUserData:any = [];
  let errorLocation = 'emma-users';
  if(!user.firebaseUser) return rawUserData;
  const {uid, bearerToken } = user.firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  // The parentUIData needs things from multiple collections 
  //(emmausers,subjects, but you can only get the subjects from the device collection).
  // For now brute force method, maybe later on the loopback connections
  // get cleaned up and these can be done in one call

  // re-add the property filter later. like when the properties stop changing
  const filt = {
    // fields:{
    //   emmauserId:true,
    //   email:true,
    //   firstname:true,
    //   lastname:true,
    //   deviceIds:true,
    //   additionalProp1:true,
    //   authLevel: true,
    // }
  };
  const userFilter = `?filter=${JSON.stringify(filt)}`;
  const userUrl = `${scoreitApiEndpoint}/emma-users${userFilter}`;

  const usersResponse = await axios.get(userUrl);
  rawUserData = usersResponse.data;
  const subjectBaseUrl = `${scoreitApiEndpoint}/subjects`;
  // The relations are not yet set on the api (sept 25 2020) so do a bruteforcish search for the 
  // users subject details
  for(const user of rawUserData){
    errorLocation = '/subjects for /emma-user';
    const subjectFilter:any = {};
    subjectFilter['where'] = {emmauserid:user.emmauserId};
    // I think this is all of the properies, but
    // for now get everything. seems like a very lazy/poor way
    // of ensuring things work.
    // TODO: This is nice. make it work? someday?
    // subjectFilter['fields'] = { 
    //   name: true, 
    //   gender: true,
    //   dob: true,
    //   duedate: true,
    //   subjectId: true,
    //   gestationAgeDays: true,
    //   additionalProp1: true//{}  //additionalProp1:{notes:'ddsf'}
    // };
    const subjectUrl = `${subjectBaseUrl}?filter=${JSON.stringify(subjectFilter)}`;
    const subjectResponse = await axios.get(subjectUrl);
    const subjectData = subjectResponse.data;
    if(subjectData.length > 0){
      user['subject'] = subjectData[0];
    }
  }
  // }catch(error){
  //   console.log(`401 ERROR on ${errorLocation}`);          
  // }
  return rawUserData;
}

export async function getDevicesHealth(user:UserProps){
  //Duplicate of what is happening on the api
  if(user.authLevel < 10) return [];
  let deviceHealth:any = [];
  //hack for speed
  if(!scoreitApiEndpoint){
    for(const rawDevice of deviceData){
      const video = videoData.find(video=>video.deviceId===rawDevice._id);
      deviceHealth.push({ 'deviceName': rawDevice.name, 'lastSeen': (video)? video.timestamp : 'never' });
    }

    return deviceHealth;
  }
  if(!user.firebaseUser) return deviceHealth;
  // And the real thing
  const url = `${scoreitApiEndpoint}/devicesHealth`;
  const {uid, bearerToken } = user.firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  try{
    const responseData = await axios.get(url);
    if(!responseData.data) return deviceHealth;
    if(responseData.data.length === 0) return deviceHealth;
    deviceHealth = responseData.data;
  }catch(error){
    console.log('401 ERROR on /deviceHealth');
  }
  return deviceHealth;
}
export async function getSubjectUsingFilter(firebaseUser:FirebaseUserProps|undefined, filter:any){
  if(!scoreitApiEndpoint || !firebaseUser){
    // bad return but better than nothing? should probably remove this soonish
    return subjectData;
  }
  const apiendpoint = `${scoreitApiEndpoint}/subjects?filter=${JSON.stringify(filter)}`;
  
  const {uid, bearerToken } = firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  // try{
  const toReturn = await axios.get(apiendpoint);
  const rawData = toReturn.data;
  if(rawData && rawData.length > 0) return rawData[0];

  // }catch {
  //   console.log('401 ERROR');
  //   return {};
  // }
}
export async function getSubjectDataForUserId(firebaseUser:FirebaseUserProps|undefined,subjectOwnerId?:string){
  if(!scoreitApiEndpoint || !firebaseUser){
    // const userId = userForSubject.emmauserId;
    const subject = subjectData.find(subject=>subject.emmauserid===subjectOwnerId);
    return subject;
  }
  if(!subjectOwnerId) {
    console.log('Subject owner is not defined');
    return {};
  }
  const filter = {'where':{'emmauserid':subjectOwnerId} };
  // Do we need the await?
  return await getSubjectUsingFilter(firebaseUser, filter);
}

export async function getIndividualOTPlans(firebaseUser:FirebaseUserProps|undefined, user:UserProps){
  let individualOtPlans:any[] = [];
  console.log('Get OT Plans for:', firebaseUser);
  if(!scoreitApiEndpoint || !firebaseUser){
  // Find one subject, using local data
    const subject = await getSubjectDataForUserId(firebaseUser, user.emmauserId);
    console.log('[OTPlans] subject:', subject);
    if(!subject) return individualOtPlans;
    const plans = individualPlanData.filter(plan => plan.subjectId === subject._id);
    for(const plan of plans){
      const completePlan: any = plan;
      completePlan.childname = subject.name;
      completePlan.otvideos = [];
      for (const instructVidId of plan.instructionalvideoIds) {
        const ivideo = instructionalVideoData.find(ivideo=>ivideo._id===instructVidId);
        if (ivideo != null) {
          completePlan.otvideos.push(ivideo);
        }
      }
      individualOtPlans.push(completePlan);
    }
    return individualOtPlans;
  }
  if(!user.firebaseUser) return individualOtPlans;
  const {uid, bearerToken, email } = user.firebaseUser;
  let url = `${scoreitApiEndpoint}/individual-plans/byuser?parentemail=${email}`;
  if (email === null){
    // could be phone authenticated user, in which case firebase user wouldn't have email field
    url = `${scoreitApiEndpoint}/individual-plans/byuser?parentemail=${user.localEmail}`;
  }
  console.log('[OTPlans] getting otplans by email:', url);
  setAxiosDefaultHeaders(uid, bearerToken);
  try{
    const toReturn = await axios.get(url);
    const rawData = toReturn.data;
    individualOtPlans = rawData;
  }catch {
    console.log('401 ERROR');
    return {};
  }
  return individualOtPlans;
}

export async function getOTPlans(user:UserProps){
  let plans:any[] = [];
  if(!scoreitApiEndpoint){
    return instructionalVideoData;
  }
  const url = `${scoreitApiEndpoint}/instructional-videos`;
  if(!user.firebaseUser) return plans;
  const {uid, bearerToken } = user.firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);

  try{
    const responseData = await axios.get(url);
    plans = responseData.data;
  }catch(error){
    console.log('401 ERROR on /emma-users');          
  }
  return plans;
}

export async function getReports(firebaseUser:FirebaseUserProps|undefined, assessmentId:string){
  let plans:any[] = [];
  if(!scoreitApiEndpoint || !firebaseUser){
    return instructionalVideoData;
  }

  const filter = {'where':{'assessmentId':assessmentId} };
  const url = `${scoreitApiEndpoint}/reports?filter=${JSON.stringify(filter)}`;
  const {uid, bearerToken } = firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);

  try{
    const responseData = await axios.get(url);
    plans = responseData.data;
  }catch(error){
    console.log('401 ERROR on /reports');
  }
  return plans;
}

// Returns a ObservationSchemaProps, but can't make it official yet
export async function getObservationDataForVideoId(firebaseUser:FirebaseUserProps|undefined, videoId:string)
  :Promise<ObservationSchemaProps[]>{
  if(!scoreitApiEndpoint || !firebaseUser){
    return [];
  }

  const toUse:Record<string,Record<string,string>> = { where: {'videoId':videoId } };
  const url = `${scoreitApiEndpoint}/observations?filter=${JSON.stringify(toUse)}`;
  const {uid, bearerToken } = firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  // let resultData:ObservationSchemaProps[] = [];
  try{
    const responseData = await axios.get(url);
    if(responseData.data){
      return responseData.data;
    }
   
  }catch(error){
    console.log('401 ERROR on /observations');
           
  }
  return [];
}

export async function getInstruments(user:UserProps):
  Promise<InstrumentSchemaProps[]>{
  if(false || !scoreitApiEndpoint){
    return allInstrumentData;
  }
  if(!user.firebaseUser) return [];
  const url = `${scoreitApiEndpoint}/instruments/`;
  const {uid, bearerToken } = user.firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  // let resultData:InstrumentSchemaProps[] = [];
  // try{
  const responseData = await axios.get(url);
  if(responseData.data){
    console.log('Response from server:', responseData.data);
    const instruments:InstrumentSchemaProps[] = responseData.data;
    const instruments_reordered:InstrumentSchemaProps[] = [];
    let index = 0;
    instruments.slice().reverse().forEach(element => {
      instruments_reordered.push(element);
    });
    console.log('Instruments:', instruments);
    console.log('Reordered:',instruments_reordered);
    //return responseData.data;
    return instruments_reordered;
  }
  throw new Error('401 ERROR on /instruments');
  // }catch(error){
  //   console.log('401 ERROR on /instruments');
  // }
  // return resultData;
}

// Ug using video id needs to be changed to observation id. Old legacy that is basically everywhere...
// being able to request via observationid and userid might also be handy
// also for some reason this returns the annotation set type and an emmausertype
export async function getAnnotationSets(user:UserProps, videoId:string):Promise<AnnotationSetFromApi[]>{
  if(!scoreitApiEndpoint){
    return annotationSetsData;
  }
  if(!user.firebaseUser) return [];
  // need to not use the video as the source of the annotation set.
  const url = `${scoreitApiEndpoint}/annotation-sets/${videoId}`;
  const {uid, bearerToken } = user.firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  let resultData:any[] = [];
  // need to not eat the error so that the user knows what happened to the
  // request via the UI
  try{
    const responseData = await axios.get(url);
    if(responseData.data){
      resultData = responseData.data;
    }
  }catch(error){
    console.log('401 ERROR on /annotation-sets');
  }
  return resultData;
}

export async function getAnnotationSetsViaObservationId(firebaseUser:FirebaseUserProps|undefined, observationId:string):Promise<AnnotationSetFromApi[]>{
  if(!scoreitApiEndpoint){
    return annotationSetsData;
  }

  console.log('annot-getting', observationId);
  if(!firebaseUser) return [];
  const filter:Record<string, any> = {where:{'observationId':observationId} };
  filter.include = [{relation:'emmaUser'}];
  // need to not use the video as the source of the annotation set.
  const url = `${scoreitApiEndpoint}/annotation-sets?filter=${JSON.stringify(filter)}`;
  console.log('annot-getting', url);
  const {uid, bearerToken } = firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  let resultData:any[] = [];
  // need to not eat the error so that the user knows what happened to the
  // request via the UI
  // try{
  const responseData = await axios.get(url);
  if(responseData.data){
    resultData = responseData.data;
    // console.log('annot-getting', resultData);
    return resultData;
  }
  throw new Error('401 ERROR on /annotation-sets');
  // }catch(error){
  //   console.log('401 ERROR on /annotation-sets');
  // }
}

export async function getVideoDataForFilterAndExtra(
  firebaseUser:FirebaseUserProps|undefined, 
  filter:any, 
  extraParams?:string)
  :Promise<VideoSchemaProps[]>{
  // The extra is needed because of the special cases in the api call
  if(!scoreitApiEndpoint || !firebaseUser){
    return [];//videoData;
  }
  // double ugh as of Sept 30 2020 the get for the videos doesn't work without
  // a user email. Thus this needs a hack
  let url = `${scoreitApiEndpoint}/videos`;
  url = `${url}?filter=${JSON.stringify(filter)}`;
  if(extraParams){
    url = `${url}&${extraParams}`;
  }

  if(filter.where?.videoId){
    url = `${scoreitApiEndpoint}/videos/${filter.where?.videoId}`;
  }
  const {uid, bearerToken } = firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);

  // setAxiosDefaultHeaders(uid, 'bearerToken');
  let resultData:VideoSchemaProps[] = [];
  // try{
  const responseData = await axios.get(url);
  resultData = responseData.data;
  // }catch(error){
  //   console.log(error);
  //   console.log('401 ERROR on /video');           
  // }
  return resultData;
}

export async function getVideoData(firebaseUser:FirebaseUserProps|undefined, deviceId='', parentMail='',
  startDate:Date|undefined, endDate:Date|undefined){
  
  if(!scoreitApiEndpoint || !firebaseUser){
    return videoData;
  }
  if(!startDate || ! endDate) return [];
  const startDateUTC = new Date(startDate);
  const endDateUTC = new Date(endDate);
  // Ick very lax
  let toUse:any = { where: { and: [{timestamp:{gt:startDateUTC} } , {timestamp:{lt:endDateUTC} }] } };
  if (deviceId !== ''){
    //include device id in the filter
    toUse = { where: { and: [{timestamp:{gt:startDateUTC} } , {timestamp:{lt:endDateUTC} }, 
      {deviceId: deviceId}] } };
  }
  let extraParam:string|undefined = undefined;
  if (parentMail !== ''){
    extraParam = `parentemail=${parentMail}`;
  }
  // Is the await needed?
  return await getVideoDataForFilterAndExtra(firebaseUser, toUse, extraParam);
}

export async function getDerivedDataVideo(user:UserProps, videoId:string){
  if(!scoreitApiEndpoint){
    const video = videoData.find(video=>video._id===videoId);
    // What the bleep! this is copied from vide.controller getallderiveddata
    const obs = observationData.find(obs=>obs.videoId ===video?._id);
    const dds = derivedDataData.filter(dd=>dd.observationId === obs?._id);
    const ddObjects:any[] = [];
    for(const dd of dds){
      const ddType = derivedDataTypeData.find(ddType=>ddType._id===dd.deriveddatatypeId);
      let ddname = '';
      if (ddType) {
        ddname = ddType.name;
      }
      ddObjects.push({...dd, additionalProps:{name:ddname}});
    }
    return ddObjects;
  }
  if(!user.firebaseUser) return [];
  console.log('Trying to get dereived data');
  const url = `${scoreitApiEndpoint}/videos/deriveddata/${videoId}`;
  const {uid, bearerToken } = user.firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  try{
    const response = await axios.get(url);
    const deriveddata = response.data;
    return deriveddata;
  }catch(error){
    console.log(`401 ERROR on /videos/deriveddata/${videoId}`);  
    return [];        
  }
}

export async function getDerivedDataStatuses(user:UserProps, url:string, filters:any={}){
  const displayableDerivedData:any[] = [];
  if(!user.firebaseUser) return displayableDerivedData;
  if(!scoreitApiEndpoint){
    ///Figure out what to do for offline stuff later.
    // const obsData = await getObservationDataForVideoId(user.firebaseUser, '');
    // const videoData = await getVideoData(user.firebaseUser,'','', undefined, undefined);
    // //Derived data does now about the original video only the observation and the result.
    // //Using the observation we can find out about the video

    // for(const rawDerivedData of derivedDataData){
    //   const placeHolder:any = undefined;
    //   const completeDD = {...rawDerivedData, video:placeHolder};
    //   const obsForDerivedData = obsData.find(obs=>obs._id===rawDerivedData.observationId);
    //   const videoForObs = videoData.find(video=>video._id===obsForDerivedData?.videoId);
    //   if(videoForObs){
    //     completeDD['video'] = videoForObs;
    //   }
    //   displayableDerivedData.push(completeDD);
    // }
    return displayableDerivedData;
  } else {
    
    const ddFilter = { fields: { id:true, deriveddataId:true, observationId:true, taskstatusId:true, 
      deriveddatatypeId:true, percentageCompleted:true}};
    const {uid, bearerToken } = user.firebaseUser;
    setAxiosDefaultHeaders(uid, bearerToken);
    let requestUrl = url;
    let append = '';
    // console.log('Calling get derived data', filters);
    const ddCombined = {...filters, ...ddFilter};
    if(ddCombined.length !==0){
      append='?';
      // Loopback makes the search by json.stringify possible
      requestUrl+=`${append}filter=${JSON.stringify(ddCombined)}`;
    }
  
    const obsUrl = `${scoreitApiEndpoint}/observations`;
    const videoUrl = `${scoreitApiEndpoint}/videos`;
    return axios.get(requestUrl).then(async (response) =>{
      const derivedData = response.data;
      const changedDataList = [];
      // The for...of loops are new thing
      for(const dd of derivedData){
        const changedData = {...dd};
  
        const obsRegUrl =  `${obsUrl}/${changedData.observationId}`;
        const obsDataReq = await axios.get(obsRegUrl);
        const obsData = obsDataReq.data;
        // should be single       
        const videoRegUrl =  `${videoUrl}/${obsData.videoId}`;
        //Note that the video getby id is a hack case. normally
        // a single element is returned.
        const video = await axios.get(videoRegUrl);
        if(video.data && video.data.length > 0){
          changedData['video'] = video.data[0];
        }
        changedDataList.push(changedData);
      }
      return changedDataList;    
    });
  }
}

export async function getNotifications(user:UserProps){
  let notifications:any[] = [];
  if(!scoreitApiEndpoint){
    notifications = notificationData;
  }else{
    if(!user.firebaseUser) return notifications;
    const url = `${scoreitApiEndpoint}/notifications`;
    const {uid, bearerToken } = user.firebaseUser;
    setAxiosDefaultHeaders(uid, bearerToken);
    try{
      const responseData = await axios.get(url);
      notifications = responseData.data;
    }catch(error){
      console.log('401 ERROR on /notifications');          
    }
  }
  return notifications;
}

//At this point the return value is unknown. The relations filters
// returns a lot of things and im not sure which will stick around...dlp
// last check it returns the asmt data and observations with annotations
// The asmt, observations, and annotations having values that match
// the schema from the api
export async function getAssessmentsAndRelationsForTable(firebaseUser:FirebaseUserProps|undefined, asmtFilters?:any):Promise<AssessmentSchemaProps[]>{
  if(!scoreitApiEndpoint || !firebaseUser){
    return asmtsTableUIData;
  }
  console.log('getAssessmentsAndRelations from API with filter:', asmtFilters);
  // Adding a fields filter would be a good optimization.
  const baseUrl = `${scoreitApiEndpoint}/assessments`;
  let url = baseUrl;
  if(asmtFilters){
    const stringedFilter = JSON.stringify(asmtFilters);
    url = `${scoreitApiEndpoint}/assessments?filter=${stringedFilter}`;
    console.log('API url:', url);
    console.log('Filter:', stringedFilter);
  }
  const {uid, bearerToken } = firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  let asmts:AssessmentSchemaProps[] = [];
  const asmtResponse = await axios.get(url);
  asmts = asmtResponse.data;
  console.log('# of Asmts from API:', asmts.length);
  return asmts;
}

export async function getAssessmentsAndRelationsForTableFromAnnotationSets(firebaseUser:FirebaseUserProps|undefined, annotSetFilters?:any):Promise<ObservationSchemaProps[]>{
  if(!scoreitApiEndpoint || !firebaseUser){
    return [];
  }
  // Adding a fields filter would be a good optimization.
  const baseUrl = `${scoreitApiEndpoint}/annotation-sets`;
  let url = baseUrl;
  if(annotSetFilters){
    const stringedFilter = JSON.stringify(annotSetFilters);
    url = `${baseUrl}?filter=${stringedFilter}`;
  }
  const {uid, bearerToken } = firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  const annotSetResponse = await axios.get(url);
  const annotSets = annotSetResponse.data;

  // For this call we are only interested in the observations
  // hack
  const observations:ObservationSchemaProps[] = [];
  annotSets.forEach((annotSet:any) => {
    if(annotSet.observation){
      observations.push(annotSet.observation);
    }
  });
  
  return observations;
}


//TODO connect to api
export function getActivitiesForRecordingDevices(videoId:string){
  const activityddType = '5e77d2930e8d6b212482f8ec';
  const obs = observationData.find(obs=>obs.videoId ===videoId);
  const ddEntity = derivedDataData.find(dd=>(dd.observationId === obs?._id && dd.deriveddatatypeId===activityddType));
  console.log('Entities', ddEntity);
  //TODO:Make a list of theses things later
  // for (const t= ) {
  //   const m = re.exec(marker.StartTimeRaw);
  //   if (m == null) continue;
  //   const t = parseInt(m[2]) * 60 + parseInt(m[3]);
  //   markers.push({
  //     id: i,
  //     time: t,
  //     color: '#ffc837',
  //     title: marker.MotorActionFriendlyName,
  //   });
  //   i++;
  // }
  return [];
}


//****** */ Move these to an api services file? maybe? different than just getting data
export async function calculateScoreById(firebaseUser:FirebaseUserProps|undefined, 
  asmtTaggerId:string,
  assessmentId: string,
  instrumentId: string,
  scoreTypeName?:string):Promise<GenericScoreSchemaProps|ErrorInformation>{
  if(!scoreitApiEndpoint || !firebaseUser){
    // Fill this in with a test score type thing
    return {totalScore:-1};
  }

  const filter:Record<string,any> = {'where':{'userIdSource':asmtTaggerId, 'instrumentTypeId': instrumentId } };
  if(scoreTypeName){
    filter.where = {'userIdSource':asmtTaggerId, 'instrumentTypeId': instrumentId, 'scoreTypeName':scoreTypeName };
  }
  const url = `${scoreitApiEndpoint}/assessments/${assessmentId}/score?filter=${JSON.stringify(filter)}`;
  const {uid, bearerToken } = firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  const errorInfo:ErrorInformation = {isErrorType:true, message:'meh'};
  // Making the try/catch more generic for api gets would be 
  // great. Currently they are all over the place.
  try{
    // getting proper return types from the api would be nice
    const responseData = await axios.get(url);
    if(responseData.data){
      return responseData.data as GenericScoreSchemaProps;
    }
    errorInfo.message = 'No score data retrieved';
  }catch(error){

    errorInfo.message = '401 ERROR on /assessments/id/score';
    errorInfo.misc = error;
    console.log(errorInfo);
  }

  return errorInfo;
}

export async function getPercentileData(age: number,  score: number):
  // Fairly sure we don't want multiple return types
  Promise<Record<string, unknown>|ErrorInformation>{
  // console.log('getPercentileData: age ', age);
  // console.log('score ', score);
  // const ebsurl = 'https://emma.us-west-2.elasticbeanstalk.com/';

  const url = `${scoreitApiEndpoint}/assessments/${age}/${score}/percentiles`;
  console.log('Getting percentiles from:', url);
  // const url = `${ebsurl}/${age}/${score}`;
  const errorInfo:ErrorInformation = {isErrorType:true, message:'meh'};
  try{
    
    const agent = new https.Agent({
      rejectUnauthorized: false
    });

    const responseData = await axios.get(url, {httpsAgent: agent});
    if(responseData.data){
      return responseData.data as Record<string, unknown>;
    }
  }catch(error){
    errorInfo.message = 'ERROR on percentile data url fetch:';
    errorInfo.misc = error;
    console.log(errorInfo);
  }
  return errorInfo;
}

///How to do a filter
// ?filter={"where":{"or":[{"id":1},{"id":2},...,{"id":20"},{"id":21}]}}
// { 
//   "where": 
//  {"or":[
//      {"SerialNumber": "DEV001"},
//  {"SerialNumber": "57K28BS98063D"}]   
//    },   
//      "include": [
//        {
//          "relation": "subject",
//          "scope": {
//            "fields": {
//              "subjectId":true,
//              "name": true,
//              "gender":true,
//              "dob":true,
//              "duedate":true     
//            }
//          }
//        }
//      ]
//  }