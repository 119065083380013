
import React, { useEffect, useState } from 'react'; 
import {
  CardContent as MuiCardContent,
  Card as MuiCard,
  Typography as MuiTypography,
  Box,
  Button,
  Icon,
  Color
} from '@material-ui/core';
import { spacing } from '@material-ui/system';

import {
  Activity,
  Smile,
  Video
} from 'react-feather';

import styled from 'styled-components';
import * as RouteNames from '../../../../common/routeNames';

import { useUserState } from '../../../../context/UserContext';
import { createAssessmentListForUI } from '../../../../common/utilities';
import { adjustAvailableAsmts, AsmtTableProps, AssessmentListActionTypes, useAsmtTableDispatch } from '../../../../context/AssessmentListContext';
const IconWrapper = styled.button`
  background:transparent;
  height:48px;
  width:48px;
  position: absolute;
  right: 16px;
  top: 32px;
  border-radius: 50%;

  border: 2px solid #ef834d;
  // svg {
  //   width: 32px;
  //   height: 32px;
  // }
  color: ${props => props.color};
`;
const Typography = styled(MuiTypography)(spacing);
const Card = styled(MuiCard)`
  color: ${props => props.color};
  margin-bottom: 2px;
`;
//  background: ${props => props.background};
const CardContent = styled(MuiCardContent)`
  position: relative;
  margin: 2px;

  &:last-child {
    padding-bottom: 4px;
  }
`;

export type AsmtCardProps = {

  handleRedirect:(route:any)=>void 
  daysBack?:number
  withItems?:boolean
  withOther?:boolean
  text?:string
  color?:string
}

// Basically a copy of the stats element
export const RecentAsmtCard=(props:AsmtCardProps):JSX.Element=>{
  const {handleRedirect, daysBack, withItems, withOther, text, color} = props;
  const asmtTableDispatch = useAsmtTableDispatch();
  const [searchForCard, setSearchForCard] = useState<AsmtTableProps>({allAssessments:[]});
  const user = useUserState();
  const [amount, setAmmount] = useState(0);
  const [title, setTitle] = useState('Unknown card');

  useEffect(() =>{
    let asmtSearchProps:AsmtTableProps|undefined = undefined;
    if(text){
      setTitle(text);
    }
    // Select a default date range

    if(daysBack){
      if(+daysBack === 1){
        setTitle('Today');
      }else if (+daysBack === 7){
        setTitle('Past 7 days');
      }
      else {
        setTitle('Get started');
      }
      if(+daysBack>0){
        const initialEndDate = new Date();
        initialEndDate.setDate(initialEndDate.getDate() + 1);
    
        const initialStartDate = new Date(initialEndDate);
        initialStartDate.setDate(initialEndDate.getDate() - daysBack);
        const startDateUTC = new Date(initialStartDate);
        const endDateUTC = new Date(initialEndDate);
        
        asmtSearchProps = {startDate:startDateUTC, endDate:endDateUTC, disableDates:false, findWithNoItems:false, findWithNoReport:false, allAssessments:[]};
      }
    }else if(withItems !== undefined){
      console.log('Check items', withItems);
      asmtSearchProps = {findWithNoItems:withItems, findWithNoReport:false, disableDates:true, allAssessments:[]};
    }else if(withOther !== undefined){
      asmtSearchProps = {findWithNoItems:false, findWithNoReport:withOther, disableDates:true, allAssessments:[]};      
    }
    hackCount(asmtSearchProps);
  }, []);

  // A dirty way of setting the search params for the card and getting an initial count without changing the context
  const hackCount = async (asmtSearchProps:AsmtTableProps|undefined) => {
    if(!asmtSearchProps) return;
    setSearchForCard(asmtSearchProps);
    const asmtList = await adjustAvailableAsmts(AssessmentListActionTypes.PREACTION, asmtSearchProps, user.firebaseUser, undefined);
    if(asmtList){
      setAmmount(asmtList.length);
    }
  };

  return (
    <Card style={{backgroundColor:'#EFF7FF'}} >     
      <CardContent>
        <Typography
          gutterBottom
          variant="h3"
        >
          {amount>0 && <Box fontWeight="fontWeightRegular">{amount}</Box>}
        </Typography>
        <Typography
          gutterBottom
          mb={0}
          mt={3}
          variant="h5"
        >
          {title}
        </Typography>
        
        <Typography
          color="primary" 
          gutterBottom
          mb={0}
          mt={3}
          onClick={()=>{
            if(handleRedirect) {
              asmtTableDispatch({type:AssessmentListActionTypes.SETSEARCH, ...searchForCard});
              handleRedirect({pathname:RouteNames.ASSESSMENTS, state:{backpage:RouteNames.DASHBOARD}});
            }
          }}
          variant="body2"
        >
            View
        </Typography>
        {/* } */}
        <IconWrapper 
          color={color}
        >
          <Activity
            onClick={()=>{ 
              if(handleRedirect) {
                asmtTableDispatch({type:AssessmentListActionTypes.SETSEARCH, ...searchForCard});
                handleRedirect({pathname:RouteNames.ASSESSMENTS, state:{backpage:RouteNames.DASHBOARD}});
              }}}/>
        </IconWrapper>
        
      </CardContent>
    </Card>
  );
};