import React,  { useState, useContext }  from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { UserAndAssessmentTable } from '../../components/FamilyManagement/UserAndAssessmentTable';
import { UserInfoDialog } from '../../dialogs/UserInfoDialog/UserInfoDialog';
import { emptyUserState, UserProps } from '../../common/entityUtilities';
import NewAssessmentDialog from '../../dialogs/NewAssessmentDialog/NewAssessmentDialog';
import { AssessmentDeleteDialog } from '../../dialogs/AssessmenteDeleteDialog/AssessmentDeleteDialog';
import { defaultGeneralControlInfo, GeneralControlActionTypes, GeneralControlContext, generalControlReducer } from '../../context/GeneralControlContext';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    flexDirection:'column',
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export function Management():JSX.Element {

  const classes = useStyles();
  // the context for the report creation
  // const [generalControlState, generalControlDispatch] = React.useReducer(generalControlReducer, defaultGeneralControlInfo);

  const {generalControlState, generalControlDispatch} = useContext(GeneralControlContext);
  const {isUserInfoDialogVisible, isNewAssessmentDialogVisible} = generalControlState;

  const [parentCreateDialogIsOpen, setParentCreateDialogIsOpen] = useState(false);
  const [asmtCreateDialogIsOpen, setAsmtCreateDialogIsOpen] = useState(false);
  // null is used to reset the ui, kind of hackish...
  const [userToUpdate, setUserToUpdate] = useState<UserProps|null>(null);
  const useDialogComplete= () =>{
    // Reset the user to create/edit
    // doesn't seem to work if you reset in the dialog.
    setUserToUpdate(null);//reset the ui
    // setParentCreateDialogIsOpen(false);
    setParentCreateDialogIsOpen((prev)=> !prev);
  };

  const userDialogComplete = ():void => {
    generalControlDispatch({type:GeneralControlActionTypes.USERINFO_DIALOG, 
      isUserInfoDialogVisible:false}); 
  };
  const newAsmtDialogComplete = ():void => {
    generalControlDispatch({type:GeneralControlActionTypes.NEWASMT_DIALOG, 
      isNewAssessmentDialogVisible:false}); 
  };

  return(

    <div className={classes.root}>
      {/* <GeneralControlContext.Provider value={{generalControlState,generalControlDispatch}}> */}
      <UserAndAssessmentTable/>

      <UserInfoDialog
        dialogComplete = {userDialogComplete}
        isOpen={isUserInfoDialogVisible}
        userInformation={userToUpdate}
      />
      <NewAssessmentDialog
        dialogComplete = {newAsmtDialogComplete}
        isOpen={isNewAssessmentDialogVisible}
        parentInformation={emptyUserState}
      /> 
      {/* <AssessmentDeleteDialog
        assessmentProp={selectedAssessment}
        onClose={handleConfirmationClose}
        open={openConfirm}
      /> */}
      {/* <AssessmentDetails 
        asmtData={selectedAssessment}
        handleAssessmentEdit={handleAssessmentEdit}
        instruments={instruments}
        isCreateAsmtDialogOpen = {isCreateAsmtDialogOpen}
        onClose={handleAssessmentDetailsClose} 
        onRefresh = {handleAsmtRefresh} 
        onSave={handleAssessmentDetailsSave}
          
        open={assessmentDetailsOpen}
      /> */}

      {/* <AssessmentRecordDialog 
        assessmentProp={selectedAssessment}
        onClose={handleRecordClose}
        open={openRecord}
      /> */}
      {/* <ReportPdfPreviewDialog /> */}

      {/* <ConfirmationDialog
        cancelText = "Cancel"
        confirmText = "Proceed"
        message = {recordConfirmationMessage}
        onCancel = {onRecordCancel}
        onConfirm = {onRecordConfirm}
        open={recordConfirm}
        title = {recordConfirmationTitle}
      /> */}
      {/* <Snackbar
        autoHideDuration={4000}
        message = {toasterMessage} 
        onClose={handleToasterClose}
        open={toasterOpen}
      /> */}
      {/* </GeneralControlContext.Provider> */}
    </div>
  );
}
