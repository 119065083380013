import React, { useEffect } from 'react';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  TextField,
  Box,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableHead,
  TableContainer,
  Paper,
  FormControl,
  FormGroup
} from '@material-ui/core';

import { 
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core/styles';
import { ReportInfoActionTypes, ReportInfoContext } from '../../context/ReportContext';
import { MonthAndDayInput } from '../Inputs';
import { convertMonthDayStringText } from '../../common/reportUtilities';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    // card: {
    //   maxWidth: '100%',
    //   minWidth: '0',
    //   width:'100%',
    //   margin: 'auto',
    //   transition: '0.3s',
    //   boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    //   '&:hover': {
    //     boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)'
    //   }
    // },
    // cardcontent: {
    //   textAlign: 'left',
    //   // padding: theme.spacing.unit * 3
    // },     
    table: {
      minWidth: 700,
    }, 
  }),
);
// was going for a simple data grid, but it failed on firefox 70. 
// by the time dlp figured out it was the firefox version
// the table version was half written
//https://codesandbox.io/s/6czle?file=/demo.tsx
// https://codesandbox.io/s/material-ui-editable-tables-wsp0c?file=/src/index.js:4731-4746
// https://codesandbox.io/s/3qdnv?file=/demo.tsx:2385-2389
// since this is a modal dialog i suspect teh useState stuff is over kill.
// but if it does get moved out a dialog then it could be handy
export const AimsScoreInfoTable= ():JSX.Element=>{

  const classes = useStyles();
  // const {total, percentile, ...rest} = aimsScoreSample;
  const {reportInfoState, reportInfoDispatch} = React.useContext(ReportInfoContext);
  // assumes aims type scoring
  const {total, percentile, ...rest} = reportInfoState?.scoreInformation;
  const {subjectAgeMonthDaysForAsmt, subjectCorrectedAgeMonthDaysForAsmt} = reportInfoState?.reportInformation;

  const [subjectAgeMonthDaysString, setSubjectAgeMonthDaysString] = React.useState('');
  // order of the table should be...
  // prone/supine/stting/standing
  const aimsSubscales:Record<string,any>[]= 
  [{...rest.prone, name:'Prone', key:'prone'}, {...rest.supine, name:'Supine', key:'supine'},
    {...rest.sitting, name:'Sit', key:'sitting'},{...rest.standing, name:'Stand', key:'standing'}];


  useEffect(() =>{
    if(subjectAgeMonthDaysForAsmt){
      const normalAge = convertMonthDayStringText(subjectAgeMonthDaysForAsmt);
      if(normalAge === ''){
        setSubjectAgeMonthDaysString('Bad age variables');
        return;
      }
      
      let ageString=`Age at assessment: ${normalAge}.`;
      
      if(subjectCorrectedAgeMonthDaysForAsmt){
        const corAge =  convertMonthDayStringText(subjectCorrectedAgeMonthDaysForAsmt);
        if(corAge !== ''){
          ageString+=`Corrected to: ${corAge}.`;

        }
      }
      setSubjectAgeMonthDaysString(ageString);
    }
  },[subjectAgeMonthDaysForAsmt, subjectCorrectedAgeMonthDaysForAsmt]);

  return (          
    // no margin???
    <TableContainer component={Paper}>
      <Table
        aria-label="spanning table"
        className={classes.table}
        size="small" 
      >
        <TableHead>
          <TableRow>
            <TableCell
              align="left"
              colSpan={3}
            >              
              <FormControl>
                <FormGroup
                  //using FormControl and Formgrup is overkill...
                  // and should be replaced with something simpler
                  aria-label="position"
                  row
                >
                  <Typography
                    color="secondary"
                    gutterBottom
                    variant={'h6'}
                  >
	                  Motor Score information
                  </Typography>
                  <TextField
                    multiline
                    style={{ width: '100%' }}
                    value={subjectAgeMonthDaysString}
                  />
                </FormGroup>
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: 25 }}>Subscale</TableCell>
            <TableCell
              align="center"
              style={{ width:100 }}
            >Subscale Score</TableCell>
            <TableCell
              align="center"
              style={{ width: 200 }}
            >Highest Item Achieved</TableCell>
            <TableCell
              align="left"
            >Notes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {aimsSubscales.map((value) => (              
            <TableRow key={value.key}>
              <TableCell>{value.name}</TableCell>
              <TableCell align="center">{value.score}</TableCell>
              <TableCell align="center">{value.maxItemName}</TableCell>
              <TableCell >
                <TextField 
                  onChange={(event) => {
                    // due to issues with objects in objects, for now pass all the info 
                    // related to the item to the dispatcher
                    reportInfoDispatch({type:ReportInfoActionTypes.UPDATE_SCORE, 
                      scoreInformation:{[value.key]:{
                        notes:event.target.value, 
                        maxItemName:value.maxItemName,
                        score:value.score }}}); 
                  }}
                  style={{ width: '100%' }}
                  value={value.notes}
                />
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={1}>Total</TableCell>
            <TableCell align="right">{total.score as number}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Percentile</TableCell>
            <TableCell
              align="left" 
              colSpan={3}
            >
              <TextField 
                onChange={(event) => {
                  reportInfoDispatch({type:ReportInfoActionTypes.UPDATE_SCORE, 
                    scoreInformation:{
                      percentile:{
                        information:event.target.value
                      }
                    }
                  }); 
                }}
                style={{ width: '100%' }}
                value={percentile.information}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};