import axios from 'axios';

import { 
  getScoreitApiEndpoint, 
  setAxiosDefaultHeaders 
} from './apiBaseUtilities';

import { 
  FirebaseUserProps
} from './entityUtilities';
import { deleteCloudVideo } from './utilities';
import { AssessmentSchemaProps } from './mongoSchemas';
import { getSubjectDataForUserId } from './apiGetUtilities';
import { firebaseAuth } from '../firebaseConfig';
import { Toast } from '@capacitor/toast';
const scoreitApiEndpoint = getScoreitApiEndpoint();

export async function deleteAssessment(firebaseUser:FirebaseUserProps, assessmentId:string){
  if(!scoreitApiEndpoint){
    return false;
  }

  const url = `${scoreitApiEndpoint}/assessments/${assessmentId}`;
  try{
    const {uid, bearerToken } = firebaseUser;
    setAxiosDefaultHeaders(uid, bearerToken);
    const response = await axios.delete(url);

    // console.log('The respons', response);
    if(response.status === 204){
      console.log('Deleted asmt');
      return true;
    }

    return false;
  }catch(error){
    console.log('API ERROR:',error);
  }
  return false;
}

export async function deleteNotification(firebaseUser: FirebaseUserProps, notificationId: string){

  if(!scoreitApiEndpoint){
    return false;
  }

  const url = `${scoreitApiEndpoint}/notifications/${notificationId}`;
  try{
    const {uid, bearerToken } = firebaseUser;
    setAxiosDefaultHeaders(uid, bearerToken);
    const response = await axios.delete(url);

    // console.log('The respons', response);
    if(response.status === 204){
      console.log('deleted notification:', notificationId);
      return true;
    }

    return false;
  }catch(error){
    console.log('API ERROR:',error);
  }
  return false;
}



export async function deleteVideo(firebaseUser: FirebaseUserProps, videoId: string, videoObject:any){

  if(!scoreitApiEndpoint){
    return false;
  }

  console.log('Video to delete:', videoId);
  console.log('Video Object to delete:', videoObject);
  const deleteVideoUrl = `${scoreitApiEndpoint}/videos/${videoId}`;
  try{

    const {uid, bearerToken } = firebaseUser;
    setAxiosDefaultHeaders(uid, bearerToken);
    const response = await axios.delete(deleteVideoUrl);
    if (response.status === 204){
      console.log('Deleted video:', videoId);
      deleteObservation(firebaseUser, videoId);
    } 
    else { return false;}

  }catch (err){
    console.log('Error deleting video:', err);
    return false;
  }
  finally {
    console.log('Deleting cloud video file as well');
    deleteCloudVideo(firebaseUser, videoObject);
  }

}

export async function deleteObservation(firebaseUser: FirebaseUserProps, videoId:string) {

  const filter:Record<string, any> = {where:{'videoId':videoId} };
  const url = `${scoreitApiEndpoint}/observations/?filter=${JSON.stringify(filter)}`;
  try{
    const {uid, bearerToken } = firebaseUser;
    setAxiosDefaultHeaders(uid, bearerToken);
    const responseData = await axios.get(url);

    if(responseData.data){
      const resultData = (responseData.data);
      console.log('observation item with that videoid', resultData);
      if (resultData.length > 0){
        console.log('Observation to delete:', resultData[0].observationId);
        const obsId = resultData[0].observationId;
        const obsDeleteUrl = `${scoreitApiEndpoint}/observations/${obsId}`;
        try {
          console.log('Deleting obs:', obsId);
          const response = await axios.delete(obsDeleteUrl);
          if (response.status === 204){
            console.log('Deleted obs:', obsId);
            return true;
          }
        } catch (err) { console.log('Error deleting Observation:', err);}
      }
    }
    if(responseData.status === 204){
      return true;
    }

    return false;
  }catch(error){
    console.log('API ERROR:',error);
  }
  return false;
}

async function deleteSubject(firebaseUser: FirebaseUserProps, subjectId:string) {

  let baseUrl = `${scoreitApiEndpoint}/subjects/${subjectId}`;
  let url = baseUrl;

  console.log('Delete URL:', url);


  const {uid, bearerToken } = firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  try{
    const response = await axios.delete(url);

    if(response.status === 204){
      console.log('Deleted subject:', subjectId);
      return true;
    }
  } catch(err) 
  {console.log('API error at:', url)}
}


async function deleteEmmaUser(firebaseUser: FirebaseUserProps, emmauserId:string) {

  let baseUrl = `${scoreitApiEndpoint}/emma-users/${emmauserId}`;
  let url = baseUrl;

  console.log('Delete URL:', url);
  

  const {uid, bearerToken } = firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  try{
    const response = await axios.delete(url);

    if(response.status === 204){
      console.log('Deleted emmauser:', emmauserId);
      return true;
    }
  } catch(err) 
  {console.log('API error at:', url)}
}



export async function deleteUserAccount(firebaseUser: any){
  
  console.log('Deleting user account');
  firebaseUser?.firebaseUserObject.delete().then ( ()=>{
    console.log('User deleted');
  }).catch((err:any)=>{
  console.log('Error at API:', err);
  });

}

export async function deleteNotifications(firebaseUser:any, emmauserId: string){


  const filter:Record<string, any> = {where:{'emmaUserId':emmauserId} };
  const url = `${scoreitApiEndpoint}/notifications`;
  //the api gets notifications for the user specified by the uid in the axios headers
  
  console.log('Delete notifications URL:', url);
  

  const {uid, bearerToken } = firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  try{

    const response = await axios.get(url);
    let alerts = response.data;
    for (const alert of alerts){
      console.log('notification:', alert);
      //delete?
      let notId = alert.notificationId;

      console.log('Deleting notification:', notId);
      await deleteNotification(firebaseUser, notId);
    }

  }catch(err){
    console.log('Error deleting notifications:', err);
  }

}


export async function deleteAllData(firebaseUser: FirebaseUserProps, userState:any, userDispatch:any){
  if(!scoreitApiEndpoint || !firebaseUser){
    return;
  }

  let baseUrl = `${scoreitApiEndpoint}/assessments`;
  let url = baseUrl;

  const {uid, bearerToken } = firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  let asmts:AssessmentSchemaProps[] = [];
  let subjectId:string|undefined = '';



  //get subject from this user's id
  let subjectForParent = await getSubjectDataForUserId(firebaseUser, userState?.emmauserId);
  console.log('Subject found for parent:', subjectForParent);
  subjectId = subjectForParent?.subjectId;
  console.log('subjectId:', subjectId);

  //what if subjectForParent is not found?
  //then, subjectId will be null or undefined. that is taken care of below

  try{

    const asmtResponse = await axios.get(url);
    asmts = asmtResponse.data;
    console.log('# of Asmts from API:', asmts.length);
    for (const asmt of asmts){
      console.log('asmt:', asmt);
      //delete asmt
      let asmtId:any = asmt?.assessmentId;
      console.log('Delete asmtid:', asmtId);
      console.log('Asmt subject:', asmt?.subjectId);
      if (asmt?.subjectId == subjectId && (subjectId != null && subjectId != undefined)){
        console.log('Asmt subjectid:', asmt?.subjectId);
        console.log('Subject for this parent:', subjectId);
        console.log('Deleting asmt:', asmtId);
        await deleteAssessment(firebaseUser, asmtId);
      }
    }

  } catch(err){
    console.log('API error at :', url);
  }

  finally {
    //get all observations!


    if (subjectId != '' && subjectId != null && subjectId != undefined){
      const filter:Record<string, any> = {where:{'subjectId':subjectId} };
      console.log('Filter:', JSON.stringify(filter));
      baseUrl = `${scoreitApiEndpoint}/observations/?filter=${JSON.stringify(filter)}`;
      url = baseUrl;
      const obsResponse = await axios.get(url);
      let observations = obsResponse.data;
      console.log('# of Obs from API:', observations.length);

      if(observations?.length < 15) { //can a user have more than 15 videos? if so, this could be an error, we don't
                                      //want to wipe out videos by mistake
        for (const obs of observations){
          console.log('obs:', obs);
          //get video object
          if (obs?.videoId !== undefined && obs?.subjectId == subjectId){
            url =  `${scoreitApiEndpoint}/videos/` + obs?.videoId;
            const videoResponse = await axios.get(url);
            let videoData = videoResponse?.data;
            console.log('Delete videoid:', obs?.videoId);
            if (videoData && videoData?.length > 0){
              console.log('Video data to delete:', videoData[0]);
              if(obs?.subjectId == subjectId){ //delete only if subjectId matches
                console.log('Deleting video:', obs?.videoId);
                await deleteVideo(firebaseUser, obs?.videoId, videoData[0]);
              }
            }
          }
          //delete obs
          console.log('Delete OBS with video:', obs?.videoId);
          if(obs?.subjectId == subjectId){ //delete only if subjectId matches
            console.log('Deleting observation:',obs);
            await deleteObservation(firebaseUser, obs?.videoId);
          }
          
          
        } // iterate over all found observations for this user/subjectid
      }
      // delete subject
      const subjectToDelete:any = subjectId;
      console.log('Subject to delete:', subjectToDelete);
      await deleteSubject(firebaseUser, subjectToDelete);
    }

    console.log('Deleting notifications for emmauser:', userState?.emmauserId);
    await deleteNotifications(firebaseUser, userState?.emmauserId);

    //finally, delete emmauser
    if (userState?.emmauserId != undefined && userState?.emmauserId != null){
      console.log('Emmauser to delete:', userState?.emmauserId);
      await deleteEmmaUser(firebaseUser, userState.emmauserId);

    }
  }

}