
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import { NavLink as RouterNavLink } from 'react-router-dom';
// import {formatTimestamp} from '../../services/utils';
// import * as Utils from '../../services/utils';
// import axios from 'axios';
import {
  Grid,
  // Link,
  // Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography
} from '@material-ui/core';

import { spacing } from '@material-ui/system';
import {Redirect} from 'react-router-dom';

import OtInfoTable from './components/OtInfoTable';

import { useUserState } from '../../context/UserContext';
import * as RouteNames from '../../common/routeNames';
import useStyles from './styles';
  
const Divider = styled(MuiDivider)(spacing);  

const OTVideos = (props) => {
  const classes = useStyles();
  const user = useUserState();
  //hmm when to use this redirect, more specifically when
  //does normal routing actually let this page exist if the condtion
  // is true ?? dlp
  const [redirect, setRedirect] = useState(null);

  useEffect(() =>{
    if(!user.isAuthenticated){
      setRedirect(RouteNames.DASHBOARD);
    }
  },[]);
    
  if (redirect) {
    return <Redirect to={{pathname:redirect, state:{backpage:RouteNames.OT_VIDEOS}}}  />;
  }
  return(
    <React.Fragment>
      <div className={classes.paper}>
        <Typography
          display="inline"
          gutterBottom
          variant="h3"
        >
          Play Activities
        </Typography>

        <Divider my={2} />
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            sm={12}
          >
            <OtInfoTable/>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};
  
export default OTVideos;
  