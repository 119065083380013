export const APP = '/app';
export const AUTH = '/auth';

export const LANDING = '/';
export const SIGN_UP = `${AUTH}/sign-up`;
export const SIGN_IN = `${AUTH}/sign-in`;
export const REST_PASSWORD = `${AUTH}/rest-password`;


export const DASHBOARD = `${APP}/dashboard`;
export const DOCUMENTATION = `${APP}/documentation`;
export const ASSESSMENTS = `${APP}/assessments`;


export const SETTINGS = `${APP}/settings`;
export const ADMIN_TASKS = `${SETTINGS}/admin-tasks`;
export const MANAGEMENT = `${SETTINGS}/management`;
export const APP_SETTINGS = `${SETTINGS}/app-settings`;
export const VIDEO_TASKS = `${SETTINGS}/video-tasks`;
export const EMMA_USER_SETINGS = `${SETTINGS}/emmausersettings`;
export const DERIVED_DATA_STATUS = `${SETTINGS}/deriveddatastatus`;
export const MESSAGES = `${SETTINGS}/messages`;
export const VIDEOS = `${APP}/videos`;
export const EMMA_VIDEOS = `${VIDEOS}/emma-videos`;
export const OT_VIDEOS = `${VIDEOS}/ot-videos`;

export const NOT_FOUND = '/not-found';
export const TEST_PAGE = `${APP}/testpage`;