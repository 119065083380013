/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect, useReducer, useContext } from 'react';
import {Redirect } from 'react-router-dom';

import styled from 'styled-components';
import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Button,
  TextField,
  IconButton,
  Link
} from '@material-ui/core';
import { OndemandVideo } from '@material-ui/icons';

import { spacing } from '@material-ui/system';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {UserInfoDialog} from '../../dialogs/UserInfoDialog';

import { ReportPdfPreviewDialog } from '../../dialogs/ReportDialog';
import NewAssessmentDialog from '../../dialogs/NewAssessmentDialog';
import AssessmentInfoTable from './components/AssessmentInfoTable';
import {useUserState} from '../../context/UserContext';
import { useUserListState } from '../../context/UserListContext';
import { 
  GeneralControlContext,
  GeneralControlActionTypes
} from '../../context/GeneralControlContext';

import {
  useAsmtTableDispatch
} from '../../context/AssessmentListContext';


import { 
  AssessmentInfoActionTypes,
  AssessmentInfoContext, 
} from '../../context/AssessmentInfoContext';

import useStyles from './styles';
import { AsmtAndUserControlComponent } from './components/AsmtAndUserControlComponent';
// import { getInstruments } from '../../common/apiGetUtilities';
// import {
//   defaultAssessmentInfo,
//   assessmentInfoReducer,
//   AssessmentInfoContext,
//   AssessmentInfoActionTypes

// } from '../../context/AssessmentInfoContext';
const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Assessments = (props)=> {
  const classes = useStyles();
  const user = useUserState();
  const { allUsers } = useUserListState(); 
  const {generalControlState, generalControlDispatch} = useContext(GeneralControlContext);
  const asmtTableDispatch = useAsmtTableDispatch();

  const {assessmentInfoState, assessmentInfoDispatch} = React.useContext(AssessmentInfoContext);

 
  // const [reportPreviewVis, setReportPreviewVis] = useState(false);
  // in order to open to the dialog via the user email prop
  // const [asmtCreateDialogIsOpen, setAsmtCreateDialogIsOpen] = useState(false);
  const [asmtToUpdate, setAsmtToUpdate] = useState();
  // const [parentCreateDialogIsOpen, setParentCreateDialogIsOpen] = useState(false);
  // i think the parent information is of type UserProps
  // const [parentInformation, setParentInformation] = useState(null);
  const [redirect] = useState(null);
  const [parentEmailForCreate, setParentEmailForCreate] = useState('');

  // const newParentDialogComplete= () =>{
  //   // setParentCreateDialogIsOpen(false);
  //   generalControlDispatch({type:GeneralControlActionTypes.USERINFO_DIALOG, 
  //     isUserInfoDialogVisible:false}); 
  // };

  // const newAsmtDialogComplete = () => {
  //   console.log('Colsoing dialo');
  //   setAsmtToUpdate(undefined);
  //   generalControlDispatch({type:GeneralControlActionTypes.NEWASMT_DIALOG, 
  //     isNewAssessmentDialogVisible:false}); 
  //   // setAsmtCreateDialogIsOpen(false);
  // };

  const updateAssessment = (assessmentToUpdate) =>{
    // setAsmtCreateDialogIsOpen(true);
    console.log('Update assessment:', assessmentToUpdate);
    setAsmtToUpdate(assessmentToUpdate);// soon to be pulled into a context

    assessmentInfoDispatch({type:AssessmentInfoActionTypes.ACTIVE_ASMTID, activeAsmtId:assessmentToUpdate.assessmentId});
    generalControlDispatch({type:GeneralControlActionTypes.NEWASMT_DIALOG, 
      isNewAssessmentDialogVisible:true}); 
  };

  const showTutorialVideo = () => {
    const url = 'https://youtu.be/C7xtMcyHmQ4';
    window.open(url,'_blank');
   
  };
  useEffect(()=>{

    //If we are redirected fron the videos page open the
    // set the selected parent email and open the create assessment
    // dialog for the user
    // alas, yet another use of the props that come from a router
    // hopefully the next time this is used it can be done with less
    // guessing
    let parentEmail = props.location?.parentEmail;
    console.log('parent email from redirect:', parentEmail);
    console.log('parent email from user:', user.localEmail);
    if (!parentEmail){
      parentEmail = user.localEmail;
    }
    if(parentEmail){
      setParentEmailForCreate(parentEmail);
      console.log('Setting parentemail:', parentEmail);
      // setToggleFlag(true);
    }

    // const dback = props?.history?.location?.state?.daysBack;
    
  },[]);
  
  // useEffect(()=>{
  //   console.log('The email;,', parentEmailForCreate);
  //   if(parentEmailForCreate === null){
  //     setParentInformation(null);
  //     return;
  //   }

  //   const matchingParent = allUsers.find(user=>user.localEmail === parentEmailForCreate);
  //   console.log('The matchinParent ', matchingParent);
  //   if(matchingParent){
  //     setParentInformation(matchingParent);
  //   }
  // }, [parentEmailForCreate]);

  if (redirect){
    return <Redirect to={redirect}/>;
  }

  return (
    <div className={classes.paper}>
      <Typography
        display="inline"
        gutterBottom
        variant="h3"
      >
            Get Started
      </Typography>
      <Divider my={2} />
      {user.authLevel >= 0 &&
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} >
            <Card mb={4} style={{height:'100%'}}>
              <CardContent style={{backgroundColor:'#EFF7FF', height:'100%'}}>
                <AsmtAndUserControlComponent
                  assessmentToUpdate={asmtToUpdate}
                  initialParentEmail={parentEmailForCreate}
                  userInfo = {user}
                />
                {}
              </CardContent>
            </Card> 
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Card mb={4} style={{height:'100%'}}>
              <CardContent>

                <Grid
                  container
                  direction="column"
                  justify="flex-start"
                  spacing={2} 
                >
                  <Typography variant='h4' gutterBottom color='secondary'>
                    Help videos
                  </Typography>
                        
                  <IconButton
                    color="primary"
                    onClick={showTutorialVideo}
                  >
                    <OndemandVideo>

                    </OndemandVideo>
                  </IconButton>
                </Grid>
              </CardContent>
            </Card>    
          </Grid> 
        </Grid>
      </React.Fragment>
      }
      <Grid
        item
        xs={12}
      >
        <AssessmentInfoTable
          daysBack={props?.history?.location?.state?.daysBack}
          // hack, way of editing, can only add not remove files
          // hopefully the next iteration does a complete job instead
          // of a half attempt
          // isCreateAsmtDialogOpen = {asmtCreateDialogIsOpen}
          updateAssessmentWithDialog={updateAssessment} // used for updating while in main asmt dialog
        />
      </Grid>
      {/* <NewAssessmentDialog
        asmtToEdit={asmtToUpdate}
        dialogComplete = {newAsmtDialogComplete}
        isOpen={generalControlState.isNewAssessmentDialogVisible}
        parentInformation={parentInformation}
      />       */}
      {/* <UserInfoDialog // uggggg, not to worry this will soon be out
        // only used for creating new parents
        dialogComplete = {newParentDialogComplete}
        // hmm consolodating the userinfo dialog calls would be nice
        isOpen={generalControlState.isUserInfoDialogVisible}
      /> */}
      <ReportPdfPreviewDialog />
    </div>
  );
};
export default Assessments;
