import React, {useEffect, useState } from 'react'; 
import {
  FormControl,
  TextField,
  FormLabel,
  FormGroup
} from '@material-ui/core';
import { calculateDaysFromWeeksAndDays, calculateWeeksAndDaysFromDays } from '../../common/dateTimeUtilities';

type WeekAndDayProps = {
  setGestationAgeInDays?:(value:number|undefined)=>void,
  initialGestationAgeInDays?:number,
  label?:string

}
export const WeekAndDayInput = (props:WeekAndDayProps):JSX.Element =>{

  const {setGestationAgeInDays, initialGestationAgeInDays, label} = props;
  //beware of undefined values.
  // const {weeks, days} = calculateWeeksAndDaysFromDays(initialGestationAgeInDays);
  
  const [weekValue, setWeekValue] = useState<number|null>(null);
  const [dayValue, setDayValue] = useState<number|null>(null);


  // initialize, but hopefully without circular issues
  useEffect(() =>{
    if(!initialGestationAgeInDays){
      console.log('circular');
      // setDayValue(null);
      // setWeekValue(null);
      return;
    }
    
    const {weeks, days} = calculateWeeksAndDaysFromDays(initialGestationAgeInDays);
    setDayValue(days);
    setWeekValue(weeks);
  },[initialGestationAgeInDays]);
  
  useEffect(()=>{
    if(weekValue !== null && dayValue===null){
      setDayValue(0);
    }
    if(setGestationAgeInDays){
      if(weekValue === null || dayValue === null) {
        // console.log(weekValue,'day', dayValue);
        setGestationAgeInDays(undefined);
        return;
      }
      //hopefully minor hack for setting day ===0 if week is not null

      const days = calculateDaysFromWeeksAndDays({weeks:weekValue, days:dayValue});
      setGestationAgeInDays(days);
    }
  }, [weekValue, dayValue]);

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label??'Gestational Age'}</FormLabel>
      <FormGroup
        aria-label="position"
        row
      >
        <TextField
          autoFocus
          
          // disabled={setGestationAgeInDays===undefined?false:true}
          helperText="Weeks"
          inputProps= { 
            {min: 0} 
          }
          margin="dense"
          onChange={(event) => {
            if(!setGestationAgeInDays) return;
            const t= event.target.value;
            setWeekValue(t===''?null:+t);}}
          style={{ width: 40 }}
          type="number"
          value={weekValue}
        />
        <TextField
          autoFocus
          helperText="Days"
          inputProps= { 
            {min: 0, max: 7}
          }
          margin="dense"
          onChange={(event) => {
            if(!setGestationAgeInDays) return;
            const t= event.target.value;
            setDayValue(t===''?null:+t);}}
          style={{ width: 30 }}
          type="number"
          value={dayValue}
        />
      </FormGroup>
    </FormControl>
  );
};