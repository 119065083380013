import {firebaseStorage} from '../firebaseConfig';
// import { UploadTaskSnapshot, UploadTask } from '@firebase/storage-types';
import path from 'path';
// Beware of circulare dependencies between utlity script holders.
import {
  setAssessment, 
  setVideo,
  setObservationInfo,
  patchObservationInfo,
  setReportFileInfo
} from './apiSetUtilities';

import {
  getAssessmentsAndRelationsForTable,
  getVideoDataForFilterAndExtra,
  getSubjectUsingFilter,
  getEmmaUserWithFilter,
  getObservationDataForVideoId,
  getReports,
  getAssessmentsAndRelationsForTableFromAnnotationSets
  
} from './apiGetUtilities';

import {
  AssessmentPayloadProps, 
  FirebaseUserProps, 
  AssessmentListUIProps,
  ObservationForAsmtListUIProps,
  AssessmentCreationFeedBackUIProps
} from './entityUtilities';

import {
  VideoSchemaProps,
  ObservationSchemaProps,
  AssessmentSchemaProps,
  ReportSchemaProps,
  ReportDataSchemaProps
} from './mongoSchemas';

import { useState } from 'react';
import { videoData } from './staticdata';
import {refreshToken} from '../context/UserContext';
import { AsmtTableProps } from '../context/AssessmentListContext';
//import { report } from 'node:process';
import {Dialog, PromptResult} from '@capacitor/dialog';

export function sleep(ms:number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function getRandomInt(min:number, max:number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

async function createVideoAndObservationForUploadedFile(uploadTaskSnapshot:firebase.default.storage.UploadTaskSnapshot,
  firebaseUser:FirebaseUserProps, subjectOwnerId:string, asmtData:AssessmentSchemaProps)
  :Promise<ObservationForAsmtListUIProps | undefined>{

  // Not sure how to deal with multiple images per upload set...
  // ignore for now and just go with one i susped the videos will go first
  // then the thumbnails and times will be added in a patch call
  const fullUrl = await firebaseStorage.ref(uploadTaskSnapshot.metadata.fullPath).getDownloadURL();
  const videoTimestamp = asmtData.date;//Video timestamp needs to match the assessment date
  // the above assumption is not entirely true
  // you could have two or more video files from the asmt date, but they have different time stamps (hour of day, minutes, etc.)
  // we should get the videotimestamp from the timestamp of the VIDEO FILE!
  // bharath april 2021
  const timeCreated = new Date(uploadTaskSnapshot.metadata.timeCreated);
  const videoDuration = -1;
  const tagCount = 0;
  const videoDataForApi:VideoSchemaProps = {
    timestamp:timeCreated,
    duration:videoDuration,
    tagCount:tagCount,
    hasBeenViewed:false,
    deviceId:'local',
    // ownerId:'',//The ownerId is expected to be the id given by ARLO to the owner of the video producer(not subjectOwnerId),
    bucketName:uploadTaskSnapshot.metadata.bucket,
    url:fullUrl,
    videoStoragePath:uploadTaskSnapshot.metadata.fullPath
  };
    // Since the device id will not exists the setvideo will not create
    // an observation. thus we must create our own.
  console.log('uploadtask:', uploadTaskSnapshot);
  console.log('Set video:', videoDataForApi);
  const videoResponseData = await setVideo(firebaseUser, videoDataForApi);

  // console.log('Response data', videoResponseData);
  if (videoResponseData.observationExisted) {
    // console.log(`Observation instance ${videoResponseData.observationId} already existed in database.`);
    return undefined;
  } 
  if (videoResponseData.observationId !== undefined) {
    // console.log(`Created observation instance ${videoResponseData.observationId}`);
    return undefined;
  }

  // console.log('Observation instance could not be created by video creation, creating one manually');
  const newObservationData:ObservationSchemaProps = {
    timestamp:videoTimestamp,
    duration:videoDuration,
    notes:'',
    need_review:true,
    subjectId:asmtData.subjectId,
    videoId:videoResponseData.videoId,
    assessmentId:asmtData.assessmentId
  };
  console.log('Creating new observation:', newObservationData);
  ///hmm odd that the observation isn't used const observationResult = 
  const createdObs = await setObservationInfo(firebaseUser, newObservationData);
  if(!createdObs || !createdObs.observationId) return undefined;
  console.log('Created obs:', createdObs);
  // Create the observation entity for the UI
  const obsForList:ObservationForAsmtListUIProps = {
    observationId:createdObs.observationId,
    timestamp:videoResponseData.timestamp,
    url:videoResponseData.url,
    thumbnailurl:videoResponseData.thumbnailurl,
    videoId:videoResponseData.videoId,
    duration:videoResponseData.duration,
    tagCount:videoResponseData.tagCount || 0,
    hasBeenViewed:videoResponseData.hasBeenViewed || false,
  };
  return obsForList;
  
}

function createVideoWithObsIdFromVideoSchema(videoSchema:VideoSchemaProps, obsId:string){
  const obsForList:ObservationForAsmtListUIProps = {
    observationId:obsId,
    timestamp:videoSchema.timestamp,
    url:videoSchema.url,
    thumbnailurl:videoSchema.thumbnailurl?videoSchema.thumbnailurl:'',
    videoId:videoSchema.videoId?videoSchema.videoId:'',
    duration:videoSchema.duration,
    tagCount:videoSchema.tagCount || 0,
    hasBeenViewed:videoSchema.hasBeenViewed || false,
  };
  return obsForList;
}

async function createVideoWithObservationIdForUI(firebaseUser:FirebaseUserProps, videoIdForObs:string, obsId:string){
  const whereFilter:any = {};// replace any=> Record<string, unknown>
  whereFilter.where = {videoId:videoIdForObs};
  const videoData = await getVideoDataForFilterAndExtra(firebaseUser, whereFilter);
  // Should onlyu be one, but a list is returned...so just return after the first one
  for(const video of videoData){
    return createVideoWithObsIdFromVideoSchema(video, obsId);
    // const obsForList:ObservationForAsmtListUIProps = {
    //   timestamp:video.timestamp,
    //   url:video.url,
    //   thumbnailurl:video.thumbnailurl,
    //   videoId:video.videoId,
    //   duration:video.duration,
    // };
    // return obsForList;
  }
  return undefined;
}

//
// Upload videos, create observations, create assessment
// If assessment payload contains an assessmentId, then, don't create an assessment -- just do the rest
//
async function uploadFilesToFirebaseWithFeedBack(firebaseUser:FirebaseUserProps|undefined,
  subjectOwnerId:string, videosForUpload:any[],
  setStatus:(val:any)=>void)
  :Promise<firebase.default.storage.UploadTaskSnapshot[]>{
    
  if(!firebaseUser) return [];
  console.log('Create Assessment with feedback');
  await refreshToken(firebaseUser);
  const deviceId = 'local';
  const bucketPath = '__videos/';
  const baseBucketPath = path.join(bucketPath,subjectOwnerId,deviceId);
  console.log('Uploading to:', baseBucketPath);
  // Ugh, this middle bit very temporary, thumbnail creation and upload
  // will also be needed, in addition we need to know how long the video is
  // ie metadata isn't getting get here.
  const videoUploadPromises = [];
  let videoUploadTasks:firebase.default.storage.UploadTaskSnapshot[] = [];
  const totalVideos = videosForUpload.length;
  let currentVideoCount = 1;
  // Upload the videos first, get their storage information for later useage
  for(const video of videosForUpload){
    const bucketLocation =path.join(baseBucketPath,video.name);
    
    var metadata = {
      contentType: 'video/mp4'
    };

    console.log('Uploading video for asmt');
    // not sure if an await will work
    const uploadTask = firebaseStorage.ref(bucketLocation).put(video,metadata);


    videoUploadPromises.push(uploadTask);
    uploadTask.on('state_changed', 
      (snapShot) => {
      //takes a snap shot of the process as it is happening
        // console.log('The details', snapShot);
        const data:AssessmentCreationFeedBackUIProps = {
          bytesTransferred:snapShot.bytesTransferred,
          totalSize:snapShot.totalBytes,
          fileName: video.name,
          videoTotal:totalVideos,
          currentVideoCount:currentVideoCount,
          genericMessage:undefined
        };
        setStatus(data);
      }, (err) => {
      //catches the errors
        console.log(err);
      });
    // remove this part if we want al lthe promises at once
    const result = await Promise.all(videoUploadPromises);
    //if success remove one, the result might be what is left in the promise array...
    videoUploadPromises.shift();
    videoUploadTasks = videoUploadTasks.concat(result);
    currentVideoCount +=1;
  }
  return videoUploadTasks;
}

export async function deleteCloudVideo(firebaseUser: FirebaseUserProps|undefined, videoObject:any) {
  var deleteRef = firebaseStorage.refFromURL(videoObject.url);

  console.log('deleteRef:', deleteRef);
  console.log('video url:', videoObject.url);
  deleteRef.delete().then( ()=> {
    console.log('Video file deleted');
  }).catch ((error) => {
    console.log('Error deleting video file:', error);
  })

}


// a hacky function that adds a video to an assessment.
export async function updateAssessmentWithNewFiles(firebaseUser:FirebaseUserProps|undefined, 
  asmtCreationPayload:AssessmentPayloadProps, setStatus:(val:any)=>void):Promise<void>{

  if(!firebaseUser) return;
  if (!asmtCreationPayload.assessmentId) return;

  // // very cool way of declaring this, bypasses the need for default, seems like it should work though
  // const asmtData = <AssessmentSchemaProps>{};
  // asmtData.assessmentId =  asmtCreationPayload.assessmentId;
  // asmtData.subjectId = asmtCreationPayload.subjectId;
  // asmtData.date = asmtCreationPayload.date;
  const asmtData:AssessmentSchemaProps = {
    assessmentId:asmtCreationPayload.assessmentId,
    date:asmtCreationPayload.date,
    assessmentName:asmtCreationPayload.assessmentName,
    subjectId:asmtCreationPayload.subjectId,
    assessor:asmtCreationPayload.assessor,
    notes:asmtCreationPayload.notes
  };
  // why is the video uploaded to the subject owners folder and not the subjects?
  const subjectOwnerId = asmtCreationPayload.ownerId;
  const videoUploadTasks = await uploadFilesToFirebaseWithFeedBack(firebaseUser, subjectOwnerId,
    asmtCreationPayload.videosForUpload, setStatus);
  const totalVideos = asmtCreationPayload.videosForUpload.length;
  // we be done
  let data:AssessmentCreationFeedBackUIProps = {
    bytesTransferred:0,
    totalSize:0,
    fileName: '',
    videoTotal:totalVideos,
    currentVideoCount:totalVideos,
    genericMessage:'Processing...'
  };
  setStatus(data);

  const newObservationsForAsmt:ObservationForAsmtListUIProps[] = [];
  for(const videoUploadTask of videoUploadTasks){
    const observationForVideo = await createVideoAndObservationForUploadedFile(
      videoUploadTask, firebaseUser, subjectOwnerId, asmtData);
    if(observationForVideo){
      newObservationsForAsmt.push(observationForVideo);
    }
  }
  data = {
    bytesTransferred:1,
    totalSize:1,
    fileName: '',
    videoTotal:totalVideos,
    currentVideoCount:0,  
    genericMessage:'Assessment update complete.'
  };
  setStatus(data);
}

// this is such a messy function, dlp barely knows what it is supposed to do now.
// dlp believes the return is not used at all, at least not in the asmt table page
export async function createAssessmentFromDataSomeFeedback(firebaseUser:FirebaseUserProps|undefined, 
  asmtCreationPayload:AssessmentPayloadProps, setStatus:(val:any)=>void):Promise<AssessmentListUIProps|undefined>{

  if(!firebaseUser) return undefined;
  console.log('Create Assessment with feedback');
  // await refreshToken(firebaseUser);
  // havein the dob nad parent email is so ugly...dlp
  const subjectOwnerId = asmtCreationPayload.ownerId;
  const videoUploadTasks = await uploadFilesToFirebaseWithFeedBack(firebaseUser, subjectOwnerId,
    asmtCreationPayload.videosForUpload, setStatus);
  const totalVideos = asmtCreationPayload.videosForUpload.length;
  // we be done
  let data:AssessmentCreationFeedBackUIProps = {
    bytesTransferred:0,
    totalSize:0,
    fileName: '',
    videoTotal:totalVideos,
    currentVideoCount:totalVideos,

    genericMessage:'Processing...'
  };
  setStatus(data);
  // For all the promises at once use this...
  // const videoUploadTasks = await Promise.all(videoUploadPromises);
  // Uploading is done, take the data and create the assessment
  const asmtDbPayload:AssessmentSchemaProps = {
    date:asmtCreationPayload.date,
    assessmentName:asmtCreationPayload.assessmentName,
    subjectId:asmtCreationPayload.subjectId,
    assessor:asmtCreationPayload.assessor,
    notes:asmtCreationPayload.notes
  };
  let asmtData = <AssessmentSchemaProps>{};

  // interesting seems like a full reload was kind of accounted for?
  // i suspect it was on accident. dlp thinks thise function shoudl
  // only be used for create.
  if (!asmtCreationPayload.assessmentId) {
    asmtData = await setAssessment(firebaseUser, asmtDbPayload) as AssessmentSchemaProps;

    if(!asmtData){
      console.log('Failure with asmt');
      //hmm failure
      return undefined;
    }
  }
  else{
    asmtData.assessmentId =  asmtCreationPayload.assessmentId;
    asmtData.subjectId = asmtCreationPayload.subjectId;
    asmtData.date = asmtCreationPayload.date;
  }
  
  console.log('Done with asmt, dealing with videos/observations', asmtData);
  // Create the videos and observation that go along with the assessment
  const obsForAsmt:ObservationForAsmtListUIProps[] = [];
  for(const videoUploadTask of videoUploadTasks){
    const observationForVideo = await createVideoAndObservationForUploadedFile(videoUploadTask, firebaseUser, subjectOwnerId, asmtData);
    if(observationForVideo){
      obsForAsmt.push(observationForVideo);
    }
  }
  // Now deal with videos/observations that were previously selected.
  // console.log('Get data for exising videos/observations for asmt');
  // ugggghhh, The api neds to be adjust really!! For now hack our way
  // to the observation id so that we can add it to the assessment.
  // design deficit for sure....
  let count = -1;
  if(asmtCreationPayload.existingVideoIdsToUse){
    count = asmtCreationPayload.existingVideoIdsToUse.length;
    data = {
      bytesTransferred:0,
      totalSize:0,
      fileName: '',
      videoTotal:count,
      currentVideoCount:0,  
      genericMessage:'Creating entities for exiting videos'
    };
    setStatus(data);
  }
  let completionCount = 1;
  for(const videoId of asmtCreationPayload.existingVideoIdsToUse){

    console.log('Adding video', videoId);
    data = {
      bytesTransferred:completionCount,
      totalSize:count,
      fileName: '',
      videoTotal:count,
      currentVideoCount:completionCount,  
      genericMessage:`Please wait. Creating assessment with ${count} observations`
    };
    setStatus(data);

    let obsForVideo:ObservationSchemaProps[] = await getObservationDataForVideoId(firebaseUser, videoId);
    // the video has no observation that is bad..so as a hack we will create one.
    // Before we used to ignore it but dlp needed it for some other tests.
    if(obsForVideo.length === 0){
      const whereFilter:Record<string, unknown> = {};
      whereFilter.where = {videoId:videoId};
      const videoData = await getVideoDataForFilterAndExtra(firebaseUser, whereFilter);
      // should only be video
      if(videoData && videoData.length >0){
        const video = videoData[0];
        const newObservationData:ObservationSchemaProps = {
          timestamp:video.timestamp,
          duration:video.duration,
          notes:'',
          need_review:true,
          subjectId:asmtData.subjectId,
          videoId:videoId,
          assessmentId:asmtData.assessmentId
        };
        const newObs = await setObservationInfo(firebaseUser,newObservationData);
        if(newObs){
          obsForVideo = [newObs];
        }  
      }
    }else{
      // The displayed values have nothing to do with the observation, in addition
      // the incoming ids are video ids. therefore were are will only get
      // video data here
      //Attach the observation to the assessment via a patch operation ObservationSchemaProps
      // but for not it returns.
      // I believe this should only be one observation per video (Feb 2 2021 dlp)
      for(const obs of obsForVideo){
        console.log('Patching observation', obs.observationId);
        const obsPatch:Partial<ObservationSchemaProps>  = {
          observationId:obs.observationId,
          assessmentId:asmtData.assessmentId
        };
        const patchResults = await patchObservationInfo(firebaseUser,obsPatch);
      }
    }

    //Hack becaues of messed up video/observation relation we got going on (Feb 2 2021)
    const observationItem = obsForVideo[0] as ObservationSchemaProps; //Hope and pray this doesn explode. Can't really do a silent death?
    // Create the UI element
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    const obsUIForList = await createVideoWithObservationIdForUI(firebaseUser, videoId, observationItem?.observationId!);
    if(obsUIForList){
      obsForAsmt.push(obsUIForList);
    }
    completionCount+=1;
  }
  // No assessment id is very bad...return nothing and hope for the best
  if(!asmtData.assessmentId) return undefined;

  const asmtForUI:AssessmentListUIProps = {
    assessmentId:asmtData.assessmentId,
    notes:asmtData.notes,
    assessor:asmtData.assessor,
    date:asmtData.date,
    dob:asmtCreationPayload.subjectDob,
    // notes:asmtCreationPayload.notes,
    observations: obsForAsmt,
    observationCount: obsForAsmt.length,
    parent:asmtCreationPayload.parentEmail,
    parentId:asmtCreationPayload.ownerId
  };
  data = {
    bytesTransferred:1,
    totalSize:1,
    fileName: '',
    videoTotal:count,
    currentVideoCount:0,  
    genericMessage:'Assessment creation complete.'
  };
  setStatus(data);
  // hmm is this asmt for UI ever used? seems like once this 
  // function returns a full reset is done on the list
  // and the entities are all created. Will keep around for future
  // optimization options 
  return asmtForUI;
}

export async function createAssessmentListForUI(firebaseUser:FirebaseUserProps|undefined, asmtFilters?:any, annotSetFilters?:any,
  asmtNoReportFilter?:any){
  
  let rawAsmtData:AssessmentSchemaProps[] = [];

  console.log('createAssessmentListForUI');
  if (asmtFilters){
    // This function expects that the filter be called with relations
    rawAsmtData = await getAssessmentsAndRelationsForTable(firebaseUser, asmtFilters);

  }else if(asmtNoReportFilter){
    const initialAsmtData = await getAssessmentsAndRelationsForTable(firebaseUser, asmtNoReportFilter);
    initialAsmtData.forEach((asmt)=>{
      if(!asmt.reports){
        rawAsmtData.push(asmt);
      }
    });
  }else if(annotSetFilters){
    const rawObs = await getAssessmentsAndRelationsForTableFromAnnotationSets(firebaseUser, annotSetFilters);
    // hack! reorganize the observation into an assessment
    const asmtHolder: Record<string, AssessmentSchemaProps> = {};
    rawObs.forEach((obs)=>{
      if(obs.assessment && obs.assessmentId){

        const {assessment, ...rawObs} = obs;
        if(obs.assessmentId in asmtHolder){

          console.log('Exists add an observation');

          asmtHolder[obs.assessmentId].observations?.push(rawObs);
        }else{
          assessment.observations = [rawObs];
          asmtHolder[obs.assessmentId] = assessment;
        }
      }
    });

    rawAsmtData = Object.values(asmtHolder);
  }
  if(!rawAsmtData) return [];// maybe undefined? an error occured in the get should probalby tel somebody
  const convertedAsmtData:AssessmentListUIProps[] = [];


  // assume subjectids are the same for all assments so just have on variable 
  // to hold the info
  let subjectId:string|undefined;
  // Get parent email for ownerid
  let parentEmail = '';
  console.log('Converting asmtdata:', rawAsmtData?.length);
  for(const rawAsmt of rawAsmtData){
    // Parent email and dob come from calls using subjectid
    // Note this might also come from the original api call
    let ownerId:string|undefined;
    
    //deal with no date later
    let dobForAsmt:Date = new Date();
    subjectId = rawAsmt.subjectId;
    let subjectToUse = rawAsmt.subject;
    if(rawAsmt.subject){
      // Things where called with the proper relations
      // get what you need and move on
      dobForAsmt= rawAsmt.subject.dob;
      ownerId = rawAsmt.subject?.emmauserid;
    }else if(subjectId){
    // The relation filter failed, so get subject hard way
      const filter:any = {'where':{'subjectId':subjectId} };
      const subject = await getSubjectUsingFilter(firebaseUser, filter);
      subjectToUse = subject;
      ownerId = subject?.emmauserid;
      dobForAsmt = subject?.dob;
    }
    if(ownerId){
      const filter = {'where':{'emmauserId':ownerId } };
      const ownerData = await getEmmaUserWithFilter(firebaseUser, filter);
      // hmm what is this non-null assertion thing in practice
      if (ownerData){
        parentEmail = ownerData!.email;
      }
      else{

        console.log('Asmt has no parent ', rawAsmt);
      }
    }
    // this might not be needed anymore since an assessment now hasmany reports
    let reports = null;
    if (rawAsmt.assessmentId) {
      reports = await getReports(firebaseUser, rawAsmt.assessmentId);      
    }

    // The observation data is actually just video data
    const obsForAsmt:ObservationForAsmtListUIProps[] = [];
    let obsCount = rawAsmt.observations?.length;
    // was this originally set ot -1? asks dlp
    if(!obsCount){
      // obsCount = -1;
      obsCount = 0;
    }  

    if(rawAsmt.observations){
      for(const rawObs of rawAsmt.observations){
        if(!rawObs.videoId){
          console.log('Bad video', rawObs.observationId);
        }else{
          const videoIdForObs = rawObs.videoId;
          const obsForList:ObservationForAsmtListUIProps = {
            observationId:rawObs.observationId!,
            timestamp:rawObs.video!.timestamp,
            url:rawObs.video!.url,
            thumbnailurl:rawObs.video!.thumbnailurl!,
            videoId:rawObs.video!.videoId!,
            duration:rawObs.video!.duration,
            tagCount:rawObs.video!.tagCount || 0,
            hasBeenViewed:rawObs.video!.hasBeenViewed || false,
          };
          obsForAsmt.push(obsForList);
        }

      }
      obsCount = obsForAsmt.length;
    }else{
      // console.log('Bingo no observation');
    }
    
    if(rawAsmt.assessmentId && ownerId){
      const asmtForList:AssessmentListUIProps = {
        assessmentId:rawAsmt.assessmentId,
        assessor:rawAsmt.assessor,
        date:rawAsmt.date,
        notes:rawAsmt.notes,
        dob:dobForAsmt,
        observations: obsForAsmt,
        observationCount:obsCount,
        parent:parentEmail,
        parentId:ownerId,
        reports:reports !== null && reports.length > 0 ? reports : undefined,
        subject:subjectToUse
      };
      // console.log(asmtForList);
      convertedAsmtData.push(asmtForList);
    }
  }
  console.log('Returning converted asmt data');
  return convertedAsmtData;
}


// Once the create report is done by the api, it will be unclear if file upload
// will be needed. As a first pass, leave file in, and simply hack modify what is
// sent to the api for report creation.
export async function uploadReportAttachToAssessment(firebaseUser:FirebaseUserProps, file:File, assessment: AssessmentListUIProps) {
  refreshToken(firebaseUser);
  const bucketPath = '__reports/';
  const baseBucketPath = path.join(bucketPath,assessment.assessmentId);

  const bucketLocation =path.join(baseBucketPath,file.name);
  const uploadTaskSnapshot = await firebaseStorage.ref(bucketLocation).put(file);
  const fullUrl = await firebaseStorage.ref(uploadTaskSnapshot.metadata.fullPath).getDownloadURL();
  
  const reportData:ReportDataSchemaProps = {
    reportTypeName:'AIMS report',
    reportCreationDate:new Date()
  };
  // temp for this commit, should be set bfore hand
  const report:ReportSchemaProps = {
    creationDate: new Date(),
    reportFileURL: fullUrl,
    reportFileStoragePath: uploadTaskSnapshot.metadata.fullPath,
    reportData:reportData,
    assessmentId: assessment.assessmentId,
    subjectId:'fd',
    emmauserId:'fdewf,'
  };
  setReportFileInfo(firebaseUser,report);
}


export const showDialog = async(title:any,message:any,oktitle:any, canceltitle:any)=> {
  const {value} = await Dialog.confirm({
    title: title,
    message: message,
    okButtonTitle: oktitle,
    cancelButtonTitle: canceltitle
  });

  return value;

};

export const showAlert = async (title: any, message:any, buttonTitle: any):Promise<boolean> =>{
  await Dialog.alert( {title: title, message: message, buttonTitle: buttonTitle} );
  return true;
};

export const showPrompt = async (title: any, message: any): Promise<PromptResult> => {

  const {value, cancelled} = await Dialog.prompt({
    title: title,
    message: message
  });
  const result: PromptResult = {value,cancelled};

  return result;

};

export const showCapacitorAlert = async(title:any, message: any) => {
  await Dialog.alert( {title: title, message: message});
};