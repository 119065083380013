/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NavLink as RouterNavLink } from 'react-router-dom';

import {
  Grid,
  Divider as MuiDivider,
  Typography,
} from '@material-ui/core';

import { spacing } from '@material-ui/system';
import {Redirect} from 'react-router-dom';
import VideoInfoTable from './components/VideoInfoTable';
import * as RouteNames from '../../common/routeNames';
import useStyles from './styles';
const Divider = styled(MuiDivider)(spacing);


const EmmaVideos=(props) => {
  const classes = useStyles();
  // Very hacky, but it works. Had to figure out
  // what props to use based on console output
  const dback = props?.history?.location?.state?.daysBack;
  const [daysBack] = useState(dback?dback:30);
  // const [daysBack] = useState();
  const [redirect] = useState(null);
  // useEffect(() =>{
  //   console.log('The props', props);
  //   if(props.history.location && props.history.location.state && props.history.location.state.daysBack){
  //     const dback = props.history.location.state.daysBack;
  //     console.log('Setting days back', dback);
  //     setDaysBack(dback);
  //   }
  // },[]);

  if (redirect) {
    return <Redirect to={{pathname:redirect, state:{backpage:RouteNames.EMMA_VIDEOS}}}  />;
  }

  return(
    <React.Fragment>
      <div className={classes.paper}>
        <Typography
          display="inline"
          gutterBottom
          variant="h3"
        >
        Home Videos
        </Typography>

        <Divider my={2} />
        <Grid
          container
          spacing={6}
        >
          <Grid
            item
            xs={12}
          >
            <VideoInfoTable
              daysBack = {daysBack}
            />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default EmmaVideos;
