/* eslint-disable react/no-multi-comp */
import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Redirect} from 'react-router-dom';
import * as RouteNames from '../../common/routeNames';
// import { NavLink as RouterNavLink } from 'react-router-dom';
import {
  Button,
  IconButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Snackbar,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { UserSettings, BabySettings, ParentSettings} from '../../components/Settings';
import { spacing } from '@material-ui/system';

import { useUserState } from '../../context/UserContext';
import NotificationsAcceptedContext from '../../context/NotificationContext';
import { sleep } from '../../common/utilities';
import useStyles from './styles';
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);



function EmmaUserSettings(){
  const classes = useStyles();
  const user = useUserState();
  const notificationContext = useContext(NotificationsAcceptedContext);
 
  const [toDashboard, setToDashboard] = useState(false);

  useEffect( ()=> {
    console.log('called upon first render. dashboard:', toDashboard);
  },[]);

  useEffect( () => {
    console.log('toDashboard state:', toDashboard);

  }, [toDashboard]);

  function handleBabySave () {
    console.log('Saved baby state');
    sleep(1000);
    setToDashboard(true);
  };

  if (toDashboard) {
    console.log('Rerouting to dashboard page');
    return (<Redirect to={RouteNames.DASHBOARD}/>);
  };
  return (
    <React.Fragment>
      <div className={classes.paper}>
        <Typography
          display="inline"
          gutterBottom
          variant="h3"
        >
          User Information 
        </Typography>
        <Divider my={2}/>
        
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            {/* {user.authLevel >= 0 &&
                      <Card mb={6}>
                        <CardContent>
                          <Typography
                            gutterBottom
                            variant="h4"
                          >
                              Notifications
                          </Typography>                  
                          <Typography
                            gutterBottom
                            variant="body1"
                          >
                            {notificationContext ? 'Notifications have been enabled in your browser. While the app is open, you will receive notifications when new activity plans are created for you.' :
                              'Browser notifications have been blocked. Please enable them in your browser settings to allow the app to display notifications when new activity plans are created for you.'
                            }
                          </Typography>
                        </CardContent>
                      </Card>   } */}
        
            <UserSettings />
            
            {user.authLevel >= 0 &&
              <BabySettings 
                handleSave = {handleBabySave}
              />
            }
            {user.authLevel >= 0 &&
              <ParentSettings />
            }
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}



export default EmmaUserSettings;

