import React, {useState, useEffect} from 'react';
import {
  Avatar,
  Button,
  Card as MuiCard,
  CardContent,
  Divider,
  FormControl as MuiFormControl,
  Grid,
  Input,
  TextField,
  Typography
} from '@material-ui/core';

import {Toast} from '@capacitor/toast';
import {Dialog} from '@capacitor/dialog';

import {isMobile, isBrowser, isIOS, isAndroid} from 'react-device-detect';

import { green, blue, orange, red, yellow, blueGrey, lightBlue } from '@material-ui/core/colors';
import { showAlert, sleep } from '../../common/utilities';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import { useUserState, useUserDispatch, UserActionTypes } from '../../context/UserContext';
import {convertUserDataToUserPropType} from '../../common/entityUtilities';
import { setUserInfo } from '../../common/apiSetUtilities';

import { SharedSnackbarConsumer } from '../../context/SnackbarContext';
import { firebaseAuth } from '../../firebaseConfig';
import validator from 'validator';
import { checkIfEmailIsTaken, checkIfStudyidValid } from '../../common/apiGetUtilities';
import { isCatchClause } from 'typescript';

const Card = styled(MuiCard)(spacing);
// const FormControl = styled(MuiFormControl)(spacing);
const CenteredContent = styled.div`
  text-align: center;
`;
export function UserSettings() {
  // Global
  const userState = useUserState();
  const userDispatch = useUserDispatch();
  // local
  const [displayName, setDisplayName] = useState('');
  const [photoURL, setPhotoURL] = useState('');
  const [registeredDate, setRegisteredDate] = useState(userState.created);
  const [email, setEmail] = useState(userState.email);
  const [userEmail, setUserEmail] = useState(userState.email);
  const [studyIdAlertShown, setStudyIdAlertShown] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  const [studyid, setStudyid] = useState(userState.studyid);
  const [isStudyIdValid, setStudyIdValid] = useState(null);
  const [firstName, setFirstName] = useState(userState.firstname);
  const [lastName, setLastName] = useState(userState.lastname);
  const [signature, setSignature] = useState(userState.signature);
  const [authProvider, setAuthProvider] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [emailValidationMessage, setEmailValidationMessage] = useState('');
  const [studyidValidationMessage, setStudyidValidationMessage] = useState('Enter a valid study ID.');

  useEffect(async() => {
    // is it better to load data here then cache it for future usage?
    // instead of having all the info a larger entitiy.
    let localEmailCopy = userState.localEmail;

    if (!userState.firebaseUser) { return; }
    console.log('[UserSettings] The user state', JSON.stringify(userState));
    console.log('[UserSettings] userState email:', userState.email);
    const providerData = userState.firebaseUser.firebaseUserObject?.providerData[0];
    console.log('[UserSettings] Auth Provider ID:', providerData?.providerId);
    // can be 'password'
    // 'google.com'
    // or 'phone' 
    //
    setAuthProvider(providerData?.providerId);

    if (providerData?.providerId == 'phone'){
      setPhoneNumber(providerData?.phoneNumber); // this should be saved in the emmauser db object
      console.log('Provider says phone number is:', providerData?.phoneNumber);
      
    }

    setDisplayName(userState.localDisplayName);
    if (userState.firebaseUser.email !== null){
      console.log('Setting userEmail from firebaseUser email:', userState.firebaseUser.email);
      setEmail(userState.firebaseUser.email);
      setStudyIdValid(false);
      setUserEmail(userState.firebaseUser.email);
      localEmailCopy = userState.firebaseUser.email;
    }
    else{
      console.log('Firebase user did not have email');
      console.log('user email:', userEmail);
      console.log('userState email:', userState.email);
      if (userEmail != null && userEmail != undefined && userEmail != '') {
        console.log('Setting userEmail to:',userEmail);
        setEmail(userEmail);
        //the state variable email is never used anywhere!
        
        setUserEmail(userEmail);
        localEmailCopy = userEmail;
        setStudyIdValid(false);
        setIsEmailValid(true);
        setEmailValidationMessage('');
      }
      else if (userState.localEmail != null && userState.localEmail != undefined && userState.localEmail != ''){
        console.log('Setting userEmail from userState localEmail:', userState.localEmail);
        setEmail(userState.localEmail);
        setUserEmail(userState.localEmail);
        localEmailCopy = userState.localEmail;
        setIsEmailValid(true);
        setStudyIdValid(false);
        setEmailValidationMessage('');
      }
    }


    console.log('[UserSettings] userEmail:', userEmail);
    console.log('[UserSettings] localEmailCopy:', localEmailCopy);
    if (providerData?.providerId == 'phone' && (localEmailCopy == '' || localEmailCopy === undefined)){
      //don't let the user UPDATE information without an email
      //when the phone auth is being used
      setIsEmailValid(false);
      setEmailValidationMessage('Need a valid email');
    }
    if(userState.created){
      setRegisteredDate(new Date(userState.created).toDateString());
    }
    setPhotoURL(userState.firebaseUser.photoURL);
   
    

    if (isIOS || isAndroid) {

      // authProvider can be 'password'
      // 'google.com'
      // or 'phone' 
      // for 'google.com' and 'password', you already have a userEmail set
      // 'phone' pathway you need user to enter email
      console.log('showPrompt?', userEmail);
      console.log('authProvider:', providerData?.providerId);
      console.log('localEmailCopy:', localEmailCopy);
      if (providerData?.providerId == 'phone' && (localEmailCopy == '' || localEmailCopy == undefined || localEmailCopy == null) ){
        showPrompt();
      }
    }


  }, []);


  const showPrompt = async() => {
    console.log('Showing email dialog');

    // can be 'password'
    // 'google.com'
    // or 'phone' 
    // for 'google.com' and 'password', you already have a userEmail set
    // 'phone' pathway you need user to enter email
    console.log('AUTHPROVIDER:', authProvider);
    console.log('isIOS', isIOS);
    console.log('studyidValid? ', isStudyIdValid);
    console.log('userEmail:', userEmail);

    const value = await showAlert('Need Info',
      'We need a valid email and the Study ID provided by Early Markers',
      'Got it'
    );

  };


  useEffect ( ()=>{
    console.log('useEffect studyidvalid:', isStudyIdValid);
    if(isStudyIdValid !== null) {
      alertUserAboutStudyId();
    }
  }, [isStudyIdValid, userEmail]);



  const alertUserAboutStudyId  = async () => {
    console.log('studyidValid?:', isStudyIdValid);
    console.log('userEmail:', userEmail);
    if (userEmail !== '' && userEmail !== undefined){
      console.log('Calling checkIfstudyidvalid');
      const value = await checkIfStudyidValid(userState?.firebaseUser, userEmail, userState?.studyid);
       
      if(value){
        console.log('user had valid studyid');
        setStudyIdValid(true);
        setStudyidValidationMessage('');
      }
      else{
        console.log('alertshown?', studyIdAlertShown);
        if (!studyIdAlertShown){
          console.log('setting alertshown to true');
          setStudyIdAlertShown(true);
          const msg =  'You need to enter your Study ID from Early Markers. Without this, the app will not be functional. Send email to study@earlymarkers.com if you have questions.';
          const returnValue = await showAlert('Study Id', msg,'Ok');
          //Toast.show( {text:msg});
        }
      }
      
    }

  };

  const modifyUserSettings = () => {
    //Firebase user should always exist since that is our main log in.
    const firebaseUser = userState.firebaseUser;
    setEmailValidationMessage('');
    setStudyidValidationMessage('');

    // Also note that an empty use is created if no matching user was found
    // in the mongodb. It is of type UserProps and is used by the user context

    // the call to register the user needs to be put elsewhere
    // but for now this will do since no other function calls the setuser
    const addingNewUserToDb = (userState.authLevel < 0); // -1 for unregisterd
    // Check to see if it is an unregistered user
    // then register or update accordingly.
    const mongoUserValuesToUpdate= {};//:Partial<EmmaUserSchemaProps> 
    if(addingNewUserToDb){
      mongoUserValuesToUpdate.authLevel = 0;
      mongoUserValuesToUpdate.created = new Date();//new Date().toISOString();
      mongoUserValuesToUpdate.uid = firebaseUser.uid;
      mongoUserValuesToUpdate.email = firebaseUser.email;
      mongoUserValuesToUpdate.phoneNumber = phoneNumber;
      mongoUserValuesToUpdate.studyid = studyid;
      console.log('firebaseuser email:', firebaseUser.email);
    }
    mongoUserValuesToUpdate.firstname = firstName;
    mongoUserValuesToUpdate.lastname = lastName;
    mongoUserValuesToUpdate.signature = signature;
    mongoUserValuesToUpdate.studyid = studyid;

    if (firebaseUser.email === null){
      let lowercaseEmail = String(userEmail).toLowerCase();
      mongoUserValuesToUpdate.email = lowercaseEmail;
      console.log('useremail to update with:', lowercaseEmail);
    }
    console.log('uservalues to update for new user:', mongoUserValuesToUpdate);
    setUserInfo(firebaseUser, userState.emmauserId, mongoUserValuesToUpdate)
      .then(updatedUser=>{
        // The updated user will have the emmauserid/mongoid
        console.log('The updated emmauser:', updatedUser);
        const fixedUser = convertUserDataToUserPropType(updatedUser);
        console.log('The user', fixedUser);
        // don't think this update the user in the list
        userDispatch({ type: UserActionTypes.UPDATE, ...fixedUser });
      });
  };

  const buttonText = userState.authLevel < 0?'Register':'Update'; 
  return (
    <Card mb={6}>
      <CardContent>
        <Typography
          gutterBottom
          variant="h4"
        >
          {userState.authLevel < 0 &&
            <div>

              <Typography gutterBottom variant='body1' color='secondary'> We need some information first before registering you.
              </Typography>
            </div>
          }
          {userState.authLevel >= 0 ?  
            (<Typography gutterBottom variant="h4">Parent info</Typography>) : 
            (<Typography gutterBottom variant="h4">Parent info first</Typography>) 
        
          }
        </Typography>
        <Grid
          container
          spacing={3}
          xs={12}
          sm={12}
          md={12}
        >

          <Grid
            item
            md={4}
            xs={8}
            sm={8}
          >
            <TextField
              id="useremail"
              label="Email"
              fullWidth
              disabled={authProvider !== 'phone'}
              m={2}
              onChange={(e) => {
                if (validator.isEmail(e.target.value)){ //is the entered text in the right format for an email?

                  setUserEmail(e.target.value);
                  setEmailValidationMessage('Entered email is ok');
                  checkIfEmailIsTaken(userState?.firebaseUser, e.target.value).then(
                    //is there any other emmauser in the database with this email?
                    (status)=>{
                      console.log('checkifemailistaken returned:', status);
                      setStudyIdValid(false); //this will be checked by a useEffect
                      if (!status){
                        setIsEmailValid(true);
                      }
                      else{
                        setEmailValidationMessage('This email is already taken. Type in a different email address.');
                        setIsEmailValid(false);
                      }
                    }
                  )
                }
                else {
                  setEmailValidationMessage('Enter a valid email');
                  setIsEmailValid(false);
                }
              }}
              value={userEmail}
              variant="outlined"
            />
          </Grid>

          <Grid
            item
            sm={4}
            md={4}
            xs={4}
            >
              <Typography variant='body2' color='primary'>
                {emailValidationMessage}
              </Typography>
          </Grid>

          <Grid
            item
            md={6}
            xs={8}
            sm={8}
          >
            <TextField
              id="studyid"
              label="Study ID"
              autoFocus
              disabled = {isStudyIdValid==true}
              m={2}
              onChange={(e)=> {
                setStudyid(e.target.value);
                console.log('studyid entered:',e.target.value);
                if (e.target.value.toLowerCase().match(/^(emcp)(\d+)/)){
                  checkIfStudyidValid(userState?.firebaseUser,userEmail, e.target.value).then( (status) => {  
                    console.log('Status from check:', status);
                    if(status){
                      setStudyIdValid(true);
                      setStudyidValidationMessage('Thank you. Your Study ID matches your email. Go ahead and Register.');
                    }else{
                    setStudyidValidationMessage('This Id is not valid. Contact the study team.');
                    setStudyIdValid(false);
                    }
                  });
                }
                else{
                  setStudyidValidationMessage('Enter Study Id provided by Early Markers team');
                }
              }}
              value={studyid}
              variant="outlined"
            />
          </Grid>

          <Grid
            item
            sm={4}
            md={4}
            xs={4}
            >
              <Typography variant='body2' color='primary'>
                {studyidValidationMessage}
              </Typography>
          </Grid>

          <Grid
            item
            md={6}
            xs={8}
            sm={8}
          >
            <TextField
              id="firstname"
              label="Parent First Name"
              m={2}
              onChange={(e)=>setFirstName(e.target.value)}
              value={firstName}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={8}
            sm={8}
          >
            <TextField
              id="lastname"
              label="Parent Last Name"
              m={2}
              onChange={(e)=>{
                console.log('lastname:', e.target.value);
                setLastName(e.target.value);}}
              value={lastName}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={4}
            xs={4} sm = {4}
          >
            <CenteredContent>
              {photoURL !== ''?
                (
                  <Avatar
                    alt=""
                    src={photoURL}
                  />):
                (
                  <Avatar
                    alt=""
                    src="/static/img/avatars/avatar-1.jpg"
                  />)}
            </CenteredContent>
          </Grid>
       


          {userState.authLevel >=0 ?  (
            <Grid
              item
              md={4}
              xs={8}
              sm={6}
            >
              <TextField
                InputProps={{
                  readOnly: true
                }}
                id="registeredDate"
                label="Registration Date"
                m={2}
                value={registeredDate}
                variant="outlined"
              />
            </Grid>): (<Grid item></Grid>)}
        </Grid>
        <Grid
          container
          spacing={2}
          xs={12}
        >


          <Grid item xs={8}>


            <SharedSnackbarConsumer>
              {({openSnackbar}) => (
                <Button
                  color="primary"
                  disabled={!isEmailValid || !isStudyIdValid}
                  onClick= {()=> {
                    openSnackbar('Saving parent settings');
                    modifyUserSettings();}}
                  variant="contained"
                >
                  {buttonText}
                </Button>

              )
              }
            </SharedSnackbarConsumer>


          </Grid>
          <Grid item xs={8}>

            {userState.authLevel >= 0 ?  
              (<div/>) : 
              (<Typography gutterBottom variant="body" color='secondary'>You'll enter Baby info next</Typography>) 
            }
          </Grid>

          {userState.authLevel >0 ? (
            <Grid
              item
              //gads none of these work as a way to keep the
              // text box full extended.
              lg={6}
              md={6}
              sm={6}
              xl={6}
              xs={12}
            >
              <TextField
                id="signature"
                label="Signature"
                multiline
                fullWidth
                onChange={(e)=>setSignature(e.target.value)}
                value={signature}
                variant="standard"
              />
            </Grid>
          ) : (<Grid item></Grid>)}
        </Grid>

      </CardContent>
    </Card>
  );  
}
