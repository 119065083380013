import React, {useEffect} from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { 
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core/styles';

import { conclusionText } from '../../common/staticdata';
import { ReportInfoActionTypes, ReportInfoContext } from '../../context/ReportContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '105ch',
      },
    },
    card: {
      maxWidth: '100%',
      minWidth: '0',
      width:'100%',
      margin: 'auto',
      transition: '0.3s',
      boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
      '&:hover': {
        boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)'
      }
    },
    cardcontent: {
      textAlign: 'left',
      // padding: theme.spacing.unit * 3
    },    
  }),
);

export const ConclusionInfo= ():JSX.Element=>{

  const classes = useStyles();

  // desing is needed to figure out what to do with the message, how much of it should be 
  // reloaded? and what if custom messages are implemented? over engeering on that?
  const {reportInfoState, reportInfoDispatch} = React.useContext(ReportInfoContext);
  const [conclusionType, setConclusionType] = React.useState<Record<string,string>>({});
  const [conclusionMessage, setConclusionMessage] = React.useState<string>('');
  const {reportConclusion} = reportInfoState?.reportInformation;
  const {percentile} = reportInfoState?.scoreInformation;
  
  // //intesting, this is needed to actually do the text updating.
  // useEffect(()=>{
  //   if(reportConclusion){
  //     setConclusionMessage(reportConclusion);
  //   }
  //   // console.log('Report info', percentile);
  // },[reportConclusion]);
  
  useEffect(() => {
    // and update the report information, timing, doing it here
    // needs the message directly.
    reportInfoDispatch({type:ReportInfoActionTypes.UPDATE_INFO, 
      reportInformation:{reportConclusion:conclusionMessage}});   
  },[conclusionMessage]);

  useEffect(() =>{

    const belowKey = 'below';
    // this calculation is done in ReportComponent so be sure to check both
    // when bug fixing 
    if(belowKey in percentile){
      const belowPercentile = percentile[belowKey] as number;
      if(belowPercentile <= 10){
        setConclusionMessage(conclusionText[1].message);
        setConclusionType(conclusionText[1]);
      }else{
        setConclusionMessage(conclusionText[0].message);
        setConclusionType(conclusionText[0]);
      }
    }
  }, [percentile.below]);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setValue(event.target.value);
    // console.log('Updating the text', event.target.value);
    // reportInfoDispatch({type:ReportInfoActionTypes.UPDATE_INFO, 
    //   reportInformation:{reportConclusion:event.target.value}});
    setConclusionMessage(event.target.value);
  };
  return (          
    <Card className={classes.card}>
      <CardContent className={classes.cardcontent}>
        <Typography
          color="secondary"
          gutterBottom
          variant={'h6'}
        >
        Further Evaluation
        </Typography>
        <form
          autoComplete="off"
          className={classes.root}
          noValidate
        >
          <div>            
            <Autocomplete
              //maybeuse a select?? have null option not hte best
              disableClearable
              getOptionLabel={(option)=>option.label}
              id="conclusion-type"
              onChange={(event: unknown, newValue: Record<string,string>) => {
                setConclusionType(newValue);
                if(newValue !== null){
                  setConclusionMessage(newValue.message);
                }else{
                  setConclusionMessage('');
                }
              }}
              options={conclusionText}
              renderInput={
                (params) => 
                  <TextField
                    style={{ width: 200 }}
                    {...params}
                    label="Concerns"
                    variant="outlined"
                  />
              }
              value={conclusionType}
            />
          </div>
          <div>
            <TextField
              autoFocus
              fullWidth
              id="conclusion-message"
              label="Message"
              margin="dense"
              multiline
              onChange={handleTextChange}
              rows={4}
              value={conclusionMessage}
              variant="outlined"
            />
          </div>
        </form>
      </CardContent>
    </Card>
  );
};