/* eslint-disable react/no-multi-comp */
// not sure if loading here is better than calling from react
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'; 
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Typography,
  Grid
} from '@material-ui/core';

import { AssessmentPayloadProps} from '../../../common/entityUtilities';

import {useUserState} from '../../../context/UserContext';
import {
  createAssessmentFromDataSomeFeedback,
  updateAssessmentWithNewFiles
} from '../../../common/utilities';

import {
  useAsmtTableDispatch,
  AssessmentListActionTypes,
  useAsmtTableState,
  adjustAvailableAsmts,
  AsmtTableProps
} from '../../../context/AssessmentListContext';

import {
  AssessmentCreationFeedBackUIProps
} from '../../../common/entityUtilities';

import { SaveStatusTypes } from '../../../common/entityUtilities';
// this elemnt takes the data from the assessment creating dialog and
// goes through the creation process with 
function LinearProgressWithLabel(props:LinearProgressProps & { value: number }) {
  return (
    <Box
      alignItems="center"
      display="flex"
    >
      <Box
        mr={1}
        width="100%"
      >
        <LinearProgress
          variant="determinate"
          {...props}
        />
      </Box>
      <Box minWidth={35}>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

export default function AsmtCreationProgressComponent(props:{
  asmtSaveValues:AssessmentPayloadProps|undefined, 
  setSavingStatus:Dispatch<SetStateAction<SaveStatusTypes>>}):JSX.Element {
  // closeParent:()=>void}

  const user = useUserState();
  const asmtTableSearchState = useAsmtTableState();

  const asmtTableDispatch = useAsmtTableDispatch();
  const {asmtSaveValues, setSavingStatus} =  props;//, closeParent 
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [progressInfo, setProgressInfo] = useState('Initializing');
  // Will need a tri state saving/in progress/complete
  // const [creatingAsmtStatus, setIsCreatingAsmt] = useState<SaveStatusTypes>(SaveStatusTypes.INITIALIZE);
  const [savingProcessStatus, setSavingProcessStatus] = useState<AssessmentCreationFeedBackUIProps>();
  let timerToCancel:any;

  async function fakeUpdateOverTime():Promise<AssessmentCreationFeedBackUIProps> {
    return await new Promise(resolve => {
      timerToCancel = setInterval(() => {
        setSavingProcessStatus((prevSavingStatus)=>{       
          // We want increments of 10%
          let totalSize =  prevSavingStatus?.totalSize;
          let increment = 10;
          if(totalSize){
            increment = totalSize/10;
          }else{
            totalSize = 100;
            increment = 10;
          }
          let bytesUp = prevSavingStatus?.bytesTransferred;
          if(bytesUp){
            bytesUp += increment;
          }else{
            bytesUp = increment;
          }
          const newValue:AssessmentCreationFeedBackUIProps = {
            ...(prevSavingStatus as AssessmentCreationFeedBackUIProps),
            bytesTransferred:bytesUp
          };
          if(bytesUp > totalSize){
            newValue.bytesTransferred = 0;
            resolve(newValue);
            clearInterval(timerToCancel);
          }
          return newValue;
        });
      }, 500);
    });
  }
  
  const displayFakeUploadTime= async (asmtCreationPayload:AssessmentPayloadProps|undefined,
    setStatus:Dispatch<React.SetStateAction<AssessmentCreationFeedBackUIProps|undefined>>)
    :Promise<void>=>{
    if(!asmtCreationPayload) return;
    let count = 0;
    const videoCount = asmtCreationPayload.videosForUpload.length;
    for(const video of asmtCreationPayload.videosForUpload){
      count+=1;
      setStatus((prevSavingStatus)=>{
        const newValue:AssessmentCreationFeedBackUIProps = {
          ...(prevSavingStatus as AssessmentCreationFeedBackUIProps),
          videoTotal:videoCount, currentVideoCount:count,
          fileName:video.name, totalSize:video.size
        };
        return newValue;
      });
    }

    await fakeUpdateOverTime();
    console.log('Done with fake upload');
  };

  React.useEffect(() => {
    // If this gets called before the async funcionts ends i suspect bad things
    // will happen. For now just think happy thoughts.

    // The real thing
    async function passThroughCreateAssessment():Promise<void>{
      const useFake = false;
      if(useFake){
        // The fake call for pretty visuals.
        await displayFakeUploadTime(asmtSaveValues,setSavingProcessStatus);
      }else{
        // Call with real data,
        if(asmtSaveValues)
        {
          console.log('Updating with', asmtSaveValues);
          if(asmtSaveValues.assessmentId){
            await updateAssessmentWithNewFiles(user.firebaseUser, 
              asmtSaveValues, setSavingProcessStatus);
          }else{
            await createAssessmentFromDataSomeFeedback(user.firebaseUser, 
              asmtSaveValues, setSavingProcessStatus);
          }
        }
      }

      // This function can probalby be moved into the context file. That would clean up
      // a lot of the imports needed. Also there are some oddities with creation.
      // ie when some gets created and the filter was set to the current time, there will not be
      // an update because the asmt gets created after the previous current time.
      await adjustAvailableAsmts(AssessmentListActionTypes.UPDATE, asmtTableSearchState, user.firebaseUser, asmtTableDispatch);
      
      setSavingStatus(SaveStatusTypes.DONE);
    }

    passThroughCreateAssessment();
    return () => {
      clearInterval(timerToCancel);
    };
  }, [asmtSaveValues]);

  useEffect(()=>{
    if(!savingProcessStatus) return;
    const {fileName, videoTotal, totalSize, genericMessage,
      bytesTransferred,currentVideoCount} = savingProcessStatus;
    const text = `Uploading ${fileName}, video ${currentVideoCount} of ${videoTotal}`;
    if(genericMessage){
      setProgressInfo(genericMessage);
    }else{
      setProgressInfo(text);
    }   
    if(bytesTransferred && totalSize){
      const perc = Math.floor(100*(bytesTransferred/totalSize));
      setProgressPercentage(perc);
    }
  },[savingProcessStatus]);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
      >
        <Typography>
          {progressInfo}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <LinearProgressWithLabel value={progressPercentage} />
      </Grid>
    </Grid>
  );
}