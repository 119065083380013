import React from 'react';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  TextField
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { 
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core/styles';
import {
  ReportInfoContext,
} from '../../context/ReportContext';
import { useUserState } from '../../context/UserContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    card: {
      maxWidth: '100%',
      minWidth: '0',
      width:'100%',
      margin: 'auto',
      transition: '0.3s',
      boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
      '&:hover': {
        boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)'
      }
    },
    cardcontent: {
      textAlign: 'left',
      // padding: theme.spacing.unit * 3
    },    
  }),
);

export const AssessorInfo= ():JSX.Element=>{
  // this would only be used for a new 
  const user = useUserState();
  
  const {reportInfoState, reportInfoDispatch} = React.useContext(ReportInfoContext);
  const [value, setValue] = React.useState<Date | null>(null);
  const {assessmentDate, reportCreatorName, 
    reportCreatorEmail, reportCreatorSignature  } = reportInfoState?.reportInformation;
  const classes = useStyles();
  
  return (          
    <Card className={classes.card}>
      <CardContent className={classes.cardcontent}>
        <Typography
          color="secondary"
          gutterBottom
          variant={'h6'}
        >
        Assessment Information
        </Typography>

        <form
          autoComplete="off"
          className={classes.root}
          noValidate
        >
          <div>
            <KeyboardDatePicker
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              format="MM/dd/yyyy"
              label="Assessment Date"
              margin="normal"
              onChange={setValue}
              value={assessmentDate}
            />
          </div>
          <div>
            <TextField
              autoFocus
              fullWidth
              id="assessorfirstname"
              label="Assessor name"
              margin="dense"
              type="text"
              value={reportCreatorName}
            />
            {/* <TextField
              autoFocus
              fullWidth
              id="assessorlastname"
              label="Last Name"
              margin="dense"
              // onChange={(event) => setDialogValue({ ...dialogValue, lastname: event.target.value })}
              type="text"
            /> */}
            <TextField
              InputLabelProps={{shrink:true}}
              autoFocus
              fullWidth
              id="assessorEmail"
              label="Assessor Email"
              margin="dense"
              type="text"
              value={reportCreatorEmail}
            />
          </div>
          <div>
            <TextField
              InputLabelProps={{shrink:true}}
              autoFocus
              fullWidth
              id="assessorSignature"
              label="Assessor Signature"
              margin="dense"
              multiline
              style={{ width: '100%' }}
              type="text"
              value={reportCreatorSignature}
            />
          </div>
        </form>
      </CardContent>
    </Card>
  );
};