import React, {useState, useEffect} from 'react';

// import PropTypes from 'prop-types';
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  // DialogContentText,
  DialogActions,
  // Divider,
  Button,
  MenuItem,
  Typography,
  // FormControl,
  Grid,
  FormGroup,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import {  KeyboardDatePicker } from '@material-ui/pickers';
import {
  AssessmentListUIProps,
  emptyUserState, UserProps,
} from '../../common/entityUtilities';

import {
  // useUserListState,
  useUserListDispatch,
  UserListTypes,
  useUserListState
} from '../../context/UserListContext';

import {
  useUserState,
  useUserDispatch,
  UserActionTypes
} from '../../context/UserContext';

import { genders } from '../../common/staticdata';

import {
  setUserInfo, 
  setSubjetInfo
} from '../../common/apiSetUtilities';

import {
  convertUserDataToUserPropType
} from '../../common/entityUtilities';

import { 
  EmmaUserSchemaProps, 
  InstrumentSchemaProps, 
  SubjectSchemaProps 
} from '../../common/mongoSchemas';
import { ScoreCard } from '../../components/Scoring';
import { WeekAndDayInput } from '../../components/Inputs';
import { prettifyMonthDay } from '../../common/reportUtilities';
import { calculateGestAgeFromExpected, calculateWeeksAndDaysFromDays} from '../../common/dateTimeUtilities';

type UIPropsForNewParentDialog = {
  email?:string,
  emmauserId?:string,
  firstname:string,
  lastname:string,
  babyname:string,
  gender:string,
  dob:Date,
  duedate:Date|null,//needs to be nullalbe in ui but null== Date(0) for db
  gestAgeDays:number|undefined, //gest age days, but gets displayed weeks/days
  notes:string,
  subjectId?:string
  // for admins
  signature?:string
};

const emptyParentUIState:UIPropsForNewParentDialog = {
  email:'',
  emmauserId:'',
  firstname:'',
  lastname:'',
  babyname:'',
  gender:'F',
  dob:new Date(),
  duedate:null,//new Date(),
  gestAgeDays:undefined,//40*7, //40 weeks * 7 days
  notes:''
};

export type UserInfoDialogProps = {
  isOpen:boolean,
  dialogComplete:()=>void,
  userInformation:UserProps|null,
  asmtInformation?:AssessmentListUIProps,
  //booo things needed but should be factored out.
  // availableInstruments?:InstrumentSchemaProps[],
  // selectedInstrumentIndex?:number

};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(2),
        width: 200,
      },
    },
  }),
);
//https://dev.to/hibaeldursi/creating-a-contact-form-with-validation-with-react-and-material-ui-1am0
// This dialog is so ugly and disorganized...
// hopefully it gets refactored out. Need the UIPropsForNewParentDialog
// makes this particularly ugly
export const UserInfoDialog = (props:UserInfoDialogProps):JSX.Element=> {
  const classes = useStyles();
  // userInformation is UserProps
  const {isOpen, dialogComplete, userInformation, asmtInformation}  = props;
  
  const userState = useUserState();
  const userListDispatch = useUserListDispatch();
  const userDispatch = useUserDispatch();
  const userList = useUserListState();
  // The allusers context populates the selections, but they 
  // are full Userprops, not just emails. which means?
  // i think the combobox has all the data about the selected
  // user, maybe
  // const { allUsers } = useUserListState();
  // const [mainValue, setMainValue] = useState(null);
  // const [open, toggleOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState(emptyParentUIState);
  const [displaySubjectInformation, setDisplaySubjectInformation] = useState(true);
  // this can probably be replaced by the emmaUserId
  const [isNewUser, setIsNewUser] = useState(true);
  const [calculatedGestAgeString, setCalculatedGestAgeString] = useState<string|undefined>();
  // hackish
  const emailSet = new Set(userList.allUsers.map((userInfo)=>userInfo.localEmail));
  const [errors, setErrors] = useState<any>({});

  // useEffect(()=>{
  //   console.log('error', errors);
  // },[errors]);

  useEffect(()=>{
    // The userinfo is of type UserProps
    if(userInformation === null) {
      console.log('Attempting a dialog reset?');
      return;//hacky way of resetting dialog which stopped working
    }
    if(!userInformation){
      setDisplaySubjectInformation(true);
      setIsNewUser(true);
      setDialogValue(emptyParentUIState);
      return;      
    }
    const displaySubjectInfo = (userInformation.authLevel <5 || asmtInformation !== undefined);
    setDisplaySubjectInformation(displaySubjectInfo);
    setIsNewUser(false);
    // bad javascript...dlp...get it to work and then wish
    // for testing set.
    let dialogValues:UIPropsForNewParentDialog =  {...emptyParentUIState, 
      firstname:userInformation.firstname,
      lastname:userInformation.lastname,
      email:userInformation.localEmail,
      emmauserId:userInformation.emmauserId,
      signature:userInformation.signature
      // authLevel:userInformation.authLevel
    };
    const {subject} = userInformation;
    if(subject){
      // something cuter would be nice
      console.log('Subject:', subject);
      dialogValues = {...dialogValues, 
        babyname:subject.name,
        // sometimes the subject.duedate does not come is a true date...
        duedate:new Date(subject.duedate).getTime()===new Date(0).getTime()?null:subject.duedate,
        gender:subject.gender,
        dob:subject.dob,
        gestAgeDays:subject.gestationAgeDays??0,
        notes:subject.notes as string, //why was this user notes before?
        //additionalProp1:{notes:''} is what it used to be/ current in existential
        //crisis.
        subjectId:subject.subjectId
      };
    }
    setDialogValue(dialogValues);
   
  },[userInformation]);
  
  useEffect(()=>{
    if(dialogValue.duedate === null){
      setCalculatedGestAgeString(undefined);
    }else{
      const weekDays = calculateGestAgeFromExpected(dialogValue.duedate, dialogValue.dob);
      // console.log('Week days', weekDays); ${+split[1]===1?'day':'days'
      setCalculatedGestAgeString(prettifyMonthDay(weekDays.weeks, weekDays.days, true));
    }
    checkForDueDateAndGestAge();
  },[dialogValue.duedate, dialogValue.dob]);

  useEffect(() =>{
    checkForDueDateAndGestAge();
  }, [dialogValue.gestAgeDays]);

  const updateUIWithValues = (createNewUser: boolean, emmaUserId: string | undefined, 
    userPropertiesMongoDb: Partial<EmmaUserSchemaProps>) =>{

    const newPropsForUser = convertUserDataToUserPropType(userPropertiesMongoDb);
    // hope and pray the createNewUser is accurate. The userProperties are only what got update
    // maybe it should include the id of what was update.
    if(createNewUser){
      const newEmmaUser = {...emptyUserState, ...newPropsForUser };
      userListDispatch({type:UserListTypes.ADD_USERS, allUsers:[newEmmaUser]});
    }else{
      // updating a user that exists
      console.log('Updating with', newPropsForUser);
      if(emmaUserId === userState.emmauserId){
        userDispatch({type:UserActionTypes.UPDATE, ...newPropsForUser});
        newPropsForUser.emmauserId = emmaUserId;
        // Hack/bad design: need to attach the local email for the update until next pass
        userListDispatch({type:UserListTypes.UPDATE_USERS, allUsers:[newPropsForUser]});
      }else{
        newPropsForUser.emmauserId = emmaUserId;
        // Hack/bad design: need to attach the local email for the update until next pass
        userListDispatch({type:UserListTypes.UPDATE_USERS, allUsers:[newPropsForUser]});
      }
    }
  };
  
  const checkForDueDateAndGestAge = ()=>{
    if(dialogValue.duedate !== null || dialogValue.gestAgeDays){
      const {duedate, ...rest} = errors;
      setErrors(rest);
      return;
    }
    setErrors({...errors, duedate:'Set due date or gestational age'});
  };

  const handleClose = () => {
    // new way of reseting dialog state when closing.
    setErrors({});
    setDialogValue(emptyParentUIState);
    dialogComplete();
  };

  const handleWeekDaysInput = (gestAgeDays:number|undefined) =>{
    // console.log('The thing', gestAgeDays);
    setDialogValue({ ...dialogValue, gestAgeDays: gestAgeDays });

  };
  
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Copied from UserSettings component
    const mongoUserValuesToUpdate:Partial<EmmaUserSchemaProps> = {};//
    // For now assuming new users can only be parents (ie not admins)
    // A checkbox in the ui can give the option though
    // the new users is not registered, and hopefully the email matches
    if(isNewUser){
      mongoUserValuesToUpdate.authLevel = -1;
      mongoUserValuesToUpdate.email = dialogValue.email;
    }
    mongoUserValuesToUpdate.firstname = dialogValue.firstname;
    mongoUserValuesToUpdate.lastname = dialogValue.lastname;
    // how does the api deal with undefined.

    // console.log('The sig', dialogValue.signature);
    if(dialogValue.signature){
      mongoUserValuesToUpdate.signature = dialogValue.signature;
    }

    // The user that is saving things
    const firebaseUser = userState.firebaseUser;
    // hmm wont this naturally be undefined?
    let userToUpdateId = userInformation?.emmauserId;
    if(isNewUser) userToUpdateId = undefined;
    // Save the new user, gads we really really really needs tests...
    // gads this save is not robust...
    setUserInfo(firebaseUser, userToUpdateId, mongoUserValuesToUpdate)
      .then(storedUserData=>{ // Stored data is EmmaUserSchemaProps
   
        // Add the subject/baby info
        // In theory this only gets called on a new parent ie
        // a parent with no subject so no need for collision checks.
        // This is the SubjectSchemaProps
        if(userToUpdateId === undefined && storedUserData.emmauserId ){
          userToUpdateId = storedUserData.emmauserId;
        }
        
        if(dialogValue.babyname !== ''){
          const babyPayload:SubjectSchemaProps = {
            name: dialogValue.babyname,
            gender: dialogValue.gender,
            additionalProp1: {notes:dialogValue.notes},
            dob: new Date(dialogValue.dob),
            // if thte dialog value is null use 0 for the date
            duedate: new Date(dialogValue.duedate===null?0:dialogValue.duedate),
            gestationAgeDays:dialogValue.gestAgeDays,
            emmauserid: userToUpdateId        
          };
          if(dialogValue.subjectId && dialogValue.subjectId !== ''){
            babyPayload.subjectId = dialogValue.subjectId;
          }
          // Save the subject data.
          setSubjetInfo(userState.firebaseUser, babyPayload)
          // All the saving is done, now time to update the UI
            .then((storedSubjectData)=>{
              // console.log('The subject post set', storedSubjectData);
              // update issues? used to be subject is now subjects
              storedUserData.subjects = storedSubjectData;
              updateUIWithValues(isNewUser, userToUpdateId, storedUserData);
            });
        }else{         
          updateUIWithValues(isNewUser, userToUpdateId, storedUserData);
        }
      });
    handleClose();
  };

  //Ugh, this UI should to be split/updated for two components, one for user and the
  // other for parents
  return (

    <Dialog
      aria-labelledby="form-dialog-title"
      onClose={handleClose}
      open={isOpen}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-dialog-title">          
          {displaySubjectInformation?'Family information':'Update user'}
        </DialogTitle>
        <DialogContent className={classes.root}>
          <div>
            <Typography
              color="secondary"
              variant="h6"
            >
              {displaySubjectInformation?'Family info':'User info'}
            </Typography>
            <TextField
              autoFocus
              error = {errors.email}
              fullWidth
              helperText = {errors.email}
              id="parentemail"
              inputProps={{
                readOnly: !isNewUser
              }}
              label="Email"
              margin="dense"
              onChange={(event) => 
              {
                const emailVal = event.target.value;
                if(emailSet.has(emailVal)){
                  setErrors({email:'Email is already registered.'});
                }else{
                  const {email, ...rest} = errors;
                  setErrors(rest);
                }
                setDialogValue({ ...dialogValue, email: emailVal });
              }}
              style={{ width: '80%' }}
              type="email"
              value={dialogValue.email}
            />
            <FormGroup
              aria-label="position"
              row
            >
              <TextField
                autoFocus                
                id="parentfirstname"
                label="First name"
                margin="dense"
                onChange={(event) => setDialogValue({ ...dialogValue, firstname: event.target.value })}
                type="text"
                value={dialogValue.firstname}
              />
              <TextField
                autoFocus                
                id="parentlastname"
                label="Last name"
                margin="dense"
                onChange={(event) => setDialogValue({ ...dialogValue, lastname: event.target.value })}
                type="text"
                value={dialogValue.lastname}
              />
            </FormGroup>
            {!displaySubjectInformation && 
            <TextField 
              fullWidth
              helperText="Administrator signature"
              id="signature"
              multiline
              onChange={(event) => setDialogValue({ ...dialogValue, signature: event.target.value })}
              placeholder="Signature for user"
              style={{ width: '80%' }}
              value = {dialogValue.signature}
            />
            }
          </div>         
          {displaySubjectInformation && 
          <div>
            <Typography     
              color="secondary"
              variant="h6"
            >
                Child info
            </Typography>
            <TextField
              autoFocus
              id="babyname"
              label="babyname"
              margin="dense"
              onChange={(event) => setDialogValue({ ...dialogValue, babyname: event.target.value })}
              type="text"
              value={dialogValue.babyname}
            />
            <TextField
              // helperText="Your baby's gender"
              id="gender"
              label="Gender"
              // m={2}
              onChange={(event) => setDialogValue({ ...dialogValue, gender: event.target.value })}
              select
              style={{ width: 60 }}
              value={dialogValue.gender}
            >
              {genders.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <KeyboardDatePicker
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              format="MM/dd/yyyy"
              label="Date of birth"
                
              margin="normal"
              onChange={(event) => {
                setDialogValue({ ...dialogValue, dob: event as Date });
              }}
              value={dialogValue.dob}
            />
            <WeekAndDayInput 
              initialGestationAgeInDays={dialogValue.gestAgeDays}
              setGestationAgeInDays = {handleWeekDaysInput}
            />
            <KeyboardDatePicker
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              error = {errors.duedate}
              format="MM/dd/yyyy"
              helperText = {errors.duedate}
              label="Due Date"
              margin="normal"
              onChange={(event) => setDialogValue({ ...dialogValue, duedate: event as Date })}
              value={dialogValue.duedate}
            />
            {calculatedGestAgeString && 
            <TextField
              fullWidth
              id="cacledGestAge"
              label="Calculated Gestational Age"
              value = {calculatedGestAgeString}
            />
            }

            {/* <WeekAndDayInput 
            // maybe encorporate the textfield into the component for when text is not
            //editable
              initialGestationAgeInDays={calculatedGestAgeDays}
              label="Calculated Gest Age"
              // setGestationAgeInDays = {handleWeekDaysInput}
            /> */}
            <TextField 
              fullWidth
              helperText="Additional Infant Notes"
              id="notes"
              multiline
              onChange={(event) => setDialogValue({ ...dialogValue, notes: event.target.value })}
              placeholder="Additional notes about your baby here."
              style={{ width: '100%' }}
              value = {dialogValue.notes}
            />
          </div>
          }
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleClose}
          >
              Cancel
          </Button>
          <Button
            color="primary"
            disabled={Object.keys(errors).length > 0 || !dialogValue.email}
            type="submit"
          >
            {isNewUser?'Create':'Update'}              
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
