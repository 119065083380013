import React from 'react';

import PropTypes from 'prop-types';
import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@material-ui/core';

const rows = [
  {
    id: 'videoId',
    numeric: false,
    disablePadding: true,
    label: 'Video'
  },
  { id: 'timestamp', numeric: true, disablePadding: false, label: 'Date' },
  { id: 'duration', numeric: true, disablePadding: false, label: 'Length' },
  { id: 'delete', numeric: true, disablePadding: false, label: 'Delete' }
  // { id: 'tagCount', numeric: true, disablePadding: false, label: '# Tags' },
  // { id: 'hasBeenViewed', numeric: false, disablePadding: false, label: 'Viewed?' }
];

export type VideoTableHeadProps = {
  numSelected: number,
  onRequestSort: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, property: string) => void,
  onSelectAllClick: () => void,
  order: 'asc' | 'desc',
  orderBy: string,
  rowCount: number,
};

const VideoTableHead = (props: VideoTableHeadProps) => {

  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount
  } = props;

  const createSortHandler = (property: string) => (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    props.onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {rowCount > -1 &&         
        <TableCell padding="checkbox">

          <Checkbox
            checked={numSelected === rowCount}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        }        
        {rows.map(
          row => (
            <TableCell
              align={row.numeric ? 'right' : 'left'}
              key={row.id}
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}
            >
              <Tooltip
                enterDelay={300}
                placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                title="Sort"
              >
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={createSortHandler(row.id)}
                >
                  {row.label}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
};

VideoTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};
export default VideoTableHead;