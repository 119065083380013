import React, {useEffect} from 'react';
// import {userData} from '../common/staticdata';
import { getAllEmmaUsers } from '../common/apiGetUtilities';
import { useUserState } from './UserContext';
import { UserProps, convertUserDataToUserPropType, emptyUserState } from '../common/entityUtilities';
type Props = {
  children:React.ReactNode;
};
enum UserListTypes {
  LOAD = 'LOAD',
  RESET = 'RESET',
  ADD_USERS = 'ADD_USER',
  UPDATE_USERS = 'UPDATE_USER'
}
type UserListProps = {
  allUsers:UserProps[]  
}
interface UserListAction extends UserListProps{
  type:UserListTypes;
}

const intialUserList:UserListProps = {
  allUsers:[]
};

// function initUserListReducer(newInit:UserListProps){
//   // Needs to be put into a sleep to emulate an api call
//   const initUsers = intialUserList;
//   for(const rawUser of userData){
//     initUsers.allUsers.push(convertUserDataToUserContext(rawUser));
//   }
//   return initUsers;
// }

function userListReducer(state:UserListProps, action:UserListAction){
  switch(action.type) {
    case UserListTypes.LOAD:{
      const {type, ...noType} = action;
      return noType;
    }
    case UserListTypes.RESET:
      return { ...state, ...intialUserList};
    case UserListTypes.ADD_USERS:{
      const list = state.allUsers;
      const newUser = action.allUsers;
      const oldUserSet = new Set(list.map(item=>item.localEmail));
      for(const user of newUser){
        if(!oldUserSet.has(user.localEmail)){
          console.log('Adding user', user.localEmail);
          list.push(user);
        }else{
          console.log('attepmting to update');
        }
      }      
      const listOfUserProps:UserListProps = {
        allUsers:list
      };
      return {  ...listOfUserProps};
    }
    //https://www.robinwieruch.de/react-update-item-in-list
    case UserListTypes.UPDATE_USERS:{
      const origList = state.allUsers;
      const updateUserList = action.allUsers;
    
      const userValueDict = new Map();
      
      for (const user of updateUserList) {
        const {emmauserId, ...others} = user;
        userValueDict.set(emmauserId, others);
      }
    
      const newUserSet = new Set(updateUserList.map(item=>item.emmauserId));
      const newList = origList.map((item) => {
        const userId = item.emmauserId;
        if (newUserSet.has(userId)) {
          const updateValues = userValueDict.get(userId);
          const updatedItem = {
            ...item,
            ...updateValues
          };   
          return updatedItem;
        }   
        return item;
      });  
      const listOfUserProps:UserListProps = {
        allUsers:newList
      };
      return {  ...listOfUserProps};
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
const UserListStateContext = React.createContext<UserListProps>(null!);
const UserListDispatchContext = React.createContext<React.Dispatch<any>>(null!);

function UserListProvider({ children }:Props) {
  const user = useUserState();
  //so ugly. Pretty sure the types are incorrect/all over the place
  const [state, dispatch] = React.useReducer(userListReducer, 
    intialUserList);//, initUserListReducer);
    
  useEffect(()=>{
    async function loadUser(){
      if(user.authLevel <0 ) return;
      const users = await getAllEmmaUsers(user);
      const initUsers:UserProps[] = [];
      for(const rawUser of users){
        const convertedUserProps = convertUserDataToUserPropType(rawUser);
        const changedUser = {...emptyUserState, ...convertedUserProps};
        initUsers.push(changedUser);
      }
      dispatch({ type: UserListTypes.LOAD, allUsers:initUsers });
    }
    loadUser();
  }, [user]);
  return (
    <UserListStateContext.Provider value={state}>
      <UserListDispatchContext.Provider value={dispatch}>
        {children}
      </UserListDispatchContext.Provider>
    </UserListStateContext.Provider>
  );
}

function useUserListState() {
  const context = React.useContext(UserListStateContext);
  if (context === undefined) {
    throw new Error('UserListStateContext must be used within a UserListProvider');
  }
  return context;
}

function useUserListDispatch() {
  const context = React.useContext(UserListDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserListProvider');
  }
  return context;
}

export { UserListProvider, useUserListState, useUserListDispatch,UserListTypes };
