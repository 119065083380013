import React from 'react';
import {
  CardMedia,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import VideoPlayer from 'react-video-markers';
export class VideoDetailsTabPanel extends React.Component {
  state = {
    isPlaying: false,
    volume: 0.7
  };

  handlePlay = () => {
    this.setState({isPlaying: true});
  };
  
  handlePause = () => {
    this.setState({isPlaying: false});
  };
  
  handleVolume = value => {
    this.setState({volume: value});
  };      

  

  render() {
    const { 
      // children, 
      value, index, videoData, timestamp, videoOverlayData,
      videoOverlayDataOP, videoOverlayDataActivity, motorRisk, markers } = this.props;
    const { isPlaying, volume } = this.state;
    if (value && value === 3){
      return (
        <div hidden={value !== index}>

          <DialogTitle id="max-width-dialog-title">
           Pose (infant)
          </DialogTitle>
      
          <DialogContent>
            <DialogContentText>
        Pose results overlaid.
            </DialogContentText>
            <CardMedia 
              component="video"
              controls
              height="256"
              image={videoOverlayData}
              title="Pose (infant)"
            />
          </DialogContent>
        </div>
      );
    }
    else if (value && value === 1) {
      // open pose overlay
      if (value == index){
        console.log('Metrics data:', motorRisk);
        console.log('video:', videoOverlayDataOP);
      }
      return (
        <div hidden={value !== index}>
          <DialogTitle id="max-width-dialog-title">
          Metrics
          </DialogTitle>     
          <DialogContent>
            <DialogContentText>
              {motorRisk &&
                <ul>
                  <li> <b>Head turn</b> Left: {parseInt(motorRisk.head_turn_left)}% Right: {parseInt(motorRisk.head_turn_right)}% 
                  Center:{parseInt(motorRisk.head_turn_center)}%</li>
                  <li> <b>Symmetry</b> Lower:{parseInt(motorRisk.lowersym)}% Upper: {parseInt(motorRisk.uppersym)}%</li>
                
                <li> <b>Motor Risk:</b> {parseFloat(motorRisk.z).toFixed(3)} Confidence: {parseFloat(100*motorRisk.p).toFixed(2)}% </li>
                </ul>
              }
            </DialogContentText>
            <CardMedia 
              component="video"
              controls
              height="256"
              image={videoOverlayDataOP}
              title="Metrics"
            />
          </DialogContent>
        </div>
      );
    }
    else if (value && value === 2){
      
      return (
        <div hidden={value !== index}>
         
          <DialogTitle id="max-width-dialog-title">
              Activities
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
            Activities detected in the snippet.
            </DialogContentText>           
            <VideoPlayer
              isPlaying={isPlaying}
              markers={markers}
              onPause={this.handlePause}
              onPlay={this.handlePlay}
              onVolume={this.handleVolume}
              url={videoData && videoData.url}              
              volume={volume}
            />    
          </DialogContent>
        </div>
      );
    } else {
      if (value == index){
        console.log('Raw video:', videoData);
      }
      return (      
        
        <div hidden={value !== index}>      
          <DialogTitle id="max-width-dialog-title">
             Raw video
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
          Video recording from <b>{timestamp}</b>.
            </DialogContentText>
            <CardMedia 
              component="video"
              controls
              height="256"
              image={videoData != null && videoData.url}
              title="Activities"
            />
          </DialogContent>
        </div>
      );
    }
  }
}