import { AimsScoreSchemaProps, ReportDataSchemaProps, ReportSchemaProps } from './mongoSchemas';


// expecting format of {number}:{othernumber}
export const convertMonthDayStringText= (monthDay:string, useWeeks = false):string =>{
  const split = monthDay.split(':',2);
  if(!split){
    //bad split...what happened?
    return '';
  }
  return prettifyMonthDay(+split[0], +split[1], useWeeks);
};

export const prettifyMonthDay = (month:number, day:number, useWeeks = false):string =>{

  let unit = 'month';
  if(useWeeks) unit = 'week';
  const ageString=`${month} ${month===1?`${unit}`:`${unit}s`} and ${day} ${day===1?'day':'days'}`;
  return ageString;
   
};

export const makeRawReportDataDisplayable = (reportInformation:ReportDataSchemaProps):Record<string, string> => {
  const {aimsScore, asmtSubjectName,assessmentDate,asmtSubjectBirthDate,
    reportConclusion, reportCreatorSignature, parentEmail, parentName,
    subjectAgeMonthDaysForAsmt, subjectCorrectedAgeMonthDaysForAsmt} = reportInformation;
  const {prone,sitting, supine, standing, total, percentile } = (aimsScore as AimsScoreSchemaProps)??{};
  
  const useCorrectedString = subjectCorrectedAgeMonthDaysForAsmt?'Yes':'No';
  let ageToUse = 'Unusable age';
  if(subjectAgeMonthDaysForAsmt)
    ageToUse = convertMonthDayStringText(subjectAgeMonthDaysForAsmt);
  if(subjectCorrectedAgeMonthDaysForAsmt)
    ageToUse += `(Corrected to ${convertMonthDayStringText(subjectCorrectedAgeMonthDaysForAsmt)})`;

  let parentInformation = '';
  if(parentEmail && parentName) parentInformation=`${parentName} (${parentEmail})`; 
  const presetableData:Record<string, string> = {
    'asmtDate':`${new Date(assessmentDate??'1/1/1969').toLocaleDateString('en-US')}`,
    'infantDob':`${new Date(asmtSubjectBirthDate??'1/1/1969').toLocaleDateString()}`,
    'ageAtAsmt':`${ageToUse}`,
    'isAgeCorrected':`${useCorrectedString}`,
    'infantName':`${asmtSubjectName}`,
    'parentDetails':`${parentInformation}`,
    'proneScore':`${prone?.score}`,
    'supineScore':`${supine?.score}`,
    'standingScore':`${standing?.score}`,
    'sittingScore':`${sitting?.score}`,
    'proneMaxName':`${prone?.maxItemName}`,
    'proneNotes':`${prone?.notes}`,
    'totalScore':`${total?.score}`,
    'supineMaxName':`${supine?.maxItemName}`,
    'sittingMaxName':`${sitting?.maxItemName}`,
    // can't correct the type until some form of versioning happens
    // for the reporting templates. or until production is up to date.
    'standingMaxName':`${standing?.maxItemName}`,
    'supineNotes':`${supine?.notes}`,
    'sittingNotes':`${sitting?.notes}`,
    'standingNotes':`${standing?.notes}`,
    'percentileNotes':`${percentile?.information}`,
    'asmtNotes':`${reportConclusion}`,
    // for some reason the line breaks are not holding
    'assessorSignature':`${reportCreatorSignature}`,
  };
  // console.log(presetableData);
  return presetableData; 
};

export const createEmailText = (reportInformation:ReportSchemaProps):string => {
  
  const {reportData, reportFileURL} = reportInformation;
  if(!reportData) return '';

  const {assessmentDate,parentEmail, parentName, asmtSubjectName}= reportData;
  if(!assessmentDate && !parentEmail && !parentName) return '';
  const asmtDateString = new Date(assessmentDate??'1/1/1969').toLocaleDateString('en-US');
  const mailHeading = `Motor Assessment Report: ${asmtDateString} visit`;
  // const body = `<p>Dear <strong> ${parentName} </strong>:</p> Thank you for helping us with our project! \
  // Attached is the report from the visit on: &nbsp;<strong> ${asmtDateString} </strong>. If you have any questions, let me know.\
  //   Thanks again.\
  //   <p>Here is the link to <strong> ${asmtSubjectName}'s </strong> motor evaluation report (in PDF): <a href="${reportFileURL}"> <b>report link</b></a></p>`;
  const bodyText = `Dear ${parentName}:\nThank you for helping us with our project! \n
          Attached is the report from the visit on: ${asmtDateString}. If you have any questions, let me know at study@earlymarkers.com.\n
          Thanks again.\n\n\tHere is the link to ${asmtSubjectName}'s  motor evaluation report (in PDF): \n
            ${reportFileURL}`;
  // console.log('Body:', bodyText);
  const mailViaWindowText = `mailto:${parentEmail}?subject=${mailHeading}&body=${encodeURIComponent(bodyText)}`;// open in new tab
  return mailViaWindowText;
};