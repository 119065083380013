import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Collapse, Button, Grid, Card, Select, MenuItem, CardContent, Typography, Paper, FormControlLabel, Slide, Switch} from '@material-ui/core';

import { VideoPlayer } from '../../components/VideoPlayer/VideoPlayer';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { VideoTagTableFull } from '../../dialogs/VideoTagDetails/components/VideoTagTableFull';
import { VideoTagTableContext } from '../../context/VideoTagTableContext';
const ResponsiveReactGridLayout = WidthProvider(Responsive);


const qlayout = {lg: [
  {i: 'q1', x: 0, y: 0, w: 6, h: 6},
  {i: 'q2', x: 6, y: 0, w: 6, h:6},
  {i: 'q3', x: 0, y: 6, w: 6, h: 4},
  {i: 'q4', x: 6, y: 6, w: 6, h: 6}
]};

export default class ShowcaseLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentBreakpoint: 'lg',
      compactType: 'vertical',
      mounted: false,
      checked1:true,
      checked2:true,
      videoTagTableContext : null,
      layouts: { lg: props.initialLayout }
    };

    this.onBreakpointChange = this.onBreakpointChange.bind(this);
    this.onCompactTypeChange = this.onCompactTypeChange.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
    this.onNewLayout = this.onNewLayout.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
  }

  componentDidMount() {
    this.setState({ mounted: true});
  }

  generateDOM() {
    return _.map(this.state.layouts.lg, function(l, i) {
      return (
        <div
          className={l.static ? 'static' : ''}
          key={i}
        >
          {l.static ? (
            <span
              className="text"
              title="This item is static and cannot be removed or resized."
            >
              Static - {i}
            </span>
          ) : (
            <span className="text">Cell:{i}</span>
          )}
        </div>
      );
    });
  }

  handleChange1(){
    let flag = this.state.checked1;
    console.log('Flag: ', flag);
    this.setState({checked1: !flag}, function(){
      console.log('Check1 status: ', this.state.checked1);
    });
  }
  handleChange2(){
    let flag = this.state.checked2;
    console.log('Flag: ', flag);
    this.setState({checked2: !flag}, function(){
      console.log('Check2 status: ', this.state.checked2);
    });
  }

  onBreakpointChange(breakpoint) {
    this.setState({
      currentBreakpoint: breakpoint
    });
  }

  onCompactTypeChange() {
    const { compactType: oldCompactType } = this.state;
    const compactType =
      oldCompactType === 'horizontal'
        ? 'vertical'
        : oldCompactType === 'vertical'
          ? null
          : 'horizontal';
    this.setState({ compactType });
  }

  onLayoutChange(layout, layouts) {
    this.props.onLayoutChange(layout, layouts);
  }

  onNewLayout() {
    this.setState({
      layouts: { lg: generateLayout() }
    });
  }

  render() {
    return (
      <div>
        <div>
          Current Breakpoint: {this.state.currentBreakpoint} ({
            this.props.cols[this.state.currentBreakpoint]
          }{' '}
          columns)
        </div>
        <div>
          Compaction type:{' '}
          {_.capitalize(this.state.compactType) || 'No Compaction'}
        </div>
        <button onClick={this.onNewLayout}>Generate New Layout</button>
        <Grid
          container
          justify="space-around"
        >
          <Grid
            item
            xs={4}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.checked1}
                  onChange={this.handleChange1}
                />}
              label="Show Videos"
            />
          </Grid>
          <Grid
            item
            xs={4}
          >
            <button onClick={this.onCompactTypeChange}>Change Compaction</button>
          </Grid>
          <Grid
            item
            xs={4}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.checked2}
                  onChange={this.handleChange2}
                />}
              label="Show Details"
            />
          </Grid>
        </Grid>





        <Grid
          container
          justify="center"
          spacing={2}
        >

          <Slide
            direction="right"
            in={this.state.checked1}
            mountOnEnter
            unmountOnExit
          >
           

            <Grid
              item
              xs={12}
            >
              <Card  variant="outlined">
                <Paper elevation={2}>
                  <CardContent pb={0}>
                    <Grid container>
                      <Grid
                        item
                        xs={4}
                      >

                        <Button
                          color="primary"
                          variant="outlined"
                        >
                            Add Label
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                      >
                        <Grid container>
                          <Grid
                            item
                            xs={6}
                          >
                            <Typography
                              color="secondary"
                              variant="h6"
                            >
                                Choose from label set:
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                          >
                            <Select />
                          </Grid>
                        </Grid>   
                      </Grid>
                    </Grid>
                  </CardContent>
                </Paper>   
              </Card>
            </Grid>
                
          </Slide>


        
          <Slide
            direction="left"
            in={this.state.checked2}
            mountOnEnter
            unmountOnExit
          >
       

            <Grid
              item
              sm={6}
              xs={12}
            >
              <Paper elevation={2}>
                <span>Cell 4</span>
              </Paper>
            </Grid>
        
          </Slide>
        
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              xs={10}
            > 
              <Paper elevation={2}>

                <span>Cell 2</span>
              </Paper>
            </Grid>
            <Grid
              item
              xs={10}
            >
              <Paper elevation={2}>

                <span>Cell 3</span>
              </Paper>
            </Grid>
                
          </Grid>

        </Grid>
     

        <ResponsiveReactGridLayout
          {...this.props}
          // layouts={this.state.layouts}
          compactType={this.state.compactType}
          layouts = {qlayout}
          measureBeforeMount={false}
          // WidthProvider option
          onBreakpointChange={this.onBreakpointChange}
          // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
          // and set `measureBeforeMount={true}`.
          onLayoutChange={this.onLayoutChange}
          preventCollision={!this.state.compactType}
          useCSSTransforms={this.state.mounted}
        >

          {/* {this.generateDOM()} */}
          <div key="q1">
            <Grid container>
              <Grid item>
                <Paper elevation={2}>
                  <span>Video List</span>
                </Paper>
              </Grid>
            </Grid>
          </div>
          
          <div key="q2" >

            <VideoPlayer
              controls
              url="https://www.youtube.com/watch?v=mail73gUb6E&feature=youtu.be"
            />
          </div>

          <div key="q3">

            <Paper elevation={2}>
              <span>Asmt Details</span>
            </Paper>
          </div>

          <div key="q4">
            
            <Paper elevation={2}>
              <span>Add Labels</span>
            </Paper>
          </div>
        </ResponsiveReactGridLayout>
      </div>
    );
  }
}


function generateDOM() {
  return _.map(this.state.layouts.lg, function(l, i) {
    return (
      <div
        className={l.static ? 'static' : ''}
        key={i}
      >
        {l.static ? (
          <span
            className="text"
            title="This item is static and cannot be removed or resized."
          >
              Static - {i}
          </span>
        ) : (
          <span className="text">Cell:{i}</span>
        )}
      </div>
    );
  });
}
function generateLayout() {
  return _.map(_.range(0, 25), function(item, i) {
    var y = Math.ceil(Math.random() * 4) + 1;
    return {
      x: (_.random(0, 5) * 2) % 12,
      y: Math.floor(i / 6) * y,
      w: 2,
      h: y,
      i: i.toString(),
      static: Math.random() < 0.05
    };
  });
}

  
ShowcaseLayout.propTypes = {
  onLayoutChange: PropTypes.func.isRequired
};

ShowcaseLayout.defaultProps = {
  className: 'layout',
  rowHeight: 60,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onLayoutChange: function() {},
  cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
  initialLayout: qlayout 
//   generateLayout()
};
