//// Hmm this context might not be needed since everythign happens on the same page and it is 
// simple enough to just be a list
import React from 'react';

import {
  AssessmentListUIProps,
  FirebaseUserProps
} from '../common/entityUtilities';

import {
  deleteAssessment
} from '../common/apiDelUtilities';
import { createAssessmentListForUI } from '../common/utilities';
import { refreshToken } from './UserContext';
//Used for typing the provider, but there is a way of putting the type in without this
type Props = {
  children:React.ReactNode;
};
export enum AssessmentListActionTypes {
  PREACTION = 'PREACTION',
  SETSEARCH='SETSEARCH',
  LOAD = 'LOAD',
  RESET = 'RESET',
  UPDATE = 'UPDATE',
  ADD = 'ADD',
  ERROR = 'ERROR'
}
export interface AsmtTableProps  {
  startDate?:Date,
  endDate?:Date,
  disableDates?:boolean,
  subjectIdForAsmt?:string,
  findWithNoItems?:boolean,
  findWithNoReport?:boolean,
  allAssessments:AssessmentListUIProps[];
  statusMessage?:string
  needListRefresh?:boolean
}
export interface AsmtTableActions extends AsmtTableProps {
  type:AssessmentListActionTypes;
}

// THis seems pretty bad...dlp
function asmtListReducer(state:AsmtTableProps, action:AsmtTableActions){
  let message = '';
  const {type, ...noType} = action;
  // There are a lot of options on this, and im not sure how/where a proper
  // wait steps gets used in this.
  switch(action.type) {
    case AssessmentListActionTypes.PREACTION:{
      return {...state, statusMessage:action.statusMessage};
    }
    case AssessmentListActionTypes.SETSEARCH:{
      const update:AsmtTableProps = { ...state, ...noType, needListRefresh:true};
      return update;
    }
    case AssessmentListActionTypes.LOAD:
    case AssessmentListActionTypes.UPDATE:{
      const length = action.allAssessments.length;
      const assessmentString = length===1?'assessment':'assessments';
      message = `Found ${noType.allAssessments.length} ${assessmentString}`;
      const update:AsmtTableProps = { ...state, ...noType, statusMessage: message, needListRefresh:false};
      return update;
    }
    case AssessmentListActionTypes.RESET:
      return { ...action};
    // Might not be used since it adding to the list would require a check on the current
    // filter state.
    case AssessmentListActionTypes.ADD:{
      // const update:AsmtTableProps = { allAssessments:action.allAssessments, endDate:action.endDate, startDate:action.startDate};      
      return {...state, allAssessments:action.allAssessments, needListRefresh:false};
    }
    case AssessmentListActionTypes.ERROR:{
      return {...state, statusMessage:action.statusMessage};
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
// There is a way of combining the dispatch and state, but i believe it gets messy?
const AsmtListStateContext = React.createContext<AsmtTableProps>(null!);
const AsmtListDispatchContext = React.createContext<React.Dispatch<any>>(null!);

function AsmtListProvider({ children }:Props) {

  const initialEndDate = new Date();
  initialEndDate.setDate(initialEndDate.getDate() + 1);  
  const initialStartDate = new Date(initialEndDate);
  initialStartDate.setDate(initialEndDate.getDate() - 8);
  const startDateUTC = new Date(initialStartDate);
  const endDateUTC = new Date(initialEndDate);

  const initValue:AsmtTableProps = {
    allAssessments:[],
    startDate:startDateUTC,
    endDate:endDateUTC,
    findWithNoItems:false,
    findWithNoReport:false,
    disableDates:false, //init value for this is false so we default to searching the last week! --Bharath Nov 11 '22
    needListRefresh:true,

  };
  const [state, dispatch] = React.useReducer(asmtListReducer, initValue);

  return (
    <AsmtListStateContext.Provider value={state}>
      <AsmtListDispatchContext.Provider value={dispatch}>
        {children}
      </AsmtListDispatchContext.Provider>
    </AsmtListStateContext.Provider>
  );
}

function useAsmtTableState() {
  const context = React.useContext(AsmtListStateContext);
  if (context === undefined) {
    throw new Error('useEmmaVideosState must be used within a AsmtListProvider');
  }
  return context;
}

function useAsmtTableDispatch() {
  const context = React.useContext(AsmtListDispatchContext);
  if (context === undefined) {
    throw new Error('useEmmaVideosDispatch must be used within a AsmtListProvider');
  }
  return context;
}


async function deleteAssessmentAndUpdate(dispatch:any, firebaseUser:FirebaseUserProps, assessmentIdToDelete:string){
  // console.log('Doing a delete displatch');
  return deleteAssessment(firebaseUser, assessmentIdToDelete);
}

export async function adjustAvailableAsmts(type:AssessmentListActionTypes, adjustmentProps:AsmtTableProps,
  firebaseUser:FirebaseUserProps|undefined, asmtTableDispatch:undefined|((instructions:AsmtTableActions)=>void)):Promise<AssessmentListUIProps[]>{
  
  const {startDate, endDate, disableDates, subjectIdForAsmt, findWithNoItems, findWithNoReport} = adjustmentProps;
  await refreshToken(firebaseUser);
  console.log('Requesting assessments');
  console.log('start:', startDate);
  console.log('end:', endDate);
  console.log('adjustmentProps:', adjustmentProps);
  
  
  let message = 'Requesting Assessments';
  if(asmtTableDispatch){
    // Send an intial request which should only update the message that is displayed
    asmtTableDispatch({type:AssessmentListActionTypes.PREACTION, ...adjustmentProps, statusMessage:message});
  }

  let dateFilter = undefined;
  let noItemsFilter = undefined;
  let noReportFilter = undefined;

  if(findWithNoItems){
    const rawAnnotSetFilter = '{"where":{"annotations":{"size":0}},"include":[{"relation":"observation",'+
    '"scope":{"include":[{"relation":"video"},{"relation":"assessment","scope":{"include":[{"relation":"subject"}]}}]}}]}';
    noItemsFilter= JSON.parse(rawAnnotSetFilter);
  } if (findWithNoReport){
    const fullFilter:any = {};
    const filterArray:any = [];
    if(subjectIdForAsmt){
      filterArray.push({subjectId:subjectIdForAsmt});
    }
    if(filterArray.length > 0){
      fullFilter.where = { and: filterArray};
    }
  
    fullFilter.order = ['date DESC'];
    // Interesting this gets ALL the relations, including the relations
    // that the observations have. at this point those are include annotations
    // which must belong to an observation? maybe?
    fullFilter.include = [{relation:'reports'}, {relation:'observations', scope: { include: [{relation: 'video'}]}}];
    noReportFilter = fullFilter;
  } else{
    const fullFilter:any = {};
    const filterArray:any = [];
    if(!disableDates && startDate && endDate){

      const startDateUTC = new Date(startDate);
      const endDateUTC = new Date(endDate);
  
      filterArray.push({date:{gt:startDateUTC} });
      filterArray.push({date:{lt:endDateUTC} });
    }
  
    // This only works because the current system is one email to one user. The needed api work for
    // a family/shared access to a subject is not yet there and could make things much more complicated.
    if(subjectIdForAsmt){
      filterArray.push({subjectId:subjectIdForAsmt});
    }
    if(filterArray.length > 0){
      fullFilter.where = { and: filterArray};
    }
  
    fullFilter.order = ['date DESC'];
    // Interesting this gets ALL the relations, including the relations
    // that the observations have. at this point those are include annotations
    // which must belong to an observation? maybe?
    fullFilter.include = [{relation:'observations', scope: { include: [{relation: 'video'}]}}];
    dateFilter = fullFilter;
  }


  try{
    // Why do update and load do the same thing -dlp???
    switch(type) {

      case AssessmentListActionTypes.LOAD:{

        console.log('Check The set setting', adjustmentProps);
        console.log('AssessmentListContext LOAD action');
        const asmtList = await createAssessmentListForUI(firebaseUser, dateFilter, noItemsFilter, noReportFilter);
        if(asmtTableDispatch){
          asmtTableDispatch({type:type, ...adjustmentProps, allAssessments:asmtList});
        }
        return asmtList;
      }
      case AssessmentListActionTypes.UPDATE:{
        console.log('AssessmentListContext UPDATE action');
        const asmtList = await createAssessmentListForUI(firebaseUser, dateFilter, noItemsFilter, noReportFilter);
        if(asmtTableDispatch){
          asmtTableDispatch({type:type, allAssessments:asmtList});
        }
        return asmtList;
      }
      case AssessmentListActionTypes.SETSEARCH:{
        console.log('AssessmentListContext SETSEARCH action');
        console.log('Check The set setting', adjustmentProps);
        if(asmtTableDispatch){
          asmtTableDispatch({type:type, ...adjustmentProps});
        }
        return [];
      }
      default:
        return [];
    }
  }catch(error){
    message = '';
    const err = error as any;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      console.log('Found the response error', err.response);
      message = err.response.data.error.message;
    } else if (err.request) {
      // client never received a response, or request never left
      console.log('Found the request error', err.request);
      message = 'Enable to connect to API';
    } else {
      // anything else
      message = `Unknown error ${err}`;
    }
    // message = `API error: h${message} Time:${Date.now().toString()}`;
    if(asmtTableDispatch){
      asmtTableDispatch({type:AssessmentListActionTypes.ERROR, ...adjustmentProps, allAssessments:[], statusMessage:message});
    }
    return [];        
  }

}

export { useAsmtTableState, deleteAssessmentAndUpdate, useAsmtTableDispatch, AsmtListProvider};