import React from 'react';
import {
  withStyles,
  makeStyles,
  createStyles,
  Theme
} from '@material-ui/core/styles';

import { red } from '@material-ui/core/colors';
import {
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText
} from '@material-ui/core';


export interface VideoDeleteDialogProps {
  open: boolean;
  videoToDelete: string;
  onClose: (confirmationValue: boolean, videoId:string) => void;
}

const DeleteButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     margin: {
//       margin: theme.spacing(1),
//     },
//   }),
// );

export function VideoDeleteDialog(props: VideoDeleteDialogProps) {
  const { 
    onClose, 
    videoToDelete, 
    open 
  } = props;

  // const classes = useStyles();

  const handleClose = (confirmationValue:boolean) => {
    //yep. basically a direct pass through, this function
    // should be removed and replaced with the prop, probably
    // console.log('The asmt', assessmentProp?.assessmentId);
    if(videoToDelete){
      onClose(confirmationValue, videoToDelete);
    }else{
      onClose(false,'');
    }  
  };

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      onClose={()=>handleClose(false)}
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Delete Video</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
            Delete all data for this video?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          // className={classes.margin}
          color="primary"
          onClick={()=>handleClose(false)}
          variant="outlined"
        >
            Cancel
        </Button>
        <DeleteButton
          autoFocus
          // className={classes.margin}
          onClick={()=>handleClose(true)}
          variant="contained"
        >
            Delete
        </DeleteButton>
      </DialogActions>
    </Dialog>
  );
}