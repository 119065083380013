import React, { FormEvent, useEffect, useState } from 'react';

import clsx from 'clsx';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Button, 
  Checkbox, 
  FormControl, 
  FormControlLabel, 
  FormGroup, 
  FormHelperText, 
  FormLabel, 
  Grid, 
  Input, 
  InputAdornment, 
  TextField
} from '@material-ui/core';
import {
  useAppSettings,
  AppSettingsActionTypes
} from '../../context/AppSettingsContext';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: '25ch',
    },
  }),
);

export type AppSettingsContextType = {
  postureUrl:string,
  postureEnabled:boolean,
  postureFrameRate:number,
  frameGrabInterval:number //milliseconds
}
export const PostureSettings = ():JSX.Element => {

  const classes = useStyles();
  const {appSettingsState, appSettingsDispatch} = useAppSettings();
  // const defaultValues:AppSettingsContextType = {
  //   postureUrl:'something',
  //   postureEnabled:false,
  //   postureFrameRate:1000,
  //   frameGrabInterval:1000 
  // };

  const [formValues, setFormValues] = useState(appSettingsState);

  useEffect(() => {
    setFormValues(appSettingsState); //hmm race condition?
  }, [appSettingsState]);
  // no input checking for now (ie numbers erc)
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    if (type === 'checkbox'){
      setFormValues({
        ...formValues,
        [name]: checked,
      });
    }else{
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }

  };
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    appSettingsDispatch({type:AppSettingsActionTypes.SET_ALL, ...formValues});
    console.log(formValues);
  };
  return (
    <form onSubmit={handleSubmit}>
      <Grid
        alignItems="flex-start"
        container
        direction="column"
        justify="center"
        // sm={6} 
        // xs={12}
      >
        <FormLabel component="legend">Posture Settings</FormLabel>
        <FormControlLabel
          control={
            <Checkbox
              checked={formValues.postureEnabled}
              name="postureEnabled"
              onChange={handleInputChange}
            />
          }
          label="Use Posture"
        />
        <TextField
          label="Posture URL"
          name="postureUrl"
          onChange={handleInputChange}
          type="text"
          value={formValues.postureUrl}
        />
        <FormControl className={clsx(classes.margin, classes.withoutLabel, classes.textField)}>
          <Input
            aria-describedby="grab-interval-helper-text"
            endAdornment={<InputAdornment position="end">ms</InputAdornment>}
            id="standard-adornment-interval"
            inputProps={{
              'aria-label': 'interval',
            }}

            name="frameGrabInterval"
            onChange={handleInputChange}
            value={formValues.frameGrabInterval}
          />
          <FormHelperText id="standard-weight-helper-text">Grab Interval</FormHelperText>
        </FormControl>
        <TextField
          label="Frame rate"
          name="postureFrameRate"
          onChange={handleInputChange}
          type="text"
          value={formValues.postureFrameRate}
        />
        <Button
          color="primary"
          type="submit"
          variant="contained"
        >
          Update
        </Button>
      </Grid>
    </form>
  );
};