import React from 'react';

export default function Error() {
  return (
    <div>
      <p>
        There was an error!
      </p>
    </div>
  );
}