
import React from 'react';
import {
  withStyles,
  makeStyles,
  createStyles,
  Theme
} from '@material-ui/core/styles';

import { red } from '@material-ui/core/colors';
import {
  AppBar,
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Fab
} from '@material-ui/core';


import {
  ArrowBack, FiberManualRecordRounded
} from '@material-ui/icons';

import StopIcon from '@material-ui/icons/Stop';

import VideoRoomComponent from 'openvidu-react';
import Timer from './render/timer';

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      top:'auto',
      bottom:0,
      position:'fixed'
    },
    title: {
      marginRight: theme.spacing(2),
      alignItems:'right',
      flex: 1,
    },
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },

    fabButton: {
      position: 'absolute',
      zIndex: 1,
      top: -30,
      left: 0,
      right: 0,
      margin: '0 auto',
    },
  }
  )
);



export function VideoSession(props) {

  const {emmaUser,recordingStart, recordingStop, recordingStarted, recording,open, userName, token, joinSession, leaveSession, error, sessionName, message
  } = props;


  //constants
  const classes = useStyles();


  return (
    <div>
      <Dialog
        aria-labelledby="simple-dialog-title"
        open={open}
        fullScreen
        onClose = {leaveSession}
      >
        <div id="session">
          <VideoRoomComponent
            id="opv-session"
            sessionName={sessionName}
            user={userName}
            token={token}
            joinSession={joinSession}
            leaveSession={leaveSession}
            error={error}
            message = {message}
            isRecording = {recording}
            recordingStarted = {recordingStarted}
          />
        </div>
        <AppBar style={{ background: 'transparent', boxShadow: 'none'}} 
          className={classes.appBar}>
          <Toolbar>
            {
              recording==true ? (
                emmaUser?.authLevel > 0  &&
                <div className={classes.root}>
                  <Fab className={classes.fabButton} color = "primary" onClick={recordingStop}>
                    <StopIcon />
                  </Fab>
                </div>
              ) : (
                emmaUser?.authLevel > 0 &&
                <div className={classes.root}>
                  <Fab className={classes.fabButton} onClick={recordingStart} >
                    <FiberManualRecordRounded />
                  </Fab>
                </div>

              )
            }
            <div>   
              {recordingStarted==true &&       
                      <Timer />
              }
            </div>
          </Toolbar>
 
        </AppBar>
        
      </Dialog>
    </div>
  );
}