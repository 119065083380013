import React,  { useState }  from 'react';

import {useAppSettings} from '../../context/AppSettingsContext';

import { PostureSettings } from '../../components/Settings';
import { useUserState } from '../../context/UserContext';

import { Typography } from '@material-ui/core';
export function AppSettings():JSX.Element {
  const {appSettingsState, appSettingsDispatch} = useAppSettings();
  const {postureUrl, postureEnabled, postureFrameRate} = appSettingsState;
  const user = useUserState();

  if (user.authLevel < 10){
    return (
      <Typography variant="h5">
        You don&apos;t have permission to view this page.
      </Typography>
    );
  }
  return (
    <div>
      Appsettings go here. {postureUrl}
      <PostureSettings/>
    </div>
  );
}