import React, { ReactNode } from 'react';
export enum GeneralControlActionTypes {
  USERINFO_DIALOG = 'USERINFO_DIALOG',
  NEWASMT_DIALOG = 'NEWASMT_DIALOG',
  ASMTINFO_DIALOG = 'ASMTINFO_DIALOG'
}

export type GeneralControlContextType = {
  // This is supposed to control the visibility of the dialog
  // and feels soooo wrong
  isUserInfoDialogVisible:boolean
  isNewAssessmentDialogVisible:boolean
  isAsmtDialogVisible:boolean
}

interface GeneralControlAction extends GeneralControlContextType {
  type:GeneralControlActionTypes;
}

export const defaultGeneralControlInfo:GeneralControlContextType ={
  isUserInfoDialogVisible:false,
  isNewAssessmentDialogVisible:false,
  isAsmtDialogVisible:false
};

export function generalControlReducer(state:GeneralControlContextType, action:Partial<GeneralControlAction>)
:GeneralControlContextType{
  const {type, ...noType} = action;
  switch(type) {
    case GeneralControlActionTypes.USERINFO_DIALOG:
    { 
      if(noType.isUserInfoDialogVisible !== undefined){
        // hope and pray that the input is a complete report
        return {...state, isUserInfoDialogVisible:noType.isUserInfoDialogVisible};
      }
      return {...state};
    }
    case GeneralControlActionTypes.NEWASMT_DIALOG:
    { 
      if(noType.isNewAssessmentDialogVisible !== undefined){
        // hope and pray that the input is a complete report
        return {...state, isNewAssessmentDialogVisible:noType.isNewAssessmentDialogVisible};
      }
      return {...state};
    }
    case GeneralControlActionTypes.ASMTINFO_DIALOG:
    { 
      if(noType.isAsmtDialogVisible !== undefined){
        // hope and pray that the input is a complete report
        return {...state, isAsmtDialogVisible:noType.isAsmtDialogVisible};
      }
      return {...state};
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export const GeneralControlContext = React.createContext<{
  generalControlState:GeneralControlContextType,
  generalControlDispatch:React.Dispatch<any>}>({
    generalControlState:defaultGeneralControlInfo,
    generalControlDispatch:()=>null
  });

type GeneralContextProps = {
  children: ReactNode;    
}
export const GeneralControlProvider = ({ children }:GeneralContextProps):JSX.Element =>{

  const [state, dispatch] = React.useReducer(generalControlReducer, defaultGeneralControlInfo);
  return (
    <GeneralControlContext.Provider value={{generalControlState:state, generalControlDispatch:dispatch}}>
      {children}
    </GeneralControlContext.Provider>
  );
};