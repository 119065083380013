import React, { useState, useCallback, useEffect } from 'react';





import PropTypes from 'prop-types';


import {
  AppBar,
  Toolbar,
  Typography,
  Grid, 
  Card,
  CardContent,
  FormControl,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  Paper,
  Snackbar,
  Divider as MuiDivider
} from '@material-ui/core';


import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import { spacing } from '@material-ui/system';
import {  KeyboardDatePicker } from '@material-ui/pickers';
import {DropzoneArea, DropzoneDialog} from 'material-ui-dropzone';
import { useDropzone } from 'react-dropzone';
import {isAndroid, isIOS} from 'react-device-detect';

import styled, { ThemeConsumer } from 'styled-components';
import DropZone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

import {
  useEmmaVideosState,
  useEmmaVideosDispatch,
  VideoListActionTypes
} from '../../context/EmmaVideosContext';

import {useUserState} from '../../context/UserContext';
import { AssessmentListUIProps, AssessmentPayloadProps, SaveStatusTypes, UserProps } from '../../common/entityUtilities';

import AsmtCreationProgressComponent from './components/AsmtCreationProgressComponent';
import { AssessmentSchemaProps, SubjectSchemaProps } from '../../common/mongoSchemas';

import CameraPreviewDialog from '../CameraPreviewDialog/CameraPreviewDialog';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
// Borrowed from https://material-ui.com/components/autocomplete/
// Sep 2020

import useStyles from './styles';
import dropZoneStyle from './styles';

const Divider = styled(MuiDivider)(spacing);
// const filter = createFilterOptions();


type NewAssessmentDialogProps = {
  isOpen:boolean,
  dialogComplete:()=>void,
  parentInformation:UserProps|null,
  asmtToEdit?:AssessmentListUIProps
}

type NewAssessmentDialogValues = {
  email:string,
  dob: Date,
  asmtdate:Date, //default asmt date is CURRENT DATE
  notes:string,
  parentemail:string,
  parentId:string,
  gestAgeWeeks:number
};

const defaultState:NewAssessmentDialogValues = {
  email:'',
  dob: new Date('2020-08-20T00:00:00.000Z'),
  asmtdate:new Date(), //default asmt date is CURRENT DATE
  notes:'',
  parentemail:'',
  parentId:'',
  gestAgeWeeks:0
};


export default function NewAssessmentDialog(props:NewAssessmentDialogProps):JSX.Element {

  const classes = useStyles();
  const disableDropZoneArea = true;
  //material-ui-dropzone was causing errors/crashes when uploading large video files
  //disabling it as of Dec 15 2022
  //react-dropzone-uploader module works fine and has worked fine on Android
  //So, both iOS and Android will use that module instead of material-ui-dropzone

  const {selectedVideoIds} = useEmmaVideosState();
  const emmaVideosDispatch = useEmmaVideosDispatch();
  const userContext = useUserState();
  const {asmtToEdit, parentInformation, isOpen, dialogComplete} =props;

  const [videos, setVideos] = React.useState<File[]>([]);
  // This is probably duplicating something, but i don't want to spend the
  // time to unravel things for now dlp
  const [subject, setSubject] = useState<SubjectSchemaProps|undefined>(undefined);
  const [dialogValue, setDialogValue] = React.useState(defaultState);
  const [hasSubject, setHasSubject] = useState(false);
  const [forUpdate, setForUpdate] = useState(false);
  const [savingStatus, setSavingStatus] = useState(SaveStatusTypes.INITIALIZE);
  const [asmtToSavePayload, setAsmtToSavePayload] = useState<AssessmentPayloadProps>();
  const [isCameraDialogVisible, setCameraDialogVisible] = useState(false);
  
  const [files, setFiles] = useState([]);
  const [dropzoneText, setDropzoneText] = useState('Add a video or Tap here for options.');

  const [toasterOpen, setToasterOpen] = useState(false);
  const [snackbarDuration, setSnackbarDuration] = useState(5000);
  const [toasterMessage, setToasterMessage] = useState('Requesting new assessment');


  useEffect (()=> {
    if (isIOS){
      setDropzoneText('Tap here for options to add video. You can use your camera or use a previously recorded video.');
    }
    else if (isAndroid){
      setDropzoneText('First, record the video using your camera app. Then, come here to attach the video file from your gallery.');
    }

  }, [isAndroid, isIOS]);

  useEffect( () => {
    console.log('videos changed:');
    if (videos.length > 0){
      setDropzoneText('Video files added. Please remember to SAVE above.');
    }
    else {

      if (isIOS){
        setDropzoneText('Tap here for options to add video. You can use your camera or use a previously recorded video.');
      }
      else if (isAndroid){
        setDropzoneText('First, record the video using your camera app. Then, come here to attach the video file from your gallery.');
      }
    }
  },[videos && videos]);

  // Interesting, seems like this gets called when the containing component
  // gets opened
  useEffect(()=>{
    if(asmtToEdit){
      console.log('asmtToEdit data:', asmtToEdit);

      const updatedDialogValues:NewAssessmentDialogValues = {
        ...defaultState,
        parentemail: asmtToEdit.parent,
        notes: asmtToEdit.notes,
        asmtdate: asmtToEdit.date,
        dob: asmtToEdit.dob,
        parentId: asmtToEdit.parentId
      };
      const tempSubj = asmtToEdit.subject;
      if(tempSubj ){setSubject(tempSubj);}
      setHasSubject(true);
      setForUpdate(true);
      setDialogValue({...dialogValue, ...updatedDialogValues});
      return;
    }

    // the parentinfo comes for the parent selectin/creation dialog
    // they are of type UserProps and comes for userListContext
    if(!parentInformation) return;
    const updatedDialogValues:NewAssessmentDialogValues = {
      ...defaultState,
      parentemail: parentInformation.localEmail!,
      parentId: parentInformation.emmauserId!
    };
    const tempSubj = parentInformation.subject;
    if(tempSubj ){
      setHasSubject(true);
      updatedDialogValues.dob = tempSubj.dob;
      updatedDialogValues.asmtdate = new Date();//tempSubj.duedate;
      // it was believed the asmt notes came from subject, that is not the case.
      // updatedDialogValues.notes = tempSubj.notes;
      // boo timing
      setSubject(tempSubj);
    }else{
      setHasSubject(false);
    }    
    setDialogValue({...dialogValue, ...updatedDialogValues});

  },[isOpen, asmtToEdit]);

  // using the savingStatus state could be a way to 
  // get a cancel control in.
  const handleClose = () => {
    if(savingStatus==SaveStatusTypes.SAVING) return;
    console.log('Clearing data', defaultState);
    setHasSubject(false);
    setForUpdate(false);
    setSubject(undefined);
    setDialogValue(defaultState);
    setSavingStatus(SaveStatusTypes.INITIALIZE);
    dialogComplete();
  };

  const setVideoFromCapture = (video:any) => {
    console.log('set video from capture');
    const videoSuffix = 1;
    let current_datetime = new Date();
    video.lastModifiedDate = current_datetime;
    let formatted_date = current_datetime.getFullYear() + '-' + (current_datetime.getMonth() + 1) + '-' + current_datetime.getDate() + ' ' + current_datetime.getHours() + ':' + current_datetime.getMinutes() + ':' + current_datetime.getSeconds(); 
    video.name = 'recording-' + formatted_date + '-' + videoSuffix;
    setVideos([video]);

  };

  const onDrop = useCallback( acceptedFiles => {
    setVideos(acceptedFiles);
  },[]);


  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  useEffect(()=>{
    // console.log('Saving change', savingStatus);
    if(savingStatus === SaveStatusTypes.DONE){
      //To avoid oddities with users having assessments with videos
      //that don't belong to them clear out the selected video ids.
      emmaVideosDispatch({type:VideoListActionTypes.UNSELECT_VIDEOS});
      handleClose();
    }
  },[savingStatus]);

  // const handleDropzoneUpload = async () => {
  //   // save all the files
  //   console.log('Save all files', videos);
  // };



  const handleToasterClose = (event:any,reason:any) => {
    console.log('toaster');
    if (reason === 'clickaway') {

      setToasterOpen(false);
      return;
    }
    setToasterOpen(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (videos && videos.length > 0){
      setToasterMessage('This will take a few minutes...Please be patient and stay on this page.');
      setToasterOpen(true);
      setSnackbarDuration(10000);
    }
    let localAssessor = userContext?.firebaseUser?.email;
    console.log('handleSubmit for user:', userContext);
    if(!userContext?.firebaseUser?.email || !subject?.subjectId){
      // the user could have used phone auth, in which case firebaseUser won't have an email
      if (!userContext?.emmauserId){
        console.log('emmauser:', userContext?.emmauserId);
        return;
      }
      else{
        console.log('saving new asmt for:', userContext?.firebaseUser);
        localAssessor = userContext?.localEmail;
      }
      
    }
    // should be of type AssessmentPayloadProps
    const assessmentSavePayload:AssessmentPayloadProps = {
      date:dialogValue.asmtdate,
      assessor: localAssessor,
      assessmentName:'New Assessment',
      subjectId:subject?.subjectId,
      ownerId:dialogValue.parentId,
      notes:dialogValue.notes,
      // additionalProp1:{notes:dialogValue.notes},
      videosForUpload:videos,
      existingVideoIdsToUse:selectedVideoIds,
      subjectDob:dialogValue.dob,
      parentEmail:dialogValue.parentemail
    };
    console.log('asmt payload:', assessmentSavePayload);
    if(asmtToEdit){
      assessmentSavePayload.assessmentId = asmtToEdit.assessmentId;
    }
    // egads, this seems like a nasty race condition
    // needing to display the element before being able to do the upload
    // This will display the component, which allows the next call
    setSavingStatus(SaveStatusTypes.SAVING);
    // Set the save payload, and let go since the 
    // the setter is connected AsmtCreationProgressCompoenent 
    // that does the upload
    setAsmtToSavePayload(assessmentSavePayload);
  };

  const handleStartRecording = () => {
    setCameraDialogVisible(true);
  };
  
  const handleCloseRecording = () => {
    setCameraDialogVisible(false);
  };

  const handleDropZoneChangeStatus = ( meta:any, status:any) => {
    console.log('Dropzone changestatus');
    console.log('Status:',status);
    console.log('Meta:',meta);
    console.log('File:', meta.file);
    console.log('File name:', meta.file.name);
    if (status == 'removed'){
      setVideos([]);
    }
    if (status == 'done'){
     let selected = [meta.file];
     console.log('Setting selected video');
     setVideos(selected);
    }
  };

  return (     
    <Dialog
      aria-labelledby="form-dialog-title"
      onClose={handleClose} //closes on click away
      open={isOpen}
      className={classes.paper}
      fullScreen
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-dialog-title">Create/update assessment</DialogTitle>
        <AppBar className={classes.paper} >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              disabled={savingStatus===SaveStatusTypes.SAVING}
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" component="div">
              Assessment
            </Typography>
            {/* <Grid container justify='flex-end'>
             
              <Button 
                variant='contained'
                color = 'primary'  
                type='submit'   
                disabled={ 
                  !hasSubject || savingStatus===SaveStatusTypes.SAVING ||
                  (forUpdate && videos.length ===0)
                }
              >
                {forUpdate?'Update videos':'Save'} 
              </Button>
            </Grid> */}
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid
            container
            spacing={2}
          >

          
            {selectedVideoIds && selectedVideoIds.length > 0 && 
              <Grid
                item
                xs={12}
              >
                <DialogContentText>
                Assessment Videos for {subject?.name}
                  {forUpdate?(<> <b>({asmtToEdit?.observationCount} ) </b></>):
                    (selectedVideoIds.length > 0 ?
                      <> <b>(preselected {selectedVideoIds.length}) </b></>:  
                      <> (no videos preselected) </>
                    )}  
              
                </DialogContentText>
                <Divider my={2} />   
            
              </Grid>           
            }

            <Grid
              item
              xs={12} sm={12} md={12}
            >
              {savingStatus===SaveStatusTypes.INITIALIZE?(
                <React.Fragment>
                  <DialogContentText
                    color="secondary"
                    variant="h6"
                  >
                  </DialogContentText>
                  {/* <Card>
                    <CardContent>
                      <div {...getRootProps()}>
                      <input {...getInputProps()}/>
                      {isDragActive ? 
                        <Typography variant='h5'>Drop the file here...</Typography>:
                        <Typography variant ='h5' color='secondary'>Add video. Click for options. Remember to SAVE.</Typography>}
                      </div>
                    </CardContent>
                  </Card> */}
                  <Grid container>
                    {(isIOS && !disableDropZoneArea) && 
                      <Grid item md={12} sm={12} xs={12}>
                        <DropzoneArea
                        maxFileSize={1000000000}
                        onChange={(files:any) => {
                          console.log('Setting video file:',files);
                          console.log('hasSubject:', hasSubject);
                          console.log('SavingStatus:', savingStatus);
                          console.log('Num videos:',videos.length);
                          console.log('forUpdate:',forUpdate);


                          if(files && files.length > 0){
                            console.log('Video file non null');
                            setVideos(files);
                          }
                        }}
                        //onChange for DropzoneArea component
                        dropzoneText={dropzoneText}
                        acceptedFiles={['video/*']}
                        showAlerts={true}
                        onAlert={(message:string) => {
                          console.log('Message from dropzone:', message);
                        }}
                        showPreviews={true}
                        showPreviewsInDropzone={false}
                        useChipsForPreview
                        previewGridProps={{container: { spacing: 1, direction: 'row' }}}
                        onDrop={(files,event)=>{
                          console.log('Event:',event);
                        }}
                        // showPreviewsInDropzone={true}
                        
                        />
                      </Grid>
                    }
                    {(isAndroid || isIOS) && 
                      <Grid item md={12} sm={12} xs={12}>
                        <Paper>
                          <Typography variant='h5' color='primary'>
                            Instruction:
                          </Typography>
                          <Typography gutterBottom variant='body2'>
                            {dropzoneText}
                          </Typography>
                        </Paper>
                      </Grid>
                    }
                    {(isAndroid || isIOS) &&
                      <Grid item md={12} sm={12} xs={12}>
                        <DropZone
                          accept='video/*'
                          multiple={false}
                          maxFiles={1}
                          maxSizeBytes={1000000000}
                          onChangeStatus={handleDropZoneChangeStatus}
                        />
                      </Grid>
                    }
                    {/* what if it is a straight up browser? */}
                    {
                      !isAndroid && !isIOS &&
                      <Grid container>
                        <Grid item md={12} sm={12} xs={12}>
                          <Paper>
                            <Typography variant='h5' color='primary'>
                              Instruction:
                            </Typography>
                            <Typography gutterBottom variant='body2'>
                              {dropzoneText}
                            </Typography>
                          </Paper>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                        <DropZone
                          accept='video/*'
                          multiple={false}
                          maxFiles={1}
                          maxSizeBytes={1000000000}
                          onChangeStatus={handleDropZoneChangeStatus}
                        />
                      </Grid>
                    </Grid>

                    }
                    {/* {videos.length > 0 && 
                      <Grid item  md={6} sm={6} xs={6}>
                        <Card>
                          <CardContent>
                            <Typography variant='h6' color='primary'>
                              Video added. Remember to Save above!
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    } */}
                  </Grid>
                </React.Fragment>
              ):(
                <AsmtCreationProgressComponent
                  asmtSaveValues={asmtToSavePayload}
                  // closeParent={handleClose}
                  setSavingStatus={setSavingStatus}
                />
              )}             
            </Grid>
            {/* {(isAndroid) && 
            <Grid item md={12} sm={12} xs={12}>
              <Card>
                <CardContent>
                  <Typography variant='h6' color='primary'>
                    You are on an Android device. To record a video directly, click here
                  </Typography>
                  <Button variant='outlined' color='secondary'
                    onClick={handleStartRecording}>Start Recording</Button>
                </CardContent>
              </Card>
            </Grid>
            } */}
            {/* { isAndroid && 
              <Grid item md={12} sm={12} xs={12}>
                <FilePond 
                  captureMethod='camera'
                  acceptedFileTypes={['video/mp4']}
                />
              </Grid>
            } */}
            <Grid item justify='flex-end'>
             
             <Button 
               variant='contained'
               color = 'secondary'  
               type='submit'   
               disabled={ 
                 !hasSubject || savingStatus===SaveStatusTypes.SAVING ||
                 (forUpdate && videos.length ===0)
               }
             >
               {forUpdate?'Update videos':'Save'} 
             </Button>
           </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <FormControl
                fullWidth
                // mb={3}
              >
                <TextField 
                  id="notes"
                  color='primary'
                  label="Anything you want to add?"
                  multiline
                  onChange={(event) => setDialogValue({ ...dialogValue, notes: event.target.value })}
                  placeholder="Additional notes about the assessment."
                  rows={3}
                  rowsMax={2}
                  value={dialogValue.notes}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5">
              Client details
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <KeyboardDatePicker
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                disabled
                format="MM/dd/yyyy"
                label="Child Date of birth"
                margin="normal"
                onChange={(event) => setDialogValue({ ...dialogValue, dob: event as Date})}
                value={dialogValue.dob}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <KeyboardDatePicker
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                color="primary"
                disabled={
                  forUpdate|| savingStatus===SaveStatusTypes.SAVING
                }
                format="MM/dd/yyyy"
                label="Date of Assessment"
                margin="normal"
                onChange={(event) => setDialogValue({ ...dialogValue, asmtdate: event as Date})}
                value={dialogValue.asmtdate}
              />
            </Grid>

            <Grid
              item
              xs={12}
            >
              <TextField
                autoFocus
                disabled
                fullWidth
                id="parentemail"
                label="Parent Email"
                margin="dense"
                onChange={(event) => setDialogValue({ ...dialogValue, parentemail: event.target.value })}
                type="email"
                value={dialogValue.parentemail}
              />
            </Grid>
          </Grid>
        </DialogContent>
        {/* <DialogActions>
          <Button
            color="primary"
            disabled={savingStatus===SaveStatusTypes.SAVING}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={
              !hasSubject || savingStatus===SaveStatusTypes.SAVING ||
              (forUpdate && videos.length ===0)
            }
            type="submit"
          >
            {forUpdate?'Update videos':'Save'}  
          </Button>
        </DialogActions> */}
      </form>

    <CameraPreviewDialog 
        asmtToEdit={asmtToEdit}
        dialogComplete = {handleCloseRecording}
        isOpen={isCameraDialogVisible}
        setVideo={setVideoFromCapture}
        parentInformation={parentInformation}/>
    
    <Snackbar
        autoHideDuration={snackbarDuration}
        message = {toasterMessage} 
        onClose={handleToasterClose}
        open={toasterOpen}
      />
    </Dialog>
  );
}

// NewAssessmentDialog.propTypes = {

//   dialogComplete:PropTypes.func,
//   // isOpen: PropTypes.bool,
//   parentInformation: PropTypes.object,
// };