import React, { ReactNode, useEffect } from 'react';
import { InstrumentSchemaProps } from '../common/mongoSchemas';
import { getInstruments } from '../common/apiGetUtilities';
import {useUserState} from './UserContext';

export enum AssessmentInfoActionTypes {
  ACTIVE_USER = 'ACTIVE_USER',
  ACTIVE_ASMTID = 'ACTIVE_ASMTID',
  ACTIVE_INSTRUMENT = 'ACTIVE_INSTRUMENT',
  ACTIVE_SCORINGTYPE = 'ACTIVE_SCORINGTYPE',
  UPDATE_INSTRUMENTS = 'UPDATE_INSTRUMENTS',
  //This is a hack because it artificially triggers a state change/rerender without actually changing anything
  HACK_UPDATESCORE = 'HACK_UPDATESCORE' 
}
export type AssessmentInfoContextType = {
  activeAsmtId:string,
  activeUserId:string,
  activeInstrumentName:string,
  // activeScoringTypeName is spefic to a value in an instrument library's scoringMethodNames.
  // if a library does not have a scoring methodsNames then 'Default' needs to be used.
  activeScoringTypeName:string,
  // TODO: Move instrumentLibraries to a more global location since
  // an assessment does need information about all the available libraries.
  instrumentLibraries:InstrumentSchemaProps[],
  hackScoreUpdate:boolean
}

interface AssessmentInfoAction extends AssessmentInfoContextType {
  type:AssessmentInfoActionTypes;
}

export const defaultAssessmentInfo:AssessmentInfoContextType ={
  activeAsmtId:'',
  activeUserId:'',
  activeInstrumentName:'',
  activeScoringTypeName:'Default',
  instrumentLibraries:[],
  hackScoreUpdate:false
};

export function assessmentInfoReducer(state:AssessmentInfoContextType, action:Partial<AssessmentInfoAction>)
:AssessmentInfoContextType{
  //TODO: Put in a check to make sure the assessment is complete and can be used to generate a complete report
  const {type, ...noType} = action;
  switch(type) {
    case AssessmentInfoActionTypes.ACTIVE_ASMTID:
    { 
      if(noType.activeAsmtId !== undefined){
        return {...state, activeAsmtId:noType.activeAsmtId};
      }
      return {...state};
    }
    case AssessmentInfoActionTypes.ACTIVE_USER:
    { 
      if(noType.activeUserId !== undefined){
        return {...state, activeUserId:noType.activeUserId};
      }
      return {...state};
    }
    case AssessmentInfoActionTypes.ACTIVE_SCORINGTYPE:
    { 
      if(noType.activeScoringTypeName){
        return {...state, activeScoringTypeName:noType.activeScoringTypeName};
      }
      return {...state};
    }
    case AssessmentInfoActionTypes.ACTIVE_INSTRUMENT:
    {
 
      if(noType.activeInstrumentName !== undefined && noType.activeScoringTypeName){
        return {...state, activeInstrumentName:noType.activeInstrumentName,
          activeScoringTypeName:noType.activeScoringTypeName};
      }
      return {...state};
    }
    case AssessmentInfoActionTypes.UPDATE_INSTRUMENTS:
    {
      if(noType.instrumentLibraries !== undefined){
        let activeName = '';
        let scoreName = 'Default';
        if(noType.instrumentLibraries !== undefined 
          && noType.instrumentLibraries.length >0){
          
          activeName = noType.instrumentLibraries[0].name;
          const scoreNames = noType.instrumentLibraries[0].scoringMethodNames as string[];

          if(scoreNames && (scoreNames).length > 0){
            if(scoreNames.length >1){
              // TODO: Find a full proof way to select the enhanced scoring method if the AIMS library gets used.
              // For now we know that AIMS is the only library with multiple scoring methods and enhanced is the 2nd
              scoreName = scoreNames[1] as string;
            }else{
              scoreName = scoreNames[0] as string;
            }
          }
        }
        return {...state, instrumentLibraries:noType.instrumentLibraries, activeInstrumentName:activeName,
          activeScoringTypeName:scoreName};
      }
      return {...state};
    }
    case AssessmentInfoActionTypes.HACK_UPDATESCORE:
    { 
      return {...state, hackScoreUpdate:!state.hackScoreUpdate };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
export const indexOfLibraryName = (assessmentInfoState:AssessmentInfoContextType):number =>{

  const {instrumentLibraries, activeInstrumentName} = assessmentInfoState;
  if(!instrumentLibraries) return 0;
  const matchingIndex = instrumentLibraries.findIndex(lib=>lib.name === activeInstrumentName);
  
  return matchingIndex;
};

export const AssessmentInfoContext = React.createContext<{
  assessmentInfoState:AssessmentInfoContextType,
  assessmentInfoDispatch:React.Dispatch<any>}>({
    assessmentInfoState:defaultAssessmentInfo,
    assessmentInfoDispatch:()=>null
  });

type AssessmentInfoProps = {
  children: ReactNode;    
}

export const AssessmentInfoProvider = ({ children }:AssessmentInfoProps):JSX.Element =>{
  const user = useUserState();
  const [state, dispatch] = React.useReducer(assessmentInfoReducer, defaultAssessmentInfo);

  useEffect(()=>{
    const fillAssessmentContext = async () => {
      try{
        const instruments = await getInstruments(user);
        dispatch({type:AssessmentInfoActionTypes.UPDATE_INSTRUMENTS, instrumentLibraries:instruments});
      }catch(e){
        console.log('there was some error');
      }      
    };
    fillAssessmentContext();
  }, [user]);
  return (
    <AssessmentInfoContext.Provider value={{assessmentInfoState:state, assessmentInfoDispatch:dispatch}}>
      {children}
    </AssessmentInfoContext.Provider>
  );
};