/* eslint-disable react/jsx-key */
import React, { useEffect, useState} from 'react';

import {
  Badge,
  Button,
  Grid,
  Divider,
  IconButton as MuiIconButton,
  ListItemText,
  List,
  ListItemIcon

} from '@material-ui/core';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import {
  Bell,
  AlertCircle
} from 'react-feather';

import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { useUserState } from '../../../context/UserContext';
import { getNotifications } from '../../../common/apiGetUtilities';
import { deleteNotification } from '../../../common/apiDelUtilities';
import { Alert } from '@material-ui/lab';

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: orange;
    color: white;
  }
`;
// background: ${props => props.theme.header.indicator.background};
// color: ${props => props.theme.palette.common.white};
const NotificationButton = (props) =>{
  const user = useUserState();
  const [emmaUser] = useState(user);
  const [notifications, setNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  // state = {
  //   notifications: [],
  //   anchorEl: null,
  // };

  // constructor(props) {
  //   super(props);
  //   this.getNotifications();
  // }
  const updateNotifications = async () => {
    console.log('Getnotifications for user');
    const notifications = await getNotifications(user);
    setNotifications(notifications);
  };
  useEffect(() =>{
    // This causes unmount problesm aka a leak, should be fixed in later iterations.
    updateNotifications();
  },[]);

  // const getNotifications = () => {

  // let authUser = AuthStore.getUser();
  // //console.log("Auth user:", authUser);

  // let token = AuthStore.getBearerToken();
  // let bearertoken = 'Bearer '  + token;

  // if (authUser == null) {
  //     console.log("No authenticated user... no getNotifications for you");
  //     this.setState({redirect:'/auth/sign-in'});
  //     return;
  // }

  // axios.defaults.headers.common = {
  //   'Authorization': bearertoken,
  //   'uid': authUser.uid
  // };

  // let apiendpoint = Utils.getScoreitApiEndpoint() + "/notifications";
  // console.log("Calling ", apiendpoint);
  // let self = this;
  // axios.get(apiendpoint).then(function(response){
  //   let notifications = response.data;
  //   self.setState({'notifications': notifications});
  // }).catch(function(error){
  //   console.log("API ERROR:",error);
  //   console.log("Error response:", error.response);
  //   if (error.response){
  //     switch(error.response.status){
  //       case 401:
  //         console.log("401 ERROR on notifications");
  //         self.setState({redirect:"/pages/blank"});
  //       break;
  //     }
  //   }
  // });
  // }

  const handleClick = event => {
    updateNotifications();
    console.log('Notifications click:', notifications);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {Promise.all( 
    //delete all the notifications for this emmauser
    notifications.map ( async (notification) => {
      console.log('Deleting Notification:', notification);
      try {
        await deleteNotification(user.firebaseUser, notification.notificationId);
      } catch(err) { console.log('Err in deleting notification:', err);}
      finally {
        console.log('Updating notifications');
        updateNotifications();
      }
    })
  );
  };


  const notificationUI = notifications.map((notification) => 
    <Grid container align='center'>
    <Grid item xs={10}>
    <ListItemText key={notification.notificationId}
      primary={notification.text}
      >
    </ListItemText>
    </Grid>
    <Grid item xs={10}>
    <Divider variant="inset" component="li" />
    </Grid>
    </Grid>);
  

  // const notificationUI = (notifications) => {
  //   if (notifications.length > 0){
  //     return(<Typography>No new alerts</Typography>);
  //   }
  //   else{
  //     notifications.map((notification) => 
  //       <li key={notification.notificationId} >{notification.text}</li>);
  //   } 
  // };
  
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  // dlp doesnt not understant the error
  return [

    <IconButton
      color="inherit"
      //Stupid key id warnings....putting the key in makes weird things happen.
      // key={Math.floor(Math.random() * 100)}
      onClick={handleClick}
    >
      <Indicator badgeContent={notifications.length}>
        <Bell />
      </Indicator>
    </IconButton>,

    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      id={id}
      // key={Math.floor(Math.random() * 100)}
      onClose={handleClose}
      open={open}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {notifications.length == 0 ? (<Typography variant='h6' color='secondary'>No alerts</Typography>): 
        (
          <div>
            <Typography variant='h5'color='secondary'>Alerts</Typography>
            <Divider></Divider>
            <Typography variant="body1">
              <List alignItems='center'>
                {notificationUI}
              </List>
            </Typography>
            <Button variant='contained' color='primary' startIcon={<DeleteOutline/>} onClick={handleDelete}>Clear</Button>
          </div>
        )}
    </Popover>            
  ];
};

export default NotificationButton;
