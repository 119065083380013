/* eslint-disable @typescript-eslint/no-use-before-define */
import React,  {useEffect, useState, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Autocomplete from '@material-ui/lab/Autocomplete';

import { useUserState } from '../../../context/UserContext';
import { VideoTagTableContext, VideoTagTableTypes } from '../../../context/VideoTagTableContext';
import { AssessmentInfoActionTypes, 
  AssessmentInfoContext,
  indexOfLibraryName } from '../../../context/AssessmentInfoContext';

import PropTypes from 'prop-types';
import { useUserListState } from '../../../context/UserListContext';

const useStyles = makeStyles((theme) => ({
  root: {
    // background: "yellow",
    flexGrow: 1,
    padding: theme.spacing(0)
  },
  buttonContainer: {
    padding: theme.spacing(1),
    alignItems:'center' ,
    display:'flex',
    // flexDirect:"column"
  },  
  button: {
    padding: theme.spacing(1),
    // color: theme.palette.primary,
  },
  selectUserContainer:{
    margin: theme.spacing(1),
    flexGrow: 1,
    flexBasis: '20%',
    maxWidth: '100%',
    display: 'flex'
  },
  selectItemContainer: {
    margin: theme.spacing(1),
    flexGrow: 1,
    flexBasis: '40%',
    maxWidth: '100%',
    display: 'flex'
  },
  userSelect: {
    // margin: theme.spacing(1),
    flexGrow: 1,
    minWidth: '130px'
  },
  itemSelect: {
    flexGrow: 1,
    minWidth: '140px'
  },
  text:{
    display:'flex',
    paddingLeft: 2,
    paddingRight: 8,
    whiteSpace:'nowrap',
    height:'100%',
    alignItems:'center' ,
    // flexDirect:"column"
  }
}));


export function SimplifiedVideoTagTableHead(props) {
  const { handleAddLabel, 
    // instrumentDetails 
  } = props;
  const user = useUserState();

  const {assessmentInfoState, assessmentInfoDispatch} = useContext(AssessmentInfoContext);
  const {activeInstrumentName, activeScoringTypeName, activeUserId, instrumentLibraries} = assessmentInfoState;

  const {tagTableState, tagTableDispatch } = React.useContext(VideoTagTableContext);
  const {annotationSetMap, currentAnnotSetOwner} = tagTableState;

  const {allUsers} = useUserListState();

  const classes = useStyles();
  const [canAddLabel, setCanAddLabel] = useState(true);
  const [filterValue, setFilterValue] = useState('');
  // a map? maybe
  const [filterOptions, setFilterOptions] = useState([]);
  const [taggedByValue, setTaggedByValue] = useState(''); //email address of selected tagger
  const [taggedByOptions, setTaggedByOptions] = useState([]);//email address, for now

  useEffect(()=>{
    // setTaggedByOptions([user.localEmail]);
    // setTaggedByValue(user.localEmail);
    const instrumentDetails = instrumentLibraries[indexOfLibraryName(assessmentInfoState)];
    // This catches a race condition, and man are there lots of them.
    if(!instrumentDetails) return;
    const filterOptions = instrumentDetails.instrumentItems.map(x => x.name);
    //Attach filter options for flagged
    filterOptions.unshift('Flagged');
    // attach the subscales, kind of the long way of doing it
    const subscales = new Set(instrumentDetails.subscales);
    // dang the ... operator is pretty darn cool saves me from doing Array.from()
    filterOptions.unshift(...subscales);
    setFilterOptions(filterOptions);
    
  },[activeInstrumentName, instrumentLibraries]);

  useEffect(()=>{
    setCanAddLabel(isAddLabelButtonDisabled());
    tagTableDispatch({type:VideoTagTableTypes.FILTERNAME, annotationFilter:filterValue});
  },[filterValue]);

  useEffect(()=>{
    setTaggedByOptions(Array.from(annotationSetMap.keys()));
  },[annotationSetMap] );

  useEffect(() =>{
    setTaggedByValue(currentAnnotSetOwner);
  }, [currentAnnotSetOwner]);

  useEffect(()=>{
    setCanAddLabel(isAddLabelButtonDisabled());
    tagTableDispatch({type:VideoTagTableTypes.ANNOTATION_OWNER, currentAnnotSetOwner:taggedByValue});
    //ugh so hacky, but we are setting the assessment annotationSetOwnerId from this call.
    const userWithEmail = allUsers.filter(user=>user.localEmail === taggedByValue);
    if(userWithEmail && userWithEmail.length >0 && userWithEmail[0].emmauserId){
      assessmentInfoDispatch({type:AssessmentInfoActionTypes.ACTIVE_USER, activeUserId:userWithEmail[0].emmauserId});
    }

  },[taggedByValue]);

  const isAddLabelButtonDisabled = ()=>{
    // if(annotationSetMap.length === 0) return false;
    if(!taggedByValue) return true;
    console.log('f', taggedByValue);
    if(filterValue !== '' && filterValue !== null) return true;
    return false;
  };
    
  const doUserAndLabelOwnerMatch = () => {
    return user.localEmail === currentAnnotSetOwner;
  };

  // the new and improved? way of transfering another users tags to your own
  // and explicit request.
  const localCopyTagUserToCurrentUser = () =>{
    if(tagTableState.currentAnnotSetOwner === user.localEmail){
      console.log('The users match nothing to do, also why are we here?');
      return;
    }
    // match the current instrument index
    const currentInstrumentIndex = indexOfLibraryName(assessmentInfoState);
    const availableSetsForUser = tagTableState.annotationSetMap.get(tagTableState.currentAnnotSetOwner);
    const setToCopy = availableSetsForUser.find((set)=>set.instrumentIndex === currentInstrumentIndex);

    if(!setToCopy){
      console.log('Could not find an index', currentInstrumentIndex, 'to copy for', tagTableState.currentAnnotSetOwner);
      return;
    }
    // setToPost should be of type AnnootationSetSchemaProp
    const setToPost = {};
    setToPost.emmaUserId = user.emmauserId; //save as our own, even if it was somebody else's
    setToPost.videoId = setToCopy.videoId;
    setToPost.instrumentIndex = setToCopy.instrumentIndex;
    setToPost.observationId = setToCopy.observationId;
    // this should be a deep copy of the annotations
    // kind of hacky, but good enough for copying
    // AnnotationSchemaProps
    setToPost.annotations = setToCopy.annotations.map((item)=>{  return {...item};});
    //now update via reference replacing what ever the user had in that instrement id slot
    const availableSetsForCurrent = tagTableState.annotationSetMap.get(user.localEmail);
    if(Array.isArray(availableSetsForCurrent)){
      const indexToReplace = availableSetsForCurrent.findIndex((set)=>set.instrumentIndex === currentInstrumentIndex);
      if(indexToReplace !== -1){
        availableSetsForCurrent[indexToReplace] = setToPost;
      }else{
        availableSetsForCurrent.push(setToPost);
      }
    }else{
      tagTableState.annotationSetMap.set(user.localEmail, [setToPost]);
    }

    tagTableDispatch({
      type:VideoTagTableTypes.COPY,
      currentAnnotSetOwner:user.localEmail,
      // annotationSetMap://updating might not be needed because of inplace modifications
    });
  };
  // uggg for now just duplicate the call that is videotagtable
  // eventually this should all be controlled via dispatcher
  const handleTaggedByChange = (event, newValue) => {
    //If there is a change in the tagged by, clear the filters? maybe?
    setTaggedByValue(newValue);
    setFilterValue('');
  };
  return (    
    <Grid
      className={classes.root}
      container
    >
      <div className={classes.buttonContainer}>

        {doUserAndLabelOwnerMatch ()? (
          <Button
            className={classes.button}
            color="primary"
            disabled={canAddLabel}
            onClick={handleAddLabel}
            variant="outlined"
          >
               Add Label
          </Button>
        ) : (
          <Button
            className={classes.button}
            color="primary"
            disabled={canAddLabel}
            onClick={localCopyTagUserToCurrentUser}
            variant="outlined"
          >
              Copy labels
          </Button>
        )}
      </div>
      <div className={classes.selectUserContainer}>
        <Autocomplete
          className={classes.userSelect}
          onChange={handleTaggedByChange}
          options={taggedByOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tagged by"
              variant="outlined"
            />
          )}
          value={taggedByValue}
        />
      </div>
      <div className={classes.selectItemContainer}>
        <Autocomplete
          className={classes.itemSelect}
          getOptionLabel={(option)=>{
            if(!option) return '';
            return option;
          }}
          onChange={(event, newValue)=> {
            setFilterValue(newValue);
          }}
          options={filterOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Filter for tags (disables add)"
              variant="outlined"
            />
          )}
          value={filterValue}
        />
      </div>
      <Grid
        item
        xs={12}
        // Instrument and scoring: {activeInstrumentName} ({activeScoringTypeName}), For {activeUserId}
      >
        Active Instrument: {activeInstrumentName} 
      </Grid>
    </Grid>
    
  );
}

SimplifiedVideoTagTableHead.propTypes = {
  handleAddLabel: PropTypes.func.isRequired,
  // instrumentDetails: PropTypes.object.isRequired,
};
//https://react.school/material-ui/grid
// https://codesandbox.io/s/material-demo-forked-xqure?file=/demo.js:0-2209
