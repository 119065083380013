import React, { useState } from 'react';
import classNames from 'classnames';
// styles
import useStyles from './styles';
import {
  AppBar,
  Fade,
  IconButton,
  Toolbar,
  Menu,
  MenuItem,
  Dialog,
  Tooltip,
  Icon
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
  PersonOutlineOutlined as PersonIcon,
  DuoRounded,
  DuoOutlined,
  ExitToApp as LogoutIcon,
  DeleteSweepOutlined as DeleteIcon,
  VideoCall
} from '@material-ui/icons';

import {User, LogOut} from 'react-feather';

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../context/LayoutContext';

import { ConfirmationDialog } from '../../dialogs/AssessmentRecordDialog/ConfirmationDialog';
import { VideoSession } from '../../dialogs/AssessmentRecordDialog/VideoSession';
// components
import { 
  Typography, 
  Button 
} from '../Wrappers/Wrappers';
import NotificationButton from './components/NotificationButton';
import { useUserDispatch, useUserState, signOut, refreshToken, deleteAccount } from '../../context/UserContext';
import { useUserListState } from '../../context/UserListContext';
import {openViduLogin, getOVToken, leaveSession, closeSession, recordingStart, recordingStop} from '../../common/ovUtilities';
import { isPropertySignature, setTokenSourceMapRange } from 'typescript';

import { Plugins } from '@capacitor/core';
import {Toast} from '@capacitor/toast';
import { isAndroid, isIOS } from 'react-device-detect';


export default function Header(props) {
  const classes = useStyles();
  // Global
  const layoutDispatch = useLayoutDispatch();
  const layoutState = useLayoutState();

  const userDispatch = useUserDispatch();
  const userState = useUserState();
  const [parentToCall, setParentToCall] = useState(null);
  const [emmaUser] = useState(useUserState());

  const { allUsers } = useUserListState(); 
  const [anchorEl, setAnchorEl] = React.useState(null);
  const addMenuOpen = Boolean(anchorEl);

  const [callConfirm, setCallConfirm] = useState(false);
  const [callConfirmationMessage, setCallConfirmationMessage] = useState('');
  const [callConfirmationTitle, setCallConfirmationTitle] = useState ('');
  // Local

  const [profileMenu, setProfileMenu] = useState(null);

  const [sessionName, setSessionName] = useState(null);
  const [showSession, setShowSession] = useState(false);
  const [token, setToken] = useState(null);
  const [userName, setUserName] = useState(null);
  const [recordingStatus, setRecordingStatus] = useState(false);
  const [recordingStarted, setRecordingStarted] = useState(false);

  const [recordingId, setRecordingId] = useState(null);

  const [message, setMessage] = useState(null);


  const handleOVCallClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleParentEmailClick = (user) => {
    console.log('parent to call:', user);
    setParentToCall(user);
    handleCallConfirmation(user);
  };

  const handleConnectToVideo = () => {
    handleSetupCall();
  };


  const handleCallConfirmation =  (parent) => {
    console.log('Raising call confirmation dialog:');
    if (parent) {
      setCallConfirmationMessage('Setup call for ' + parent.lastname + ', ' + parent.firstname + ' (' + parent.localEmail + ')?');
      setCallConfirmationTitle('Setup call' );
    }
    setCallConfirm(true);
  };

  const onCallCancel = () =>{
    console.log('yeah, no on the record');
    setCallConfirm(false);
  };

  const onCallConfirm = () => {
    handleSetupCall();
    setCallConfirm(false);
  };

  const handleSetupCall = async () => {
    const authLevel = emmaUser.authLevel;

    console.log('emmauser before refresh:', emmaUser);
    await refreshToken(emmaUser);
    console.log('after refresh:', emmaUser);

    setUserName(emmaUser.localEmail);
    if (authLevel > 0){
      console.log('Handle setup call to parent:', parentToCall);
      setSessionName(parentToCall?.uid);
    }
    else if (authLevel == 0){ // parent user with authLevel 0
      console.log('Handle call for:', emmaUser);
      setSessionName(emmaUser.uid);
  
    }
    // call openvidu browser API
    if (authLevel > 0){
      console.log('OV login for non parent');
      const data = await openViduLogin(emmaUser, parentToCall?.uid);
      console.log('openvidulogin response:', data);
      if(data && data.status == 200){ 
        console.log('user logged into OV');
        joinSession(parentToCall?.uid);
      }
    } 
    else if (authLevel == 0){
      console.log('OV login for parent');
      const data = await openViduLogin(emmaUser, emmaUser.uid);
      console.log('openvidulogin response:', data);
      if(data && data.status == 200){ 
        console.log('user logged into OV');
        joinSession(emmaUser.uid);
      }
    }
  };

  const joinSession = async (name) => {
    console.log('Join session');
    const tok = await getOVToken(name);
    console.log('Token from getOvToken:', tok);
    if (tok === undefined){
      console.log('Token undefined...closing session');
      await closeSession(name);
    }
    setToken(tok);
    setShowSession(true);
    console.log('Show video session');
  
  };


  const handlerJoinSessionEvent = () => {
    console.log('Join session');
  };

  const handlerLeaveSessionEvent = () => {
    console.log('Leave session : ', sessionName);
    setShowSession(false);
    leaveSession(sessionName, token);
    setSessionName(null);
  };

  const handlerCloseSessionEvent = () => {
    console.log('Close session:', sessionName);
    setShowSession(false);
    leaveSession(sessionName, token);
    setSessionName(null);
  };

  const handlerErrorEvent = () =>{
    console.log('Leave session');
  };

  const handlerStartRecordingEvent = async () => {
    console.log('start recording for session:', sessionName);
    setRecordingStatus(true);
    var recordingResponse = await recordingStart(sessionName);
    setRecordingStarted(true); // this is when the OV server acknowledges recording start
    console.log('recording started with response:', recordingResponse);
    if (recordingResponse != null){
      setRecordingId(recordingResponse.id);
    }
    setMessage('Recording ' + recordingResponse?.id);
  };

  const handlerStopRecordingEvent = async() => {
    console.log('stop recording');
    setRecordingStatus(false);
    var response = await recordingStop(recordingId);
    setRecordingStarted(false);
    console.log('Recording stop response:', response);
    setMessage('Stopped recording');
  };


  const showToast = async (msg) => {
    await Toast.show({
      text: msg
    });
  };


  return (
    <AppBar
      className={classes.appBar}
      position="fixed"
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
            
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>          
        <img
          alt="Logo"
          className={classes.logotype}
          src="/images/logos/logo-earlymarkers.png"
        />
        <div className={classes.grow} />
        <NotificationButton/>
        

        {/* Since this video conf feature is not being used presently, and is causing issues with Appstore review
        we are commenting it out for now. OCT 3 2022 */}

        
        {/* UNCOMMENT WHEN VIDEO CONF IS ENABLED */}
        
        {/* <IconButton
          aria-controls="fade-menu"
          aria-haspopup="true"
          color="inherit"
          onClick={handleOVCallClick}
        >
          <VideoCall/>
        </IconButton>
        <Menu
          TransitionComponent={Fade}
          anchorEl={anchorEl}
          id="fade-menu"
          keepMounted
          onClose={handleMenuClose}
          open={addMenuOpen}
        >
          {emmaUser.authLevel === 0 ? (
           
            <MenuItem disabled onClick = { ()=> {
              setAnchorEl(null);
              handleConnectToVideo();
            }}>
              Start Video
            </MenuItem>
          ) : (
            allUsers.map( user => {
              return (
                <MenuItem onClick={()=>{
                  setAnchorEl(null);
                  handleParentEmailClick(user);
                }}>
                  {user.localEmail}
                </MenuItem>
              );
            })
          )}
          
        </Menu> */}

        {/* END COMMENT FOR VIDEO CONF feature */}


        <IconButton
          aria-controls="profile-menu"
          aria-haspopup="true"
          className={classes.headerMenuButton}
          color="inherit"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <User classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          anchorEl={profileMenu}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
          id="profile-menu"
          onClose={() => setProfileMenu(null)}
          open={Boolean(profileMenu)}
        >
          <div className={classes.profileMenuUser}>
            <Typography
              align="center"
              variant="h5"
              weight="medium"
            >
              {userState.localDisplayName}
            </Typography>
          </div>
          <MenuItem>
            <IconButton
                className={classes.profileMenuLink}
                color="primary"
                onClick={() => signOut(userDispatch, props.history)}
            >
                <LogoutIcon/>
                <Typography variant='h6' weight="medium">
                Sign Out
                </Typography>     
            </IconButton>
          </MenuItem>
          <MenuItem>
            {/* <DeleteIcon className={classes.profileMenuIcon} /> Delete Account */}

            <IconButton
                className={classes.profileMenuLink}
                color="primary"
                onClick={async () => {
                  const returnValue = await deleteAccount(userDispatch, props.history, userState);
                  if(returnValue){
                    console.log('Signing out the deleted account');
                    signOut(userDispatch, props.history);
                  }
                }}
            >
                <DeleteIcon/>
                <Typography variant='h6' weight="medium">
                Delete Account
                </Typography>     
            </IconButton>
          </MenuItem>
          

        </Menu>
      
      </Toolbar>  
      
      <ConfirmationDialog
        cancelText = "Cancel"
        confirmText = "Proceed"
        message = {callConfirmationMessage}
        onCancel = {onCallCancel}
        onConfirm = {onCallConfirm}
        open={callConfirm}
        title = {callConfirmationTitle}
      /> 

      <VideoSession
        open = {showSession}
        sessionName={sessionName}
        userName={userName}
        token={token}
        joinSession={handlerJoinSessionEvent}
        leaveSession={handlerLeaveSessionEvent}
        closeSession={handlerCloseSessionEvent}
        recordingStart ={handlerStartRecordingEvent}
        recordingStop ={handlerStopRecordingEvent}
        recording={recordingStatus}
        recordingStarted = {recordingStarted}
        error={handlerErrorEvent} 
        message = {message}
        emmaUser={emmaUser}
      />

    </AppBar>
  );
}