//This super inefficient way of keeping global track of emma videos that are selected
// Seems like all the results of a search are held in memory and dealt with accordingly
import React, { useEffect } from 'react';
import { useUserState } from '../context/UserContext';
import {VideoSchemaProps} from '../common/mongoSchemas';

//Used for typing the provider, but there is a way of putting the type in without this
type Props = {
  children:React.ReactNode;
};
export enum VideoListActionTypes {

  PREACTION = 'PREACTION',
  LOAD = 'LOAD',
  RESET = 'RESET',
  REFRESH = 'REFRESH',
  ERROR = 'ERROR',
  SELECT_VIDEO = 'SELECT_VIDEO',
  SELECT_VIDEOS = 'SELECT_VIDEOS',
  UNSELECT_VIDEO = 'UNSELECT_VIDEO',
  UNSELECT_VIDEOS = 'UNSELECT_VIDEOS'
}

//Matches the case of the values in the db, but with a videoId
interface LocalEmmaVideoProps extends VideoSchemaProps  {
  videoId:string;
}

interface EmmaVideosProps  {
  startDate?:Date,
  endDate?:Date
  allVideos:LocalEmmaVideoProps[];
  selectedVideoIds:string[];
  statusMessage?:string
}
interface EmmaVideosAction extends EmmaVideosProps {
  type:VideoListActionTypes;
}
const emptyVideoProps:EmmaVideosProps = {
  allVideos:[],
  selectedVideoIds:[]
};

// function rawVideoToEmmaVideo(rawVideo:any){
//   const convertedVideo:VideoSchemaProps = {...rawVideo, id:rawVideo._id, videoId:rawVideo._id};

//   return convertedVideo;
// }
function initEmmaVideosReducer(videosState:EmmaVideosProps){
  // // Needs to be put into a sleep to emulate an api call
  const initialVideosState = videosState;
  // for(const rawVideo of videoData){
  //  initialVideosState.allVideos.push(rawVideoToEmmaVideo(rawVideo));
  // }
  return initialVideosState;
  // const emptyVideos:EmmaVideo[] = [];
  // return emptyVideos;
}

// THis seems pretty bad...dlp
function emmaVideosReducer(state:EmmaVideosProps, action:EmmaVideosAction){
  // There are a lot of options on this, and im not sure how/where a proper
  // wait steps gets used in this.
  switch(action.type) {
    case VideoListActionTypes.PREACTION:
      return { ...state, statusMessage:action.statusMessage};
    case VideoListActionTypes.LOAD:
      return { ...action};
    case VideoListActionTypes.RESET:
      return { ...action};
    case VideoListActionTypes.REFRESH:{
      // Adjust the selected
      const availableVideos = (action.allVideos.map(n=>n.videoId));
      let intersection:any[] = [];
      console.log('availableVideos:', availableVideos);
      // deleting a video and hitting refresh or 'Show' led to a crash
      // Dec 11 2022
      if (availableVideos && state.selectedVideoIds) {
        intersection = availableVideos.filter(x => state.selectedVideoIds.includes(x));
      }
      return { ...action, selectedVideoIds:intersection};
    }
    case VideoListActionTypes.SELECT_VIDEO:
      return { ...state, selectedVideoIds:action.selectedVideoIds };
    case VideoListActionTypes.UNSELECT_VIDEO:
      return { ...state, selectedVideoIds:action.selectedVideoIds };
    case VideoListActionTypes.SELECT_VIDEOS:
      // const fullSelection = state.allVideos.map(n=>n.videoId ? n.videoId: n.id); // legacy bug fix
      return { ...state, selectedVideoIds:(state.allVideos.map(n=>n.videoId)) };
    case VideoListActionTypes.UNSELECT_VIDEOS:      
      return { ...state, selectedVideoIds:[]};
    case VideoListActionTypes.ERROR:
      // Do we clear things on error?
      console.log('reducer message', action.statusMessage);
      return { ...state, statusMessage:action.statusMessage,selectedVideoIds:[]};
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// There is a way of combining the dispatch and state, but i believe it gets messy?
const EmmaVideosStateContext = React.createContext<EmmaVideosProps>(null!);
const EmmaVideosDispatchContext = React.createContext<React.Dispatch<any>>(null!);


function EmmaVideosProvider({ children }:Props) {
  const user = useUserState();
  //so ugly. Pretty sure the types are incorrect/all over the place
  //The inital values come from an api call, not sure where the call should
  //be made so that the UI knows something is happening.
  const [state, dispatch] = React.useReducer(emmaVideosReducer, 
    emptyVideoProps, initEmmaVideosReducer);

  useEffect(() =>{
    dispatch( {type:VideoListActionTypes.RESET, ...emptyVideoProps });
  }, [user]);

  return (
    <EmmaVideosStateContext.Provider value={state}>
      <EmmaVideosDispatchContext.Provider value={dispatch}>
        {children}
      </EmmaVideosDispatchContext.Provider>
    </EmmaVideosStateContext.Provider>
  );
}

function useEmmaVideosState() {
  const context = React.useContext(EmmaVideosStateContext);
  if (context === undefined) {
    throw new Error('useEmmaVideosState must be used within a EmmaVideosProvider');
  }
  return context;
}

function useEmmaVideosDispatch() {
  const context = React.useContext(EmmaVideosDispatchContext);
  if (context === undefined) {
    throw new Error('useEmmaVideosDispatch must be used within a EmmaVideosProvider');
  }
  return context;
}

export {EmmaVideosProvider, useEmmaVideosState, useEmmaVideosDispatch};