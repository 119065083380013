import * as React from 'react';

export interface Marker {
  id: number
  time: number
  title: string
}

export interface MarkerConfiguration {
  color: string
  selectionColor: string
}

interface Props {
  marker: Marker
  duration: number
  onMarkerClick: (marker: Marker) => void
  selectedMarker?: Marker
  configuration?: MarkerConfiguration
}

export class MarkerView extends React.Component<Props, never> {
  getPosition = () => {
    const { marker, duration } = this.props;
    // console.log(marker, 'duration', duration);
    const { time } = marker;
    if (duration) {
      const percent = time <= duration ? time / duration : 1;
      const val = `calc(${percent * 100}% - 2px)`;
      // console.log('the val', val);
      return val; 
    }
    return '-9999px';
  }

  render() {
    const { marker, configuration, onMarkerClick } = this.props;
    const { title } = marker;
    const id = String(marker.id);

    const selectedColor =
      this.props.selectedMarker !== undefined && this.props.selectedMarker.id === marker.id
        ? configuration !== undefined
          ? configuration.selectionColor
          : '#4CAF50'
        : configuration !== undefined
          ? configuration.color
          : '#F44336';

    return (
      <i
        id={id}
        // className="react-video-marker"
        style={{
          background: selectedColor,
          left: this.getPosition(),
          // background-color: #666666;
          cursor: 'pointer',
          display: 'block',
          height: '18px',
          width: '4px',
          position: 'absolute',
          top: '9px',
          // left: '50%'
        }}
        title={title}
        // onClick={() => {
        //   onMarkerClick(marker)
        // }}
      />
    );
  }
}
