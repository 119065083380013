import React, {useState, useEffect, useContext} from 'react';
import { SharedSnackbarConsumer } from '../../context/SnackbarContext';
import {Redirect} from 'react-router-dom';
import * as RouteNames from '../../common/routeNames';

import {
  Button,
  Card as MuiCard,
  CardContent,
  FormControl as MuiFormControl,
  Grid,
  TextField,
  MenuItem,
  Typography
} from '@material-ui/core';

import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { 
  useUserState,
  useUserDispatch,
  UserActionTypes 
} from '../../context/UserContext';
import { getSubjectDataForUserId } from '../../common/apiGetUtilities';
import { setSubjetInfo } from '../../common/apiSetUtilities';
import { genders } from '../../common/staticdata';
import {convertUserDataToUserPropType} from '../../common/entityUtilities';
import { roundToNearestMinutes } from 'date-fns';
import { sleep } from '../../common/utilities';

const Card = styled(MuiCard)(spacing);

const FormControl = styled(MuiFormControl)(spacing);

export function BabySettings({handleSave}) {
  
  // Global
  const userState = useUserState();

  const userDispatch = useUserDispatch();
  // local
  const [gender, setGender] = useState('F');
  const [subject, setSubject] = useState();
  const [dob, setDob] = useState(Date());
  const [dueDate, setDueDate] = useState(Date());
  const [name, setName] = useState('');
  const [notes, setNotes] = useState('');


  useEffect(() => {
    async function loadSubjectInfo(){
      const subject = await getSubjectDataForUserId(userState.firebaseUser, userState.emmauserId);
      if(subject){
        setSubject(subject);
      }
    }
    loadSubjectInfo();
  }, []);
  
  // WOuld it be ok to get the data in this call and avoid the 'initializer'
  useEffect(()=>{
    if(!subject) return;
    setDob(subject.dob);
    setDueDate(subject.duedate);
    setName(subject.name);
    setGender(subject.gender);
    if(subject.additionalProp1 && subject.additionalProp1.notes){
      setNotes(subject.additionalProp1.notes);
    }

  },[subject]);
 
  const saveBabySettings = () => {


    const babyPayload = {
      name: name,
      gender: gender,
      additionalProp1: {notes:notes},
      dob: new Date(dob),
      duedate: new Date(dueDate),
      emmauserid: userState.emmauserId        
    };
    setSubjetInfo(userState, babyPayload)
      .then((storedSubjectData)=>{
        const uiUpdateValues = {};

        uiUpdateValues.subject = storedSubjectData;
        const newUserForWeb = convertUserDataToUserPropType(uiUpdateValues);
      
        console.log('Post subject save', newUserForWeb);
        userDispatch({type:UserActionTypes.UPDATE, ...newUserForWeb});
        
      // userListDispatch({type:UserListTypes.UPDATE, allUsers:[newUserForWeb]});
      });
  };

  if(userState.authLevel < 0 ){
    return (
      <Card mb={6}>
        <CardContent>
          <Typography
            gutterBottom
            variant="h4"
          >
         Please register
          </Typography>
        </CardContent>
      </Card>
    );
  }
  return (
    <Card mb={6}>
      <CardContent>
        <Typography
          gutterBottom
          variant="h4"
        >
                Baby info
        </Typography>      
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={4}
          >
            <TextField
              id="babyname"
              label="Name"
              multiline
              onChange={e=>setName(e.target.value)}
              placeholder="Your baby's name"
              value={name}
            />
          </Grid>
          <Grid
            item
            md={4}
          >
            <FormControl  mb={3}>
              <TextField
                helperText="Your baby's gender"
                id="gender"
                label="Gender"
                m={2}
                onChange={e=>setGender(e.target.value)}
                select
                value={gender}
              >
                {genders.map(option => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={6}
        >
          <Grid
            item
            md={4}
          >
            <KeyboardDatePicker
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              format="MM/dd/yyyy"
              label="Date of birth"
              margin="normal"
              onChange={date=>setDob(date)}
              value={dob}
            />
          </Grid>
          <Grid
            item
            md={4}
          >
            <KeyboardDatePicker
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              format="MM/dd/yyyy"
              label="Due Date"
              margin="normal"
              onChange={date=>setDueDate(date)}
              value={dueDate}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={6}
        >
          <Grid
            item
            md={8}
          >
            <FormControl
              fullWidth
              mb={3}
            >
              <TextField 
                id="notes"
                label="Notes"
                multiline
                onChange={e=>setNotes(e.target.value)}
                placeholder="Additional notes about your baby here."
                rows={3}
                rowsMax={2}
                value = {notes}
              />
            </FormControl>
          </Grid>
        </Grid>
        <SharedSnackbarConsumer>
          {({openSnackbar}) => (
            <Button
              color="primary"
              onClick= {()=> {
                openSnackbar('Saving baby settings');
                saveBabySettings();
                sleep(1000);
                handleSave(true);}}
              variant="contained"
            >
              Save Baby Info
            </Button>

          )
          }
        </SharedSnackbarConsumer>

      </CardContent>
    </Card>
  );
}