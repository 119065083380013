import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import {
  Grid,
  Button,
  IconButton,
  Divider as MuiDivider,
  Toolbar,
  Tooltip,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';

import {
  // Delete as DeleteIcon,
  Build as BuildIcon,
  FilterList as FilterListIcon,
  Label as LabelIcon,
  ChildCareSharp
} from '@material-ui/icons';

import {  KeyboardDatePicker } from '@material-ui/pickers';

import {useUserListState} from '../../../context/UserListContext';
import {refreshToken, useUserState} from '../../../context/UserContext';
import { getVideoData } from '../../../common/apiGetUtilities';
import { 
  useEmmaVideosState, 
  useEmmaVideosDispatch,
  VideoListActionTypes
} from '../../../context/EmmaVideosContext';
// import { Video } from 'react-feather';

const Divider = styled(MuiDivider)(spacing);
const Spacer = styled.div`
  flex: 1 1 100%;
`;

const VideoTableToolbar = props => {

  const loggedInUser= useUserState();

  const { handleEditVideos, handleTagVideo , handleAssessment } = props;

  const emmaVideosDispatch = useEmmaVideosDispatch();
  const { startDate, endDate, selectedVideoIds, allVideos, needsRefresh } = useEmmaVideosState();
  const { allUsers } = useUserListState();
  const [ emmaUser ] = useState(useUserState());

  let defaultSearchRange = 7; // This is a sketchy/useless variable
  const [selectedVideoCount, setSelectedVideoCount] = useState(selectedVideoIds ? selectedVideoIds.length : 0);
  const [searchStartDate, setSearchStartDate] = useState(Date());
  const [searchEndDate, setSearchEndDate] = useState(Date());
  
  // Future version should keep track of what the most recent search parameters
  // are. For now any change will make the list not match.
  // Honestly it is a bit confusing. The goal is that something should always
  // know if the displayed videos matches the filters that are being displayed.
  // For Dec 2 2020, we only care about the parent email, so we are only adjusting
  // when that changes.
  const [doesListMatchSearch, setDoesListMatchSearch] = useState(false);

  const [parentEmail, setParentEmail] = useState('');
  const [deviceIds, setDeviceIds] = useState([]);
  const [deviceId, setDeviceId] = useState('');

  useEffect ( ()=> {
    if (needsRefresh){
      console.log('Videos need refresh:', allVideos.length);
      adjustAvailableVideos(VideoListActionTypes.REFRESH, searchStartDate, searchEndDate);
    }
  },[needsRefresh]);

  // Hacky way of checking to see if a refresh is needed on the displayed/stored data
  // this should probably be moved to the emmavideos context.
  // This basically duplicates the reducer in emmavideoscontext, but it is async
  const adjustAvailableVideos = async (type, startDate, endDate) =>{
    let message = 'Adjusting videos...refreshing FB token';
    refreshToken(emmaUser?.firebaseUser);
    emmaVideosDispatch({type:VideoListActionTypes.PREACTION, statusMessage:message});
      
    try{
      // Get the video data via this getVideoData utility, alas no stopping it once it starts
      switch(type) {
        case VideoListActionTypes.REFRESH:{
          const allVids = await getVideoData(emmaUser.firebaseUser, deviceId, parentEmail, startDate, endDate);
          const videoString = allVids.length===1?'video':'videos';
          message = `Found ${allVids.length} ${videoString}`;
          emmaVideosDispatch({type:type, allVideos:allVids, statusMessage:message, startDate:startDate, endDate:endDate});
          setDoesListMatchSearch(true);
          return;
        }
        case VideoListActionTypes.LOAD:{
          const allVids = await getVideoData(emmaUser.firebaseUser, deviceId, parentEmail, startDate, endDate);
          const videoString = allVids.length===1?'video':'videos';
          message = `Found ${allVids.length} ${videoString}`;
          emmaVideosDispatch({type:type, allVideos:allVids,  statusMessage:message, selectedVideoIds:[], startDate:startDate, endDate:endDate});
          setDoesListMatchSearch(true);
          return;
        }
      }
    }catch(err){
      let message = '';
      if (err.response) {
        // client received an error response (5xx, 4xx)
        console.log('Found the response error', err.response);
        message = err.response.data.error.message;
      } else if (err.request) {
        // client never received a response, or request never left
        console.log('Found the request error', err.request);
        message = 'Enable to connect to API';
      } else {
        // anything else
        message = 'Unknown error';
      }
      // message = `API error: h${message} Time:${Date.now().toString()}`;
      emmaVideosDispatch({type:VideoListActionTypes.ERROR, allVideos:[], statusMessage:message, selectedVideoIds:[], startDate:startDate, endDate:endDate});
          
    }
  };

  useEffect(()=>{
    // Seems like people like the async in this, not sure why...dlp maybe it is easier to cancel?
    // async function initialLoadInEffect(startDate, endDate){
    //   console.log('Blah reducer setting dates', startDate);
    //   const allVideos = await getVideoData(emmaUser, deviceId, parentEmail, startDate, endDate);
    //   emmaVideosDispatch({type:VideoListActionTypes.LOAD, allVideos:allVideos, selectedVideoIds:[], startDate:startDate, endDate:endDate});
    //   return;
    // }
    // Not sure what is expected for daysback property or how/when that changes values
    const { daysBack } = props;
    // console.log('Days back', daysBack);
    let actionToTake = VideoListActionTypes.LOAD;
    let changeDate = false;
    if(daysBack){
      defaultSearchRange = daysBack;
      changeDate = true;
    }
    if(startDate && endDate && !changeDate){
      // Assumes everything is the same as before and no 
      // call was made to change the search dates from an external source
      // ie the redirects
      actionToTake = VideoListActionTypes.REFRESH;
      setSearchStartDate(startDate);
      setSearchEndDate(endDate);
      // Essentially no new search is supposed to happen, because nothing new
      // has been requested.
      // adjustAvailableVideos(actionToTake, startDate, endDate);
    }else{
      // Nothing has been initialized so do so now
      // a new search date has been requested
      const initialStartDate = new Date(searchEndDate);
      initialStartDate.setDate(initialStartDate.getDate() - defaultSearchRange);
      setSearchStartDate(initialStartDate);
      // initialLoadInEffect(initialStartDate, searchEndDate);
      adjustAvailableVideos(actionToTake,initialStartDate, searchEndDate);
    }    
  }, []);

  useEffect(() => {
    setSelectedVideoCount(selectedVideoIds ? selectedVideoIds.length:0);
  },[selectedVideoIds && selectedVideoIds.length]);

  useEffect(()=>{
    // Effects only get called when there is a change in value
    if(allUsers){
      const userDetails = allUsers.find((user)=>user.localEmail===parentEmail);
      if(userDetails && userDetails.deviceIds){
        setDeviceIds(userDetails.deviceIds);
      }
      setDoesListMatchSearch(false);
    }
  },[parentEmail]);

  return (
    <div>
      <Toolbar>
        <Divider my={8}></Divider>
        <Grid
          align="center"
          container 
          justify="center"
          spacing={6}
        >
          <Grid
            align="left"
            container
            
            justify="left"
            md={12} 
            spacing={4}
            xs={12}
          >
            <Grid
              item
              xs={6}
            >
              <KeyboardDatePicker
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                format="MM/dd/yy"
                label="Start Date"
                margin="normal"
                onChange={setSearchStartDate}
                value={searchStartDate}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <KeyboardDatePicker
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                format="MM/dd/yy"
                label="End Date"
                margin="normal"
                onChange={setSearchEndDate}
                value={searchEndDate}
              />
            </Grid>
          </Grid>
          
          {loggedInUser.authLevel >= 5 && <Grid
            align="left"
            justify="left"
            container 
            xl={12}
            lg={12}
            md={12}
            spacing={4}
            sm={12}
            xs={12}
          >
            <Grid
              item
              align="left"
              justify="left"
              lg={4}
              md={4}
              sm={4}
              xs={12}
            >
              <FormControl
                fullWidth
                mb={12}
                sm={12}
                xs={12}
              >
                <InputLabel fullWidth htmlFor="age-simple">Parent</InputLabel>
                <Select
                  inputProps={{
                    name: 'parentemail',
                    id: 'parentemail'
                  }}
                  onChange={e=>setParentEmail(e.target.value)}
                  value={parentEmail}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {allUsers && emmaUser.authLevel === 10 &&  
                    allUsers.map(n=>
                      <MenuItem
                        key={n.uid}
                        value={n.localEmail}
                      > {n.localEmail}
                      </MenuItem>)
                  }
                  {emmaUser && emmaUser.authLevel != 10 &&
                    <MenuItem
                      key={emmaUser.uid}
                      selected
                      value={emmaUser.localEmail}
                    >
                      {emmaUser.localEmail}
                    </MenuItem>}
                </Select>
              </FormControl>
            </Grid>             
            <Grid
              item
              align="left"
              justify="left"
              lg={4}
              md={4}
              sm={4}
              xs={12}
            >
              <FormControl
                fullWidth
                mb={8}
              >
                <InputLabel fullWidth htmlFor="age-simple">Devices</InputLabel>
                <Select fullWidth
                  inputProps={{
                    name: 'deviceid',
                    id: 'deviceid'
                  }}
                  onChange={e=>setDeviceId(e.target.value)}
                  value={deviceId}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {
                    deviceIds.map(n=>
                      <MenuItem
                        key={n}
                        value={n}
                      > {n}
                      </MenuItem>)                  
                  }
                </Select>
              </FormControl>
            </Grid>
            
            
          </Grid>
          }
          <Grid
            container
            item
            align="center"
            justify="center"
            md={12}
            xs={12}
          >
            <Button
              color="primary"
              onClick={e=>adjustAvailableVideos(VideoListActionTypes.REFRESH, searchStartDate, searchEndDate)}
              size="small"
              variant="contained"
            >Show</Button>   
          </Grid>
        </Grid>
      </Toolbar>
      <Divider my={2} />
      <Toolbar>
        <div>
          {selectedVideoIds && selectedVideoIds.length > 0 ? (
            <Typography
              color="inherit"
              variant="subtitle1"
            >
              {selectedVideoCount} selected
            </Typography>
          ) : (
            <Typography
              id="tableTitle"
              variant="h6"
            >
            Videos
            </Typography>
          )}
        </div>
        <Spacer />
        {selectedVideoCount > 0 && loggedInUser.authLevel > 0 ? (
          <Tooltip title="Label">
            <IconButton
              aria-label="Tag"
              onClick={handleTagVideo}
            >
              <LabelIcon />
            </IconButton>
          </Tooltip>
        ):(<div />)}
        <Spacer/>
        <div>
          {selectedVideoCount > 0 && loggedInUser.authLevel > 0 ? (
            <Tooltip title="Process">
              <IconButton
                aria-label="Process"
                onClick={handleEditVideos}
              >
                <BuildIcon />
              </IconButton>
            </Tooltip>
          ):(<div />)}
        </div>
        <Spacer />
        <div>
          {selectedVideoCount > 0 && loggedInUser.authLevel > 0 && doesListMatchSearch? (
            <Tooltip title="Assessment">
              <IconButton
                aria-label="Assessment"
                onClick={()=> handleAssessment(parentEmail)}
              >
                <ChildCareSharp />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Filter list">
              <IconButton aria-label="Filter list">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </Toolbar>
    </div>
  );
};

export default VideoTableToolbar;
