import React, {useState, useEffect} from 'react';
import {
  // Avatar,
  Button,
  Card as MuiCard,
  CardContent,
  FormControl as MuiFormControl,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';

import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import { useUserState, useUserDispatch, UserActionTypes } from '../../context/UserContext';
import {convertUserDataToUserPropType} from '../../common/entityUtilities';
import { setUserInfo } from '../../common/apiSetUtilities';

const Card = styled(MuiCard)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
// const CenteredContent = styled.div`
//   text-align: center;
// `;
export function ParentSettings() {
  // Global
  const userState = useUserState();
  const userDispatch = useUserDispatch();
  // local
  const [device1SerialNumber, setDevice1SerialNumber] = useState('');
  const [device2SerialNumber, setDevice2SerialNumber] = useState('');
  const [notes, setNotes] = useState(userState.notes);
  const [sharedEmails, setSharedEmails] = useState(userState.sharedEmails);

  useEffect(() => {
    if(userState.deviceIds){
      let c = 0;    
      for(const dev of userState.deviceIds){
        if(c===0) setDevice1SerialNumber(dev);
        if(c===1) setDevice2SerialNumber(dev);
        c++;
      }
    }

    setNotes(userState.notes);
    setSharedEmails(userState.sharedEmails);
  }, []);


  const modifyParentSettings = () => {
    const ids = [];
    if (device1SerialNumber != ''){
      ids.push(device1SerialNumber);
    }
    if (device2SerialNumber != ''){
      ids.push(device2SerialNumber);
    }
    const mongoUserValuesToUpdate= {};//:Partial<EmmaUserSchemaProps> 
    // modify fields the user can change.
    // this method of creating the object seems to break
    // the api save...dlp
    mongoUserValuesToUpdate.deviceIds = ids;
    mongoUserValuesToUpdate.additionalProp1 =  {'comments':notes};
    mongoUserValuesToUpdate.sharedEmails = sharedEmails;
    const firebaseUser = userState.firebaseUser;
    setUserInfo(firebaseUser, userState.emmauserId, mongoUserValuesToUpdate)
      .then(updatedUser=>{

        console.log('The user', updatedUser);
        const fixedUser = convertUserDataToUserPropType(updatedUser);
        console.log('The user', fixedUser);
        userDispatch({ type: UserActionTypes.UPDATE, ...fixedUser });
      });
  };

  const buttonText = userState.authLevel < 0?'Register':'Save changes'; 
  
  return(
    userState.authLevel > 0 ? (
      <Card mb={6}>
        <CardContent>
          <Typography
            gutterBottom
            variant="h4"
          >
            Device Settings
          </Typography>    
          <Grid
            container
            spacing={6}
          >
            <Grid
              item
              md={4}
            >
              <TextField
                id="device1"
                label="Device Serial #"
                multiline
                onChange={e => setDevice1SerialNumber(e.target.value)}
                placeholder="Your baby monitor serial number"
                value={device1SerialNumber}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={4}
            >
              <TextField
                id="device2"
                label="Additional Device Serial #"
                multiline
                onChange={e => setDevice2SerialNumber(e.target.value)}
                placeholder="Your baby monitor serial number"
                value={device2SerialNumber}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={6}
          >
            <Grid
              item
              md={8}
            >
              <FormControl
                fullWidth
                mb={3}
              >
                <TextField
                  id="sharedemails"
                  label="Share Account"
                  multiline
                  onChange={e => setSharedEmails(e.target.value)}
                  placeholder="Share account with others. Type in emails here, separated by space."
                  rows={3}
                  rowsMax={2}
                  value={sharedEmails}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={6}
          >
            <Grid
              item
              md={8}
            >
              <FormControl
                fullWidth
                mb={3}
              >
                <TextField
                  id="notes"
                  label="Notes"
                  multiline
                  onChange={e => setNotes(e.target.value)}
                  placeholder="Your additional notes here."
                  rows={3}
                  rowsMax={2}
                  value={notes}
                />
              </FormControl>
            </Grid>    
          </Grid>
          <Button
            color="primary"
            onClick={modifyParentSettings}
            variant="contained"
          >
            {buttonText}              
          </Button>
        </CardContent>
      </Card>
    ): (<div></div>) 
  );
}
