// This is copied from all the other TableHeads, a refactor should be done to unify these
// in case something changes down the line
import React from 'react';

import PropTypes from 'prop-types';
import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@material-ui/core';

const columns = [
  {
    id:'download',
    numeric:true,
    disablePadding:true,
    label:'Report'
  }, 
  {
    id:'date',
    numeric:true,
    disablePadding:false,
    label:'Date'
  },
  {
    id:'dob',
    numeric:true,
    disablePadding: false,
    label: 'Infant DOB'
  },
  {
    id:'parentemail',
    numeric:false,
    disablePadding: false,
    label:'Parent'
  },
  // {
  //   id:'record',
  //   numeric:true,
  //   disablePadding:true,
  //   label:'Record'
  // },
  {
    id:'update',
    numeric:true,
    disablePadding:true,
    label:'Update'
  },
  {
    id:'delete',
    numeric:true,
    disablePadding:true,
    label:'Delete'
  } 
];

const AssessmentTableHead = (props)=> {

  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount
  } = props;

  const createSortHandler = property => event => {
    props.onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
      
        {columns.map(
          column => (
            <TableCell
              align={column.numeric ? 'center' : 'left'}
              key={column.id}
              padding={column.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === column.id ? order : false}
            >
              <Tooltip
                enterDelay={300}
                placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                title="Sort"
              >
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={order}
                  onClick={createSortHandler(column.id)}
                >
                  {column.label}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          ),
          this
        )}
      </TableRow>
    </TableHead>
  );
};

AssessmentTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};
export default AssessmentTableHead;