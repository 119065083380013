import React from 'react';

import { makeStyles } from '@material-ui/styles';
import { 
  Typography, 
  Link,
  Grid,
  List,
  ListItemText,
  ListItem as MuiListItem
} from '@material-ui/core';

import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 2px
    4px;
  background: white;
  position: relative;
`;
// padding: ${props => props.theme.spacing(1) / 4}px
// ${props => props.theme.spacing(4)}px;
// background: ${props => props.theme.palette.common.white};
const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: 2px;
  padding-right: 2px;

  &,
  &:hover,
  &:active {
    color: #000;
  }
`;
// padding-left: ${props => props.theme.spacing(2)}px;
// padding-right: ${props => props.theme.spacing(2)}px;
const Footer = () =>{
  return (
    <Wrapper>
      <Grid container spacing={0}>
        {/* <Hidden smDown>
          <Grid container item xs={12} md={6}>
            <List>
              <ListItem component="a" href="#">
                <ListItemText primary="Support" />
              </ListItem>
              <ListItem component="a" href="#">
                <ListItemText primary="Help Center" />
              </ListItem>
              <ListItem component="a" href="#">
                <ListItemText primary="Privacy" />
              </ListItem>
              <ListItem component="a" href="#">
                <ListItemText primary="Terms of Service" />
              </ListItem>
            </List>
          </Grid>
        </Hidden> */}
        <Grid container item xs={12} md={6} justify="flex-end">
          <List>
            <ListItem>
              <ListItemText primary="© 2021 - Early Markers" />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default Footer;
