import React, {  ReactNode } from 'react';
export enum AppSettingsActionTypes {
  SET_ALL = 'SET_ALL',
  POSTURE_URL = 'POSTURE_URL',
  POSTURE_ENABLED = 'POSTURE_ENABLED',
  POSTURE_FRAMERATE = 'POSTURE_FRAMERATE',
  FRAME_GRAB_INTERVAL = 'FRAME_GRAB_INTERVAL'
}
export type AppSettingsContextType = {
  postureUrl:string,
  postureEnabled:boolean,
  postureFrameRate:number,
  frameGrabInterval:number //milliseconds
}

interface AppSettingsAction extends AppSettingsContextType {
  type:AppSettingsActionTypes;
}

export const defaultAppSettings:AppSettingsContextType = {

  postureUrl:'some url',
  postureEnabled:false,
  postureFrameRate:30,
  frameGrabInterval:1000
};

export function AppSettingsReducer(state:AppSettingsContextType, action:Partial<AppSettingsAction>)
:AppSettingsContextType{
  const {type, ...noType} = action;
  switch(type) {
    case AppSettingsActionTypes.SET_ALL: //Overkill? and maybe a temp hack?
    { 
      console.log(noType);
      return {...state, ...noType};
    }
    case AppSettingsActionTypes.POSTURE_URL:
    { 
      if(noType.postureUrl !== undefined){
        return {...state, postureUrl:noType.postureUrl};
      }
      return {...state};
    }
    case AppSettingsActionTypes.POSTURE_ENABLED:
    { 
      if(noType.postureEnabled !== undefined){
        return {...state, postureEnabled:noType.postureEnabled};
      }
      return {...state};
    }
    case AppSettingsActionTypes.POSTURE_FRAMERATE:
    { 
      if(noType.postureFrameRate){
        return {...state, postureFrameRate:noType.postureFrameRate};
      }
      return {...state};
    }
    case AppSettingsActionTypes.FRAME_GRAB_INTERVAL:
    { 
      if(noType.frameGrabInterval){
        return {...state, frameGrabInterval:noType.frameGrabInterval};
      }
      return {...state};
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const AppSettingsContext = React.createContext<{
  appSettingsState:AppSettingsContextType,
  appSettingsDispatch:React.Dispatch<Partial<AppSettingsAction>>}>({
    appSettingsState:defaultAppSettings,
    appSettingsDispatch:()=>null
  });
//https://dev.to/mitchkarajohn/working-with-react-context-providers-in-typescript-1fk0

type AppSettingProps = {
  children: ReactNode;  
}

export const AppSettingsProvider = ({ children }:AppSettingProps):JSX.Element => {
  //For now nothing will be passed to this provider, everyting is built into when
  //it gets created. Seems kind of meh, but we will see how it turn out.
  const [state, dispatch] = React.useReducer(AppSettingsReducer, defaultAppSettings);
  return (
    <AppSettingsContext.Provider value={{appSettingsState:state, appSettingsDispatch:dispatch }}>
      {children}
    </AppSettingsContext.Provider>
  );
};

export const useAppSettings = () => React.useContext(AppSettingsContext);