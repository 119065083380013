import React, {useEffect, useState} from 'react';
import {
  Card,
  CardContent,
  TextField,
  Grid,
  Typography} from '@material-ui/core';

import { 
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core/styles';
// import { PercentileScoreSchemaProps } from '../../../common/mongoSchemas';
import { getPercentileData } from '../../common/apiGetUtilities';
import { ReportInfoContext } from '../../context/ReportContext';

const useStyles = makeStyles((theme:Theme) =>
  createStyles({
    card: {
      maxWidth: '100%',
      minWidth: '0',
      width:'100%',
      margin: 'auto',
      transition: '0.3s',
      boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
      '&:hover': {
        boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)'
      }
    },
    carddivider: {
      margin: theme.spacing(0)
    },
    cardcontent: {
      textAlign: 'left',
      padding: theme.spacing(3)
    },
    container:{
      display: 'flex'
    },
  }),
);
//hack for speed
type AimsPercentileInfoProps = {
  recalculatePercentiles:(age:number, aimsScore:number|undefined)=>void;
}
export const AimsPercentileInfo= (props:AimsPercentileInfoProps):JSX.Element => {
  
  //constants
  const classes = useStyles();
  const {recalculatePercentiles} = props;
  // Variables
  const {reportInfoState, reportInfoDispatch} = React.useContext(ReportInfoContext);
  const [percentileDataInfo, setPercentileDataInfo] = useState<Record<string, unknown>|undefined>(undefined);
  //Special case that can be modified by user.
  const [ageInMonths, setAgeInMonths] = useState(0);

  useEffect(() =>{
    calculateAgeAndAimsScore();
  }, [reportInfoState.scoreInformation.total]);

  useEffect(() => {    
    const score = reportInfoState.scoreInformation.total.score as number;
    if(recalculatePercentiles){
      recalculatePercentiles(ageInMonths,score);
    }
    localUpdatePercentileValues(ageInMonths, score);
  }, [ageInMonths]);

  // ug soooooo bad
  const localUpdatePercentileValues = async(ageMonths:number, aimsScore:number) => {
    // do we want to calc the score again or use it from another source...
    const percResult = await getPercentileData(ageMonths, aimsScore);
    // fake type check, blargh!!! bad dlp
    if('isErrorType' in percResult){
      //do something with the error
    }else{
      const _percentileData = (percResult as unknown) as Record<string, unknown>;
      // console.log('The percentile', _percentileData);
      setPercentileDataInfo(_percentileData);
      // const percInfo = {percentile:{information:_percentileData['message']}};
      // reportInfoDispatch({type:ReportInfoActionTypes.UPDATE_SCORE, scoreInformation:percInfo});
    }
  };  
  const calculateAgeAndAimsScore = async() =>{

    const age = reportInfoState.reportInformation.subjectAgeMonthDaysForAsmt;
    const correctedAge = reportInfoState.reportInformation.subjectCorrectedAgeMonthDaysForAsmt;
    let estAgeMonths = 0;
    if(age){
      const split = age.split(':',2);
      estAgeMonths = +split[0]+(+split[1]/30);
    }
    if(correctedAge){
      const split = correctedAge.split(':',2);
      estAgeMonths = +split[0]+(+split[1]/30);
    }
    const score = reportInfoState.scoreInformation.total.score as number;
    setAgeInMonths(estAgeMonths);
    if(recalculatePercentiles){
      await recalculatePercentiles(estAgeMonths,score);
    }

    localUpdatePercentileValues(ageInMonths, score);
  };

  const getScore = (percentile:string):string => {
    if(percentileDataInfo && percentile in percentileDataInfo){
      return percentileDataInfo[percentile] as string;
    }
    return '-1';
  };

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardcontent}>
        <Grid 
          container
          justify="space-between"
          spacing={2}
        >
          <Grid
            container
            direction="row"
            justify="flex-start"
            spacing={2}
          >
            <Grid
              item
              md={4}
              sm={4}
              xs={4}
            >
              <Typography 
                color="secondary"
                variant="subtitle1"
              >
                  Expected Scores at Percentiles
              </Typography>
            </Grid>
            <Grid
              item
              md={2}
              sm={2}
              xs={2}
            >
              <TextField 
              //ensure only numbers are here
                autoFocus
                // disabled
                id="age"
                label="Age (months)"
                onChange={(event) => {
                  // console.log('event', event.target.value);
                  setAgeInMonths(+event.target.value);
                }}
                placeholder=""
                type="number"
                // what is this supposed to do?
                value={Number(ageInMonths).toFixed(2)}
              />
            </Grid>
            {/* <Grid
              item
              md={3}
              sm={3}
            >                  
              <Button
                color="primary"
                endIcon={<Refresh/>}
                onClick={()=> {updateAIMSPercentileScoreValues();}}
                size="small"
                variant="contained"
              >
                Refresh
              </Button>
            </Grid> */}
          </Grid>
          <Grid
            container
            direction="row"
            item
            justify="space-between"
            md={11}
            sm={11}
            spacing={2}
            xs={11}
          >
            <Grid
              item
            >
              <Typography>5th: </Typography>
              <Typography
                color="secondary"
              >
                {getScore('5')} 
              </Typography>
            </Grid>
            <Grid
              item
            >
              <Typography>10th: </Typography>
              <Typography
                color="secondary"
              >
                {getScore('10')} 
              </Typography>
            </Grid>
            <Grid
              item
            >
              <Typography>25th: </Typography>
              <Typography
                color="secondary"
              >
                {getScore('25')} 
              </Typography>
            </Grid>
            <Grid
              item
            >
              <Typography>50th: </Typography>
              <Typography
                color="secondary"
              >
                {getScore('50')} 
              </Typography>
            </Grid>
            <Grid
              item
            >
              <Typography>75th: </Typography>
              <Typography
                color="secondary"
              >
                {getScore('75')} 
              </Typography>
            </Grid>
            <Grid
              item
            >
              <Typography>90th: </Typography>
              <Typography
                color="secondary"
              >
                {getScore('90')} 
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
          >
            <Grid
              item
            >
              <Typography 
                color="primary"
                // variant="body"
              >
                {getScore('message')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};