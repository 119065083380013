import React, { useEffect, useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';

import {
  AssessmentListUIProps,
} from '../../common/entityUtilities';

import {
  useUserState,
} from '../../context/UserContext';

import { ReportComponent } from '../../components/Report';
import { ReportInfoActionTypes, ReportInfoContext } from '../../context/ReportContext';
import { AssessmentInfoContext,
  indexOfLibraryName
} from '../../context/AssessmentInfoContext';

export type ReportInfoDialogProps = {
  isOpen:boolean,
  dialogComplete:()=>void,
  asmtInformation?:AssessmentListUIProps,
  //Items needed for scoring, location needs to be refactored.
  // availableInstruments?:InstrumentSchemaProps[],
  // selectedInstrumentIndex?:number
  reviewForId:string,
  // openPreview:()=>void
  //current logged in user is also needed but grabbed from context
  // scored is derived from that user
}

// First pass at the report dialog. This is just the shell, real
// work is done in other parts.

// It is unclear how/where in the ui the instrument is to be selected.
export const ReportDialog = (props:ReportInfoDialogProps):JSX.Element=> {

  // userInformation is UserProps
  const {isOpen, dialogComplete, reviewForId,
    asmtInformation, 
    // availableInstruments,selectedInstrumentIndex
  }  = props;
  
  const {reportInfoState, reportInfoDispatch} = React.useContext(ReportInfoContext);

  // hope and pray that this falls in an assessment context provider
  // also should this be moved further up?
  const {assessmentInfoState} = React.useContext(AssessmentInfoContext);
  const {instrumentLibraries, activeInstrumentName} = assessmentInfoState;

  const loggedInUser = useUserState();

  const [selectedInstrumentIndex, setSelectedInstrumentIndex] = useState<number>(indexOfLibraryName(assessmentInfoState));
  
  useEffect(() =>{
    setSelectedInstrumentIndex(indexOfLibraryName(assessmentInfoState));
  }, [instrumentLibraries, activeInstrumentName]);

  const handleClose = () => {
    dialogComplete();
  };  


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if(reportInfoDispatch){      
      reportInfoDispatch({type:ReportInfoActionTypes.UPDATE_REPORTDIALOG, 
        isReportPreviewVisible:true}); 
    }
  };

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={'md'} // 'sm' || 'md' || 'lg' || 'xl'
      onClose={handleClose}
      open={isOpen}        
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-dialog-title">          
          Report information
        </DialogTitle>
        <DialogContent>
          <ReportComponent 
            // would love to pass a full object instead of hte bits
            asmtInformation={asmtInformation}
            availableInstruments= {instrumentLibraries}
            reportForUserId={reviewForId}
            selectedInstrumentIndex={selectedInstrumentIndex}
            // grabbed on init. somebody should keep track
            // of how these things are supposed to work.
            // asmtSubjectHack={asmtInformation?.subject}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={reviewForId===loggedInUser.emmauserId?false:true}
            type="submit"
          >
            {reportInfoState.dbInformation?.reportId?'View Completed Report':'Preview report'}           
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
