import React from 'react';
import { 
  EmmaUserSchemaProps,
  AnnotationSetSchemaProps,
  AnnotationSchemaProps
} from '../common/mongoSchemas';
 
// import { 
//   AnnotationSetFromApi
// } from '../common/entityUtilities';
// This is kind of turning into a generic assessment context.
// I believe having one would be benificial, but i don't know 
// the specs yet
// type Props = {
//   children:React.ReactNode;
// };

enum VideoTagTableTypes {
  LOAD = 'LOAD',
  COPY = 'COPY',
  REMOVEITEM = 'REMOVEITEM',
  ADDITEM = 'ADDITEM',
  ANNOTATIONSET='ANNOTATIONSET',
  FILTERNAME = 'FILTERNAME',
  ANNOTATION_OWNER='ANNOTATION_OWNER'
}

export type VideoTagTableContextType = {
  // This context type is a mishmash a full assessment
  // and the individual parts of the assessment.
  // it is not easy to manipuate via react components....[dlp]
  // But in the context, things should be broken into smaller pieces
  // updating the full context is icky
  // which will hold current observation-> and all related items
  observationId:string,
  hackInstrumentIndexNumber:number,
  // selectedAnnotationSet: number,// a numeric value of currentAnnotSetOwner
  currentAnnotSetOwner:string,// For now just use email, later this should change to id
  annotationFilter:string|undefined,
  // keyed to email address
  annotationSetMap: Map<string, [AnnotationSetSchemaProps]>,
  forceVisualUpdateHack: boolean,// a hack way of forcing a redraw when directly messing with arrays
  // a future version of this should have the list of what is currently being displayed.
  forceSaveHack:boolean
}

interface VideoTagTableAction extends VideoTagTableContextType {
  type:VideoTagTableTypes;
}

const initialVideoTabTable:VideoTagTableContextType = {
  //dlp thinks there should be a more complete assessment context
  // which will hold current observation-> and all related items
  observationId:'',
  currentAnnotSetOwner:'',
  hackInstrumentIndexNumber:-1,
  annotationFilter:undefined,
  annotationSetMap: new Map(),
  // selectedAnnotationSet: -1,
  forceVisualUpdateHack: false,
  forceSaveHack:false
};
// Additional reducer info/help
// https://www.benmvp.com/blog/type-checking-react-usereducer-typescript/
function videoTagTableReducer(state:VideoTagTableContextType, action:Partial<VideoTagTableAction>):VideoTagTableContextType{
  
  const {type, ...noType} = action;// remove the action type
  // hackity hack for force save, kind of reset in case somebody uses it
  // and since it is currently not reset after being used. ie hack!
  noType.forceSaveHack = false;
  switch(action.type) {
    case VideoTagTableTypes.LOAD:{
      const forceUpdate = !state.forceVisualUpdateHack;
    
      return {...state, ...noType, forceVisualUpdateHack:forceUpdate  };
    }
    case VideoTagTableTypes.COPY:{
      const forceUpdate = !state.forceVisualUpdateHack;
      
      return {...state, ...noType, forceVisualUpdateHack:forceUpdate, forceSaveHack:true  };
    }
    case VideoTagTableTypes.ANNOTATIONSET:
    case VideoTagTableTypes.ANNOTATION_OWNER:{
      let newOwner = '';
      if(action.currentAnnotSetOwner) newOwner = action.currentAnnotSetOwner;
      return {...state, currentAnnotSetOwner:newOwner};
    }
    case VideoTagTableTypes.FILTERNAME:
      return { ...state, annotationFilter:action.annotationFilter};
    case VideoTagTableTypes.ADDITEM:{
      // So hacky
      // https://www.robinwieruch.de/react-state-array-add-update-remove
      // console.log(state.forceVisualUpdateHack, 'adding', action.forceVisualUpdateHack);
      const forceUpdate = !state.forceVisualUpdateHack;
      const {annotationSetMap: annotationSets, currentAnnotSetOwner} = state;
      const {hackInstrumentIndexNumber} = noType;
      if(annotationSets.has(currentAnnotSetOwner)){
        // check the previous annotations
        const annotations = annotationSets.get(currentAnnotSetOwner)?.
          find(annotSet=>annotSet.instrumentIndex===hackInstrumentIndexNumber)?.annotations;
        // the new key is a hack name that is used when creating the 'new' tag table
        const newKey = 'new';
        if(annotations){
          // see if a new lable exists in the annotation set
          const nLFirst = noType.annotationSetMap?.get(newKey);
          const nL = nLFirst?.find(annotSet=>annotSet.instrumentIndex === hackInstrumentIndexNumber)?.annotations[0];
          // if it does (based on the startTime) get its index
          const tagIndex = annotations.findIndex(tag => tag.startTime === nL?.startTime);
          if(nL){
            if (tagIndex > -1) {
              annotations[tagIndex] = nL;
            } else {
              annotations.push(nL); 
            }
          }
        }else{
          // there were no annotations for the user,
          // so add it to the owners list
          const nT = noType.annotationSetMap?.get(newKey)?.
            find(annotSet=>annotSet.instrumentIndex===hackInstrumentIndexNumber);//should probably be user
          if(nT) {
            if(annotationSets.has(currentAnnotSetOwner) && annotationSets.get(currentAnnotSetOwner) !== undefined){
              annotationSets.get(currentAnnotSetOwner)?.push(nT);
            }else{
              annotationSets.set(currentAnnotSetOwner, [nT]);
            }
            
          }
        }
      }else{
        // console.log('blah User does not have an entry, this should not happen');
      }
      return { ...state, forceVisualUpdateHack:forceUpdate };
    }
    case VideoTagTableTypes.REMOVEITEM:{
      // hack and a half. a better way is:
      // https://www.robinwieruch.de/react-state-array-add-update-remove
      const forceUpdate = !state.forceVisualUpdateHack;
      return { ...state, forceVisualUpdateHack:forceUpdate};
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
export const VideoTagTableContext = React.createContext<VideoTagTableContextType>(null!);

export function createEmpty(timeStamp:number, instrumentIndex:number):VideoTagTableContextType{
  const newLabel:AnnotationSchemaProps = {// TODO: probs a new GUID goes here
    startTime: timeStamp,
    label: 'new item'
  };
  const annotations:AnnotationSchemaProps[] = [
    newLabel
  ];
  const annotationSet:AnnotationSetSchemaProps ={
    instrumentIndex:instrumentIndex,
    emmaUserId:'string', // User that created the codings
    observationId:'string', // Observation the coding is for.
    annotations:annotations,
    //legacy but it is unknown how many only have this for id
    videoId:'string',
  };
  const userIdentifier = 'new';
  const annotSetMap = new Map();
  annotSetMap.set(userIdentifier,[annotationSet]);
  const initialTagTableContextValues:VideoTagTableContextType = {
    observationId:'empty', 
    currentAnnotSetOwner:'',
    hackInstrumentIndexNumber:instrumentIndex,//hack, maybe it should be -1?
    annotationFilter:undefined,
    annotationSetMap: annotSetMap,
    forceVisualUpdateHack: false,
    forceSaveHack:false
  };
  return initialTagTableContextValues;
}

export { videoTagTableReducer,  VideoTagTableTypes, initialVideoTabTable };
