import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { CssBaseline } from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import Themes from './themes';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { LayoutProvider } from './context/LayoutContext';
import { UserProvider } from './context/UserContext';
import { UserListProvider } from './context/UserListContext';
import { EmmaVideosProvider } from './context/EmmaVideosContext';
import { AsmtListProvider } from './context/AssessmentListContext';
import { AppSettingsProvider } from './context/AppSettingsContext';
import { ReportInfoProvider } from './context/ReportContext';
import { AssessmentInfoProvider } from './context/AssessmentInfoContext';
import { GeneralControlProvider } from './context/GeneralControlContext';
import { SharedSnackbarProvider } from './context/SnackbarContext';


// Multiple entry points for faster testing? without messing with the main code?
// https://blog.logrocket.com/multiple-entry-points-in-create-react-app-without-ejecting/
ReactDOM.render(
  <LayoutProvider>
    <AppSettingsProvider>
      <UserProvider>
        <UserListProvider>
          <GeneralControlProvider>    
            <EmmaVideosProvider>          
              <AsmtListProvider>
                <AssessmentInfoProvider>
                  <ReportInfoProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={Themes.default}>
                        <CssBaseline />
                        <SharedSnackbarProvider>
                          <App />
                        </SharedSnackbarProvider>
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                  </ReportInfoProvider>
                </AssessmentInfoProvider>
              </AsmtListProvider>
            </EmmaVideosProvider>
          </GeneralControlProvider>
        </UserListProvider>
      </UserProvider>
    </AppSettingsProvider>
  </LayoutProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//This undoes the notification serviceworker!! booo!
// serviceWorker.unregister();