import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Autocomplete } from '@material-ui/lab';
import { spacing } from '@material-ui/system';
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  Button,
  IconButton,
  Divider as MuiDivider,
  Toolbar,
  Tooltip,
  Typography,
  TextField
} from '@material-ui/core';

import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  Label as LabelIcon
} from '@material-ui/icons';

import {  KeyboardDatePicker } from '@material-ui/pickers';

import { useUserState } from '../../../context/UserContext';
import { 
  useAsmtTableState,
  useAsmtTableDispatch,
  AssessmentListActionTypes,
  adjustAvailableAsmts
} from '../../../context/AssessmentListContext';

import { useUserListState } from '../../../context/UserListContext';
import { getSorting, stableSort } from '../../../common/tableUtilities';


const Divider = styled(MuiDivider)(spacing);
const Spacer = styled.div`
  flex: 1 1 100%;
`;

// Basically a duplicate of the VideoTableToolbar...
const AssessmentTableToolbar = props => {

  const user = useUserState();
  const { allUsers } = useUserListState();
  const tableSearchState = useAsmtTableState();
  const asmtTableDispatch = useAsmtTableDispatch();

  const { handleTagVideo } = props; 
  const [selectedVideoIds] = useState([]);
  const [checkedState, setCheckedState] = useState({findWithNoItems:true, findWithNoReport:false, disableDates:false});
  // const { withItems, withOther, disableDates } = checkedState;
  let defaultSearchRange = 8; // This is a sketchy/useless variable
  // Selection not used yet
  const [selectedVideoCount, setSelectedVideoCount] = useState([]);
  const [searchStartDate, setSearchStartDate] = useState(Date());
  const [searchEndDate, setSearchEndDate] = useState(Date());
  const [searchDisabled, setSearchDisabled] = useState(false);
  
  const [currentSelectedParent, setCurrentSelectedParent] = useState(null);
  const [parentEmailForSearch, setParentEmailForSearch] = useState('');
  const [subjectIdForSearch, setSubjectIdForSearch] = useState('');

  useEffect(() =>{
    setSearchStartDate(new Date(tableSearchState.startDate));
    setSearchEndDate(new Date(tableSearchState.endDate));
    setCheckedState({findWithNoItems:tableSearchState.findWithNoItems, findWithNoReport:tableSearchState.findWithNoReport, disableDates:false});
    if(tableSearchState.needListRefresh){
      console.log('Need to get a new list');
    }
    console.log('include all dates:', checkedState.disableDates);
  }, [tableSearchState]);

  useEffect(()=>{
    if(searchEndDate === null || searchStartDate === null ||
      isNaN(searchStartDate) || isNaN(searchEndDate)){
      setSearchDisabled(true);
      return;
    }
    setSearchDisabled(false);
  },[searchStartDate,searchEndDate]);

  // Copied from AsmtAndUserControlComponent
  useEffect(()=>{
    console.log('The email;,', parentEmailForSearch);
    if(parentEmailForSearch === null){
      setCurrentSelectedParent(null);
      setSubjectIdForSearch(undefined);
      return;
    }

    const matchingParent = allUsers.find(user=>user.localEmail === parentEmailForSearch);
    console.log('The matching email ', matchingParent);
    if(matchingParent){
      
      setCurrentSelectedParent(matchingParent);
      if(matchingParent.subject){
        setSubjectIdForSearch(matchingParent.subject?.subjectId);
        console.log('subject id:', matchingParent.subject?.subjectId);
      }else{
        // parent has no subject adjust accordingly
        setSubjectIdForSearch('shouldnotmatchanything');
      }      
    }
  }, [parentEmailForSearch]);

  useEffect(()=>{
    if(subjectIdForSearch === null){
      setCurrentSelectedParent(null);
      setSubjectIdForSearch(undefined);
      return;
    }
    const matchingParent = allUsers.find(user=>user.subject?.subjectId === subjectIdForSearch);
    console.log('The parent for subject ', matchingParent);
    if(matchingParent){
      
      setCurrentSelectedParent(matchingParent);
      if(matchingParent.subject){
        setParentEmailForSearch(matchingParent.localEmail);
      }else{
        // parent has no subject adjust accordingly
        setParentEmailForSearch('noemail@found.here');
      }      
    }
  }, [subjectIdForSearch]);

  // This is the show button, which only sets a new filter, the table calls the actuall action
  const updateFilteredAsmtList = () => {
    const asmtSearchProps = {startDate:searchStartDate, endDate:searchEndDate, subjectIdForAsmt:subjectIdForSearch, allAssessments:[],
      ...checkedState};
    console.log('asmt table dispatch with search props:' + JSON.stringify(asmtSearchProps));
    asmtTableDispatch({type:AssessmentListActionTypes.SETSEARCH, ...asmtSearchProps});
    //adjustAvailableAsmts(AssessmentListActionTypes.UPDATE, asmtSearchProps, user.firebaseUser, asmtTableDispatch);
  };

  //these make the search start/end date inclusive
  const setSearchStartDateStart = (date) => {
    if(!isNaN(date) && date !== null) {
      date.setHours(0, 0, 0, 0);
    }    
    setSearchStartDate(date);
  };

  const setSearchEndDateEnd = (date) => {
    if(!isNaN(date) && date !== null) {
      date.setHours(23, 59, 59, 999);
    }    
    setSearchEndDate(date);
  };
  const handleChange = (event) => {
    setCheckedState({ ...checkedState, [event.target.name]: event.target.checked });
    console.log('include all dates:',event.target.checked);
  };

  return (
    <div>
      <Toolbar>
        <Grid
          align="left"
          container 
          direction="column"
          justify="left"
          xs={12}
          sm={12}
          spacing={2}
        >
          <Grid
            align="left"
            container
            item
            justify="left"
            md={12} 
            spacing={1}
            xs={12}
            sm={8}
          >
            <Grid
              item
              xs={6}
            >
              <KeyboardDatePicker
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                disabled={checkedState['disableDates']}
                format="MM/dd/yyyy"
                label="From"
                margin="normal"
                onChange={setSearchStartDateStart}
                value={searchStartDate} 
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <KeyboardDatePicker
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                disabled={checkedState['disableDates']}
                format="MM/dd/yyyy"
                label="To"
                margin="normal"
                onChange={setSearchEndDateEnd}
                value={searchEndDate}
              />
            </Grid>
            <Grid item xs={12} sm={6} justify="left">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedState.disableDates}
                    name="disableDates"
                    onChange={handleChange}
                  />
                }
                label="Include all dates"
              />
          
            </Grid>
            {user.authLevel > 0 ? (

              <Grid            
                item
                xs={12} sm={8}
              >
                <Autocomplete
                  autoHighlight
                  blurOnSelect
                  getOptionLabel={(option)=> (option && option.localEmail)?option.localEmail:'unknown'}
                  // { // functionally equivalent, gads all this short hand
                  //   return (option && option.localEmail)?option.localEmail:'unknown';
                  // }}
                  handleHomeEndKeys
                  id="parent-email-selector"
                  onChange={(event, newValue) => {
                    console.log('Selected parent:',newValue);
                    if(newValue && newValue.localEmail){
                      setParentEmailForSearch(newValue.localEmail);
                    }else{
                      setParentEmailForSearch(null);
                    }
                  }}
                  options={stableSort(allUsers,getSorting('asc','localEmail'))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Parent Email"
                      variant="outlined"
                    />
                  )}
                  selectOnFocus
                  style={{ width: 300 }}        
                  value={currentSelectedParent}
                />
              </Grid>): (<div></div>)}
            {user.authLevel > 0 ? (
              <Grid item xs={12} sm={8}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedState.findWithNoItems}
                        name="findWithNoItems"
                        onChange={handleChange}
                      />}
                    label="Assessments with no tags"
                  />
              
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedState.findWithNoReport}
                        name="findWithNoReport"
                        onChange={handleChange}
                      />}
                    label="Assessments with no reports"
                  />
                </FormGroup>
              </Grid>) : (<div></div>)}

          </Grid>

          <Grid
            alignItems="center"
            container 
            item
            md={4}
            spacing={10}
            xs={4}
          >            
            <Grid
              item
              md={12}
              xs={12}
            >
              <Button
                color="primary"
                disabled = {searchDisabled}
                onClick={updateFilteredAsmtList}
                size="small"
                variant="contained"
              >Show</Button>   
            </Grid>
          </Grid>

        </Grid>
      </Toolbar>
      <Divider my={6} />
      <Toolbar>
        <div>
          {selectedVideoIds.length > 0 ? (
            <Typography
              color="inherit"
              variant="subtitle1"
            >
              {selectedVideoCount} selected
            </Typography>
          ) : (
            <Typography
              id="tableTitle"
              variant="h6"
            >
            Assessments
            </Typography>
          )}
        </div>
        <Spacer />
        {selectedVideoCount > 0 ? (
          <Tooltip title="Label">
            <IconButton
              aria-label="Tag"
              onClick={handleTagVideo}
            >
              <LabelIcon />S
            </IconButton>
          </Tooltip>
        ):(<div />)}
        <Spacer />
        <div>
          {selectedVideoCount > 0 ? (
            <Tooltip title="Delete">
              <IconButton
                aria-label="Delete"
                // onClick={handleDeleteClickLocal}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Filter list">
              <IconButton aria-label="Filter list">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </Toolbar>
    </div>
  );
};

export default AssessmentTableToolbar;
