import React, { useEffect } from 'react';
import { getAssessmentsAndRelationsForTable, calculateScoreById, getEmmaUserWithFilter, getPercentileData } from '../../common/apiGetUtilities';
import { AssessmentListUIProps } from '../../common/entityUtilities';
import { AimsScoreSchemaProps, AssessmentSchemaProps, GenericScoreSchemaProps, InstrumentSchemaProps, ReportDataSchemaProps, ReportSchemaProps } from '../../common/mongoSchemas';
import { calculateAgeMonthDaysForAimsReport } from '../../common/dateTimeUtilities';
import { aimsScoreEmptySample, conclusionText } from '../../common/staticdata';
import { 
  ReportInfoContext,
  ReportInfoActionTypes,
  ReportUIInformation,
  ReportDbInformation,
  ReportInfoContextType
} from '../../context/ReportContext';
import { 
  AssessmentInfoActionTypes, 
  AssessmentInfoContext,
  indexOfLibraryName
} from '../../context/AssessmentInfoContext';
import { useUserState } from '../../context/UserContext';
import { AimsPercentileInfo } from './aimsPercentileInfo';

import { AssessorInfo } from './assessorInfo';
import { ConclusionInfo } from './conclusionInfo';
import { ParentInfo } from './parentInfo';
import { AimsScoreInfoTable } from './scoreInfoTable';
import { SubjectInfo } from './subjectInfo';
// const MyComponent : FunctionComponent<IUser> = (props) => (
//https://dev.to/umeshiscreative/use-multiple-interfaces-types-as-props-in-react-with-typescript-2bkg
// For javascript pdf generation? https://labelmake.jp/javascript-pdf-generator-library/
export type ReportComponentProps = {
  asmtInformation?:AssessmentListUIProps,
  //Items needed for scoring, location needs to be refactored.
  availableInstruments?:InstrumentSchemaProps[],
  selectedInstrumentIndex?:number,
  // fullReportToSave:ReportSchemaProps,
  // hacky way to show somebody else report info
  reportForUserId:string

  //current logged in user is also needed but grabbed from context
  // scored is derived from that user
  //// grab this already via api on init
  // asmtSubjectHack?:SubjectSchemaProps
}
export const ReportComponent= (props:ReportComponentProps):JSX.Element=>{
  const   {asmtInformation, availableInstruments,selectedInstrumentIndex,
    reportForUserId}  = props;
  const loggedInUser = useUserState();
  const {firebaseUser} = loggedInUser;
  // bad things happen if a reducer is doubled up....
  // const [reportInfoState, reportInfoDispatch] = useReducer(reportInfoReducer, defaultReportInfo);
  const {reportInfoState, reportInfoDispatch} = React.useContext(ReportInfoContext);


  const {assessmentInfoState} = React.useContext(AssessmentInfoContext);
  const {activeInstrumentName, activeScoringTypeName, instrumentLibraries, activeAsmtId, 
    activeUserId, hackScoreUpdate} = assessmentInfoState;
  
  // Update calls for selected instrumentindex and asmtInformation
  useEffect(() => {
    const fetchData = async () => {

      // Some hacky house keeping
      let indexToUse = 0;
      if(selectedInstrumentIndex !== undefined){
        indexToUse = selectedInstrumentIndex;
      }
      if(availableInstruments && asmtInformation && loggedInUser.emmauserId){
        //omg design of this could have been cleaner(dlp)
        const temp = availableInstruments[indexToUse]?.instrumentId;
        let instrumentId = '0';
        if(temp){
          instrumentId = temp;
        }
        // when to update, that is the questions
        const previousReport = await findReportsForAssessment(asmtInformation.assessmentId);
        const loadPrevious = false;
        if(previousReport && loadPrevious){
          console.log('There are previous reports what do we do?');
          // console.log('Loading previous report', previousReport);
          // const {aimsScore, ...restReport} = previousReport.reportData;
          // reportInfoDispatch({type:ReportInfoActionTypes.LOAD, 
          //   scoreInformation:aimsScore as AimsScoreSchemaProps, 
          //   reportInformation:restReport});  
        }else{
          console.log('Creating a new report');
          // the return is redundent since the results are put into the report context.
          const newReportContext = await createNewReportContextForAssessment(asmtInformation.assessmentId,
            reportForUserId);
          // console.log('Attemptin new report', newReportInfo);
          reportInfoDispatch({type:ReportInfoActionTypes.LOAD, 
            ...newReportContext});
          // to deal with loading the values need to be returned
          const scoreInfo = await updateAimsItemScore(instrumentId, asmtInformation.assessmentId, 
            reportForUserId);//loggedInUser.emmauserId);
          // console.log('Attemptin', scoreInfo);
          reportInfoDispatch({type:ReportInfoActionTypes.UPDATE_SCORE, scoreInformation:scoreInfo});
          // Find and fill in report conclusion (score)
          if(newReportContext && newReportContext.reportInformation){
            const {subjectAgeMonthDaysForAsmt, subjectCorrectedAgeMonthDaysForAsmt} = newReportContext.reportInformation;
            let estimatedMonths = 0;
            const score = scoreInfo?.total.score as number;
            if(subjectAgeMonthDaysForAsmt){
              const split = subjectAgeMonthDaysForAsmt.split(':',2);
              if(split){
                // Create a function for true month calculation please
                estimatedMonths = +split[0]+(+split[1]/30);             
              }
            }
            // if corrected age exists use that ... figure out details of corrected later
            if(subjectCorrectedAgeMonthDaysForAsmt){
              const split = subjectCorrectedAgeMonthDaysForAsmt.split(':',2);
              if(split){
                // Create a function for true month calculation please
                estimatedMonths = +split[0]+(+split[1]/30);             
              }
            }
            updateAIMSPercentileScoreValues(estimatedMonths, score);
          }          
        } 
      }
      return;
    };
    // console.log('Attempting to refresh the report information');
    fetchData();
  }, [asmtInformation, selectedInstrumentIndex]);

  /****Please move this, it is kind of a utility, but that is getting way to big and messy */
  
  // TODO: tidy this up later, ie no need to always call for reports and subject
  const findAssessmentWithSubjectAndReports = async(asmtId:string):Promise<AssessmentSchemaProps[]> =>{
    const fullFilter:Record<string, unknown> = {};

    fullFilter.where = { assessmentId:asmtId};
    fullFilter.include = [{relation:'subject'},{relation:'reports'}];
    const rawAsmtData = await getAssessmentsAndRelationsForTable(firebaseUser, fullFilter);
    return rawAsmtData;
  };
  
  const findReportsForAssessment = async(asmtId:string):Promise<ReportSchemaProps[] |undefined> =>{
  // going directly to the report controller might be better?
    const rawAsmtData = await findAssessmentWithSubjectAndReports(asmtId);
    // there should never be more than one assessment wiht the same id
    if(!rawAsmtData || rawAsmtData.length != 1) return undefined;//throw error?
    const singleAsmt = rawAsmtData[0];

    const {reports}= singleAsmt;
    if(reports && reports.length > 0){
      return reports;
    }
    return undefined;
  };

  // this does waaaay too much and is breaking a lot of good (even decent coding practices)
  const createNewReportContextForAssessment = async(asmtId:string, reviewForId:string)
  :Promise<ReportInfoContextType|undefined> =>{
    
    console.log('The id to find', reviewForId);

    const rawAsmtData = await findAssessmentWithSubjectAndReports(asmtId);
    // assume that the relation calls are connected correctly and the only one asmt is returned
    // very similar set of calls as createAssessmentListForUI from utilities.
    if(!rawAsmtData || rawAsmtData.length != 1) return undefined;//throw error?
    const singleAsmt = rawAsmtData[0];

    const {reports}= singleAsmt;
    console.log('There is an existing report', reports);

    // Setup the basics for a new report. Assume we have dealt with previous
    // report stuff earlier.
    const createDate = new Date();

    const reportDbInfo = {
      creationDate:createDate,
      assessmentId:singleAsmt.assessmentId!,//singleAsmt.assessmentId? ?? 'blah';
      emmauserId:reviewForId//loggedInUser.emmauserId!;
    } as ReportDbInformation;

    const reportData:ReportDataSchemaProps={
      reportTypeName:'Initial report',
      reportCreationDate: createDate
    };

    const {subject} = singleAsmt;
    // From the subject get parent
    if(subject){
    //emmauserid is all lowercase in api, oops
      const {emmauserid} = subject;
      // Things where called with the proper relations
      // get what you need and move on
      if(emmauserid){
        reportDbInfo.subjectId = subject.subjectId!;
        reportDbInfo.subjectParentId = subject.emmauserid!;

        const filter = {'where':{'emmauserId':emmauserid } };
        const ownerData = await getEmmaUserWithFilter(firebaseUser, filter);
        // deep in the bowels of for loops we should have the data we need
        reportData.assessmentDate = singleAsmt.date;
        //hacky way of setting up for a review
        if(loggedInUser.emmauserId === reviewForId){
          reportData.reportCreatorName = `${loggedInUser.firstname} ${loggedInUser.lastname}`;
          reportData.reportCreatorEmail = loggedInUser.localEmail;
          reportData.reportCreatorSignature = loggedInUser.signature;
        }else{
          reportData.reportCreatorName = `Reviewing for ${reviewForId}`;
          reportData.reportCreatorEmail = 'No email';
        }

        reportData.asmtSubjectName = subject.name;
        reportData.asmtSubjectBirthDate = subject.dob;
        reportData.subjectGender = subject.gender;
        const uiInfo:ReportUIInformation = {gestAgeDays:subject.gestationAgeDays, dueDate:subject.duedate,
          subjectNotes:subject.additionalProp1?.notes as string??''};
        // console.log('The ui info', uiInfo);
        reportInfoDispatch({type:ReportInfoActionTypes.UPDATE_UIINFO, 
          uiInformation:uiInfo});
        // use the above dates to calculate corrected age
        // how do we want this stored?
        const calcedAges = calculateAgeMonthDaysForAimsReport(
          new Date(singleAsmt.date), new Date(subject.dob), subject.duedate, subject.gestationAgeDays);
        if(calcedAges && calcedAges.length >0){
          const regAge = calcedAges[0];
          reportData.subjectAgeMonthDaysForAsmt = `${regAge.months}:${regAge.leftOverDays}`;
          if(calcedAges.length>1){
            const corAge = calcedAges[1];
            reportData.subjectCorrectedAgeMonthDaysForAsmt = `${corAge.months}:${corAge.leftOverDays}`;
          }    
        }
       
        reportData.parentEmail = ownerData?.email;
        reportData.parentName = `${ownerData?.firstname} ${ownerData?.lastname}`;
        const initialReportContext:ReportInfoContextType = {
          reportInformation:reportData,
          scoreInformation:aimsScoreEmptySample,
          uiInformation:uiInfo,
          dbInformation:reportDbInfo,
          isReportPreviewVisible:false
        };
        return initialReportContext;
      }
    }
  };

  const updateAimsItemScore = async(instrumentId:string, asmtId:string, taggedById:string)
  :Promise<AimsScoreSchemaProps|undefined> => {
    const _scoreData = await calculateScoreById(loggedInUser.firebaseUser, 
      taggedById, asmtId, instrumentId, activeScoringTypeName);
    if('isErrorType' in _scoreData){
    //do something with the error
      return undefined;
    }else{

      //note that the aimscoreschema also needs the max subscale reached in order
      // to make the report useful.
      // icky poooo!
      const castScore = (_scoreData as unknown) as GenericScoreSchemaProps;
      // const scoreTypes = ['totalScore', 'prone', 'sitting', 'supine', 'standing'];
      //only change items that are different
      const {scoreInformation} = reportInfoState;
      // ug so ugly
      if(!scoreInformation){
        // Be aware the the notes get reset each time the score is change.
        // this seems like safe behaviour, but it is unknown if this is desired
        // behaviour (or to what extent it is desired)

        // const schemaScore:AimsScoreSchemaProps = {
        //   total:{score:castScore.totalScore, notes:''},
        //   prone:{score:castScore.subscaleScores?.prone, notes:''},
        //   sitting:{score:castScore.subscaleScores?.sitting, notes:''},
        //   supine:{score:castScore.subscaleScores?.supine, notes:''},
        //   standing:{score:castScore.subscaleScores?.standing, notes:''},
        //   percentile:{information:'No information'}
        // };
        return aimsScoreEmptySample;
      } else{

        // score information already exists, update it. Because it is an object
        //dlp wanted to be cute and use javascript map functions etc but for now 
        // brute force in the modifications
        const {aimsScore} = castScore;
        if(!aimsScore) return aimsScoreEmptySample;
        const {prone, sitting, standing, supine} = aimsScore as Record<string, unknown>;

        const schemaScore:AimsScoreSchemaProps = {
          total:{score:castScore.totalScore, notes:''},
          prone:{
            score:(prone as Record<string, unknown>).score, 
            notes:scoreInformation.prone.notes,
            maxItemName:(prone as Record<string, unknown>).maxItemName, 
          },
          supine:{
            score:(supine as Record<string, unknown>).score, 
            notes:scoreInformation.supine.notes,
            maxItemName:(supine as Record<string, unknown>).maxItemName, 
          },
          sitting:{
            score:(sitting as Record<string, unknown>).score, 
            notes:scoreInformation.sitting.notes,
            maxItemName:(sitting as Record<string, unknown>).maxItemName, 
          },
          standing:{
            score:(standing as Record<string, unknown>).score, 
            notes:scoreInformation.standing.notes,
            maxItemName:(standing as Record<string, unknown>).maxItemName, 
          },
          percentile:{information:'No information'}
        };
        if((prone as Record<string, unknown>).score !== scoreInformation.prone.score){
          schemaScore.prone.notes = '';
        }
        if((sitting as Record<string, unknown>).score  !== scoreInformation.sitting.score){
          schemaScore.sitting.notes = '';
        }
        if((supine as Record<string, unknown>).score !== scoreInformation.supine.score){
          schemaScore.supine.notes = '';
        }
        if((standing as Record<string, unknown>).score  !== scoreInformation.standing.score){
          schemaScore.standing.notes = '';
        }
        // reportInfoDispatch({type:ReportInfoActionTypes.UPDATE_SCORE, scoreInformation:schemaScore});

        return schemaScore;
      }
    }
  };

  const updateAIMSPercentileScoreValues = async(ageMonths:number, aimsScore:number|undefined) => {
    if(!aimsScore) return;
    // do we want to calc the score again or use it from another source...
    const percResult = await getPercentileData(ageMonths, aimsScore);
    // fake type check, blargh!!! bad dlp
    if('isErrorType' in percResult){
      //do something with the error
    }else{
      const _percentileData = (percResult as unknown) as Record<string, unknown>;
      // things are getting messy with the ui's involved
      const infoProp:Record<string,unknown> = {information:_percentileData['message']};

      const belowKey = 'below';
      const aboveKey = 'above';
      let conclusionSummary = 'Unknown result value';
      // the percentile data will always have above/below

      // will be 0 if the value is ''
      const aboveValue = +(_percentileData[aboveKey] as string);
      //avoid an incorrect 0 belowvalue
      const belowValue = aboveValue>=90?100:+(_percentileData[belowKey] as string);
      
      infoProp[belowKey] = belowValue;
      infoProp[aboveKey] = aboveValue;
      if(belowValue <= 10){
        conclusionSummary = conclusionText[1].message;
      }else{
        conclusionSummary = conclusionText[0].message;
      }
      const percInfo = {percentile:infoProp};
      
      reportInfoDispatch({type:ReportInfoActionTypes.UPDATE_INFO, reportInformation:{reportConclusion:conclusionSummary}}); 
      reportInfoDispatch({type:ReportInfoActionTypes.UPDATE_SCORE, scoreInformation:percInfo});
    }
  };
  
  return (
    <div>
      <SubjectInfo/>
      <ParentInfo/>
      <AssessorInfo/>
      <AimsScoreInfoTable/>
      <ConclusionInfo/>
      <AimsPercentileInfo
        recalculatePercentiles={updateAIMSPercentileScoreValues}
      />
    </div>
  );
};
