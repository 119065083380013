import * as Entities from './entityUtilities';
import * as Schemas from './mongoSchemas';
import { ReportSchemaProps, ReportDataSchemaProps } from './mongoSchemas';
///////////// Raw db data from demo
export const videoData = [
  {_id:'5ede925d600d180003b607f2',timestamp:'2020-03-25T03:23:37Z',duration:15,url:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/EM0001/1572888180765.mp4?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=GryeHN2GDaTqqtjaqxbVEx%2BgqlkARiXycFo%2FmuWULSat6nDiD11e2eqW8FSE9f9OdGf%2Btry7V%2F36emPLbHWhxVd5dUJe24aBnp85SD5gMgDFu6Cd4gZWWwqEaAsvQp3kg400Wo%2B6CYScSulzpkIK%2FgUcu7XiHKKVTz1v54SWU2aoBwcifYw2D9HnjjXCnxyfL4CiXJuAvmwVuVgWTmzw40R0eAXkSftlBvns0imop7ZNLYuDvPuAIL4q5enGRrsxFzGAb2KvP0dpb1W0uYYgr%2FO1caLwiaKNPMHP2jyiUm0WY%2BahJ3g%2F3V1piliLmtfFveoNcnm5KN3UPBP31dRwwg%3D%3D',thumbnailurl:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/EM0001/1572888180765.jpg?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=MWkjVNVc826tb6McVG177XpnhOITsI2rzSCX616mT%2B%2B4TIVRu%2BZcYx7M2vROtw9QNxr1%2FuWqSsxFZlmtu2NHvxfY%2BBOe67wkaKA%2ByU69bAOKc7%2BlNbkBXghSMit%2FKdQp9Q4cGn4f6F6iTqcvzsnZrAuJqRjYks0IuBDplqyEjY9AcxMwaOPsLdslVuP7IUbQoKJ4y8xvanbOYyvV8h%2BklrK4zOADiEGPbsnIN0m2DJpikX7JSKnzcklU%2Bd%2FILY4NxWZBkZs8%2BZlGKEQD4uelrktcXy0oAiZyn%2FZsdOUaqmjM%2Bvs7CuQ8eUCKxAhewovOZniBueuWxVX0ogl1aoVcAA%3D%3D',bucketName:'scoreit-demo.appspot.com',thumbnailStoragePath:'__videos/EM0001/1572888180765.jpg',videoStoragePath:'__videos/EM0001/1572888180765.mp4',deviceId:'EM0001'},
  {_id:'5ede925d600d180003b607f3',timestamp:'2020-03-25T03:23:37Z',duration:17,url:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/EM0002/MIMM5021-3750-to-4260.mp4?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=Xm36VXU%2BtEIxUUG5mbLk7BVFemXVDw4Mi7JrvTvlzUDvd7IDPF8ppFx2%2FWSzL05Q2HLA%2F9QTnf79%2FrETr9YCk7xDgVCR%2FR8MyuPD%2F64fB4t9CBaKoJ4ulkZOKZFA6wuE1YKjwHI3UfkVlEq1wJphhiAZgQETWkyiMpU%2BzhU61Tux6V%2FKhb%2BV82SFUT3wefLRwf0yILYDov6UxyNIDDMzemiwv4Bgt1NPcUqnQ6u91y1tnLCDPv9w9k9DXcE6UQ0o72%2FCbyLdL9SFBVoNDSB3u57Kd%2BHX1B0CRCesQm0%2BYXo8V3OR5sSCuaFMFWJQA8lNt%2FoPaWGTvr015uAtJc97Yg%3D%3D',thumbnailurl:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/EM0002/MIMM5021-3750-to-4260.jpg?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=kJ5ayY8ywaUh2rzsdZ55ojs1GPxI2BA9XBAhX21fE%2Bo7VnwscUQSXJ6SUlyZ%2FgXaCgtKEPQgFArCObtpQWf9UeRLFrKkLSriTMwqgGTPqnaj9RhTokktqfthqvGd47x7rFTHPheeZOfenO5Yt3EaX%2BaCUYwnHb%2B1Y%2BazKV3Otlc6NtQCU3SPP5zya3R2cIJUXR8UBcMKxDKeifd6Xy2blla3Iodx943Abc6Hd%2BE5A2j7vSvnyhWRXcRLsoAInTXDjf%2FPpqE%2FZt%2Bb9qK2dtGhX1t%2Fd9jr6dzS5JAKB9ja772AR8hT9JFw0OKkCYbZuTk7KF1LfAuJu8s3y5rI%2FY3UTA%3D%3D',bucketName:'scoreit-demo.appspot.com',thumbnailStoragePath:'__videos/EM0002/MIMM5021-3750-to-4260.jpg',videoStoragePath:'__videos/EM0002/MIMM5021-3750-to-4260.mp4',deviceId:'EM0002'},
  {_id:'5ede925d600d180003b607f4',timestamp:'2020-03-25T03:23:37Z',duration:6,url:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/EM0002/MIMM5021-100-to-300.mp4?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=m9nxT3tmyHeUulc6cW%2BjfMUXbkPMf4MPvDX5VR%2BkRiRCO6MzMCj9iawmtOG8Awj7WzpvXnM8qfCgcbhm%2FDI5CjSs9mbnvyU4sGG7Z%2FfqIrE8wqDywkeYFNNwhI%2FMQ08dZ7BzVLSEZkZbHOWdMd9tvM0RSZ49t1hwtgb0RJJ6xgGD0aIc7c91I72Pt5tPFOOMvthtOhogyWuI8o6E%2BIR76wcC8Zr2P8do%2BRYvFZXRd%2B%2B6hwUnb4jlSQcRb9JGMTVFnPUHb%2F4GwntSTu0S22p57mdfHCO%2BM7b54%2BF9Z8Mx%2B9objff%2BHF2zo8jMXZ6VysCYtlLfau3dgdGHdgauPt8tuA%3D%3D',thumbnailurl:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/EM0002/MIMM5021-100-to-300.jpg?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=H1L4e44yruqC3SFZlZdA559L52mzO6KlWUvSrCre6iFFbP9mcIBUTGyHuZkpgO2R5Nwp8PsSSAHu%2FOiFGr3BgZAhZoBA%2Fp4H3SDhlP8EQdfC1N8pjdlypx%2FU070cv4YegA%2Br8IeuSloQ5m5vTqJOK6quINnUM4KvuicOJWnoo%2F1CeQ4gl0VLIj2QI3X%2FnGQjS5bXxVCbqiOAaBGSrB9NlpYRVclx38bmsvxg8PaDWF0yoGsde1SofzAC6%2BDyUsQZPcIQQpT3nQIjfjEJ7OW9l9L335L%2FmfsuE%2FFG%2F6eh3tuo%2BhOX0banJKEbZeIKM6U7d0OqJgiZcZD4x4A5LhijVg%3D%3D',bucketName:'scoreit-demo.appspot.com',thumbnailStoragePath:'__videos/EM0002/MIMM5021-100-to-300.jpg',videoStoragePath:'__videos/EM0002/MIMM5021-100-to-300.mp4',deviceId:'EM0002'},
  {_id:'5ede925e600d180003b607f5',timestamp:'2020-03-25T03:23:37Z',duration:16,url:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/EM0001/1572363813042.mp4?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=rlQ3y8QdlIu1ms%2FjWLrzTqXz40Try8RiZoOWiLb2jlRMSIgQpaLNmePYZ9elmCJ%2BU9VEZ6LwVVKQg%2B7xpkAx1mRu5trIN4o3GjAQ3ts3q7gcF369o53qDzjR9MC5b8dE0cr%2FhesnmxU24jhosQPiE32qD7fR3N8yF5dhwcv4WmH4cu79Dgd7iVQlLybEkXlYxuRTdAQGIV0QcE8w%2FadSmGgZqBlDT1vvfsjgeHe8AHow0KHjsFA%2F59qn6nSvFmKLtBqzq42mL3IMErM4TvLAJWy%2FXnLUt7LVfD8CeTwEcm9Rj3nzqCZUGL%2BSMqVUT9l6d1V71HQdBH8%2F64zzYv7cIw%3D%3D',thumbnailurl:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/EM0001/1572363813042.jpg?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=uNAPrKcAQgWvidPzoomWDBjBdmqPW2IuTj4zpBu%2Bxpf%2FYYyw%2FKbMqjnpXgxR9UKMHTF0xKkaR5GlETskFnhicHFDIbOXZ4SsmkCBL6E6en2Sx%2FWW6nnuUKZoHdpK7%2BE0t5IvsZKnYo5lLwXbjHgqMOKOPs%2B4%2F1JTeCrord%2FaQ9i%2FbGdPOyhShWMTsji9r7eQ5bnQ%2B0arqcM4TXKA4OW8TObscgWUwWp6uUV8OTau6VyH%2FF9EHZMkzuO%2FADOm7yL4IQwSsBXx%2F56dzLXYToA3%2F%2B5cjaikmf2LNISAf2Z9IYSQKUgWnVn0Kvy4KcoMJApsufE9s0U%2FGCx8nDp%2BnVszEw%3D%3D',bucketName:'scoreit-demo.appspot.com',thumbnailStoragePath:'__videos/EM0001/1572363813042.jpg',videoStoragePath:'__videos/EM0001/1572363813042.mp4',deviceId:'EM0001'},
  {_id:'5ede925e600d180003b607f6',timestamp:'2020-03-25T03:23:37Z',duration:3,url:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/EM0002/MIMM5021-20400-to-20500.mp4?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=ZOrP2ZL0rhM7V4ntimO%2BMrTGmwj4BQ9Q6Cd%2BE56GQ1flFWIgTELgg3IWuV2cpbXs8gzpojWoxrgCtLSNIywHfv90qgIEEW1EqDV58Racz1AJfT5D1IiOhqQ503RFR8AR%2F6GeWUabQNObZ%2FMZE4Kn8P0KTza55jiKcYD86H5uLKaXDNSkYW5Irc5RKAi5BfQSM3oL5961BjGO6SxQxcHVmOVNj6uoQ6ymnH3c5FYSndpxFGxKIoh5ByL9GBZWbuTuEr2Ye79VXGJhlGGMlCpMw4jDLdy3%2F2tFLGg8pJaW9ZE%2FZSU%2B8YbAJShdUqAZDZ77oReNEzB4cSgF5LiyicYviQ%3D%3D',thumbnailurl:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/EM0002/MIMM5021-20400-to-20500.jpg?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=P27FC8OkP86XivT8yOdtlQJXIX10wCzQpvImFXSXTEr9DdqTM8TRCVEAeYR1d%2FlaVaTs6v6TcLXjyJHWrujveUIRTGAGvH6PDAdH%2F5QLbnSBUurHHzruKJr52c7N3lUpk4Df%2Bu%2FGChuTAu2ShNeyhAXymHSzpl2NxmY1%2BBHnr4OFAsZSMA%2F%2FUcGH%2BMJGqGgP4KYRHFYOzBcTFN5%2BuwuRZe0dA%2BQbU9Qd9UYkcNxorTYiN9mvDBOone0O4CQhZHyG4AtL2SdLCEyLUmyhxzm1Ns97GV%2BsJidATGNwsy%2FA8DXs3YkHfobg9J51RJyUJdbO6FFLkfWwVkNHm6zE26UMJQ%3D%3D',bucketName:'scoreit-demo.appspot.com',thumbnailStoragePath:'__videos/EM0002/MIMM5021-20400-to-20500.jpg',videoStoragePath:'__videos/EM0002/MIMM5021-20400-to-20500.mp4',deviceId:'EM0002'},
  {_id:'5ede925e600d180003b607fb',timestamp:'2019-11-16T16:51:35.806Z',duration:1945,url:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/57K28BSU82047/1573923095806.mp4?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=WyCbA2OUwOenQW47OuXhpgt2mc2nS0wrXwrPh%2BQvIQNDLO6hawNpQwBZFbnDY8buxcxBd6QryQ1zUaCfQIK67QOzep%2FJdkv0d2p5k%2BKfOWf36X9d21wu03XR7iauVch9ZrLSAgB5BkOH85rEOEUYkHxlfLzfzj%2BrrNDLNSHs%2BNPoY3bu%2FQWrOxvaqNXcU%2B8H9GRxNCaQDLMkQWdrciXakNAwy%2FdcuVk20GSgwGU4VOoVs6eIkPKOcFiQ%2BEcIL1D1jqeAiPumCL869XbASlCcRt%2BS0CKSfehqpP5tDeZohFv2z7emkzrE30MEyhRsB18aiDj%2F8Vbhz5q0d%2FUC2Mtz5A%3D%3D',thumbnailurl:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/57K28BSU82047/1573923095806_thumb.jpg?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=Oc0y3luTSsyb3vVsaQvwNOLuvjvrt6lffhhIYiFlvlSuYJSq7dZ6bzTd6ah0qRJalHwApZ7x10zzkMXNJDOPJoHFO4bP91HQdO8raNYnUkd0eq19PdHtZ62vyT1jUgWU8MkhpvjxTg2imdVo6uPqf%2FTM3Ix9nlAJ9O78Kcggt8yABStUnwL7trLov%2Fh8BLlbL%2FwZRI3yrt7rR%2F%2BUpnrWyQ3bQbPoNaQMdrBeONoXMn0mptDes%2BQmcDfyzVb%2F2JqJ14xCFlMjCdja4wQFMbybB8sL19mn3AA7GEeJ5HlDWW9jwHxlnta80hqaVVXarYf%2BnU0gn8MWg1vKZWwg856FoA%3D%3D',bucketName:'scoreit-demo.appspot.com',thumbnailStoragePath:'__videos/57K28BSU82047/1573923095806_thumb.jpg',videoStoragePath:'__videos/57K28BSU82047/1573923095806.mp4',deviceId:'57K28BSU82047'},
  {_id:'5ede925f600d180003b607fd',timestamp:'2019-11-16T17:24:04.682Z',duration:14,url:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/57K28BSU82047/1573925044682.mp4?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=pB7ZcFEy91T%2BOnAMMgr6f8T8D40GWT4eY9eFg33lYCd3eniYWG7LIgfB3A7OHNRtEqHxDKvRqj6I%2BI8cNtlRh7zcFc0Gw%2F4NPApnAsLHaC0Eo4BDmId9DrRU%2FeXhl3mnO0RZ0dEamEe%2BniSq8Vr5FFjYVxUm87WvGMb%2F4zG2ru%2FXNPkTac32jHAAKh6HTJEeETK0esgNQQIwTEeupt8u5PJ3%2BqReRumxUI3eYCCGDnzL6fRR2Kink6V6PUqtdnDiHFm%2BrD7vRuuphGavxf2A2lTtNP%2Brrb1Ab%2Fno19K%2FrBND73k%2FPzkQDHUOvNonDDI88o0K%2FZNNPofwpDLcCWSlKg%3D%3D',thumbnailurl:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/57K28BSU82047/1573925044682_thumb.jpg?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=tB62OnY1bWWYCtL1xK2njyrLPVSnhBpDIWXm2HRFe2154TLDb%2FadCeZo1ERTqPBXTcu5j1NxgFEIgcwvJb3yLFK%2BJeDLRZLq0I5bZD97JcXCjp99P7%2FDwzM8H9EYRtyE6zMNjH8ioW5%2F2n07Tgav8Rj%2BOu%2F7S1Q2etUwh8HT1%2FqpAQ6IeMRA90jjdNubTDW2b7PoAE6UiFL4%2BLYuxpQjr3uoEXst1NksrfgEQeSqxMu77CQwoQf1TpNKsDPHgkRIzUjNh1eKKSkW2Bo2NkMBTCHI0sgUn6AI%2FZ6aUdSH5g1N117DzivOb6P5hQYF4W4guVCO9CoJDNmaC%2F7t413fIA%3D%3D',bucketName:'scoreit-demo.appspot.com',thumbnailStoragePath:'__videos/57K28BSU82047/1573925044682_thumb.jpg',videoStoragePath:'__videos/57K28BSU82047/1573925044682.mp4',deviceId:'57K28BSU82047'},
  {_id:'5ede9260600d180003b607fe',timestamp:'2019-11-16T17:24:38.283Z',duration:38,url:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/57K28BSU82047/1573925078283.mp4?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=vSOl6vhgAmRAvfp55Mrw%2FeMW79ZgWmKU8ZrWspfyc9voK9PKtuvTx3sGkowUFsX0zRqLdwSyEWH8hCr4twgKNWlGbLIApEBJ5POUdcimtrjVDr0bZwRMuc56vPdtNotwVRIEcK6IGkouTEM1Ti0bawtdO6oZWPiF6aBQKrQA6aWEVglaf8ouuiBtcE7okJXAVdCQC5cg70AGb1YNsljJu%2BIAwImxHOceVcuF3zDPPSkgIYUic3TwBJnDnNAYz4IVtsdUFiyi2C6ChxYVv6TnShW7P8etDC5Ck8sb%2FfAjVYmrKhpsID4JjhRM8RV1M%2BKD9VsATn7cASJlsl8EB6rsgg%3D%3D',thumbnailurl:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/57K28BSU82047/1573925078283_thumb.jpg?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=EdjSQVTWyw1qAIamPdvtJxrAnF7vYO2ssTtwOzhtnzYdB8LKFOH0I76w7Q7pv5lrNmip1MyGeuf3d%2FJ3RMkyIQ7eLmc1%2FDGcFXyxJsqXbFHNaCYn5LawKsyH%2BLL3KCm%2By4iWv1AuxIFiUQXOg2ITtnZo9PgKJA27EcEokdJPtPCqHbeEm%2BK9Q1UHWqoeqIq19RGEeqRC4xHBaGbKXxkfvR1jBhiDW4597mU%2Bm4aoqzSCc8VY2NG5QSEle4nvbB58HA7HILnZRh%2BcYqmwVtToRVDoZV4bvBpLL1LmAAL2cnt9LyGKL1CUfcyKu2cGEn0RRnnnOICR1W0%2FCIAysJmF4A%3D%3D',bucketName:'scoreit-demo.appspot.com',thumbnailStoragePath:'__videos/57K28BSU82047/1573925078283_thumb.jpg',videoStoragePath:'__videos/57K28BSU82047/1573925078283.mp4',deviceId:'57K28BSU82047'},
  {_id:'5ede9260600d180003b607ff',timestamp:'2019-11-16T17:25:29.135Z',duration:314,url:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/57K28BSU82047/1573925129135.mp4?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=MPTX3RyUTYxpc8Icg6gPFcJh9Dg17A0snyjXSRyIouw6e2coin0t2OAdtOkYfeq0RB%2FND5ZIiC9G3YZcCsXh4H3tZudM%2Fjv3Yuf9xcNSWHsMyu0VP8rz3RZFcKCp5OLV6RhA6S%2B9VtALFBc4H1Knl636qZw7MBpkMF6JjyiciN9W7AFvA2w4ONmWNCYZEg3RytJg3DegRxER2Y7BBjMgHxdX%2BSpwi4Rb3q77LIkH%2BBLfzKdhDKQHfwrKBvi2Yg0U7jU7oByO6Hhd8A7F%2B1hz43RYQbgq3GkRs5vMN2wrToXf5U7h55M1pG2k4TmwZ5R1MOrzFndl0s7m%2FWiYJwqvyQ%3D%3D',thumbnailurl:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/57K28BSU82047/1573925129135_thumb.jpg?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=EEgkTrlrz0EVgF0hlZk3se6ti7zwM3XnWh18aTGe6GbSKpDunRfQbAgDqj9gpTu8AoaLcjaQo0eW%2Bz4UTNRm9AYePpJh22s7FqojIeMK7GHuN8Tzk1XqCkGe1ypt5mjAFlBoVueVdhNNtvizRa9id63DluOIrLsJGKTF6SiryKiQmczEIlJekTuDqjZ%2FbZ%2Bo6XOC5p1cIRPjbHJcTa20UOePWVj0I9a0U4cMycFYe1hk1limeT%2B5dcnfDTW85ghHpXx7gL4JEthy4giWOCPzZpmAuJ770sLSL6PIDPwBSEG8ldd%2Ba1%2BbPsSxetHubno13fZq7Ws%2B2dt8ZMBSSVKBwQ%3D%3D',bucketName:'scoreit-demo.appspot.com',thumbnailStoragePath:'__videos/57K28BSU82047/1573925129135_thumb.jpg',videoStoragePath:'__videos/57K28BSU82047/1573925129135.mp4',deviceId:'57K28BSU82047'},
  {_id:'5ede9261600d180003b60800',timestamp:'2019-11-16T17:30:43.02Z',duration:14,url:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/57K28BSU82047/1573925443020.mp4?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=ilOGX5zvb69a%2FRE417dwoXCfsbQ0eMKiUPvu3x89M8XSVu%2BoVUXqqIljWVLNt772BiQ09KCazgnien4xZhY6UQXkl%2BDWLHqB%2FEldz7%2FF7%2BMPnjtl4nLAfRXyJEofF8G72WuBBRorh54ZSTXJLiVTAr4FU%2BbqYSyReYSQ6GQaqzLPOCEqXTRGIiCcKEgNxVXhjc0y9D9xqjmOnytFHj72PA1CYYuxMxC1%2Fo4%2FuuhvwenDLQzjU1u6nJcAXFbrQOoxe4UgR1I30Vq7bzIIyhNvXVH%2FnvzzpRSme%2FS354JX6N7zVL8bbj%2BHnw4%2FfFpwgME%2FvfDSx7dvToak0DexeHrlHg%3D%3D',thumbnailurl:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/57K28BSU82047/1573925443020_thumb.jpg?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=WG7aKch59xcNsoa98%2BO1Ad8f9oMlemS1MI8xgYBvjDRDDqxFu%2BCLpTucj7nNYwBD%2BIzmW40%2B%2BPvO8KGlT153XUOSA1ILagjb4su1Q4SfBNUfTUlDRYlaI8dvknMfAEq%2BRd1qOueY5m7o8hrPVku%2F2K%2FVwxULxdb%2FIy7BPzKs1ba5I8T6nAgFriwOOu6jgPyhazsCJhjMpKNJGhiOiyEC6%2Fzwg9aV9YAvxJC2MWH6bfM8ukdeAbcLaKnDp9PwL4Tm4G%2FGi%2BwEYDFxFB3T2Vsm0uWKR%2Fu4oDh4Kk%2Bo8AMFXLclKf2Bppg%2FVpqnzwZuWch45sujJ%2Bw4zi5g7L3Cp3ejcg%3D%3D',bucketName:'scoreit-demo.appspot.com',thumbnailStoragePath:'__videos/57K28BSU82047/1573925443020_thumb.jpg',videoStoragePath:'__videos/57K28BSU82047/1573925443020.mp4',deviceId:'57K28BSU82047'},
  {_id:'5ede9262600d180003b60801',timestamp:'2019-11-16T17:32:06.635Z',duration:14,url:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/57K28BSU82047/1573925526635.mp4?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=KDGjneUMhsBrcXGaHRb7WBVSJqcThu4Lr%2BIl5ekq7e34DkpYNLOg%2BcQ29CWOpTdAppJaLlLSzjnvHtGuivEGiS1L%2FEezRmYe7pRyOPL6g2zBVYnaNCEIdS1hofrLN%2FAF74e7k1KeByUeLw3VctMU056v6nf5z6sWo6AOfYmu38xY%2FrkydZM4a5b1timd9I4cRC0FItlp%2BPqtFXD0Jl%2FaQFd5r4ZgMDVW2UKHtpoLSAQvqCrYSFmDIBHf1SZL1as1jlROSrGhZkgIpbUjnZVNQH%2BuQBTnE7yQ91XvoepL3SgQEMQ2DJLhvpTL30tib2JImlVXs86ciDWDsxJ%2BnJP5bQ%3D%3D',thumbnailurl:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/57K28BSU82047/1573925526635_thumb.jpg?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=geGh9cNF9mY835EKDBmmNPBV4BZgdfQUll%2BgnRspqp3ub2GPekP0RwdDRKf%2BVsD9rMuJgo%2F06u96%2BybwFbEK7nBYOhFT4k9K2Pjk5WK29zs%2FE%2B7giMdj1SoRLC3omEeQPz0FyH1TNKbO9lNmWGZ0VpGCeMnCiSll1YY8%2FxuGmh86RF6bEARnSlI2u2%2BV4JX5CJbrUZw1MOyfxo5djLRQbaD1D%2FlrGbLd%2By%2BCvnUa2e4093ztr%2FbJEReBU2HF2A%2FKf8KsIgz%2FsCTMfoVRbfZVNF1DRRsyONvSzdC6RzAaqgmwYQpe8PnouAvVf7H122xS%2Bb%2BBTouUqSQDPmp8GiIPmw%3D%3D',bucketName:'scoreit-demo.appspot.com',thumbnailStoragePath:'__videos/57K28BSU82047/1573925526635_thumb.jpg',videoStoragePath:'__videos/57K28BSU82047/1573925526635.mp4',deviceId:'57K28BSU82047'},
  {_id:'5ede9263600d180003b60802',timestamp:'2019-11-16T17:33:20.841Z',duration:37,url:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/57K28BSU82047/1573925600841.mp4?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=tlmbirsmrgt8clRNU6zA77TylO%2B8%2F9YdEcmPsMS4ubN6t0NQm6cTimsxJhA8poxN9AqnwJC8X4tSB2i0ePrVKjoTBc0K7abgQxT4CsQk7qMyCa9Fqfot%2F4ul6tqc69Osyp00MDA0mv7epSEPt0rzjHAInkWJCl58VX8vMRPQW%2B0SJCmXFqLazmTQobz8k%2Fov0n2qKXpTjCohEolH74cKFdo3lwmxF3oqQBpT1DZze%2F30TYv82qN1oZXdvpT61YXoFcajjfMRNnioEk1pP4R5%2F1xC63gSHc7wkhRuG%2BxgfGhi%2F4rTrrvWwvv5mYOqok%2FJH38RfcSaYjc1Ed7gd5VphQ%3D%3D',thumbnailurl:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/57K28BSU82047/1573925600841_thumb.jpg?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=UGbBrZvABrnFJH3UfkWUz7U3pbkcxNwcH0VqXEtTPzlugqA7mQ7Dj%2FomOen%2F42mtYOc357IUhttTVTpRP0Ai1FtwVyaLtArFPDQrz4jAE4ifT2qiO%2BxJGfJ%2FMfOP7jo6XNA8%2Bf7EFB8sy1%2BuQipcteEFulc8otThNwJqM5386IjyzG0Y%2BM5cpctujAQJbsToO2EbVQLP0%2FIRmPv6pbS71G2DfnLyH8dQ4KEBZTeAPutBT4R9kC%2BcdZK92gSIBeKXJebhAuBlviojNbpjzAm7aiCMcTm0tRJ7UGgjJELxM9goFahB1geqH%2BbbxeOADAw5B9y8w3U9njvMaR2DR7Brtg%3D%3D',bucketName:'scoreit-demo.appspot.com',thumbnailStoragePath:'__videos/57K28BSU82047/1573925600841_thumb.jpg',videoStoragePath:'__videos/57K28BSU82047/1573925600841.mp4',deviceId:'57K28BSU82047'},
  {_id:'5ede9263600d180003b60803',timestamp:'2019-11-16T17:34:57.997Z',duration:23,url:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/57K28BSU82047/1573925697997.mp4?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=StYzfPa8MSyHXINmRFRWgkFSoJ5uE28Ncm%2FAeeGokMxEcmXpXU7%2FGxhFF18cD%2FUhX4xpucAWMVCkgwUbYFFsmPhAZDA7AmWnGav%2FiysObrAoT3tkPMqASqpaOn3jUeRfqO8fg1qCNFTcNo28X9%2FE3bakWOBvApBc%2B%2B%2FBQe9FVp%2FFznHqMFD%2FYpOL0k4eVUEvlzgcK1HdeWdUvoZPshfI9mMLfjah%2FRMmfx4mVmfh14i5pKD20ezItSpQty%2FVU6qM7yeukxxh8y3h%2BYdXBxRlb8PL8kY3z5y2r2e7S4UkX3yfnVmk29pJtq5kaZY0PJaY8n5WCJmCnhOUX%2F%2BAqwmMWw%3D%3D',thumbnailurl:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/57K28BSU82047/1573925697997_thumb.jpg?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=BDeVycZIyA7rd0M7xtdgaKpPspahVkArVfMnd9W8uQJufPXg%2FtN3dhlNr00tBmRcOCQS4lWkgNCCHpTBCQ9KFuyXKe4vYUZRMjbXteSp4azBURBFg0yI3fStxpdv0KKOdgwm3tqopgI9DAs65XQT6oZtSbK4YCTxa97Kcdz1UwKI7I%2FbPt2uoMO8wu1n6OWrMhMzvTURtU6o2oAhax4%2F6vZkfTusPXvxd43U7lqFI2vd8mFE8QysrS6NXNIhArsW1dpiYzjWPryZNNKo404FqFFjh33juzpN9HtIBBpkjAoxuQDwc4DylEGng%2FESky57WAVyhJFbzVGFHJP8YtBKiQ%3D%3D',bucketName:'scoreit-demo.appspot.com',thumbnailStoragePath:'__videos/57K28BSU82047/1573925697997_thumb.jpg',videoStoragePath:'__videos/57K28BSU82047/1573925697997.mp4',deviceId:'57K28BSU82047'},
  {_id:'5ede9264600d180003b60804',timestamp:'2019-11-16T17:36:57.576Z',duration:21,url:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/57K28BSU82047/1573925817576.mp4?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=rJ2S23R7eheNJh0BET2DVRVxi7r0WGxdxQSVe%2B3FmdNjrD0S6yL5RTv%2BHume5p79yo0YMT5fy5GhiV71BNDiAsaQo278PQBsDqJ3pWsE5Nbz8lcjXkRypH6%2FKFmQmWrlw1w3WsRaZ4wiO40Mk3CgneWICNP5gyCJqnPB7Lt3qYg4VmOkQ5pLu3SqycR6LA61SJi8Tv4yOotrjaXnzNTZw7XFIPR4WosxZt3wrOqMhlTMlm%2Fwe6JcuKi5jp%2FmP0TRHvOH%2FfQeHkcW2DSoqXEdM7HbzJpQR28rpaOfhNGwIANNSzYUZ%2FNtTzKC3hMYMQXzdJ4v5AyNG5F6JqEGu8yRmA%3D%3D',thumbnailurl:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/57K28BSU82047/1573925817576_thumb.jpg?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=Qw8ke7R8WXFZCac0YlhxC%2FR%2BQdLjzX0vu7Aju2NLPAWhCTGMg61iAiLmSkdEAj%2Fi6RFkwRLqfOa9sSvPOhozJel839SWTVr4oCRR9neBpin16%2BmdXcZEdwz%2FQwPeGbSf7sSYH6vFukqFLqzhzcfnGlr8151zC0kaoNcusPauJ47SryMtc5l80JpuYBj%2BUiSqhWDdK5sZyggUAqf0wGrVrCTwmpILjfTV3F9k5hpGLD5w59jVDCbR9RYmFCla9i0ocQV0s5PTfMbXwnMGW8KVJDDOjQbinGzfuIkxwBiTuFAF3jK9CroV32qIYXE2vlZkHW8iBqMBL9Qr58PaSFr%2BjQ%3D%3D',bucketName:'scoreit-demo.appspot.com',thumbnailStoragePath:'__videos/57K28BSU82047/1573925817576_thumb.jpg',videoStoragePath:'__videos/57K28BSU82047/1573925817576.mp4',deviceId:'57K28BSU82047'},
  {_id:'5ede9265600d180003b60805',timestamp:'2019-11-16T17:37:59.34Z',duration:19,url:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/57K28BSU82047/1573925879340.mp4?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=Y7aiNpGiV4ftDDLl6pNwZHm1l7ZkSYW1GVS5Ca7BdLv3by5%2BPsxn56koIBvlwzTbDPHTxhM59qOwsj0qfA8J46hNCoSIoVTUxzL4hBUCCJPwhy%2Bb7q6liD8Fo%2BtACL8dH2%2BNb51xPwIZgtdsmpfKUGDr3xRlX6aXN0BrnhrPcDDnOjTucyTEfiwPJagLk7l7hBPRdmoEEpXOSAQAwdgsi5Put%2FweWrAMlzikndCO2qRbA5irJMM8jmZepntml3avre4M0weHCKS5jSn668nhrF5iYcWT0jF8aP40vrPzVuFYDdau%2BoQTFDWdx%2BkBlpe6Mt1RctO%2FdW6yevbG%2BfBbYA%3D%3D',thumbnailurl:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/57K28BSU82047/1573925879340_thumb.jpg?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=Q8jw3GVnG29HVz%2FZCi1PodTQDWeL0RlGb8lgNXHX4LHVXAyVXURvBKc1KzFXSnzQBho0Ghrb8t3yJRdoq9TBctgzkW3NB5r1h%2BaEw0Kidk0W8A5wgunxSfZJiQirS00RS6uuBuCHIfVFYyM56W8fNf7QfrGURn%2Bwp7JsetdFovKdNhSFeqpH9CsHkjZYdx523gDWCbrutAALuUsQYa3%2FkeoYPYhTG2ENPrvHjBvh339en2TSHGnzJZQ9XMjVkmYjHKaKIcoyR%2BqgLOXt0EWV1NhhFZOlh385BT0RGxefp4aDCBjAm2%2FUmG60IUyAphCoIZ34Lm2qP%2FzXh%2BtagHp5zw%3D%3D',bucketName:'scoreit-demo.appspot.com',thumbnailStoragePath:'__videos/57K28BSU82047/1573925879340_thumb.jpg',videoStoragePath:'__videos/57K28BSU82047/1573925879340.mp4',deviceId:'57K28BSU82047'}
];

export const observationData = [
  {_id:'5ede925e600d180003b607f7',timestamp:'2020-03-25T03:23:37Z',duration:17,notes:'MIMMTest',subjectId:'5ede925c600d180003b607ee',videoId:'5ede925d600d180003b607f3'},
  {_id:'5ede925e600d180003b607f8',timestamp:'2020-03-25T03:23:37Z',duration:6,notes:'MIMMTest',subjectId:'5ede925c600d180003b607ee',videoId:'5ede925d600d180003b607f4'},
  {_id:'5ede925e600d180003b607f9',timestamp:'2020-03-25T03:23:37Z',duration:15,notes:'MIMMTest',subjectId:'5ede925c600d180003b607ee',videoId:'5ede925d600d180003b607f2'},
  {_id:'5ede925e600d180003b607fa',timestamp:'2020-03-25T03:23:37Z',duration:16,notes:'MIMMTest',subjectId:'5ede925c600d180003b607ee',videoId:'5ede925e600d180003b607f5'},
  {_id:'5ede925e600d180003b607fc',timestamp:'2020-03-25T03:23:37Z',duration:3,notes:'MIMMTest',subjectId:'5ede925c600d180003b607ee',videoId:'5ede925e600d180003b607f6'}
];

export const annotationSetsData:Entities.AnnotationSetFromApi[] = [
  {
    'annotationSetId':'5f9374b37c2afe797bced1d1',
    'instrumentIndex':0,
    'emmaUserId':'5f72841b3f230a4bfedfe8ca',
    'observationId':'fds',
    'videoId':'5ef2ac5e99cdde297a4e5222',
    'annotations':[
      {'startTime':1.69687,'label':'new item'},
      {'startTime':3.273333,'label':'new item'}
    ]
  },
  {
    'annotationSetId':'5f9374b37c2afe797bced1d2',
    'videoId':'5ef2ac5e99cdde297a4e5222',
    'emmaUserId':'5f739284b4ac623bd01a20a3',
    'observationId':'fds',
    'instrumentIndex':0,
    'annotations':[
      {'startTime':5.69687,'label':'not new item'},
      {'startTime':9.273333,'label':'also not new item'}
    ]
  }
];


export const derivedDataData = [
  {_id:'5ef2676d0ee5b6000363d610',auto_generated:true,percentageCompleted:100,deriveddatatypeId:'5e77d50b0e8d6b212482f8ef',taskstatusId:'5e77d6ec0e8d6b212482f8f1',observationId:'5ede925e600d180003b607f8',url:'https://storage.googleapis.com/scoreit-demo.appspot.com/5ef2676d0ee5b6000363d610/5ede925e600d180003b607f8-pose-inference.pickle?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1898582400&Signature=nemigEYZ2evM9MIqtr4H6JmaJdsOBpY2WbgEg18s%2F2RwV76wN%2F%2FTCpm8iIKBf8JF2qIDMd8SF8JE3dAu%2FwsiBFodsxwqikJZv2kyYAKwraNE%2BYJ%2BM3J92P3JXltpnRgwAvl9KkWMJrWefXDnBsnTe98UXyxWe9kp0%2BVPF6qwON3aSdFgy7oNkX7Q0KHSiQGAgG4XtTWX0uw%2Fr%2BMY5UvbQpMz9DsbrHqPFfROnEYgZWYxdffKgsTptS%2BWIO47519hY5jZjAgdlO1N1U%2F1RUEOEbPtkc2D%2FjPutbdpm0wEcxRKWVVhj6imhn6f%2BPwcW%2FXubFWYTmk2tuNvs6YM7TG6yA%3D%3D'},
  {_id:'5ef267d00ee5b6000363d611',auto_generated:true,percentageCompleted:100,deriveddatatypeId:'5e77d2a10e8d6b212482f8ed',taskstatusId:'5e77d6ec0e8d6b212482f8f1',observationId:'5ede925e600d180003b607f8',url:'https://storage.googleapis.com/scoreit-demo.appspot.com/5ef267d00ee5b6000363d611/5ede925e600d180003b607f8-pose-inference.webm?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1898582400&Signature=CuwelnirjBytrjb8bJkGeOQ4XQfJmZFO0%2BnM%2FVVVUYoa%2BBANEOb61BR1GI69j69GDxhTlFqAms%2FKDuLCda8AmxCR1eVAaE1zW9TuhRENWBXSvc2o72Js1EtfWJdMsaDFN4Ew1R0reHJmhENK%2F4dsf0ntnFyB1UulYwR6EqASdG%2B0UUFLD5f5PBUyIezgfH2GyfyxzC7B1uwd0Kj84W%2FAn%2Bp4OCGQtbiky0CET0PZ5JHSPDiKCkYTmzxFCz6cAsnlx5OuvpG1m81T8wEo%2FKpyAKq5UpkuyRvQYY%2B8KuMPW16mWOmRH5aLkJSb3FT7RUFh8H%2BPU39JM%2FgJ2QeJzZILJA%3D%3D'},
  {_id:'5ef267e70ee5b6000363d612',auto_generated:true,percentageCompleted:0,deriveddatatypeId:'5e77d2aa0e8d6b212482f8ee',taskstatusId:'5e77d6f40e8d6b212482f8f2',observationId:'5ede925e600d180003b607f8'},
  {_id:'5ef267f00ee5b6000363d613',auto_generated:true,percentageCompleted:100,deriveddatatypeId:'5e77d5180e8d6b212482f8f0',taskstatusId:'5e77d6ec0e8d6b212482f8f1',observationId:'5ede925e600d180003b607f8',url:'https://storage.googleapis.com/scoreit-demo.appspot.com/5ef267f00ee5b6000363d613/5ede925e600d180003b607f8-op-annotations-video.webm?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1898582400&Signature=Rs38iLITNuOpSLTPp%2BkwK3tvr3RKAszzp5mofQbB3OGse%2B%2F9ThEeGP9R3XRKJDUBFxsVXy2L2S60fW1tBFZOyfnEpxtQmad%2FNy7jY85l3uAc3TOI2AKfcEFiqJVWYCp5lmbR1wm5T8WHBaiwrm4LjKYBp3zW6wzpSWAczU2FzZNp%2FuZsW1nWrKO5jQvoB8KKTV5l0Nc6tYpciqCU%2B8UTQ4un4TsFVAF8cfIBawVsKSCJvMF8oGKE%2FAS5pS%2Bji2wlh9BQGXgpNuLhbzUmXLezmi%2BbKYSDuQ0h54HtfKqH34dVw5Mg8QK%2FkEFIOsfYTBAkSTbKcufK7ek2pLY4%2BgufKQ%3D%3D'},
  {_id:'5ef267f40ee5b6000363d614',auto_generated:true,percentageCompleted:100,deriveddatatypeId:'5e8e4b6f1c9d4400002d152e',taskstatusId:'5e77d6ec0e8d6b212482f8f1',observationId:'5ede925e600d180003b607f8',url:'https://storage.googleapis.com/scoreit-demo.appspot.com/5ef267f40ee5b6000363d614/5ede925e600d180003b607f8-activities.webm?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1898582400&Signature=EuLSB%2BAWp0rS9u19Al5HAPJSthu9BAu2GS1%2Fd5dBcUvBkOHMGkiUV0HLjqsjIUaY%2FxOI0SdObKcK8wyvYWHnnyYQxGGkPJqccfGjwmNCyUfWu3IUOJfra3KUtvAz5BMkHMsNfEZrBngKKKf4Y1TK6%2BJ4TrG0C02OeNThUC64pMK9Ahjqc%2FOXGjoORUNYGcfPlm8KGr8025UgLLV3ErGPvpoXz9WlsQ3hesfzOGCQjbXoOqNt2o5LDotdYyra9WQo2sy4yn4qemQKnhzjYKyEBXvoAKejm7ulXdkItPrLoNO9gLb0XSSoqIBLWmcmybzD1iiOm3OHkEzj2rT74yGViQ%3D%3D'}
];

export const derivedDataTypeData = [
  {_id:'5e77d2a10e8d6b212482f8ed',name:'keypoints_video'},
  {_id:'5e77d2930e8d6b212482f8ec',name:'activities'},
  {_id:'5e77d2aa0e8d6b212482f8ee',name:'keypoints_openpose'},
  {_id:'5e77d5180e8d6b212482f8f0',name:'keypoints_video_openpose'},
  {_id:'5e8e4b6f1c9d4400002d152e',name:'activities_video'},
  {_id:'5e77d50b0e8d6b212482f8ef',name:'keypoints'},
  {_id:'626c55598642e005c5c77954', name:'motor_risk'} //this is a two-fer, will produce a overlay video URL and a json url for motor risk surprise
  //added May 3 2022
];

export const userData = [
  {_id:'5ede925b600d180003b607e8',email:'emmatest02@launchbottle.com',uid:'wjJcnY6ZYpgQd1nsQ45AtdsC9O63',authLevel:0,firstname:'John',lastname:'Doe',created:'2020-03-25T03:23:37Z',deviceIds:[]},
  {_id:'5ede925b600d180003b607e9',email:'emmatest01@launchbottle.com',uid:'mLW1cyie3SdsTVEOLxIV5kGHsKx2',authLevel:0,firstname:'Jane',lastname:'Doe',created:'2020-03-25T03:23:37Z',deviceIds:['EM0001','EM0002'],sharedEmails:'emmatest02@launchbottle.com'},
  {_id:'5ede925b600d180003b607ea',email:'jason.overland@launchbottle.com',uid:'xfgZ5vnkFPOr1wObbcVrTHwQP542',authLevel:10,firstname:'Jason',lastname:'Overland',created:'2020-03-25T03:23:37Z',deviceIds:[]},
  {_id:'5ede925b600d180003b607eb',email:'bmodayur@gmail.com',uid:'11omv4lS8CUr0JdF2XQoKlqI5Hw2',authLevel:10,firstname:'Bharath',lastname:'Modayur',created:'2020-03-25T03:23:37Z',deviceIds:[]},
  {_id:'5ede925b600d180003b607ec',email:'dlipino79@gmail.com',uid:'eBQ0EBq0GBWIQqbfz2L3UmBGMaw1',authLevel:10,firstname:'David',lastname:'Li',created:'2020-03-25T03:23:37Z',deviceIds:[],sharedEmails:'',additionalProp1:{comments:'Some notes'}},
  {_id:'5ede925c600d180003b607ed',email:'emmatest03@launchbottle.com',uid:'L2IJdeeHG1MUXy4vMJDROL3ucH93',authLevel:0,firstname:'Kath',lastname:'Mandu',created:'2020-03-25T03:23:37Z',deviceIds:[]}
];

export const subjectData = [
  {_id:'5ede925c600d180003b607ee',name:'MIMMTest',gender:'M',dob:'2020-03-25T03:23:37Z',duedate:'2020-03-25T03:23:37Z',emmauserid:'5ede925b600d180003b607e9'}
];

export const instructionalVideoData = [
  {_id:'5ede925c600d180003b607f0',title:'Mirrors all around',url:'https://firebasestorage.googleapis.com/v0/b/scoreit-firestore.appspot.com/o/mirrorsallaround.mp4?alt=media&token=2b308c21-73cb-46f1-82bd-c005ba05dc41',thumbnailurl:'https://firebasestorage.googleapis.com/v0/b/scoreit-firestore.appspot.com/o/mirrorsallaround.jpg?alt=media&token=d789dcab-ae65-44dd-9f7b-dbb5e1a84040',instruction:'Take baby around the house. Share your reflection in each mirror. Point to your eyes, ask if they see them, then ask if they see their eyes, and point them out.',background:'Background information about this play activity goes here.'},
  {_id:'5ede925c600d180003b607ef',title:'Get on down',url:'https://firebasestorage.googleapis.com/v0/b/scoreit-firestore.appspot.com/o/getondown.mp4?alt=media&token=551de725-d2e5-4301-98bb-0afafc2b630b',thumbnailurl:'https://firebasestorage.googleapis.com/v0/b/scoreit-firestore.appspot.com/o/getdown.jpg?alt=media&token=4f31f170-1b47-4155-9c75-788523e5728a','instruction':'When baby is on tummy, get down on their level. Encourage eye contact.',background:'Background information about this play activity goes here.'},
  {_id:'5ede925c600d180003b607f1',title:'Head Lifts',url:'https://firebasestorage.googleapis.com/v0/b/scoreit-firestore.appspot.com/o/headlift.mp4?alt=media&token=408ec41f-9d71-48a3-87da-c3b0a70e9863',thumbnailurl:'https://firebasestorage.googleapis.com/v0/b/scoreit-firestore.appspot.com/o/headlift.jpg?alt=media&token=15cd705a-cd05-43f5-971b-b7bb5cbe02a4','instruction':'Baby should start to lift their head a little bit when doing Tummy Time. Get baby to move by dangling a toy in front to look up at.',background:'Background information about this play activity goes here.'}
];

export const deviceData = [
  {_id:'5ede925a600d180003b607e5',name:'Device One',SerialNumber:'EM0001'},
  {_id:'5ede925a600d180003b607e6',name:'Device Two',SerialNumber:'EM0002'},
  {_id:'5ede925a600d180003b607e7',name:'Arlo at Early Markers Office',SerialNumber:'57K28BSU82047'}
];

export const individualPlanData = [
  {_id:'5f611f70591a7d00039a3ce7',timestamp:'2020-09-15T20:09:20Z',notes:'plan created by david as a test, and is deletable',instructionalvideoIds:['5ede925c600d180003b607ef','5ede925c600d180003b607f0','5ede925c600d180003b607f1'],createdbyEmmauser:'5ede925b600d180003b607ec',subjectId:'5ede925c600d180003b607ee'},
  {_id:'5f612702591a7d00039a3ce9',timestamp:'2020-09-15T20:41:38Z',notes:'test plan for emmatest01 created Sep 15 2020',instructionalvideoIds:['5ede925c600d180003b607ef'],createdbyEmmauser:'5ede925b600d180003b607eb',subjectId:'5ede925c600d180003b607ee'}
];

export const notificationData = [
  {_id:'5f611f70591a7d00039a3ce8',title:'New OT plan',text:'Tue Sep 15 2020 David has created an individualized plan for you.',url:'/',emmaUserId:'5ede925b600d180003b607e9'},
  {_id:'5f612702591a7d00039a3cea',title:'New OT plan',text:'Tue Sep 15 2020 Bharath has created an individualized plan for you.',url:'/',emmaUserId:'5ede925b600d180003b607e9'}
];


//// Data that is expected by the ui
export const genders:Record<string,string>[] = [
  {
    value:'M',
    label:'M'
  },{
    value:'F',
    label:'F'
  }
  // ,{
  //   value:'X',
  //   label:'X'
  // }
];
// ug this needs to be grabbed from the api, but as of now there is no solid way to change the values in the 
// api without rebuilding it, which means it is easier to change things on the front end
export const conclusionText:Record<string, string>[] = [
  {
    value:'no-concerns',
    label:'No Concerns',
    message:'Based on the results of the screening done today your infant is demonstrating age-appropriate motor skills. Should you ever have concerns with any area of your child’s development, please discuss them with your pediatrician as early intervention is important to address potential delays. You can also self-refer to most early intervention agencies before the age of three and most provide an evaluation at no cost to the family.'
  },
  {
    value:'yes-concerns',
    label:'Yes Concerns',
    message:'Based on the results of the screening done today a full developmental evaluation is recommended. You can discuss this recommendation with your infant’s pediatrician, but you can also self-refer to most early intervention agencies without a doctor’s referral. Most provide an evaluation at no cost to the family.'
  }
];

export const asmtsTableUIData:Schemas.AssessmentSchemaProps[] = [
  {
    assessmentId:'asmt1',
    date:new Date('2020-03-25T03:23:37.000Z'),
    assessor:'dlipino79@gmail.com',
    assessmentName:'No name',
    subjectId:'goodquestion',
    notes:'asmt1 note'
  },
  {
    assessmentId:'asmt2',
    date:new Date('2020-03-25T03:23:37.000Z'),
    assessor:'dlipino79@gmail.com',
    assessmentName:'No name',
    subjectId:'goodquestion',
    notes:'asmt2 note'
  }
];
// export const asmtsTableUIData:Entities.AssessmentListUIProps[] = [
//   {assessmentId:'asmt1',
//     date:new Date('2020-03-25T03:23:37.000Z'), assessor: 'Fair-Field', parent:'emmatest01@launchbottle.com',
//     dob:new Date('2020-01-01T03:23:37.000Z'), 
//     observationCount:2,
//     notes: 'asmt1 note',
//     observations: [
//       { timestamp:new Date('2020-03-25T03:23:37.000Z'),
//         url:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/EM0001/1572888180765.mp4?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=GryeHN2GDaTqqtjaqxbVEx%2BgqlkARiXycFo%2FmuWULSat6nDiD11e2eqW8FSE9f9OdGf%2Btry7V%2F36emPLbHWhxVd5dUJe24aBnp85SD5gMgDFu6Cd4gZWWwqEaAsvQp3kg400Wo%2B6CYScSulzpkIK%2FgUcu7XiHKKVTz1v54SWU2aoBwcifYw2D9HnjjXCnxyfL4CiXJuAvmwVuVgWTmzw40R0eAXkSftlBvns0imop7ZNLYuDvPuAIL4q5enGRrsxFzGAb2KvP0dpb1W0uYYgr%2FO1caLwiaKNPMHP2jyiUm0WY%2BahJ3g%2F3V1piliLmtfFveoNcnm5KN3UPBP31dRwwg%3D%3D', 
//         thumbnailurl:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/EM0001/1572888180765.jpg?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=MWkjVNVc826tb6McVG177XpnhOITsI2rzSCX616mT%2B%2B4TIVRu%2BZcYx7M2vROtw9QNxr1%2FuWqSsxFZlmtu2NHvxfY%2BBOe67wkaKA%2ByU69bAOKc7%2BlNbkBXghSMit%2FKdQp9Q4cGn4f6F6iTqcvzsnZrAuJqRjYks0IuBDplqyEjY9AcxMwaOPsLdslVuP7IUbQoKJ4y8xvanbOYyvV8h%2BklrK4zOADiEGPbsnIN0m2DJpikX7JSKnzcklU%2Bd%2FILY4NxWZBkZs8%2BZlGKEQD4uelrktcXy0oAiZyn%2FZsdOUaqmjM%2Bvs7CuQ8eUCKxAhewovOZniBueuWxVX0ogl1aoVcAA%3D%3D',
//         videoId:'',duration:0}, 
//       {timestamp:new Date('2020-03-25T03:25:37.000Z'),
//         url:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/EM0002/MIMM5021-3750-to-4260.mp4?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=Xm36VXU%2BtEIxUUG5mbLk7BVFemXVDw4Mi7JrvTvlzUDvd7IDPF8ppFx2%2FWSzL05Q2HLA%2F9QTnf79%2FrETr9YCk7xDgVCR%2FR8MyuPD%2F64fB4t9CBaKoJ4ulkZOKZFA6wuE1YKjwHI3UfkVlEq1wJphhiAZgQETWkyiMpU%2BzhU61Tux6V%2FKhb%2BV82SFUT3wefLRwf0yILYDov6UxyNIDDMzemiwv4Bgt1NPcUqnQ6u91y1tnLCDPv9w9k9DXcE6UQ0o72%2FCbyLdL9SFBVoNDSB3u57Kd%2BHX1B0CRCesQm0%2BYXo8V3OR5sSCuaFMFWJQA8lNt%2FoPaWGTvr015uAtJc97Yg%3D%3D',
//         thumbnailurl:'https://storage.googleapis.com/scoreit-demo.appspot.com/__videos/EM0002/MIMM5021-3750-to-4260.jpg?GoogleAccessId=firebase-adminsdk-b7osf%40scoreit-demo.iam.gserviceaccount.com&Expires=1742169600&Signature=kJ5ayY8ywaUh2rzsdZ55ojs1GPxI2BA9XBAhX21fE%2Bo7VnwscUQSXJ6SUlyZ%2FgXaCgtKEPQgFArCObtpQWf9UeRLFrKkLSriTMwqgGTPqnaj9RhTokktqfthqvGd47x7rFTHPheeZOfenO5Yt3EaX%2BaCUYwnHb%2B1Y%2BazKV3Otlc6NtQCU3SPP5zya3R2cIJUXR8UBcMKxDKeifd6Xy2blla3Iodx943Abc6Hd%2BE5A2j7vSvnyhWRXcRLsoAInTXDjf%2FPpqE%2FZt%2Bb9qK2dtGhX1t%2Fd9jr6dzS5JAKB9ja772AR8hT9JFw0OKkCYbZuTk7KF1LfAuJu8s3y5rI%2FY3UTA%3D%3D',
//         videoId:'',duration:0
//       }]},
//   {assessmentId:'asmt2',
//     date:new Date('2020-08-25T03:23:37.000Z'), assessor: 'MaraM', parent:'emmatest02@launchbottle.com',
//     dob:new Date('2020-01-01T03:23:37.000Z'), notes:'asmt2-note', observations: [], observationCount:0},
// ];

// // Not liking the creation of the perentUIprops, dlp needs more info about the general usage when/where
// export const parentUIData:Entities.UIPropsForNewParentDialog[] = [
//   { email: 'test1@gmail.com', firstname: 'Jane', lastname:'Doe', emmauserId:'1', babyname:'Babe Em',gender:'F',dob:new Date('2020-03-15T00:00:00.000Z'),
//     duedate:new Date('2020-03-15T00:00:00.000Z'),notes:'jane doe parent'},
//   { email: 'test2@gmail.com', firstname: 'Jane', lastname:'Doe', emmauserId:'2', babyname:'Babe Em',gender:'F',dob:new Date('2020-03-15T00:00:00.000Z'),
//     duedate:new Date('2020-03-15T00:00:00.000Z'),notes:'jane doe parent'}  
// ];

export const aimsScoreSample:Schemas.AimsScoreSchemaProps = {
  prone:{score:-1, maxItemName:'pmax', notes:'p default notes'},
  sitting:{score:-1, maxItemName:'sit max', notes:'sit default notes'},
  supine:{score:-1, maxItemName:'sup max', notes:'sup default notes'},
  standing:{score:-1, maxItemName:'stand max', notes:'stand default notes'},
  total:{score:-1, notes:''},
  percentile:{information:'Some default percentile info'}
};
export const uninitiatedScore:Schemas.AimsScoreSchemaProps = {
  prone:{score:-50, maxItemName:'pmax', notes:'Uninitiated Prone'},
  sitting:{score:-50, maxItemName:'sit max', notes:'Uninitiated Sitting'},
  supine:{score:-50, maxItemName:'sup max', notes:'Uninitiated Supine'},
  standing:{score:-50, maxItemName:'stand max', notes:'Uninitiated Standing'},
  total:{score:-50, notes:''},
  percentile:{information:'Uninitiated Percentile Infomration'}
};
export const aimsScoreEmptySample:Schemas.AimsScoreSchemaProps = {
  prone:{score:0, maxItemName:'', notes:'No Prone'},
  sitting:{score:0, maxItemName:'', notes:'No Sitting'},
  supine:{score:0, maxItemName:'', notes:'No Supine'},
  standing:{score:0, maxItemName:'', notes:'No Standing'},
  total:{score:0, notes:''},
  percentile:{information:''}
};

export const singleReportData:ReportDataSchemaProps = {
  reportTypeName:'AIMS', //Not needed for new
  assessmentDate:new Date('2018-03-15T00:00:00.000Z'),
  reportCreatorName: 'Test creator',
  reportCreationDate: new Date('2018-03-15T00:00:00.000Z'),
  asmtSubjectName:'a subject',
  asmtSubjectBirthDate:new Date('2018-03-15T00:00:00.000Z'),
  subjectAgeMonthsForAsmt: 10,
  subjectGender:'F',
  // Hmm stuff that is not in the api yet also unknown how needed this will be
  parentEmail:'parent@mail.com',
  parentName:'Default parent fullname',

  reportConclusion: 'Some conclusion text, how should we note that conclusion is auto generated.',
  // And now the score info
  aimsScore: aimsScoreEmptySample
  // aimsScore: { //ReportDataSchemaProps type, not sure how to set it.
  //   sitting: {total:10, maxItemName:'', notes:'fdd'}, 
  //   supine: {total:10, maxItemName:'', notes:'fdd'} ,
  //   standing: {total:10, maxItemName:'', notes:'fdd'},
  //   prone: {total:10, maxItemName:'', notes:'fdd'},
  //   total:{score:10, notes:'fdd'},
  //   percentile:{information:'fe'}, 
  // } as Schemas.AimsScoreSchemaProps
};
export const singleReport:ReportSchemaProps = {
  creationDate:new Date('2020-03-15T00:00:00.000Z'),
  reportFileURL:'fackeUrl',
  reportFileStoragePath:'fakestorage',
  assessmentId:'someasmtId',

  subjectId:'fd',
  emmauserId:'fdewf', //assessor Id, prop name will probably be changed.
  subjectParentId:'isitneeded', // this prop is meh
  reportData: singleReportData
};

export const allReportData:ReportSchemaProps[] = [
  singleReport
];

export const uninitiatedReportData :ReportDataSchemaProps = {
  reportTypeName:'AIMS', //Not needed for new
  assessmentDate:new Date(),
  reportCreatorName: 'Creator not Initiated',
  reportCreationDate: new Date(),
  asmtSubjectName:'Subject not Initiated',
  asmtSubjectBirthDate:new Date(),
  subjectAgeMonthsForAsmt: -50,
  subjectGender:'U',
  // Hmm stuff that is not in the api yet also unknown how needed this will be
  parentEmail:'notset@mail.com',
  parentName:'Parent not set',

  reportConclusion: 'This report needs to be initiated with proper data',
  // And now the score info
  aimsScore: uninitiatedScore
};
export const uninitiatedReport:ReportSchemaProps = {
  creationDate:new Date(),
  reportFileURL:'Not initiated',
  reportFileStoragePath:'Not initiated',
  assessmentId:'novalidAsmtId',

  subjectId:'subjectIdHasNotBeenSet',
  emmauserId:'emmauserId_assessorIdHasNotBeenSet', //assessor Id, prop name will probably be changed.
  subjectParentId:'subjectParentIdNotSet', // this prop is meh

  reportData: uninitiatedReportData
};