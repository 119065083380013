import React from 'react';
import {
  Grid,
} from '@material-ui/core'; 

import PropTypes from 'prop-types';

import { VideoTagTable } from './VideoTagTable';


export const VideoTagTableFull = (props) => {

  return(
    <React.Fragment>
      <Grid
        container
      >
        <Grid
          item
          xs={12}
        >
          <VideoTagTable
            handleAddLabel = {props.handleAddLabel}
            handleSaveLabels = {props.handleSaveLabels}
            handleSeekTo = {props.handleSeekTo}
            // instrumentDetails = {props.instrument}
            returnCurrentTimeStamp = {props.returnCurrentTimeStamp}
            videoId = {props.videoId}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
  // }
};

VideoTagTableFull.propTypes = {
  handleAddLabel: PropTypes.func.isRequired,
  handleSaveLabels: PropTypes.func.isRequired,
  handleSeekTo: PropTypes.func.isRequired,
  // instrument: PropTypes.object.isRequired,
  returnCurrentTimeStamp: PropTypes.func.isRequired,
  videoId: PropTypes.string.isRequired,
};

