/* eslint-disable react/no-multi-comp */
import {useState, useEffect, React} from 'react';
import styled from 'styled-components';
import { NavLink as RouterNavLink } from 'react-router-dom';
import {FirebaseCrashlytics} from '@capacitor-community/firebase-crashlytics';

import {
  // Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Button,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography
} from '@material-ui/core';

import { spacing } from '@material-ui/system';
import {codePush} from 'capacitor-codepush';
import {isMobile, isBrowser, isIOS, isAndroid} from 'react-device-detect';
import {Capacitor} from '@capacitor/core';
import useStyles from './styles';

// const NavLink = React.forwardRef((props, ref) => (
//   <RouterNavLink innerRef={ref} {...props} />
// ));

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

// const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Code = styled.pre`
  margin-bottom: 0;
`;

const Chip = styled(MuiChip)`
  height: 20px;
  margin-top: -3px;
  font-weight: bold;
  font-size: 75%;
`;

const crash = async () => {
  await FirebaseCrashlytics.crash({ message: 'Test Crash' });
};

const setContext = async () => {
  await FirebaseCrashlytics.setContext({ 
    key: 'page',
    value: 'home',
    type: 'string'
  });
};

const setUserId = async () => {
  await FirebaseCrashlytics.setUserId({
    userId: '123'
  });
};

const addLogMessage = async () => {
  await FirebaseCrashlytics.addLogMessage({
    message: 'Test'
  });
};

const setEnabled = async () => {
  await FirebaseCrashlytics.setEnabled({
    enabled: true,
  });
};

const isEnabled = async () => {
  const result = await FirebaseCrashlytics.isEnabled();
  return result.enabled;
};

const didCrashDuringPreviousExecution = async () => {
  const result = await FirebaseCrashlytics.didCrashDuringPreviousExecution();
  return result.crashed;
};

const sendUnsentReports = async () => {
  await FirebaseCrashlytics.sendUnsentReports();
};

const deleteUnsentReports = async () => {
  await FirebaseCrashlytics.deleteUnsentReports();
};

const recordException = async () => {
  await FirebaseCrashlytics.recordException({
    message: 'This is a non-fatal message.'
  });
};


const handleUpdateApp = () => {
  console.log('Calling codepush sync');
  if(isAndroid || isIOS){
    // codePush.sync();
        //Sep 2022
        //putting codepush on hold
  }
  // call a crashlytics test crash
  crash();

};

function Introduction() {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Introduction
        </Typography>
        <Typography variant="body1" gutterBottom my={2}>
         EMMA (Early Markers Movement Analyzer) is currently a research study
         that will explore infant movements in early stages of development.
         The goal is to develop tools that can screen for developmental and behavioral
         conditions, including Cerebral Palsy (CP) and Autism Spectrum Disorder (ASD). 
          <br></br>
          <br></br>
         The study will assess infant movements from crib videos obtained using smartphones.

        </Typography>
      </CardContent>
    </Card>
  );
}

function Contents() {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          How can the parent use the EMMA Baby app
        </Typography>
        <Typography variant="body2" gutterBottom my={2}>
          
          <b>Home -- </b> This is the home page where you will see a summary of the data
          collected, including home videos, and play activities suggested for your baby.
          <br></br>
          Parents can request a new assessment and upload a crib video by clicking on the  `Get Started` card.
          <br></br> 
          <br></br>
          <b>Settings and Tasks/Emma User Settings -- </b> Use this to edit/update your profile information as well
          as information about your baby.
          <br/>
          This information can also be accessed from the home page by choosing the 'Family details' card.
          <br></br>
          <br></br>
          
          <b>Videos/Home Videos -- </b> Short video snippets, collected as part of the study, can be found here.
          These videos will be used to assess your baby as well as to prescribe
          play activities to augment overall development.
          <br/> 
          Previously uploaded home videos can be accessed from the home page by choosing the 'Previous videos' card.
          <br></br>
          <br></br>

          <b>Videos/Play Activities -- </b>Play activity videos, designed for your baby by a pediatric occupational therapist (OT),
          can be viewed here.
          <br/>Activity plan videos can also be accessed from the home page by choosing the 'Activity plans' card.
          <br></br>
          <br></br>
          
          <b>Assessments -- </b>Prior motor assessments for your infant will be available on this page.
          <br></br>
          <br></br>
          The menu options are shown below.
        </Typography>

        <Code>{`
  ├── Home
  ├── Settings & Tasks/
  │   ├── Emma User Settings
  ├── Videos/
  │   ├── Home Videos
  │   ├── Play Activities
  ├── Assessments
  ├── About`}
      </Code>
      </CardContent>
    </Card>
  );
}

function QuickStart() {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Quick start
        </Typography>
        <Typography variant="body2" gutterBottom my={2}>
          To enroll in the study, head on over to {' '}
          <Link
            href="https://earlymarkers.com/projects/emma"
            target="_blank"
          >
            Early Markers
          </Link> and click on <b>Participate.</b>
        </Typography>

        <Typography variant="body2" gutterBottom my={4}>
          You can view instructions on {' '}
          <Link href="https://youtu.be/C7xtMcyHmQ4" target="_blank">
            how best to acquire and upload the short crib videos.
          </Link>{' '}
          

          

        </Typography>
      </CardContent>
    </Card>
  );
}


function Changelog() {

  const [currentPackageDesc, setCurrentPackageDesc] = useState('');

  useEffect(()=>{
    if (Capacitor.isPluginAvailable('codePush')) {
      codePush.getCurrentPackage().then(function (update) {
        if (!update) {
            console.log("No updates have been installed");
            setCurrentPackageDesc('No updates installed');
            return;
        }
    
        // If the current app "session" represents the first time
        // this update has run, and it had a description provided
        // with it upon release, let's show it to the end user
        if (update.isFirstRun && update.description) {
            // Display a "what's new?" modal
            setCurrentPackageDesc(update.description);
        }
    });
  }
  },[]);
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Changelog
        </Typography>
        <Chip color="secondary" label="v1.0" /> – Dec 15, 2022
        <ul>
          <li>Bug fixes. Handle screens with notches, video upload crashes, delete video errors.</li>
        </ul>
        <Chip color="secondary" label="v1.0" /> – Oct, 2022
        <ul>
          <li>Emma Baby v1 released to App Store and Google Play Store</li>
        </ul>
        <Chip color="secondary" label="v0.1" /> – 15 June, 2022
        <ul>
          <li>Portal released to production</li>
        </ul>
        <Divider my={3}></Divider>
        <Typography variant='body1'>
          {currentPackageDesc}
        </Typography>
        {/* {(isIOS || isAndroid) &&
        <Button variant='outlined' color='primary' 
          disabled
          onClick={handleUpdateApp}>
          Update App
        </Button> } */}
      </CardContent>
    </Card>
  );
}

function Documentation() {
  const classes = useStyles();
  return (
    <div className={classes.paper}>
      <Typography variant='h3' gutterBottom>
        About EMMA Baby app
      </Typography>

      <Changelog />

      <Typography variant="h3" gutterBottom display="inline">
        Documentation
      </Typography>

      {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Typography>Documentation</Typography>
      </Breadcrumbs> */}

      <Divider my={3} />

      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Introduction />
          <QuickStart />
        </Grid>
        <Grid item xs={12} md={6}>
          <Contents />
        </Grid>
      </Grid>
    </div>
  );
}

export default Documentation;
