
import React from 'react';
import {
  withStyles,
  makeStyles,
  createStyles,
  Theme
} from '@material-ui/core/styles';

import { red } from '@material-ui/core/colors';
import {
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText
} from '@material-ui/core';


export function ConfirmationDialog(props) {

  const {onConfirm, onCancel, title, confirmText, cancelText, message, open} = props;


  const handleClose = (confirmationValue) => {
    if (confirmationValue){
      onConfirm();
    }
    else {
      onCancel();
    }
  };

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      onClose={()=>handleClose(false)}
      open={open}
    >
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          // className={classes.margin}
          color="primary"
          onClick={()=>handleClose(false)}
          variant="outlined"
        >
          {cancelText}
        </Button>
        <Button
          autoFocus
          // className={classes.margin}
          onClick={()=>handleClose(true)}
          variant="contained"
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}