import React, { useState, useRef, useEffect } from 'react';
import {
  Avatar,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Card,
  Button,
  IconButton,
  Typography,
  CircularProgress
} from '@material-ui/core';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import { red } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';
// import { SettingsRemoteRounded } from '@material-ui/icons';
import { OTVideoDetails } from './OTVideoDetails';
import {getRandomInt}from '../../../common/utilities';

const styles = theme => ({
  card: {
    maxWidth: 345
  },
  media: {
    height: 140,
    paddingTop: '56.25%' // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: red[500]
  }
});

const OTPlanCard = (props) => {
  const [otplan] = useState(props.otplan);
  const [babyName, setBabyName] = useState('');
  const [notes, setNotes] = useState('');
  const [currentImage, setCurrentImage] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const { classes} = props;

  const otvideosplayer = useRef();

  const selectRandomIndex = (size) => {
    // pick an index between 0 and size-1
    return getRandomInt(0,size-1);
  };

  function handleOTVideos(){
    otvideosplayer.current.handleClickOpen();
  }

  useEffect(()=>{
    setBabyName(otplan.childname);
    setNotes(otplan.notes);
    const interval = setInterval(() => {
      setCurrentIndex(selectRandomIndex(otplan.otvideos.length));
    }, 10000);
    return () => clearInterval(interval);
  },[]);

  useEffect(()=>{
    setCurrentImage(otplan.otvideos[currentIndex].thumbnailurl);
  },[currentIndex]);

  return (
    <Card className={classes.card}>
      <CardHeader
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        avatar={
          <Avatar
            aria-label="recipe"
            className={classes.avatar}
          >
            OT
          </Avatar>
        }
        subheader={otplan === null ? ('September 14, 2016') : otplan.timestamp}
        title={otplan === null ?  ('Baby'):(otplan.childname)}
      />

      {otplan.otvideos === undefined ? 
        (<div>
          <CircularProgress />
        </div>) :
        (
          <CardMedia
            className={classes.media}
            image={otplan.otvideos === undefined ? ('') : (currentImage)}
            title="OT Plan"
          />)
      }
      <OTVideoDetails
        otplan={otplan}
        ref={otvideosplayer}
      />
      <CardContent>
        <Typography
          color="textSecondary"
          component="p"
          variant="body2"
        >
          {otplan === null ? '': otplan.notes}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          color="primary"
          onClick={()=>{
            handleOTVideos();
          }}
          size="small"
        >
          View
        </Button>
        {/* <Button
          color="primary"
          size="small"
        >
          Learn More
        </Button> */}
      </CardActions>
    </Card>
  );
};

export default withStyles(styles)(OTPlanCard);
