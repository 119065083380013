import React, { useState } from 'react';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import {
  Grid,
  Button,
  IconButton,
  Divider as MuiDivider,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';

import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
} from '@material-ui/icons';

import {useUserListState} from '../../../context/UserListContext';
const Divider = styled(MuiDivider)(spacing);
const Spacer = styled.div`
  flex: 1 1 100%;
`;

const OtTableToolbar = (props) => {
  
  const [emmaUsers] = useState(useUserListState().allUsers);
  // const [localStartDate, setLocalStartDate] = useState(Date());
  // const [localEndDate, setLocalEndDate] = useState(Date());
 
  const [parentEmail, setParentEmail] = useState('');
  const [planNotes, setPlanNotes] = useState('');
  // const [otplans, setOtplans] = useState([]);

  const handleCreatePlanLocal = event => {
    // console.log('create plan local');
    const payload = {
      parentemail: parentEmail,
      plannotes: planNotes
    };
    props.handleCreatePlan(event, payload);
  };

  // const handleDeleteClickLocal = event => {
  //   // console.log('Delete click local');
  //   // this.props.handleDeleteClick();
  // };

  // const handleStartDateChangeLocal = date => {
  //   // this.setState({localStartDate: date});
  //   // console.log('Start Date, first handler:',date);
  //   // this.props.handleStartDateChange(date);
  // };

  return (
    <div>
      <Toolbar>
        <Grid
          align="left"
          alignItems="flex-end" 
          container
          justify="center"
          spacing={4}
        >
          <Grid
            item
            xs={6}
          >
            <FormControl
              fullWidth
              mb={6}
            >
              <TextField
                id="plannotes"
                label="Plan Notes"
                multiline
                onChange={e=>setPlanNotes(e.target.value)}
                placeholder="Enter instructions for the parent here."
                rows={3}
                rowsMax={2}
                value={planNotes}
              />
            </FormControl>
          </Grid>
          <Grid
            align="bottom"
            item
            xs={4}
          >
            <FormControl
              fullWidth
              mb={6}
            >
              <InputLabel htmlFor="age-simple">Parent Email</InputLabel>
              <Select
                inputProps={{
                  name: 'parentEmail',
                  id: 'parentEmail'
                }}
                onChange={e=>setParentEmail(e.target.value)}
                value={parentEmail}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem> {emmaUsers.map(n=>
                  <MenuItem
                    key={n.uid}
                    value={n.localEmail}
                  > {n.localEmail}</MenuItem>)
                })
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          item
          xs={2}
        >
          <Button
            color="primary"
            onClick={handleCreatePlanLocal}
            size="small"
            variant="contained"
          >Create Plan</Button>      
        </Grid>
      </Toolbar>
      <Divider my={6} />
      <Toolbar>
        <div>
          {props.numSelected > 0 ? (
            <Typography
              color="inherit"
              variant="subtitle1"
            >
              {props.numSelected} selected
            </Typography>
          ) : (
            <Typography
              id="tableTitle"
              variant="h6"
            >
          Videos
            </Typography>
          )}
        </div>
        <Spacer />       
        <div>
          {props.numSelected > 0 ? (
            <Tooltip title="Delete">
              <IconButton
                aria-label="Delete"
                onClick={props.handleDeleteClickLocal}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Filter list">
              <IconButton aria-label="Filter list">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </Toolbar>
    </div>
  );
};

export default OtTableToolbar;