import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';

const firebaseConfig = {
  // apiKey: 'AIzaSyA3K5KJExoZkXK6ZLdFQ33NJ5a2xEMk4rg',
  // authDomain: 'scoreit-firestore.firebaseapp.com',
  // databaseURL: 'https://scoreit-firestore.firebaseio.com',
  // projectId: 'scoreit-firestore',
  // storageBucket: 'scoreit-firestore.appspot.com',
  // messagingSenderId: '271080142213',
  // appId: '1:271080142213:web:f364a2406552a26a8240ec',
  // measurementId: 'G-H04QNF9NZQ'
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  // messagingSenderId: '271080142213',
  // appId: '1:271080142213:web:f364a2406552a26a8240ec',
  // measurementId: 'G-H04QNF9NZQ'
};

// Exporting only the part of firebase that are needed would be
// ideal
firebase.initializeApp(firebaseConfig);
const firebaseAuth = firebase.auth();
const firebaseStorage = firebase.storage();

export {firebaseAuth, firebaseStorage, firebase as default};