
import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Container } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { MarkerView } from './MarkerView';

const useStyles = makeStyles((theme) => ({
  // controlsWrapper: {
  //   visibility: 'visible',
  //   position: 'absolute',
  //   top: 0,
  //   left: 0,
  //   right: 0,
  //   bottom: 0,
  //   height: '100%',
  //   background: 'rgba(0,0,0,0.6)',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'space-between',
  // },
  progressWrap:{
    flexGrow: 1,
    position: 'relative',
    //magic numbers the centers the tags on default view
    bottom:'43px',
    left:'54px',
    width:'calc(100% - 54px - 223px);',
    minWidth:'60px'
  }
}));

const VideoOverlay = forwardRef(
  (
    {
      duration,
      markers,
    }, 
    ref
  ) => {
    const classes = useStyles();

    return (
      <div
        className={classes.progressWrap}
        ref={ref}
      >
        {markers &&
        markers.map((marker, index) => {
          return (
            <MarkerView
              duration={duration}
              key={index}
              marker={marker}
              // onMarkerClick={this.handleOnMarkerSelection}
              // selectedMarker={selectedMarker}
              // configuration={markerConfiguration}
            />
          );
        })}              
      </div>
    );
  }
);
VideoOverlay.propTypes = {
  duration: PropTypes.number,
  markers: PropTypes.array,

};
export default VideoOverlay;