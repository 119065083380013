import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';

import {
  useUserState,
} from '../../context/UserContext';

import { ReportInfoActionTypes, ReportInfoContext } from '../../context/ReportContext';
import { EmailStatusSchemaProps } from '../../common/mongoSchemas';
import { updateReportFileInfo } from '../../common/apiSetUtilities';

export type EmailConfirmationDialogProps = {
  isOpen:boolean,
  setIsOpen:(status:boolean)=>void
}

// It is unclear how/where in the ui the instrument is to be selected.
export const EmailConfirmationDialog = (props:EmailConfirmationDialogProps):JSX.Element=> {

  const {isOpen, setIsOpen} = props;
  const {reportInfoState, reportInfoDispatch} = React.useContext(ReportInfoContext);
  const {dbInformation} = reportInfoState;
  const loggedInUser = useUserState();
  const {firebaseUser} = loggedInUser;

  const handleClose = () => {
    setIsOpen(false);
  };  

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if(dbInformation){
      const emailStatus:EmailStatusSchemaProps = {
        sendVerified:true,
        sendVerifierId:loggedInUser.emmauserId?loggedInUser.emmauserId:'',
        sendRequesterId:loggedInUser.emmauserId?loggedInUser.emmauserId:'',
        sendRequested:true,

      };
      const res = await updateReportFileInfo(firebaseUser, {reportId:dbInformation.reportId, emailData:emailStatus});
      if(res){
        dbInformation.emailStatus = emailStatus;
        reportInfoDispatch({type:ReportInfoActionTypes.UPDATE_DBINFO, dbInformation:dbInformation});
      }
    }
    setIsOpen(false);
  };

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={'sm'} // 'sm' || 'md' || 'lg' || 'xl'
      onClose={handleClose}
      open={isOpen}        
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-dialog-title">          
          Email Information
        </DialogTitle>
        <DialogContent>
          <Typography
            color="textSecondary"
            variant="subtitle2"
          >
            Please confirm that report information for {reportInfoState.reportInformation.asmtSubjectName}&apos;s 
            assessment on {new Date(reportInfoState.reportInformation.assessmentDate?reportInfoState.reportInformation.assessmentDate:'').toLocaleDateString()} has been sent to
            {reportInfoState.reportInformation.parentEmail}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
          >
            Confirm email has been sent
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
