/* eslint-disable react/no-multi-comp */
import React, {useState, useEffect, useReducer, ReactNode} from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';

import * as AppRoutes from '../common/routeNames';
// components
import Layout from './Layout';
// pages
import Login from '../pages/auth/login/Login';
import ResetPassword from '../pages/auth/restpassword/ResetPassword';
import Error from '../pages/error/Error';
// context
import { useUserState } from '../context/UserContext';
import {addDeviceToken, addNotification} from '../common/apiSetUtilities';

import NotificationsAcceptedContext from '../context/NotificationContext';
import {
  isPushNotificationSupported,
  registerServiceWorker,
  askUserPermission,
  createNotificationSubscription,
  sendSubscriptionToPushServer,
  // sendNotification
} from '../common/PushNotification';
//the above was the old way of doing it
//switching to capacitor push-notifications below
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { Toast } from '@capacitor/toast';
import { 
  reportInfoReducer,
  ReportInfoContext,
  ReportInfoActionTypes,
  defaultReportInfo
} from '../context/ReportContext';
import {
  defaultAssessmentInfo,
  assessmentInfoReducer,
  AssessmentInfoContext,
  AssessmentInfoActionTypes

} from '../context/AssessmentInfoContext';
import { EmmaUserSchemaProps } from '../common/mongoSchemas';
import { codePush } from 'capacitor-codepush';
import {isMobile, isBrowser, isIOS, isAndroid} from 'react-device-detect';

export function App() {
  // global
  const { isAuthenticated,firebaseUser, authLevel } = useUserState();
  const userState = useUserState();
  const [emmaUser] = useState(useUserState());
  const nullEntry: any[] = [];
  const [acceptedNotifications, setAcceptedNotifications] = useState(false);
  const [capacitorNotifications, setCapacitorNotifications] = useState(nullEntry);
  const [isPushNotificationsAvailable, setPushNotificationsAvailable] = useState(Capacitor.isPluginAvailable('PushNotifications'));
  const [deviceToken, setDeviceToken]  = useState('');
  const [oldNotificationsList, setOldNotificationsList] = useState([]);
  useEffect( ()=> {
    const capPushNotifictionAllowed = false;

    //ask user for permission?

    if(!isAuthenticated) return;
    console.log('Checking push permissions');
    isPushNotificationsAvailable && PushNotifications.checkPermissions().then((res) => {
      if (res.receive !== 'granted') {
        PushNotifications.requestPermissions().then((res) => {
          if (res.receive === 'denied') {
            showToast('Push Notification permission denied');
          }
          else {
            showToast('Push Notification permission granted');
            register();
          }
        });
      }
      else {
        showToast('Registering push notification');
        register();
      }
    });

  },[isAuthenticated]);

  useEffect ( () => {
    if (isIOS || isAndroid){
      console.log('isIOS:', isIOS);
      console.log('isMobile:', isMobile);
      console.log('isAndroid:', isAndroid);
      console.log('SYNC CODE PUSH AT APP STARTUP...');
      if (isIOS || isAndroid){
        console.log('Codepush plugin is available on this platform');
        //codePush.sync();
        //Sep 2022
        //putting codepush on hold
      }
      else{
        console.log('Codepush is not available');
      }
      if (Capacitor.isPluginAvailable('PushNotifications')){
        console.log('Push notifications plugin available');
      }
    }
  },[]);


  useEffect( ()=> {
    if (!isAuthenticated) { return;}
    if (deviceToken == '') {return;}
    console.log('addDeviceToken:', deviceToken);
    addDeviceTokenToEmmaUser(deviceToken);

  }, [deviceToken, isAuthenticated,userState?.emmauserId]);

  const addDeviceTokenToEmmaUser = (token:any)=>{

    console.log('Trying to add devicetoken to emmauser:', userState?.emmauserId);
    if (deviceToken == '' || userState.emmauserId == null) { return; }

    console.log('deviceIds of current emmaUser:', userState.deviceIds);
    const mongoUserValuesToUpdate:Partial<EmmaUserSchemaProps> = {};
    mongoUserValuesToUpdate.deviceIds = [];
    for (const id of userState.deviceIds){
      mongoUserValuesToUpdate.deviceIds.push(id);
    }
    console.log('DeviceIds of emmaUser before update:',mongoUserValuesToUpdate.deviceIds);

    addDeviceToken(firebaseUser, userState.emmauserId, mongoUserValuesToUpdate, token)
      .then(updatedUser=>{
        // The updated user will have the emmauserid/mongoid
        console.log('The updated user after addDeviceToken:', updatedUser);
      });

  };

  const register = async() => {
    console.log('Registering Notifications via Capacitor');
    
    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        //showToast('Push registration success with token' );
        console.log('Push token:', JSON.stringify(token));
        console.log('Value:', token.value);
        setDeviceToken(token.value);
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        setCapacitorNotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }]);
        const message = notification.title + ': ' + notification.body;
        showToast(message);
        console.log('Push message received');
        console.log('NOTIFICATION:', JSON.stringify(notification));
        //use SCOREIT API to stash this notification
        //message sender (server) would already have added this notification
        //don't do it on the client end
        //addNotification(firebaseUser, userState.emmauserId, notification.title, notification.body);
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      async(notification: ActionPerformed) => {
        setCapacitorNotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }]);
        
        console.log('Notification actionperformed:', JSON.stringify(notification));
        if (notification.notification.data.body != '' && notification.notification.data.body != undefined){
          //use SCOREIT API to stash this notification
          const message = notification.notification.data.title + ': ' + notification.notification.data.body;
        //message sender (server) would already have added this notification
        //don't do it on the client end
          //addNotification(firebaseUser, userState.emmauserId, notification.notification.data.title, notification.notification.data.body);
        }

        const notificationList = await PushNotifications.getDeliveredNotifications();
        console.log('Delivered Notifications:', JSON.stringify(notificationList.notifications));
      }
    );

    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();

    //get existing notifications that were received while app was offline?

    const notificationList = await PushNotifications.getDeliveredNotifications();
    console.log('Delivered Notifications:', notificationList.notifications);
    
  };

  
  const showToast = async (msg: string) => {
    await Toast.show({
      text: msg
    });
  };
  //For useEffect and async
  //https://stackoverflow.com/questions/53332321/react-hook-warnings-for-async-function-in-useeffect-useeffect-function-must-ret
  //https://github.com/facebook/react/issues/14326
  //https://www.robinwieruch.de/react-hooks-fetch-data
  useEffect(()=>{
    //Ug that was a waste of time, the call the serviceworker.unregister in index.tsx messed everything up.
    // async function enableNotifications() {
    //   // Hmm this registration is a bit odd
    //   // const swRegistration = await registerServiceWorker();
    //   // console.log('registered ' + swRegistration);
    //   const consent = await askUserPermission();
    //   console.log('fddf', consent);
    //   if(consent === 'granted') {
    //     sendNotification();
    //     const userSubscription = await createNotificationSubscription();
    //     sendSubscriptionToPushServer(userSubscription);
    //     setAcceptedNotifications(true);
    //   }        
    // }
    if(!isAuthenticated) return;
    const pushNotificationsSupported = isPushNotificationSupported();
    if (pushNotificationsSupported) {
      //What happens on multiple registers?
      registerServiceWorker();
      // enableNotifications();
      askUserPermission().then(function(consent){
        if(consent === 'granted') {
          // sendNotification();
          const userSubscription = createNotificationSubscription();
          if (firebaseUser){
            sendSubscriptionToPushServer(userSubscription, firebaseUser.bearerToken, firebaseUser.uid);
            setAcceptedNotifications(true);
          }
        }
      });
    }
  },[isAuthenticated]);


  interface RouteProps {
    component: any,
    path:any,
    children? : ReactNode
  };

  function UserRoute({ component, ...rest }: RouteProps) {
    return (
      <Route
        {...rest}
        // This will need to be stricter, ie check authlevel and
        // then give each page a required authlevel to view.
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: `${AppRoutes.SIGN_IN}`,
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }
  

  const PublicRoute = ({ component, ...rest }: RouteProps) =>{
    const redirectPage = authLevel < 0?AppRoutes.EMMA_USER_SETINGS:AppRoutes.LANDING;
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: `${redirectPage}`,
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  };

  return (
    <NotificationsAcceptedContext.Provider value={acceptedNotifications}>
      <HashRouter>
        <Switch>
          <Route
            exact
            path={AppRoutes.LANDING}
            render={() => <Redirect to={AppRoutes.DASHBOARD}/>}
          />
          <Route
            exact
            path={AppRoutes.APP}
            render={() => <Redirect to={AppRoutes.DASHBOARD} />}
          />
          <UserRoute
            component={Layout}
            path={AppRoutes.APP}
          />
          <PublicRoute
            component={Login}
            path={AppRoutes.SIGN_IN}
          />
          <PublicRoute
            component={ResetPassword}
            path={AppRoutes.REST_PASSWORD}
          />
          <Route component={Error} />
        </Switch>
      </HashRouter>
    </NotificationsAcceptedContext.Provider>
  );
}

export default App;
