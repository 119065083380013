import React from 'react';

import PropTypes from 'prop-types';

import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@material-ui/core';

const rows = [
  {
    id: 'instructionalvideoId',
    numeric: false,
    disablePadding: true,
    label: 'Video'
  },
  { id: 'title', numeric: true, disablePadding: false, label: 'Title' } 
];

const OtTableHead = (props) => {

  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount
  } = props;

  const createSortHandler = property => event => {
    props.onRequestSort(event, property);
  };

  return (
    
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            checked={numSelected === rowCount}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {rows.map(
          row => (     
            <TableCell
              align={row.numeric ? 'right' : 'left'}
              key={row.id}
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}
            >
              <Tooltip
                enterDelay={300}
                placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                title="Sort"
              >
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={createSortHandler(row.id)}
                >
                  {row.label}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          ),
          this
        )}
      </TableRow>
    </TableHead>
  );
};

OtTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

export default OtTableHead;