
import React, {useEffect, useContext, useRef, useState} from 'react';
import { FormControl, FormGroup, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { calculateScoreById } from '../../common/apiGetUtilities';
import { GenericScoreSchemaProps, InstrumentSchemaProps } from '../../common/mongoSchemas';
import { AssessmentInfoActionTypes, 
  AssessmentInfoContext,
  indexOfLibraryName
} from '../../context/AssessmentInfoContext';
import { useUserState } from '../../context/UserContext';

import { AimsItemScoreCard } from './AimsItemScoreCard';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

export const LiveScoring = ():JSX.Element => {
  const classes = useStyles();

  const user = useUserState();
  const {assessmentInfoState, assessmentInfoDispatch} = useContext(AssessmentInfoContext);
  const {activeInstrumentName, activeScoringTypeName, instrumentLibraries, activeAsmtId, 
    activeUserId, hackScoreUpdate} = assessmentInfoState;

  const [scoreData, setScoreData] = useState<GenericScoreSchemaProps>({totalScore:-1});
  //gets called at each redraw
  const [selectedLibrary, setSelectedLibrary] = useState<InstrumentSchemaProps | undefined>(()=>{
    if(instrumentLibraries && instrumentLibraries.length >0){
      return instrumentLibraries[indexOfLibraryName(assessmentInfoState)];
    }    
  });
  const [allScoreModes, setAllScoreModes] = useState<string[]>([]);

  useEffect(()=>{
    calcScore();    
  }, [activeScoringTypeName, activeInstrumentName, activeUserId]);

  useEffect(() => {
    // A change to the instrument type has been triggered
    // update the scoremode if the current scoremode is not part of the new library
    if(!selectedLibrary) return;
    const defaultScoringName = 'Default';
    const scoringOptions = selectedLibrary.scoringMethodNames as string[] || [defaultScoringName];
    if(activeScoringTypeName && scoringOptions.includes(activeScoringTypeName)){
      // if the current scoremode (activeScoringTypeName) is present and matches the currently set
      // value, update with the new library name, but keep current active scoring name.
      setAllScoreModes(scoringOptions);
      assessmentInfoDispatch({type:AssessmentInfoActionTypes.ACTIVE_INSTRUMENT, activeScoringTypeName:activeScoringTypeName,
        activeInstrumentName:selectedLibrary.name});
    }else{
      // Scoring mode was not set or it did not match a value in the scoring options so we 
      // need to reset the scoring option 
      setAllScoreModes(scoringOptions);
      assessmentInfoDispatch({type:AssessmentInfoActionTypes.ACTIVE_INSTRUMENT, activeScoringTypeName:scoringOptions[0],
        activeInstrumentName:selectedLibrary.name});
    }
  },[selectedLibrary]);

  useEffect(() =>{
    calcScore();
  },[hackScoreUpdate]);

  const calcScore = async () => {
    if(selectedLibrary === undefined) return;//actually set score to bad values

    let instrumentId = selectedLibrary.instrumentId;
    if(instrumentId === undefined) instrumentId = '0';
    if(!user.emmauserId){
      return;
    }
    const _scoreData = await calculateScoreById(user.firebaseUser, activeUserId, 
      activeAsmtId, instrumentId, activeScoringTypeName);
    if('isErrorType' in _scoreData){
      //do something with the error
    }else{
      // icky poooo!
      const castScore = _scoreData;//(_scoreData as unknown) as GenericScoreSchemaProps;
      setScoreData(castScore);
    }
  };

  return (
    <div className={classes.root}>
      <div >
        <Autocomplete
          disableClearable
          getOptionLabel={(libraryType) => libraryType.name}
          onChange={(event: any, newValue: InstrumentSchemaProps) => {
            setSelectedLibrary(newValue);
          }}
          options={instrumentLibraries}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Instrument Name"
              variant="outlined"
            />
          )}
          style={{paddingTop: 10 }}
          value={selectedLibrary}
        />
      </div>

      {(allScoreModes !== undefined && allScoreModes.length > 1) &&
      <div>
        <Autocomplete
          disableClearable
          onChange={(event, newValue)=> {
            assessmentInfoDispatch({type:AssessmentInfoActionTypes.ACTIVE_SCORINGTYPE, activeScoringTypeName:newValue});
          }}
          options={allScoreModes}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Scoring Method"
              variant="outlined"
            />
          )}
          style={{paddingTop: 10 }}
          value={activeScoringTypeName}
        />
      </div>
      }
      <AimsItemScoreCard
        {...scoreData}
      />
    </div>
  );
};


     
{/* <Card className={classes.card}>
        <CardContent className={classes.cardcontent}>
          <Typography
            color="secondary"
            gutterBottom
            variant={'h6'}
          >
                              Details
          </Typography>
          <Divider
            className={classes.divider}
            light
          />
          <Grid
            alignItems="center" 
            container 
            justify="space-between"
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <Typography variant="subtitle2">
                                  Parent
              </Typography>
              <Typography variant="caption">
                {asmtData.parent}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Typography variant="subtitle2">
                                  Assessment Date
              </Typography>
              <Typography variant="caption" >
                {new Date(asmtData.date).toDateString()}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Typography variant="subtitle2">
                                Infant DOB
              </Typography>
              <Typography variant="caption">
                {new Date(asmtData.dob).toDateString()}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
            >  
              <Typography variant="subtitle2">
                                  Assessor
              </Typography>
              <Typography variant="caption">
                {asmtData.assessor}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card> */}