import { Typography, TextField, Button, Grid, Link} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { FormEvent, useContext, useEffect, useState, useCallback} from 'react';
import { setUserInfo } from '../../../common/apiSetUtilities';
import { UserProps } from '../../../common/entityUtilities';
import { GeneralControlActionTypes, GeneralControlContext } from '../../../context/GeneralControlContext';
import { useUserState } from '../../../context/UserContext';
import { useUserListState } from '../../../context/UserListContext';
import NewAssessmentDialog from '../../../dialogs/NewAssessmentDialog/NewAssessmentDialog';
import { UserInfoDialog } from '../../../dialogs/UserInfoDialog/UserInfoDialog';

import { getSubjectDataForUserId } from '../../../common/apiGetUtilities';
import {showAlert} from '../../../common/utilities';
import useStyles from './styles';

type AsmtAndUserControlComponenttProps = {
  initialParentEmail?:string
  assessmentToUpdate:any
}

export const AsmtAndUserControlComponent = (props:AsmtAndUserControlComponenttProps):JSX.Element => {

  const classes = useStyles();
  const {generalControlState, generalControlDispatch} = useContext(GeneralControlContext);
  const { allUsers } = useUserListState();
  const userState = useUserState();
  const [emmaUser] = useState(useUserState());
  const [subjectInfo, setSubjectInfo] = useState();
  const {initialParentEmail, assessmentToUpdate} = props;
  const [parentInformation, setParentInformation] = useState<UserProps|null>(null);
  const [asmtToUpdate, setAsmtToUpdate] = useState();
  const [parentEmailForCreate, setParentEmailForCreate] = useState<string|null>('');
  const loggedInUser= useUserState();

  // Hackish
  useEffect(() => {
    if(!initialParentEmail){
      setParentEmailForCreate(null);
    }else{
      setParentEmailForCreate(initialParentEmail);
    }
  }, [initialParentEmail]);

  // need to do this if logged in user is a parent and not an assessor/administrator
  useEffect( ()=> {
    console.log('useeffect');
    if (loggedInUser.authLevel == 0){ //parent
      console.log('parent user logged in:', emmaUser);
      const matchingParent = allUsers.find( user=>user.localEmail == loggedInUser.localEmail);
      if (matchingParent){
        console.log('setting parentinfo with:', matchingParent);
        setParentInformation(matchingParent);
      }
    }
  }, [] );
  
  // in order to do async things inside useEffect, follow this model
  //
  const fetchSubject = useCallback(async () => {
    const subject = await getSubjectDataForUserId(userState.firebaseUser, userState.emmauserId);
    setSubjectInfo(subject);

    let parentInfo:any = [];
    parentInfo = emmaUser;
    parentInfo['subject'] = subject;
    console.log('Parent info with subject in useEffect:', parentInfo.subject);
    setParentInformation(parentInfo);

  }, []);

  useEffect( () => {
    fetchSubject()
      .catch(console.error);

  }, [fetchSubject]);

  useEffect(  () => {
    console.log('parentEmailforCreate;,', parentEmailForCreate);
    if(parentEmailForCreate === null){
      setParentInformation(null);
      return;
    }

    if (loggedInUser.authLevel == 0) {
      return;
    }

    if (allUsers.length == 0) return;

    const matchingParent = allUsers.find(user=>user.localEmail === parentEmailForCreate);
    console.log('The matchingParent ', matchingParent);
    if(matchingParent){
      setParentInformation(matchingParent);
    }
  }, [parentEmailForCreate]);

  useEffect(  () => {
    if (parentInformation && parentInformation?.subject == null && loggedInUser.authLevel == 0){
      console.log('This parent user does not have a subject properly registered');
      showAlert('Baby Info', 'You have not set up your baby info. Head to the HOME page, select FAMILY DETAILS, enter details under' + 
      ' Baby Info section, and Save.', 'Got it');
    }
  }, [parentInformation]);


  useEffect(()=>{
    console.log('asmtToUpdate for parent;,', parentEmailForCreate);
    setParentEmailForCreate(assessmentToUpdate?.parent);
   
    const matchingParent = allUsers.find(user=>user.localEmail === assessmentToUpdate?.parent);
    console.log('The matchingParent ', matchingParent);
    if(matchingParent){
      setParentInformation(matchingParent);
    }

    setAsmtToUpdate(assessmentToUpdate);
  }, [assessmentToUpdate]);



  const newParentDialogComplete= () =>{
    // setParentCreateDialogIsOpen(false);
    generalControlDispatch({type:GeneralControlActionTypes.USERINFO_DIALOG, 
      isUserInfoDialogVisible:false}); 
  };

  const newAsmtDialogComplete = () => {
    console.log('Closing dialog');
    setAsmtToUpdate(undefined);
    generalControlDispatch({type:GeneralControlActionTypes.NEWASMT_DIALOG, 
      isNewAssessmentDialogVisible:false});
  };

  // const updateAssessment = (assessmentToUpdate) =>{
  //   generalControlDispatch({type:GeneralControlActionTypes.NEWASMT_DIALOG, 
  //     isNewAssessmentDialogVisible:true}); 
  //   setAsmtToUpdate(assessmentToUpdate);// soon to be pulled into a context
  // };
  return (
    <div className={classes.paper}>
      {loggedInUser?.authLevel > 0 ? (
        <Grid
          container
          direction="column"
          justify="flex-start"
          spacing={1}
        >
          
          <Grid
            container
            item
            direction="row"
            xs={12} sm={12}
            spacing={2}
          >
            <Grid item xs={12} sm={4}>
              <Typography
                color="secondary"
                gutterBottom
                variant="h4"
              >
                Add new assessment for:             
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div>
                <Autocomplete
                  autoHighlight
                  blurOnSelect
                  getOptionLabel={(option)=> (option && option.localEmail)?option.localEmail:'unknown'}
                  // { // functionally equivalent, gads all this short hand
                  //   return (option && option.localEmail)?option.localEmail:'unknown';
                  // }}
                  handleHomeEndKeys
                  id="assessment-for-parent-selector"
                  onChange={(event, newValue) => {
                    console.log('Selected parent:',newValue);
                    if(newValue && newValue.localEmail){
                      setParentEmailForCreate(newValue.localEmail);
                    }else{
                      setParentEmailForCreate(null);
                    }
                  }}
                  options={allUsers}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Parent Email"
                      variant="outlined"
                    />
                  )}
                  selectOnFocus
                  style={{ width: 300 }}        
                  value={parentInformation}
                />
              </div>
            </Grid>
        

            <Grid
              container 
              direction="row"
              item
              xs={12} sm={6}
            >
              <Grid 
                item
                xs={6}
              >
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  color="secondary"
                  id="parent-firstname"
                  label = "Parent Firstname"
                  value = {parentInformation ? parentInformation.firstname : ''}
                />
              </Grid>
              <Grid 
                item
                xs={6}
              >
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  color="secondary"
                  id="parent-lastname"
                  label = "Parent Lastname"
                  value = {parentInformation ? parentInformation.lastname : ''}
                />
              </Grid>
            </Grid>

          </Grid>
        
          <Grid container 
            direction="column"
            spacing={2}>
            <Grid item>
              Do you not see the parent here? Click on the Add Family button below and enter information
            </Grid>
            <Grid
              alignItems="stretch"
              container
              direction="row"
              item
              spacing={2}
              xs={12}
            >
              <Grid
                item
                xs={5} sm={3}
              >
                <Button
                  color="primary"
                  disableElevation
                  disabled={!parentInformation}
                  fullWidth
                  onClick={()=>{
                    generalControlDispatch({type:GeneralControlActionTypes.NEWASMT_DIALOG, 
                      isNewAssessmentDialogVisible:true}); 
                    // setAsmtCreateDialogIsOpen(true);
                  }}
                  variant="contained"
                >
              New Asmt
                </Button>
              </Grid>
              <Grid
                item
                xs={5} sm={3}
              >
                <Button
                  color="primary"
                  disableElevation
                  fullWidth
                  onClick={()=>{
                    generalControlDispatch({type:GeneralControlActionTypes.USERINFO_DIALOG, 
                      isUserInfoDialogVisible:true}); 
                    // setParentCreateDialogIsOpen(true);
                  }}
                  variant="contained"
                >
                Add Family
                </Button>
              </Grid>
            </Grid>

          </Grid>
        </Grid>): (<div></div>)}

      {loggedInUser.authLevel == 0 ? (
        <div>
          <Grid
            container
            direction="column"
            justify="flex-start"
            spacing={2} 
          >
            <Grid container 
              spacing={1}>
              <Grid item>
                <Typography 
                  gutterBottom
                  color="secondary"
                  variant="h4">
                Send a new video
                </Typography>
              </Grid>
              <Grid
                item
                xs={5} sm={3}
              >
                <Button
                  color="primary"
                  disableElevation
                  fullWidth
                  disabled = {parentInformation?.subject === undefined}
                  onClick={()=>{
                    generalControlDispatch({type:GeneralControlActionTypes.NEWASMT_DIALOG, 
                      isNewAssessmentDialogVisible:true}); 
                    console.log('parent info:', parentInformation);
                    // setAsmtCreateDialogIsOpen(true);
                  }}
                  variant="contained"
                >
                  Start
                </Button>
              </Grid>
              <Grid item>
                <Typography variant='body1' >
                  be ready to upload your video
                </Typography>
              </Grid>
            </Grid>
          
          </Grid>
        </div>

      ): (<div></div>)}   

      <NewAssessmentDialog
        asmtToEdit={asmtToUpdate}
        dialogComplete = {newAsmtDialogComplete}
        isOpen={generalControlState.isNewAssessmentDialogVisible}
        parentInformation={parentInformation}
      />      
      <UserInfoDialog // uggggg, not to worry this will soon be out
        // only used for creating new parents
        dialogComplete = {newParentDialogComplete}
        // hmm consolodating the userinfo dialog calls would be nice
        isOpen={generalControlState.isUserInfoDialogVisible}
        userInformation={null}
      />
    </div>  
  );
};


