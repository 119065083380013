import React from 'react';
import styled from 'styled-components';

import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';

import { 
  // red, 
  green, 
  orange 
} from '@material-ui/core/colors';

import { spacing } from '@material-ui/system';
import { useTheme } from '@material-ui/styles';

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${props => props.rgbcolor};
  color: ${props => props.theme.palette.common.white};
`;
const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${props => props.theme.spacing(12)}px);
`;

// Data
let id = 0;
function createData(name, date, link, state) {
  id += 1;
  return { id, name, date, link, state };
}

const DashboardTable = () => {
  const theme = useTheme();

  const rows = [
    createData(
      'Hands to feet',
      '01/01/2019',
      'Link',
      <Chip label="Ready" rgbcolor={green[500]} theme={theme}/>,
    ),
    createData(
      'Head lift',
      '01/10/2019',
      'Link',
      <Chip label="Ready" rgbcolor={green[500]} theme={theme}/>,
    ),
    createData(
      'Roll over',
      '01/17/2019',
      'Link',
      <Chip label="In progress" rgbcolor={orange[500]} theme={theme}/>,
    ),
  ];

  return (
    <Card mb={6}>
      <CardContent pb={1}>
        <Typography variant="h6" gutterBottom>
        Latest milestones
        </Typography>
      </CardContent>
      <Paper>
        <TableWrapper theme={theme}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Activity</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Videos</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.link}</TableCell>
                  <TableCell>{row.state}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </Paper>
    </Card>
  );
};

export default DashboardTable;
