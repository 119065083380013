/* eslint-disable no-inner-declarations */
import {firebaseStorage} from '../firebaseConfig';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

export const generateDocument = async (dataSet, baseFileName) => {
  if(!dataSet) return 'No Data set';
  const fullUrl = await firebaseStorage.ref('templates/MIMM Kindering Report Templated.docx').getDownloadURL();
  console.log('docs', fullUrl);

  //https://docxtemplater.com/tag-example.docx
  // const prodFile = 'https://firebasestorage.googleapis.com/v0/b/emma-production-8d3c4.appspot.com/o/templates%2FMIMM%20Kindering%20Report%20Templated.docx?alt=media&token=b451d44c-e446-4e44-8fe5-0f76bda527d6';
  const devFile = 'https://firebasestorage.googleapis.com/v0/b/scoreit-dev-api.appspot.com/o/templates%2FMIMM%20Kindering%20Report%20Templated.docx?alt=media&token=c6bad2c9-7e2d-46ae-935c-4dbc5bac1644';
  loadFile(fullUrl, function(
    error,/*  */
    content
  ) {
    if (error) {
      console.log('error', error);
      throw error;
    }
    var zip = new PizZip(content);
    var doc = new Docxtemplater().loadZip(zip);
    doc.setData(dataSet);
    try {
      // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
      doc.render();
    } catch (error) {
      // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
      function replaceErrors(key, value) {
        if (value instanceof Error) {
          return Object.getOwnPropertyNames(value).reduce(function(
            error,
            key
          ) {
            error[key] = value[key];
            return error;
          },
          {});
        }
        return value;
      }
      console.log(JSON.stringify({ error: error }, replaceErrors));

      if (error.properties && error.properties.errors instanceof Array) {
        const errorMessages = error.properties.errors
          .map(function(error) {
            return error.properties.explanation;
          })
          .join('\n');
        console.log('errorMessages', errorMessages);
        // errorMessages is a humanly readable message looking like this :
        // 'The tag beginning with "foobar" is unopened'
      }
      throw error;
    }
    var out = doc.getZip().generate({
      type: 'blob',
      mimeType:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    }); //Output the document using Data-URI
    saveAs(out, `${baseFileName}.docx`);
  });
};
