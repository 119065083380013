import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import FlipCameraAndroidTwoTone from '@material-ui/icons/FlipCameraAndroidTwoTone';
import { useReactMediaRecorder } from 'react-media-recorder';
import VideoRecorder from 'react-video-recorder';
import { SaveStatusTypes } from '../../common/entityUtilities';
import AsmtCreationProgressComponent from '../NewAssessmentDialog/components/AsmtCreationProgressComponent';
import {ConfirmationDialog} from './ConfirmationDialog';

import { useUserState, refreshToken} from '../../context/UserContext';
import RecordRenderActions from './render/render-actions';

import {
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Snackbar
} from '@material-ui/core';

import {useUserListState} from '../../context/UserListContext';
import { sleep } from '../../common/utilities';

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const FACING_MODE_USER = 'user';
const FACING_MODE_ENVIRONMENT = 'environment';
const videoConstraints = {
  audio:true,
  video: {
    frameRate: { ideal: 30, min:25, max:30 },
    width: { min: 640, ideal:1280, max: 1920 },
    height: { min: 480,  ideal:720, max: 1080 },
    autoGainControl: false,
    facingMode: {ideal: FACING_MODE_ENVIRONMENT}
  }
};

export  const AssessmentRecordDialog = (props) => {
  

  const {
    onClose, 
    assessmentProp, 
    open 
  } = props;

  const [videoBlob, setVideoBlob] = useState(null);
  const [savingStatus, setSavingStatus] = useState(SaveStatusTypes.INITIALIZE);
  const [asmtToSavePayload, setAsmtToSavePayload] = useState(null);
  const [videos, setVideos] = React.useState([]);
  const [confirm, setConfirm] = React.useState(false);
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);
  const [isRecording, setIsRecording] = React.useState(false);

  const { allUsers } = useUserListState();
  const user = useUserState();
  const videoRecorderRef = React.useRef();



  const [toasterOpen, setToasterOpen] = useState(false);
  const [toasterMessage, setToasterMessage] = useState('Saving');

  const [frameGrabberTimer, setFrameGrabberTimer] = useState(null);
  const [thumbnailBlob, setThumbnailBlob] = useState(null);

  const [partialDataTimer, setPartialDataTimer] = useState(null);


  useEffect( ()=>{
    if (isRecording){
      handleGetThumbnail();
      handleGetPartialRecording();
    }
  }, [isRecording]);

  useEffect( () => {
    console.log('VIDEOS LENGTH:', videos.length);
  }, [videos]);

  const handleSave = async () => {

    handleRefresh();
    console.log('SAVING RECORDING...');
    sleep(500);

    // if the blob is empty, do we proceed with video upload?
    if (videos.length == 0){
      console.log('Empty blob...returning without uploading');
      setSavingStatus(SaveStatusTypes.DONE);
      return;
    }
    else{
      console.log('Number of blobs to save:', videos.length);
      
    }

    // Get details about the parent in this assessment
    // those are needed to create new observations
    if(assessmentProp.parent){
      const matchingParent = allUsers.find(user=>user.localEmail === assessmentProp.parent);
      if (matchingParent){
        console.log('Matching parent:', matchingParent);
        assessmentProp.ownerId = matchingParent.emmauserId;
        assessmentProp.subjectId = matchingParent.subject.subjectId;
      }
      else{
        assessmentProp.ownerId = assessmentProp.parent; // this is the parent email, not their emmaUserId
      }
    }

    

    var videoSuffix = 0;
    for (const vid of videos){
      // videos from blobs recorded
      // they need a timestamp
      let current_datetime = new Date();
      vid.lastModifiedDate = current_datetime;
      let formatted_date = current_datetime.getFullYear() + '-' + (current_datetime.getMonth() + 1) + '-' + current_datetime.getDate() + ' ' + current_datetime.getHours() + ':' + current_datetime.getMinutes() + ':' + current_datetime.getSeconds(); 
      vid.name = 'recording-' + formatted_date + '-' + videoSuffix;
      videoSuffix = videoSuffix + 1; // this is to make sure the blobs have different names
      console.log('saving video of size:' +  vid.size + ' with name:', vid.name);
    }

    assessmentProp.videosForUpload = videos;
    assessmentProp.existingVideoIdsToUse = [];
    console.log('Asmt to save:', assessmentProp);

    setSavingStatus(SaveStatusTypes.SAVING);
    
    setToasterMessage('Saving video ' + videos[0].size + ' bytes');
    setToasterOpen(true);

    console.log('Setting asmt payload to save');
    setAsmtToSavePayload(assessmentProp);
    setVideos([]); //reset videos
  };

  const handleCameraFlip = () => {
    console.log('FLIPPING camera from:', facingMode);
    console.log(videoRecorderRef.current);
    videoRecorderRef.current.handleSwitchCamera();
    console.log('After Switch');
    //const supported = navigator.mediaDevices.getSupportedConstraints();
    //print('Supported constraints:', supported);

    setFacingMode(
      prevState =>
        prevState === FACING_MODE_USER
          ? FACING_MODE_ENVIRONMENT
          : FACING_MODE_USER
    );
  };

  const handleClose = (confirmationValue) => {
    if(savingStatus==SaveStatusTypes.SAVING) return;


    if (savingStatus != SaveStatusTypes.DONE){
      // make sure user has SAVED the recording
      setConfirm(true);
      return;
    }

    if(assessmentProp){
      onClose(confirmationValue, assessmentProp.assessmentId, videos);
      console.log('Assessment: ', assessmentProp.assessmentId);
      console.log('Blob:', videos);
    }else{
      onClose(false,'');
    }  


    setSavingStatus(SaveStatusTypes.INITIALIZE);
  };

  const onAlertCancel = () => {
    console.log('Alert cancelled');
    setConfirm(false);
    onClose(false,'');
    setVideos([]);
  };
  
  const onAlertConfirm = () => {
    console.log('Alert Confirmed');
    setConfirm(false);
    handleSave();
  };


  const handleToasterClose = (event,reason) => {
    if (reason === 'clickaway') {
      return;
    }
    console.log('toaster close reason:', reason);
    setToasterOpen(false);
  };

  const handleRefresh = async () => {

    console.log('Refreshing token for user:', user);
    await refreshToken(user);
    if (user.firebaseUser){
      console.log('Refreshing firebaseuser:', user.firebaseUser);
      await refreshToken(user.firebaseUser);
    }
  };


  const handleGetThumbnail = () => {
    setFrameGrabberTimer(setInterval(getThumbnailFromRecorder, 5000));
  };

  const getThumbnailFromRecorder = () => {
    //console.log('Calling videorecorders getthumbnail, recording status:', isRecording);
    if ( videoRecorderRef && videoRecorderRef.current && isRecording){
      try{
        videoRecorderRef.current.handleGetThumbnail().then( (thumbnail)=> {    
          //console.log('Thumbnail RECEIVED:', thumbnail);
          //process the thumbnail, which is a full-sized image frame
          //obtained every X secs, set in the setFrameGrabberTimer above
          //
        });

        videoRecorderRef.current.handleGetThumbnailSmall().then( (thumbnail)=> {    
          //console.log('SMALL Thumbnail received');
          if (thumbnail != null){
            setThumbnailBlob(thumbnail);
          }
          //this is a small, 64x64 thumbnail image
          //that goes in the title bar of the recording dialog
          //gives feedback to user that recording is happening
        });
      } catch(err){
        console.log('Error obtaining thumbnail:', err);
      }
    } 
  };

  const handleGetPartialRecording = () => {
    setPartialDataTimer(setInterval(getChunkRecording,300000)); //5min
  };

  const getChunkRecording = () => {
    console.log('Get chunk recording');

    if ( videoRecorderRef && videoRecorderRef.current && isRecording){
      
      try{
        videoRecorderRef.current.handleChunkRecording(); 
        //react-video-recorder will end up calling onRecordingComplete callback

      } catch(err){
        console.log('Error obtaining partial blob:', err);
      }
    } 
  };

  const getPartialRecording = () => {

    console.log('GET partial recording');
    if ( videoRecorderRef && videoRecorderRef.current && isRecording){
      
      try{
        videoRecorderRef.current.handlePartialRecording(); //react-video-recorded will end up calling onRecordingComplete callback

      } catch(err){
        console.log('Error obtaining partial blob:', err);
      }
    } 
  };


  const classes = useStyles();
 
  return (
    <Dialog
      fullScreen
      open={open}
    >

      <DialogContent>
        <VideoRecorder
          ref = {videoRecorderRef}
          isOnInitially
          constraints = {{
            ...videoConstraints
          }}
          renderActions = {RecordRenderActions}
          onRecordingComplete = { (videoBlob,startedAt,thumbnail,duration) => {
            console.log('videoBlob: ' , videoBlob);
            console.log('Assessment: ', assessmentProp.assessmentId);
            console.log('Blob size: ', videoBlob.size);
            console.log('Blob type: ', videoBlob.type);
            console.log('Duration:', duration);
            console.log('Started at:', new Date(startedAt));
            
            console.log('videos array length:', videos.length);
            setVideos( old => [...old, videoBlob]);
            console.log('Recording COMPLETE');
          }}
          replayVideoAutoplayAndLoopOff
          showReplayControls
          isFlipped={true}

          onTurnOffCamera = { () => {
            console.log('Camera turned OFF');
          }}

          onTurnOnCamera = { () => {
            console.log('Camera turned ON');
          }
          }
          onOpenVideoInput = { () => {
            console.log('Video input ON');
          }}

          onSwitchCamera = { () => {
            console.log('Switch camera callback');
          }}
          
          onStartRecording = { () => {
            console.log('on Start recording');
            console.log('videorecorderref:', videoRecorderRef);
            setIsRecording(true);
          }}
          
          onStopRecording = { () => {

            console.log('Clearing framegrabber timer');
            clearInterval(frameGrabberTimer);
            setFrameGrabberTimer(null);
            clearInterval(partialDataTimer);
            setPartialDataTimer(null);
            console.log('on Stop recording');
            setIsRecording(false);
          }}
        />
          
      </DialogContent>

        
      {savingStatus===SaveStatusTypes.INITIALIZE?(
        <div />
      ):(
        <AsmtCreationProgressComponent
          asmtSaveValues={asmtToSavePayload}
          //closeParent={handleClose}
          setSavingStatus={setSavingStatus}
        />
     
      )
      }     
      <DialogActions>
        <AppBar 
          position="static"
          color="inherit">
          <Toolbar>
            {thumbnailBlob!=null ? (
              <img src={URL.createObjectURL(thumbnailBlob)}/>):
              (<div></div>)
            }
            <Typography
              className={classes.title}
              variant="h6"
              color="inherit"
            >
                Record Assessment (parent: {assessmentProp?.parent})
            </Typography>
          
          
            {/* <IconButton
              aria-label="close"
              color="inherit"
              onClick={handleCameraFlip}
            >
              <FlipCameraAndroidTwoTone />
            </IconButton> */}

            {savingStatus === SaveStatusTypes.SAVING ? 
              (<div>

                <Typography
                  className={classes.title}
                  variant="h6"
                  color="inherit"
                >
                    saving...
                </Typography>
              </div>):
              (<div></div>)}
            <Button
              autoFocus
              disabled={isRecording || savingStatus === SaveStatusTypes.SAVING}
              color="primary"
              onClick={handleSave}
              variant="outlined"
            >
          Save
            </Button>
            <IconButton

              disabled={isRecording || savingStatus === SaveStatusTypes.SAVING}
              aria-label="close"
              color="inherit"
              edge="end"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </DialogActions>
    
      <ConfirmationDialog
        cancelText = "No"
        confirmText = "OK"
        message = "Should I SAVE your recording?"
        onCancel = {onAlertCancel}
        onConfirm = {onAlertConfirm}
        open={confirm}
        title = "Warning"
      />

      <Snackbar
        autoHideDuration={4000}
        message = {toasterMessage} 
        onClose={handleToasterClose}
        open={toasterOpen}
      />
    </Dialog>
      
  );
};