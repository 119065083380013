// This needs to be broken up. dlp
// https://material-ui.com/components/tables/
// https://www.robinwieruch.de/react-hooks-fetch-data
// https://www.sitepoint.com/replace-redux-react-hooks-context-api/
// https://dev.to/email2vimalraj/react-hooks-lift-up--pass-down-state-using-usecontext-and-usereducer-5ai0
// https://towardsdatascience.com/passing-data-between-react-components-parent-children-siblings-a64f89e24ecf
// https://blog.logrocket.com/use-hooks-and-context-not-react-and-redux/
// https://dev.to/spukas/manipulating-dom-elements-with-react-hook-useref-446c
/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect, useReducer, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import {
  FormControlLabel,
  Button as MuiButton,
  Card as MuiCard,
  CardContent as MuiCardContent,
  CircularProgress as MuiCircularProgress,
  Paper as MuiPaper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  LinearProgress as MuiLinearProgress,
  Toolbar,
  Tooltip,
  Typography
} from '@material-ui/core';

import {
  Movie
} from '@material-ui/icons';
import {VideoDetails} from '../../dialogs/VideoDetails';
import SocketIOContext, {initializeSocket} from '../../context/SocketIOContext';
import {getDerivedDataStatuses} from '../../common/apiGetUtilities';
import {sleep} from '../../common/utilities';
import {getScoreitApiEndpoint} from '../../common/apiBaseUtilities';
import {useUserState} from '../../context/UserContext';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}));
// This is not the way styling will be done in future versions
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - 12px);
`;
// max-width: calc(100vw - ${props => props.theme.spacing(12)}px);

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);

const columns = [

  { id: 'video', numeric: false, disablePadding: false, label: 'Video'},
  { id: 'duration', numeric: true, disablePadding: false, label: 'Video Duration', clickable:true},
  { id: 'videodate', numeric: false, disablePadding: false, label: 'Video Date', format: value => new Date(value).toLocaleString() },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status'},
  // At some poitn we will want to do format: value => value.toFixed(2),
  { id: 'lastupdate', numeric: false, disablePadding: false, label: 'Last Update', format: value => new Date(value).toLocaleString()}
];
const SearchContext = React.createContext(null);
const PERC_CHANGE_EMIT = 'PercentageCompleted';
const STATUS_CHANGE_EMIT = 'StatusChanged';

const statusMap = {'2tqWSihO73IAe0F2PUtK':'Completed', '3c72yUMdIkdMaQT3utZx':'Not started',
  'iyW4DUw6nH2bcoCVglHh':'In progress', '5e77d6ec0e8d6b212482f8f1':'Completed', '5e77d6f40e8d6b212482f8f2':'Not started',
  '5e77d6fd0e8d6b212482f8f3':'In progress'};
  // taskStatusTypeIdDict['2tqWSihO73IAe0F2PUtK'] = 'complete';
  // taskStatusTypeIdDict['3c72yUMdIkdMaQT3utZx'] = 'not started';
  // taskStatusTypeIdDict['iyW4DUw6nH2bcoCVglHh'] = 'in progress';
const FETCH_INIT = 'FETCH_INIT';
const FETCH_SUCCESS = 'FETCH_SUCCESS';
const FETCH_FAILURE = 'FETCH_FAILURE';

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case FETCH_INIT:
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
        filters: action.filters
      };
    case FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    default:
      throw new Error();
  }
};
async function mockVideoDataPromise(user){
  await sleep(2000);
  return new Promise(resolve=>{
    return resolve(getDerivedDataStatuses(user,'',{}));
  });
}
// I kind of assume/hope this is not a static function
// Relationships between models is import, it can be hacked in
// mroe complete solution is to use the builtin loopback relations
// https://loopback.io/doc/en/lb4/BelongsTo-relation.html
const useEmmaApi= (initialUrl, initialFilters, initialData) =>{

  const activeUser = useUserState();
  // The setUrl is fake since this component only looks for derived data
  const [url, setUrl] = useState(initialUrl);
  const [filters, setFilters] = useState(initialFilters);
  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    data: initialData,
    filters: initialFilters
  });
  
  useEffect(() => {
    let didCancel = false;
    const fetchData = async () => {
      dispatch({ type: FETCH_INIT });
      try {
        let result = [];
        if(process.env.REACT_APP_DERIVED_DATA_SOURCE === 'mock'){
          result = await mockVideoDataPromise(activeUser);
        }else {
          // console.log('Filters', filters);
          // result = await mockVideoDataPromise(activeUser);
          result = await getDerivedDataStatuses(activeUser, url, filters);
          console.log('got result', result);
        }
        if (!didCancel) {
          dispatch({ type: FETCH_SUCCESS, payload: result, filters:filters });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: FETCH_FAILURE});
        }
      }
    };
    fetchData();
    return () => {
      didCancel = true;
    };
    //The last part of the useEffect a [] will update on all changes
  }, [filters]);
  return [state, setFilters];
};

const StatusTableCell = ({cellId, statusId, perc}) =>{

  const [message, setMessage] = React.useState('Not started');
  const [completion, setCompletion] = React.useState(perc);
  const { socket } = useContext(SocketIOContext); 
  const handleStatusChange = (data) => {
  
    const { deriveddataId, percentage } = data;
    if (deriveddataId !== cellId) return;
    if(parseInt(percentage) ===0) {
      setMessage('Not started');
    }
    else if (parseInt(percentage) < 100){
      setMessage(percentage);
    }
    else if (parseInt(percentage) >= 100) {
      setMessage('Completed');
    }else {
      setMessage('In progress');
    }
  };
  const handlePercChange = (data) => {
  
    const { deriveddataId, percentage } = data;
    if (deriveddataId !== cellId) return;
    setCompletion(percentage);
    //setMessage(percentage);
  };
  useEffect(()=>{
    socket.on(PERC_CHANGE_EMIT, handlePercChange);
    socket.on(STATUS_CHANGE_EMIT, handleStatusChange);
    // Set initial status
    if(statusId in statusMap) {
      setMessage(statusMap[statusId]);
    }
  });

  return (
    <TableCell align="right">{message} 
      <CircularProgress
        color="secondary"
        my={2}
        value={completion}
        variant="determinate"
      />
    </TableCell>
  );
};

const DerivedDataStatus = () =>{
  //setup socket connection with services endpoint
  //Because this is probably going to get passed around it should probably
  //be initialized in the App call.
  const activeUser = useUserState();
  const socket = initializeSocket();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [{ data, isLoading, isError, filters }, updateFilter] = useEmmaApi(
    `${getScoreitApiEndpoint()}/derived-data`,
    {},
    [],
  );
  const videoDisplayRef = React.useRef();

  const handleChangePage = (event, newPage) =>{
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleVideoDetails = videoData => {
    videoDisplayRef.current.setVideoData(videoData, activeUser);
    videoDisplayRef.current.handleClickOpen();
  };
  // hack, this will only notify of changes to existing elements
  // if a new element is added nothing happens.
  const checkForGlobalListChange = socketData =>{
    const { deriveddataId } = socketData;
    // brute force for code writing speed
    // console.log('Getting the global change', socketData);
    // console.log('Data', data);
    // console.log('Filter', filters);
    if (!data || data.length == 0){      
      updateFilter(filters);
    }else {
      let idIsIn = false;
      // Brute force is in
      data.some(displayedDd =>{
        if (displayedDd.id === deriveddataId) {
          idIsIn = true;
          return;
        }
      });
      if(!idIsIn) updateFilter(filters);
    }
  };
  useEffect(()=>{
    socket.connect();
    return ()=>{
      socket.disconnect();
    };
  },[]);

  useEffect(()=>{
    socket.on(STATUS_CHANGE_EMIT, checkForGlobalListChange);

    // socket.on('connect', ()=>{
    //   console.log('Connecting to socket');
    // });
    // socket.on('disconnect', () => {
    //   console.log('user disconnected');
    // });
  });

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  if (activeUser.authLevel < 10){
    return (
      <Typography variant="h5">
        You don&apos;t have permission to view this page.
      </Typography>
    );
  }
  return (
    
    <Card mb={6}>
      <CardContent pb={0}>
        <Typography
          gutterBottom
          variant="h6"
        >
            Derived Data Information & Status
        </Typography>
        <Typography
          gutterBottom
          variant="body2"
        >
          Display all the derived data that is being calculated.
        </Typography>
        <VideoDetails ref={videoDisplayRef} user={activeUser}/>
      </CardContent>
      {/* it might be worth adding in the current set of fitlers */}
      <SearchContext.Provider value={{filters, updateFilter}}>
        <DerivedDataTableToolbar/>
        {isLoading ? (
          <div align="center">
            <CircularProgress/>
          </div>
        ) : (
          <Paper>
            {isError && <div>Something went wrong ...</div>}
            <SocketIOContext.Provider value={{socket}}>
              <TableWrapper>
                <Table
                  aria-label="sticky table"
                  stickyheader="true"
                >
                  <DerivedDataTableHead/>
                  <TableBody>             
                    {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map(n => {
                        const idToUse = n.deriveddataId ? n.deriveddataId: n.id;
                        return (                          
                          <TableRow
                            hover
                            key={idToUse}
                            onClick = {()=>handleVideoDetails(n.video)}
                            role="checkbox"
                            tabIndex={-1}
                            
                          >
                            <TableCell align="right">
                              <img                                
                                src={n.video.thumbnailurl}
                                width="128"
                              />
                            </TableCell>
                            <TableCell align="right">{n.video.duration == -1 ? (<div>&lt;5m</div>):(<div>{(n.video.duration)/60}m</div>)}</TableCell>
                            <TableCell align="right">{new Date(n.video.timestamp).toLocaleString()}</TableCell>
                            <StatusTableCell
                              cellId={idToUse}
                              perc={n.percentageCompleted}
                              statusId={n.taskstatusId}
                            />
                            <TableCell align="right">{n.lastupdate}</TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 49 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableWrapper>
            </SocketIOContext.Provider>
            <TablePagination
              backIconButtonProps={{
                'aria-label': 'Previous Page'
              }}
              component="div"
              count={data.length}
              nextIconButtonProps={{
                'aria-label': 'Next Page'
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Paper>
        )} 
      </SearchContext.Provider>
    </Card>
  );
};

const DerivedDataTableHead = () =>{
  return (
    <TableHead>
      <TableRow>
        {columns.map(column => (
          <TableCell
            align={column.numeric ? 'right' : 'left'}
            key={column.id}
            padding={column.disablePadding ? 'none' : 'default'}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const DerivedDataTableToolbar = () =>{
  const classes = useStyles();
  // This is how filters work now.
  // {
  //    where: {taskstatusId:'2tqWSihO73IAe0F2PUtK'},
  //    fields: {id:'id'}
  // }
  const { filters, updateFilter } = useContext(SearchContext);

  const [viewType, setViewType] = useState('all');
  const handleChange = event => {
    setViewType(event.target.value);
  };
  const tstatusName = 'taskstatusId';
  const percCompleteName = 'percentageCompleted';
  // const completed = '2tqWSihO73IAe0F2PUtK';
  // const not_started = '3c72yUMdIkdMaQT3utZx';
  // const in_progress = 'iyW4DUw6nH2bcoCVglHh';
  const completed = '5e77d6ec0e8d6b212482f8f1';
  const not_started = '5e77d6f40e8d6b212482f8f2';
  const in_progress = '5e77d6fd0e8d6b212482f8f3';
  //"where][taskstatusId":"2tqWSihO73IAe0F2PUtK"
  //"fields][id":true
  //   "include][relation":"owner"
  // http://localhost:3000/derived-data?filter[where][taskstatusId]=2tqWSihO73IAe0F2PUtK&filter[fields][id]=true
  // taskStatusTypeIdDict['2tqWSihO73IAe0F2PUtK'] = 'complete';
  // taskStatusTypeIdDict['3c72yUMdIkdMaQT3utZx'] = 'not started';
  // taskStatusTypeIdDict['iyW4DUw6nH2bcoCVglHh'] = 'in progress';
  const handleSubmit = () =>{
    // let results ={};
    const where = {};
    if(viewType === completed) {
      where[tstatusName] = completed;
    } else if(viewType === in_progress) {
      where[tstatusName] = in_progress;
    }else if(viewType === not_started) {
      where[tstatusName] = not_started;
      // where[percCompleteName] = 0;
    }

    // console.log('head filter', filters);
    updateFilter({where:where});
  };

  return (
    <Toolbar>
      <Card mb={2}>
        <CardContent>
          <div className={classes.root}>
            <RadioGroup
              aria-label="position"
              name="position"
              onChange={handleChange}
              row
              value={viewType}
            >
              <FormControlLabel
                control={<Radio color="primary" />}
                label="All"
                labelPlacement="end"
                value="all"
              />
              <FormControlLabel
                control={<Radio color="primary" />}
                label="Completed"
                labelPlacement="end"
                value={completed}
              />
              <FormControlLabel
                control={<Radio color="primary" />}
                label="In progress"
                labelPlacement="end"
                value={in_progress}
              />
              <FormControlLabel
                control={<Radio color="primary" />}
                label="Not started"
                labelPlacement="end"
                value={not_started}
              />
            </RadioGroup>
            <Button
              color="primary"
              onClick={handleSubmit}
              variant="contained"
            >
          Filter
            </Button>
          </div>
        </CardContent>
      </Card>      
    </Toolbar>
  );
};

export default DerivedDataStatus;