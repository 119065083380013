import React, {FC, useEffect} from 'react';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography} from '@material-ui/core';

import { 
  createStyles,
  makeStyles,
  Theme 
} from '@material-ui/core/styles';

import { GenericScoreSchemaProps } from '../../common/mongoSchemas';

const useStyles = makeStyles((theme:Theme) =>
  createStyles({
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    card: {
      maxWidth: '100%',
      minWidth: '0',
      width:'100%',
      margin: 'auto',
      transition: '0.3s',
      boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
      '&:hover': {
        boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)'
      }
    },
    carddivider: {
      // margin: `${theme.spacing.unit}px 0`
    },
    cardmedia: {
      paddingTop: '56.25%'
    },
    cardcontent: {
      textAlign: 'left',
      // padding: theme.spacing.unit * 3
    },
    container:{
      display: 'flex'
    },
  }),
);

// Sad face! why can't i just pass the whole thing in
export const AimsItemScoreCard = (props:GenericScoreSchemaProps):JSX.Element  => {
  //constants
  const classes = useStyles();
  const {aimsScore, totalScore} = props;
  const {prone, sitting, standing, supine} = aimsScore as Record<string, unknown> || {};

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardcontent}>
        <Typography
          color="secondary"
          gutterBottom
          variant={'h6'}
        >
          Motor Score
        </Typography>
        <Divider
          // className={classes.divider}
          light
        />
        <Grid
          alignItems="center" 
          container 
          direction="row"
          justify="space-between"
          spacing={2}
        >
          <Grid
            item
            md={3}
            sm={6}
            xs={6}
          >
            <Typography variant="subtitle2">
                Prone
            </Typography>
            <Typography
              color="secondary"
              variant="caption"
            >
              {(prone as Record<string, unknown>)?.score as number || -1}
            </Typography>
          </Grid>
          <Grid
            item
            md={3}
            sm={6}
            xs={6}
          >
            <Typography variant="subtitle2">
                Supine
            </Typography>
            <Typography
              color="secondary"
              variant="caption"
            >
              {(supine as Record<string, unknown>)?.score as number || -1}
            </Typography>
          </Grid>
          <Grid
            item
            md={3}
            sm={6}
            xs={6}
          >
            <Typography variant="subtitle2">
              Sitting
            </Typography>
            <Typography
              color="secondary"
              variant="caption"
            >
              {(sitting as Record<string, unknown>)?.score as number || -1}
            </Typography>
          </Grid>
          <Grid
            item
            md={3}
            sm={6}
            xs={6}
          >  
            <Typography variant="subtitle2">
                Standing
            </Typography>
            <Typography
              color="secondary"
              variant="caption"
            >
              {(standing as Record<string, unknown>)?.score as number || -1}
            </Typography>
          </Grid>
        </Grid>
        <Divider
          // className={classes.divider}
          light
        />
        <Grid 
          alignItems="center" 
          container 
          direction="row"
          justify="space-between"
          // spacing={12}
        >
          <Grid
            item
            md={12}
            sm={12}
          >
            <Typography variant="subtitle2">
              Total Score
            </Typography>
            <Typography
              color="secondary"
              variant="subtitle1"
            >
              {totalScore}
            </Typography>
          </Grid>
          {/* <Grid
            item
            md={12}
            sm={12}
          >
            <Typography
              color="secondary"
              variant="subtitle1"
            >
              {totalScore}
            </Typography>
          </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  );
};