import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';
import {Redirect} from 'react-router-dom';

import { spacing } from '@material-ui/system';
import {
  Checkbox,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';

import ReactPlayer from 'react-player';
import OtTableHead from './OtTableHead';
import OtTableToolbar from './OtTableToolbar';

import { VideoDetails } from '../../../dialogs/VideoDetails';
import OTPlanCard from '../../../pages/dashboard/components/OTPlanCard';
import {stableSort, getSorting} from '../../../common/tableUtilities';

import { useUserListState } from '../../../context/UserListContext';
import { useUserState } from '../../../context/UserContext';
import { getOTPlans,  getIndividualOTPlans } from '../../../common/apiGetUtilities';
import { setOTPlan } from '../../../common/apiSetUtilities';

const Divider = styled(MuiDivider)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);
const TableWrapper = styled.div`
overflow-y: auto;
max-width: calc(100vw - 12px);
`;
// max-width: calc(100vw - ${props => props.theme.spacing(12)}px);

const OtInfoTable = (props) => {
  // Important to note that this pages changes what is displayed depending on the auth
  // level of the user. If it is admin all available otplans are displayed
  // if not only individual plans get displayed.videos and plans are very different. 
  const user = useUserState();
  // const [restapi] = useState('');
  // const [daysBack] = useState(7);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('timestamp');
  const [selectedIds, setSelectedIds] = useState([]);
  const [videos, setVideos] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage,setRowsPerPage] = useState(10);
  const [selectedStartDate] = useState(Date());
  const [selectedEndDate] = useState(Date());
  const [detailsPageOpen] = useState(false);
  const [redirect] = useState(null);
  // const [bearerToken] = useState('');
  // const [emmaUser] = useState(null);
  // Why doing both?
  const [emmaUsers,setEmmaUsers] = useState(useUserListState().allUsers);
  const [adminUser, setAdminUser] = useState(false);
  const [otPlans, setOtPlans] = useState([]); //VideoStore.getOTPlans(),//hmm by otplans !== otvidoes
  const [detailsPageElement] = useState(useRef()); // These are giving errors, are they really needed?
  const [tableToobar] = useState(useRef()); // These are giving errors, are they really needed?

  useEffect(()=>{
    async function setAllData(){
      if(user.authLevel >= 10){
        const plans = await getOTPlans(user);
        setVideos(plans);
      }else{
        const individualPlans = await getIndividualOTPlans(user.firebaseUser, user);
        setOtPlans(individualPlans);
      }
    }
    if(user.authLevel >= 10){
      setAdminUser(true);
    }
    setAllData();
  }, []);
 
  const handleRequestSort = (event, property) => {
    const orderByProp = property;
    let orderProp = 'desc';

    if (orderBy === property && order === 'desc') {
      orderProp = 'asc';
    }
    setOrder(orderProp);
    setOrderBy(orderByProp);
  };

  const handleSelectAllClick = event => {
    const selectedItems = videos.map(n=>n.instructionalvideoId?n.instructionalvideoId:n._id);
    console.log('Selected items:',selectedItems);

    if (event.target.checked) {
      setSelectedIds(selectedItems);
      //this.setState(state => ({ selected: state.videos.map(n => n.id) }));
      // VideoActions.selectOTVideos(this.state.videos.map(n=>n.instructionalvideoId));
      return;
    }else {
      setSelectedIds([]);
    }
    // VideoActions.selectOTVideos([]);
  };

  const handleClick = (event, id) => {
    const selected = [...selectedIds];
    const selectedIndex = selected.indexOf(id);
    if(selectedIndex > -1){
      selected.splice(selectedIndex, 1);
    }else{
      selected.push(id);
    }
    setSelectedIds(selected);
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  // handleEdit = (id) => {
  //   console.log('Video ID:',id);
  // };

  const handleVideoDetails = (data) => {
    console.log('Setting video data for Details page->');
    detailsPageElement.current.setVideoData(data, user);
    detailsPageElement.current.handleClickOpen();
  };

  const handleStartDateChange = (date) => {
    console.log('Start Date: ',date);
    // Legacy...check later dlp
    // // VideoActions.setGalleryStartDate(date);
    // // instead of updating a state variable, we trigger an action
    // // this action dispatches an event that is received by the store
    // // store does what it does and then emits a change event
    // // since this component registered for a change event, it will receive it
    // // and then it can get the data from the store

  };
  const handleEndDateChange = (date) => {
    console.log('End Date: ',date);
    // VideoActions.setGalleryEndDate(date);
  };  

  const handleReceivedVideoData = (data) =>{  
    if(data != null){
      console.log('Returned videos count:',data.length);
      console.log('First video returned:', data[0]);
      if (data.length != null && data.length > 0){
        // VideoActions.deleteAllOTVideos();
        // VideoActions.addOTVideos(data);
      }
      else {
        // VideoActions.deleteAllOTVideos();
      }  
    }
  };

  const handleCreatePlan = (event,payload) => {
    const planpayload = {
      parentemail: payload.parentemail,
      plannotes: payload.plannotes,
      createdby: user.uid,
      videos: selectedIds
    };
    // Not sure if waiting for this matters, nothing visible 
    // gets updated
    setOTPlan(user, planpayload);
  };

  const handleDeleteClick = (event) => {
    console.log('Items selected for delete:',this.state.selected);
    /*Call the REST API here to delete the videos */
  };

  const handleEditVideos = (event) => {

    // console.log('Items selected for processing:',this.state.selected);
    // console.log('History -> ', this.props.history);
    // // /this.props.history.push("/pages/videotasks");

    // this.setState({redirect:'/pages/videotasks'});

  };

  const checkSelected = (id) => selectedIds.indexOf(id) !== -1;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, videos.length - page * rowsPerPage);
  // Is this every used?
  if (redirect) {
    return <Redirect to={redirect} />; // Back to?
  }

  if (user.authLevel < 0){
    return (
      <Typography variant="h5">
        Hmmm...you may not yet have content here.
      </Typography>
    );
  }
  
  return (
    <Card mb={6}>
      <CardContent pb={0}>
        <Typography
          gutterBottom
          variant="h6"
        >
          Video Gallery
        </Typography>           
        {adminUser ? (
          <Typography
            gutterBottom
            variant="body2"
          >
            Play activity video gallery displayed below. Select the videos you want to include for the child. Click  <b>Create Plan</b> button.
          
          </Typography>
        ):(
          <Typography
            gutterBottom
            variant="body2"
          >
            {otPlans && otPlans.length > 0 ? (
              <div>Your play activity plans are shown below.</div>):
              (
                <div>You currently have no activity plans.</div>
              )}
          </Typography>
        )}
        <Divider my={6}/>
        <VideoDetails user={user} ref={detailsPageElement}/>
      </CardContent>
      {!adminUser ? (
        otPlans && otPlans.length > 0 && otPlans.map( (plan,index) => {
          return(
            <Grid
              container
              direction="vertical"
              key={index}
              spacing={3}
            >
              <Grid
                item
                xs={12} sm={12}
              > 
                <OTPlanCard otplan={plan}/> 
              </Grid>
            </Grid>
          );              
        })
      )
        :
        (
          <Paper>
            <OtTableToolbar
              emmaUsers = {emmaUsers}
              handleCreatePlan = {handleCreatePlan}
              // handleDateFilterClick = {handleDateFilterClick}
              handleDeleteClick = {handleDeleteClick}
              handleEditVideos = {handleEditVideos}
              handleEndDateChange = {handleEndDateChange}
              handleStartDateChange = {handleStartDateChange}
              numSelected={selectedIds.length}
              ref={tableToobar} 
              // restapi={this.props.restapi}
              selectedEndDate = {selectedEndDate}
              selectedStartDate = {selectedStartDate}
            />
            <TableWrapper>
              <Table aria-labelledby="tableTitle">
                <OtTableHead
                  numSelected={selectedIds.length}
                  onRequestSort={handleRequestSort} 
                  onSelectAllClick={handleSelectAllClick}
                  order={order}
                  orderBy={orderBy}
                  rowCount={videos.length}
                />
                <TableBody>
                  {videos && 
                  stableSort(videos, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(n => {
                      const idToUse = n.instructionalvideoId?n.instructionalvideoId:n._id;
                      const isSelected = checkSelected(idToUse);                      
                      return (
                        <TableRow
                          aria-checked={isSelected}
                          hover
                          key={idToUse}
                          role="checkbox"
                          selected={isSelected}
                          tabIndex={-1}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isSelected} 
                              onClick={event => handleClick(event, idToUse)}
                            />
                          </TableCell>
                          <TableCell align="left">                           
                            <ReactPlayer
                              controls
                              url={n.url}
                              width="100%"
                            />
                          </TableCell>                        
                          <TableCell align="left">
                            <Typography
                              gutterBottom
                              variant="h6"
                            >
                              {n.title}
                            </Typography>
                          </TableCell>                            
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableWrapper>
            <TablePagination
              backIconButtonProps={{
                'aria-label': 'Previous Page'
              }}
              component="div"
              count={videos.length}
              nextIconButtonProps={{
                'aria-label': 'Next Page'
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Paper>
        )}
    </Card>
  );
};

export default OtInfoTable;