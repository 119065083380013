import axios from 'axios';
import https from 'https';



const getPostureApiEndpoint = () => {

  let apiendpoint = process.env.POSTURE_API;
  console.log('posture api endpoint:', apiendpoint);
  if (!apiendpoint || apiendpoint==='LOCAL_FILE') {
    // console.log('Getting local api endpoint');
    apiendpoint = 'http://192.168.59.12:8080/predictions/mimmv3';
    // apiendpoint = 'http://127.0.0.1:5000';// hmmm which to choose.

    apiendpoint = 'http://71.231.170.165:8080/predictions/mimmv3';
    //apiendpoint = 'http://felinity.minmini.ai:8080/predictions/mimmv3';
    //apiendpoint = 'http://192.168.59.12:8080/predictions/mimmv3';
    // apiendpoint = 'http://127.0.0.1:5000';// hmmm which to choose.
  }
  return apiendpoint;

};

const postureApiEndpoint = getPostureApiEndpoint();

export async function getPosture  (blobdata: any) {
  const url = postureApiEndpoint;
  console.log('posture api at:', url);
  try{
    const response = await axios.post (url, blobdata, {headers:{
      'Content-Type':'application/octet-stream'}});
    console.log('getPosture response:', response.data);
    return response.data;
  }
  catch(err){
    console.log('getposture error:', err);
  };

  
};



export const ChoosePostureClass = (data: any) => {
  if (data) {
    const proneProb = data['0'];
    const sittingProb = data['1'];
    const standingProb = data['2'];
    const supineProb = data['3'];
    const probArray = [proneProb, sittingProb, standingProb, supineProb];
    const i = probArray.indexOf(Math.max(...probArray));
    if (i == 0){
      return 'Prone';
    }
    if (i == 1){
      return 'Supine';
    }
    if (i ==2) {
      return 'Sitting';
    }
    else { return 'Standing';}
  }
};

export const ChoosePostureClassV3 = (data: any) => {

  if (!data){
    return 'Unknown';
  }
  //get probabilities for the SEVEN classes
  let supineLying = -1 ;
  let supineNonLying = -1;
  let proneCrawling = -1;
  let proneNonCrawling = -1;
  let sitting = -1;
  let standingNonSupported = -1;
  let standingSupported = -1;

  
  for(let index=0; index<5; index++){
      
    if (supineLying <= 0){
      supineLying = data[index] ['supine_lying'] ? data[index] ['supine_lying'] : 0;
    }
    if (supineNonLying <= 0){
      supineNonLying = data[index]  ['supine_non_lying'] ? data[index]  ['supine_non_lying'] : 0;
    }
    if (proneCrawling <=0 ) {
      proneCrawling = data[index]  ['prone_crawling'] ? data [index] ['prone_crawling'] : 0;
    }
    if (proneNonCrawling <= 0){
      proneNonCrawling = data[index]  ['prone_noncrawling'] ? data [index] ['prone_noncrawling'] : 0 ;
    }
    if (sitting <= 0){
      sitting = data[index]  ['sitting'] ? data [index] ['sitting'] : 0;
      
    }
    if (standingNonSupported <= 0){
      standingNonSupported = data[index]  ['standing_nonsupported'] ?  data[index]  ['standing_nonsupported'] : 0;
    }
    if (standingSupported <= 0){
      standingSupported = data[index]  ['standing_supported'] ? data[index]  ['standing_supported'] : 0;
    }

  }

  const probArray = [proneNonCrawling, proneCrawling, supineLying, supineNonLying, sitting, standingSupported, standingNonSupported];
  

  const i = probArray.indexOf(Math.max(...probArray));
  if (i==0){
    return 'Prone (non crawling)';
  }
  else if (i == 1){
    return 'Prone (crawling)';
  }
  else if (i == 2){
    return 'Supine (lying)';
  }
  else if (i == 3){
    return 'Supine (non lying)';
  }
  else if (i == 4){
    return 'Sitting';
  }
  else if (i == 5){
    return 'Standing (supported)';
  }
  else {
    return 'Standing (non supported)';
  }
};
  