import React from 'react';
import {
  Typography
} from '@material-ui/core';

import { ReportInfoContext } from '../../../context/ReportContext';
import { AimsScoreSchemaProps } from '../../../common/mongoSchemas';

export const ReportSummary = ():JSX.Element =>{
  const {reportInfoState} = React.useContext(ReportInfoContext);
  const {reportInformation} = reportInfoState;
  return(
    reportInformation.asmtSubjectName === 'Subject not Initiated'? 
      (
        <Typography
          color="textSecondary"
          variant="h4"
        >
          Report comes from a legacy source, please update the report for this assessment.
        </Typography>
      ):(
        <div>
          <Typography
            color="textSecondary"
            variant="h4"
          >
          Report summary
          </Typography>
          <Typography
            color="textSecondary"
            variant="subtitle2"
          >
            {`Parent name: ${reportInformation.parentName}`}
          </Typography>
          <Typography
            color="textSecondary"
            variant="subtitle2"
          >
            {`Infant name: ${reportInformation.asmtSubjectName}`}
          </Typography>
          <Typography
            color="textSecondary"
            variant="subtitle2"
          >
            {`Assessment Date: ${new Date(reportInformation.assessmentDate??'1/1/1969').toLocaleDateString('en-US')}`}
          </Typography>
          <Typography
            color="textSecondary"
            variant="subtitle2"
          >
            {`Percentile: ${(reportInformation?.aimsScore as AimsScoreSchemaProps)?.percentile?.information}`}
          </Typography>

          <Typography
            color="textSecondary"
            variant="subtitle2"
          >
            {`Email report send Requested:${reportInfoState?.dbInformation?.emailStatus?.sendRequested}`}
          </Typography>
          <Typography
            color="textSecondary"
            variant="subtitle2"
          >
            {`Email report sent Confirmed:${reportInfoState?.dbInformation?.emailStatus?.sendVerified}`}
          </Typography>
        </div>
      )
  );
};