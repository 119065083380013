import React from 'react';
import {
  DialogContent,
  DialogContentText,
  TextField,
  Grid,
  DialogTitle
} from '@material-ui/core';

import { VideoPlayer } from '../../../components/VideoPlayer/VideoPlayer';
import { VideoTagTableFull } from './VideoTagTableFull';
import { VideoTagTableContext } from '../../../context/VideoTagTableContext';
import { getAnnotationSets } from '../../../common/apiGetUtilities';
import { refreshToken } from '../../../context/UserContext';

// dlp is not sure when this gets used.
// For sure it is using a bunch of older code, no clue it hasn't been updated.
export class TagTabPanel extends React.Component {
  constructor(props){
    super(props);
    this.videoPlayer = React.createRef();
    if (props.index === 0) {
      this.initializeVideoTagTableContext();
    }
  }

  initializeVideoTagTableContext = async () => {
    console.log('Initializing the tag table context');
    await refreshToken(this.props.user.firebaseUser);
    const annotationSets = (await getAnnotationSets(this.props.user, this.props.videoData.videoId));
    const index = annotationSets.findIndex(annotationSet => annotationSet.emmaUserId === this.props.user.emmauserId);
    this.setState({videoTagTableContext: {annotationSets, selectedAnnotationSet: Math.max(0, index)}});
  }

  state = {
    isPlaying: false,
    volume: 0.7,
    videoTagTableContext: null,
  };

  handlePlay = () => {
    this.setState({isPlaying: true});
  };
  
  handlePause = () => {
    this.setState({isPlaying: false});
  };
  
  handleVolume = value => {
    this.setState({volume: value});
  };      

  handleAddLabel = () => {
    const currentTimeStamp = this.videoPlayer.current.pauseVideo();
    this.setState({'isPlaying':false});
    return currentTimeStamp;
  };

  handleSaveLabels = () => {
    console.log('handle save labels');
  };

  handleOnPlay = () => {
    this.setState({ isPlaying: true });
    console.log('Tagging video playing');
  }

  handleOnPause = () => {
    this.setState({ isPlaying: false });
    console.log('Tagging video paused');
    const currentTimeStamp = this.videoPlayer.current.pauseVideo();
    console.log('Timestamp set to:', currentTimeStamp);
  }

  seekTo = (amt) => {
    console.log('SEEKTO amt:', amt);
    this.videoPlayer.current.seekTo(amt);
  }

  onSeek = () => {

    const currentTimeStamp = this.videoPlayer.current.pauseVideo();
    console.log('Current time after seek:', currentTimeStamp);
  }

  render() {
    const { value, index, videoData, timestamp } = this.props;
    if (value && value == 1){
      return (
        <div hidden={value !== index}>

          <DialogTitle id="max-width-dialog-title">
           Notes
          </DialogTitle>
      
          <DialogContent>
            <DialogContentText>
            Notes on observation video.
            </DialogContentText>
            <Grid container xs={4}>
              <Grid item xs={12}>
                <TextField
                  style={{textAlign: 'left'}}
                  hintText="Assessment Notes"
                  floatingLabelText="Multiline"
                  multiline
                  rows={20}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </div>
      );
    }else {
      return (
        <div hidden={value !== index}>
          <DialogTitle id="max-width-dialog-title">
             Raw video
          </DialogTitle>
          <DialogContent>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <DialogContentText>
                  Video recording from <b> {timestamp}</b>
                </DialogContentText>
                <VideoPlayer
                  ref = {this.videoPlayer}
                  controls
                  playing = {this.state.isPlaying}
                  component="video"
                  height="256"
                  url={videoData != null && videoData.url}
                  title="Video"
                  onPlay={() => this.handleOnPlay()}
                  onPause={() => this.handleOnPause()}
                  onSeek = {()=> this.onSeek()}
                />
              </Grid>
              <Grid item>
                {/* What the heck? why is this the way it is a table within a table? dlp */}
                {this.state.videoTagTableContext && 
                <VideoTagTableContext.Provider value={this.state.videoTagTableContext}>
                  <VideoTagTableFull
                    videoId = {videoData.videoId}
                    handleAddLabel = {this.handleAddLabel}
                    handleSaveLabels = {this.handleSaveLabels}
                    handleSeekTo = {this.seekTo}
                  />
                </VideoTagTableContext.Provider>}                
              </Grid>
            </Grid>
          </DialogContent>
        </div>
      );
    }
  }
}
