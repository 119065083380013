
export const captureThumb = (videoTag) =>
  new Promise((resolve, reject) => {
    try{
      const canvas = document.createElement('canvas');
      canvas.width = videoTag.videoWidth;
      canvas.height = videoTag.videoHeight;
      //224 is said work best for ResNet-50 for posture classification inference
      canvas.getContext('2d').drawImage(
        videoTag,
        0, // top
        0, // left
        videoTag.videoWidth,
        videoTag.videoHeight
      );
      canvas.toBlob((thumbnail) => {
        resolve(thumbnail);
      }, 'image/jpeg');
    } catch(err){ resolve(null);}
  });

export const captureThumbSmall = (videoTag) =>
  new Promise((resolve, reject) => {
    try{
      console.log('Capturing small thumbnail from videoElement');
      console.log('width/height:' + videoTag.videoWidth + ' ' + videoTag.videoHeight);
      const canvas = document.createElement('canvas');
      canvas.width = 64;
      canvas.height = 64;
      canvas.crossOrigin = 'anonymous';
      canvas.getContext('2d').drawImage(
        videoTag,
        0, // top
        0, // left
        64,
        64
      );
      canvas.toBlob((thumbnail) => {
        console.log('resolving thumbnail blob:', thumbnail);
        resolve(thumbnail);
      }, 'image/jpeg');
    } catch(err){
      console.log('resolve error:', err);
      resolve(null);
    }
  });


export const fetchUrl =  (videoUrl) => {
  // Fetch the original image
  return(

    fetch(videoUrl,  {
      mode: 'cors',
      headers: {
        'Origin':'http://localhost:3001'
      }
    })
    // Retrieve its body as ReadableStream
      .then(response => {
        const reader = response.body.getReader();

        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          }  
        });
      })
      .then(stream => new Response(stream))
      .then(response => response.blob())
      .then(blob => URL.createObjectURL(blob))
      .then((url) => {
        // gives the blob url which solves cors error in reading stream(using captureStream() func)

        console.log('Fetched blob url:',url);

        return url;
      })
      .catch(err => {console.error(err);}
      )
  );
};
