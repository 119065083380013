import React, { ReactNode } from 'react';

import {aimsScoreEmptySample, uninitiatedScore, uninitiatedReportData} from '../common/staticdata';
import { AimsScoreSchemaProps, EmailStatusSchemaProps, ReportDataSchemaProps, ReportSchemaProps } from '../common/mongoSchemas';


export enum ReportInfoActionTypes {
  LOAD = 'LOAD',
  UPDATE_INFO = 'UPDATE_INFO',
  UPDATE_SCORE = 'UPDATE_SCORE',
  UPDATE_DBINFO = 'UPDATE_DBINFO',
  UPDATE_UIINFO = 'UPDATE_UIINFO',
  UPDATE_REPORTDIALOG = 'UPDATE_REPORTDIALOG'
}
// for visual verification of age on the report
// but never explicitly used on the report.
export type ReportUIInformation = {
  gestAgeDays?:number,
  dueDate:Date,
  subjectNotes:string
}
// No real update value so this is kind of hacky,
// but it makes for a simple way to pass info around without
// having to send it through compoenents
// mostly the first part of the Mongodb schema
export type ReportDbInformation = {
  reportId?:string, //Not needed for new
  creationDate:Date,
  reportFileURL?:string, // optional?
  reportFileStoragePath?:string,//optional?
  assessmentId:string,
  emmauserId: string, //assessor id, api keeps it from emmaUserId
  subjectId: string,
  subjectParentId?:string, // this prop is meh
  emailStatus?:EmailStatusSchemaProps
}

export type ReportInfoContextType = {
  reportInformation:ReportDataSchemaProps,
  // this is part of the report information
  scoreInformation:AimsScoreSchemaProps,
  // information needed for the UI
  uiInformation:ReportUIInformation,
  // not sure what this
  dbInformation?:ReportDbInformation,

  // This is supposed to control the visibility of the dialog
  // and feels soooo wrong
  isReportPreviewVisible:boolean
}

interface ReportInfoAction extends ReportInfoContextType {
  type:ReportInfoActionTypes;
}
export const defaultReportInfo:ReportInfoContextType ={
  reportInformation:uninitiatedReportData,
  scoreInformation:aimsScoreEmptySample,
  uiInformation:{dueDate:new Date(), gestAgeDays:0, subjectNotes:''},
  isReportPreviewVisible:false
};
// https://medium.com/@patrick.gross1987/how-to-use-the-react-context-api-with-an-asynchronous-reducer-5651c2dc26aa
export function reportInfoReducer(state:ReportInfoContextType, action:Partial<ReportInfoAction>)
:ReportInfoContextType{
  const {type, ...noType} = action;
  // such terrible use of the types, it could just be one type
  // based on the way this is written.
  switch(type) {
    case ReportInfoActionTypes.LOAD:{
      if(noType.reportInformation && noType.scoreInformation){
        // hope and pray that the input is a complete report
        return {...state, ...noType} as ReportInfoContextType; //interesting need for type casting
      }
      return {...state};
    }
    case ReportInfoActionTypes.UPDATE_REPORTDIALOG:{
 
      if(noType.isReportPreviewVisible !== undefined){
        // hope and pray that the input is a complete report
        return {...state, isReportPreviewVisible:noType.isReportPreviewVisible};
      }
      return {...state};
    }
    case ReportInfoActionTypes.UPDATE_UIINFO:{
      if(noType.uiInformation){
        // hope and pray that the input is a complete report
        // console.log('reducer', {...state, uiInformation:noType.uiInformation});
        return {...state, uiInformation:noType.uiInformation};
      }
      return {...state};
    }
    case ReportInfoActionTypes.UPDATE_DBINFO:{
      if(noType.dbInformation){
        // Well this makes me cringe for sure.
        return {...state, dbInformation:noType.dbInformation};
      }
      return {...state};
    }
    case ReportInfoActionTypes.UPDATE_INFO:{
      if(noType.reportInformation){
        // console.log('Reducer update va', noType.reportInformation);
        // console.log('Reducer state', state.reportInformation);
        const updatedReportInfo = {...state.reportInformation, ...noType.reportInformation};

        // console.log('Reducer update', updatedReportInfo);
        return {...state, reportInformation:updatedReportInfo};
      }
      return {...state};
    }
    case ReportInfoActionTypes.UPDATE_SCORE:{
      // console.log('Updating score');
      if(noType.scoreInformation){
        // console.log('Updating score real');
        // current issue is that i don't know how to do a generic replace for
        // objects in objects (not a full overwrite).
        // One way is to pass all the info the top level
        // other option is to get the name of the options passed and update individually
        //dlp is tired, will pass all at top level
        // console.log('Reducer update', {...noType.scoreInformation});
        // console.log('Reducer state', state.scoreInformation);
        const updatedScoreInfo = {...state.scoreInformation, ...noType.scoreInformation};

        // console.log('Reducer update', updatedScoreInfo);
        // const toRet = {...state, scoreInformation:updatedScoreInfo};
        // console.log('Reducer to return', toRet);
        return {...state, scoreInformation:updatedScoreInfo};
      }
      return {...state};
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// This is just one big hack, dlp is very embarrassed about it and will eventually
// get rid of it or at least organize all the stuff around a report 'context'
// hopefully
export const convertReportSchemaToContext = (reportSchema:ReportSchemaProps,
  reportInfoDispatch:(value:any)=>null) =>{
  console.log('Full report info', reportSchema);
  const reportInformation:ReportDataSchemaProps = reportSchema.reportData?reportSchema.reportData:uninitiatedReportData;
  // console.log('report info', reportInformation);
  // this is part of the report information
  const scoreProps = (reportInformation?.aimsScore as AimsScoreSchemaProps);
  const scoreInformation:AimsScoreSchemaProps = scoreProps?scoreProps:uninitiatedScore;
  // console.log('score info', scoreInformation);
  // // information needed for the UI
  const uiInformation:ReportUIInformation = {gestAgeDays:-1, dueDate:new Date(),
    subjectNotes:'Loaded Data. Notes are note stored'};
  console.log('The email', reportSchema.emailData);
  if(reportSchema.emailData === undefined){
    const emailStatus:EmailStatusSchemaProps = {
      sendVerified:false,
      sendVerifierId:reportSchema.emmauserId,
      sendRequesterId:'norequestsenderidyet',
      sendRequested:false,
    };
    reportSchema.emailData = emailStatus;
  }
  // Gads 
  const reportDbInfo:ReportDbInformation = {
    reportId:reportSchema.reportId,
    creationDate:reportSchema.creationDate,
    reportFileURL:reportSchema.reportFileURL,
    reportFileStoragePath:reportSchema.reportFileStoragePath,
    assessmentId:reportSchema.assessmentId,
    emmauserId:reportSchema.emmauserId,
    subjectId:reportSchema.subjectId,
    subjectParentId:reportSchema.subjectParentId,
    emailStatus:reportSchema.emailData

  };
  const initialReportContext:ReportInfoContextType = {
    reportInformation:reportInformation,
    scoreInformation:scoreInformation,
    uiInformation:uiInformation,
    dbInformation:reportDbInfo,
    isReportPreviewVisible:false
  };
  console.log('Updating the report context from something');
  reportInfoDispatch({type:ReportInfoActionTypes.LOAD, 
    ...initialReportContext});
};

export const ReportInfoContext = React.createContext<{
  reportInfoState:ReportInfoContextType,
  reportInfoDispatch:React.Dispatch<any>}>({
    reportInfoState:defaultReportInfo,
    reportInfoDispatch:()=>null
  });

type ReportInfoProps = {
  children: ReactNode;  
}


export const ReportInfoProvider = ({ children }:ReportInfoProps):JSX.Element =>{

  const [state, dispatch] = React.useReducer(reportInfoReducer, defaultReportInfo);
  return (
    <ReportInfoContext.Provider value={{reportInfoState:state, reportInfoDispatch:dispatch}}>
      {children}
    </ReportInfoContext.Provider>
  );
};