// The parameters are no longer correct, names are though
// {"name":"Prone Lying (1)", "index":0, "behavioralInstrumentName":"AIMS", "subscale :":{"name":"Prone", "index":0}},

// All these are copied from data/instrumentDb.json in the api
import * as Schemas from './mongoSchemas';

const aimsInstrumentsData:Schemas.InstrumentSchemaProps = 
  {
    instrumentId: '0',
    name: 'AIMS',
    subscales: [
      'Prone',
      'Supine',
      'Sitting',
      'Standing'
    ],

    scoringMethodNames:['Enhanced','Classic'],
    instrumentItems: [
      {
        'name': 'Prone Lying (1)',
        'index': 0,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Prone',
          index: 0
        }
      },
      {
        'name': 'Prone Lying (2)',
        'index': 1,
        'behavioralInstrumentName': 'AIMS',
        subscale :{ 
          name: 'Prone',
          index: 1}
      },
      {
        'name': 'Prone Prop',
        'index': 2,
        'behavioralInstrumentName': 'AIMS',
        subscale :
          {
            name: 'Prone',
            index: 2}
      },
      {
        'name': 'Forearm Support (1)',
        'index': 3,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Prone',
          index: 3}
      },
      {
        'name': 'Prone Mobility',
        'index': 4,
        'behavioralInstrumentName': 'AIMS',
        subscale :{ 
          name: 'Prone',
          index: 4}
      },
      {
        'name': 'Forearm Support (2)',
        'index': 5,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Prone',
          index: 5}
      },
      {
        'name': 'Extended Arm Support',
        'index': 6,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Prone',
          index: 6}
      },
      {
        'name': 'Rolling Prone to Supine Without Rotation',
        'index': 7,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Prone',
          index: 7}
      },
      {
        'name': 'Swimming',
        'index': 8,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Prone',
          index: 8}
      },
      {
        'name': 'Reaching from Forearm Support',
        'index': 9,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Prone',
          index: 9}
      },
      {
        'name': 'Pivoting',
        'index': 10,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Prone',
          index: 10}
      },
      {
        'name': 'Rolling Prone to Supine With Rotation',
        'index': 11,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Prone',
          index: 11}
      },
      {
        'name': 'Four-Point Kneeling (1)',
        'index': 12,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Prone',
          index: 12}
      },
      {
        'name': 'Propped Sidelying',
        'index': 13,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Prone',
          index: 13}
      },
      {
        'name': 'Reciprocal Crawling',
        'index': 14,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Prone',
          index: 14}
      },
      {
        'name': 'Four-Point Kneeling to Sitting or Half-Sitting',
        'index': 15,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Prone',
          index: 15}
      },
      {
        'name': 'Reciprocal Creeping (1)',
        'index': 16,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Prone',
          index: 16}
      },
      {
        'name': 'Reaching from Extended Arm Support',
        'index': 17,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Prone',
          index: 17}
      },
      {
        'name': 'Four-Point Kneeling (2)',
        'index': 18,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Prone',
          index: 18}
      },
      {
        'name': 'Modified Four-Point Kneeling',
        'index': 19,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Prone',
          index: 19}
      },
      {
        'name': 'Reciprocal Creeping (2)',
        'index': 20,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Prone',
          index: 20}
      },
      {
        'name': 'Supine Lying (1)',
        'index': 21,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Supine',
          index: 0}
      },
      {
        'name': 'Supine Lying (2)',
        'index': 22,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Supine',
          index: 1}
      },
      {
        'name': 'Supine Lying (3)',
        'index': 23,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Supine',
          index: 2}
      },
      {
        'name': 'Supine Lying (4)',
        'index': 24,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Supine',
          index: 3}
      },
      {
        'name': 'Hands to Knees',
        'index': 25,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Supine',
          index: 4}
      },
      {
        'name': 'Active Extension',
        'index': 26,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Supine',
          index: 5}
      },
      {
        'name': 'Hands to Feet',
        'index': 27,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Supine',
          index: 6}
      },
      {
        'name': 'Rolling Supine to Prone Without Rotation',
        'index': 28,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Supine',
          index: 7}
      },
      {
        'name': 'Rolling Supine to Prone with Rotation',
        'index': 29,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Supine',
          index: 8}
      },
      {
        'name': 'Sitting With Support',
        'index': 30,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Sitting',
          index: 0}
      },
      {
        'name': 'Sitting With Propped Arms',
        'index': 31,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Sitting',
          index: 1}
      },
      {
        'name': 'Pull to Sit',
        'index': 32,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Sitting',
          index: 2}
      },
      {
        'name': 'Unsustained Sitting',
        'index': 33,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Sitting',
          index: 3}
      },
      {
        'name': 'Sitting With Arm Support',
        'index': 34,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Sitting',
          index: 4}
      },
      {
        'name': 'Unsustained Sitting Without Arm Support',
        'index': 35,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Sitting',
          index: 5}
      },
      {
        'name': 'Weight Shift in Unsustained Sitting',
        'index': 36,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Sitting',
          index: 6}
      },
      {
        'name': 'Sitting Without Arm Support (1)',
        'index': 37,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Sitting',
          index: 7}
      },
      {
        'name': 'Reach With Rotation in Sitting',
        'index': 38,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Sitting',
          index: 8}
      },
      {
        'name': 'Sitting to Prone',
        'index': 39,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Sitting',
          index: 9}
      },
      {
        'name': 'Sitting to Four-Point Kneeling',
        'index': 40,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Sitting',
          index: 10}
      },
      {
        'name': 'Sitting Without Arm Support (2)',
        'index': 41,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Sitting',
          index: 11}
      },
      {
        'name': 'Supported Standing (1)',
        'index': 42,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Standing',
          index: 0}
      },
      {
        'name': 'Supported Standing (2)',
        'index': 43,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Standing',
          index: 1}
      },
      {
        'name': 'Supported Standing (3)',
        'index': 44,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Standing',
          index: 2}
      },
      {
        'name': 'Pulls to Stand With Support',
        'index': 45,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Standing',
          index: 3}
      },
      {
        'name': 'Pulls to Stand/Stands',
        'index': 46,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Standing',
          index: 4}
      },
      {
        'name': 'Supported Standing With Rotation',
        'index': 47,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Standing',
          index: 5}
      },
      {
        'name': 'Cruising Without Rotation',
        'index': 48,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Standing',
          index: 6}
      },
      {
        'name': 'Half-Kneeling',
        'index': 49,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Standing',
          index: 7}
      },
      {
        'name': 'Controlled Lowering Through Standing',
        'index': 50,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Standing',
          index: 8}
      },
      {
        'name': 'Cruising With Rotation',
        'index': 51,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Standing',
          index: 9}
      },
      {
        'name': 'Stands Alone',
        'index': 52,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Standing',
          index: 10}
      },
      {
        'name': 'Early Stepping',
        'index': 53,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'PrStandingone',
          index: 11}
      },
      {
        'name': 'Standing from Modified Squat',
        'index': 54,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Standing',
          index: 12}
      },
      {
        'name': 'Standing from Quadruped Position',
        'index': 55,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Standing',
          index: 13}
      },
      {
        'name': 'Walks Alone',
        'index': 56,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Standing',
          index: 14}
      },
      {
        'name': 'Squat',
        'index': 57,
        'behavioralInstrumentName': 'AIMS',
        subscale :{
          name: 'Standing',
          index: 15}
      }
    ]
  };
const mimmInstrumentsData:Schemas.InstrumentSchemaProps = 
  {
    instrumentId: '1',
    name: 'AIMS-MIMM',
    subscales: [
      'Prone',
      'Supine',
      'Sitting',
      'Standing'
    ],
    instrumentItems: [
      {
        'name': 'Prone Prop, Forearm Support 1&2',
        'index': 0,
        'behavioralInstrumentName': 'AIMS-MIMM',
        'description': 'Forearm support 1 (PR04) and Forearm support 2 (PR06) combined',
        subscale :{
          name: 'Prone',
          index: 0}
      },
      {
        'name': 'Extended Arm Support',
        'index': 1,
        'behavioralInstrumentName': 'AIMS-MIMM',
        'description': 'Extended Arm Support (PR07)',
        subscale :{
          name: 'Prone',
          index: 1}
      },
      {
        'name': 'Pivoting',
        'index': 2,
        'behavioralInstrumentName': 'AIMS-MIMM',
        'description': 'Pivoting (PR11)',
        subscale :{
          name: 'Prone',
          index: 2}
      },
      {
        'name': 'Reciprocal Creeping 1&2',
        'index': 3,
        'behavioralInstrumentName': 'AIMS-MIMM',
        'description': 'Reciprocal Creeping 1&2 combined (PR17 & PR21)',
        subscale :{
          name: 'Prone',
          index: 3}
      },
      {
        'name': 'Supine Lying 3',
        'index': 4,
        'behavioralInstrumentName': 'AIMS-MIMM',
        'description': 'Supine Lying 3 (SU03)',
        subscale :{
          name: 'Supine',
          index: 4}
      },
      {
        'name': 'Supine Lying 4',
        'index': 5,
        'behavioralInstrumentName': 'AIMS-MIMM',
        'description': 'Supine Lying 4 (SU04)',
        subscale :{
          name: 'Supine',
          index: 5}
      },
      {
        'name': 'Hands to Knees',
        'index': 6,
        'behavioralInstrumentName': 'AIMS-MIMM',
        'description': 'Hands to Knees (SU05)',
        subscale :{
          name: 'Supine',
          index: 6}
      },
      {
        'name': 'Rolling Supine to Prone (with/without rotation)',
        'index': 7,
        'behavioralInstrumentName': 'AIMS-MIMM',
        'description': 'Rolling supine to prone with and without rotation combined (SU08 & SU09)',
        subscale :{
          name: 'Supine',
          index: 7}
      },

      {
        'name': 'Unsustained Sitting (with/without arm support)',
        'index': 8,
        'behavioralInstrumentName': 'AIMS-MIMM',
        'description': 'Unsustained sitting & Unsustained sitting without arm support combined (ST04 & ST06)',
        subscale :{
          name: 'Sitting',
          index: 8}
      },
      {
        'name': 'Sitting without arm support 1/Reach With Rotation',
        'index': 9,
        'behavioralInstrumentName': 'AIMS-MIMM',
        'description': 'Sitting without arm support 1 (ST08) + Reach with rotation (ST09) combined',
        subscale :{
          name: 'Sitting',
          index: 9}
      },
      {
        'name': 'Sitting to Four-Point Kneeling',
        'index': 10,
        'behavioralInstrumentName': 'AIMS-MIMM',
        'description': 'Sitting to four-point kneeling (ST11)',
        subscale :{
          name: 'Sitting',
          index: 10}
      },
      {
        'name': 'Sitting Without Arm Support 2',
        'index': 11,
        'behavioralInstrumentName': 'AIMS-MIMM',
        'description': 'Sitting without arm support 2 (ST12)',
        subscale :{
          name: 'Sitting',
          index: 11}
      },

      {
        'name': 'Supported Standing With Rotation',
        'index': 12,
        'behavioralInstrumentName': 'AIMS-MIMM',
        'description': 'Supported standing with rotation (SD06)',
        subscale :{
          name: 'Standing',
          index: 12}
      },

      {
        'name': 'Cruising with rotation',
        'index': 13,
        'behavioralInstrumentName': 'AIMS-MIMM',
        'description': 'Cruising with rotation (SD10)',
        subscale :{
          name: 'Standing',
          index: 13}
      }
    ]
  };
const tffInstrumentsData:Schemas.InstrumentSchemaProps = 
  {
    instrumentId: '2',
    name: 'AIMS-Fairfield-Komori',
    subscales: [
      'Prone',
      'Supine',
      'Sitting',
      'Standing'
    ],
    instrumentItems: [
      {
        'name': 'Prone Prop, Forearm Support 1&2',
        'index': 0,
        'behavioralInstrumentName': 'AIMS-Fairfield-Komori',
        'description': 'Pushes up on forearms when on tummy',
        subscale :{
          name: 'Prone',
          index: 0}
      },
      {
        'name': 'Extended Arm Support',
        'index': 1,
        'behavioralInstrumentName': 'AIMS-Fairfield-Komori',
        'description': 'Pushes up with straight arms when on tummy',
        subscale :{
          name: 'Prone',
          index: 1}
      },
      {
        'name': 'Rolling prone to supine with or without rotation',
        'index': 2,
        'behavioralInstrumentName': 'AIMS-Fairfield-Komori',
        'description': 'Rolls from back to belly',
        subscale :{
          name: 'Prone',
          index: 2}
      },
      {
        'name': 'Reciprocal Creeping 1 or 2',
        'index': 3,
        'behavioralInstrumentName': 'AIMS-Fairfield-Komori',
        'description': 'Crawling',
        subscale :{
          name: 'Prone',
          index: 3}
      },
      {
        'name': 'Supine 3',
        'index': 4,
        'behavioralInstrumentName': 'AIMS-Fairfield-Komori',
        'description': 'Lying on back, holds a toy with one hand',
        subscale :{
          name: 'Supine',
          index: 4}
      },
      {
        'name': 'Supine 4',
        'index': 5,
        'behavioralInstrumentName': 'AIMS-Fairfield-Komori',
        'description': 'Lying on back, holds a toy with two hands',
        subscale :{
          name: 'Supine',
          index: 5}
      },
      {
        'name': 'Hands to Knees/ Hands to Feet',
        'index': 6,
        'behavioralInstrumentName': 'AIMS-Fairfield-Komori',
        'description': 'Reaches and grabs own knees or feet',
        subscale :{
          name: 'Supine',
          index: 6}
      },
      {
        'name': 'Rolling Supine to Prone (with or without rotation)',
        'index': 7,
        'behavioralInstrumentName': 'AAIMS-Fairfield-KomoriIMS',
        'description': 'Rolls from belly to back',
        subscale :{
          name: 'Supine',
          index: 7}
      },

      {
        'name': 'Sitting With Propped Arms',
        'index': 8,
        'behavioralInstrumentName': 'AIMS-Fairfield-Komori',
        'description': 'Leans on hands for support in sitting',
        subscale :{
          name: 'Sitting',
          index: 8}
      },
      {
        'name': 'Pull to Sit',
        'index': 9,
        'behavioralInstrumentName': 'AIMS-Fairfield-Komori',
        'description': 'Pull from lying on back to sitting. Hold wrists while maintaining eye contact',
        subscale :{
          name: 'Sitting',
          index: 9}
      },
      {
        'name': 'Unsustained Sitting (with/without arm support)',
        'index': 10,
        'behavioralInstrumentName': 'AIMS-Fairfield-Komori',
        'description': 'Sitting, cannot be left alone',
        subscale :{
          name: 'Sitting',
          index: 10}
      },
      {
        'name': 'Sitting without arm support 1/Reach With Rotation',
        'index': 11,
        'behavioralInstrumentName': 'AIMS-Fairfield-Komori',
        'description': 'Sitting, can be left alone',
        subscale :{
          name: 'Sitting',
          index: 11}
      },
      {
        'name': 'Sitting Without Arm Support (2)',
        'index': 12,
        'behavioralInstrumentName': 'AIMS-Fairfield-Komori',
        'description': 'Gets into and out of sitting on their own',
        subscale :{
          name: 'Sitting',
          index: 12}
      },

      {
        'name': 'Pulls to Stand with support. Pulls to Stand/Stands',
        'index': 13,
        'behavioralInstrumentName': 'AIMS-Fairfield-Komori',
        'description': 'Pulls to stand with support (SD04) combined with Pulls to stand/stands (SD05)',
        subscale :{
          name: 'Standing',
          index: 13}
      },
      {
        'name': 'Cruising with or without rotation',
        'index': 14,
        'behavioralInstrumentName': 'AIMS-Fairfield-Komori',
        'description': 'Walks, holding on furniture',
        subscale :{
          name: 'Standing',
          index: 14}
      }
    ]
  };

const fmInstrumentData:Schemas.InstrumentSchemaProps =
{
  "instrumentId": "3",
  "name": "General Movements (FM)",
  "subscales": [],
  "instrumentItems": [
    {
      "name": "Fidgety Movement",
      "index": 0,
      "behavioralInstrumentName": "General Movements (FM)",
      "description": "Normal fidgety movement"
    },
    {
      "name": "Abnormal Fidgety Movement",
      "index": 1,
      "behavioralInstrumentName": "General Movements (FM)",
      "description": "Abnormal fidgety movement"
    }
  ]
}
export const allInstrumentData:Schemas.InstrumentSchemaProps[]  = [
  aimsInstrumentsData, mimmInstrumentsData, tffInstrumentsData, fmInstrumentData
];