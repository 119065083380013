import React, {useContext} from 'react';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  MenuItem,
  TextField
} from '@material-ui/core';

import { KeyboardDatePicker } from '@material-ui/pickers';
import { 
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core/styles';

import { genders } from '../../common/staticdata';

import { 
  reportInfoReducer,
  ReportInfoContext,
  ReportInfoContextType,
  defaultReportInfo
} from '../../context/ReportContext';
import { WeekAndDayInput } from '../Inputs';
import { prettifyMonthDay } from '../../common/reportUtilities';
import { calculateGestAgeFromExpected, calculateWeeksAndDaysFromDays } from '../../common/dateTimeUtilities';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    card: {
      maxWidth: '100%',
      minWidth: '0',
      width:'100%',
      margin: 'auto',
      transition: '0.3s',
      boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
      '&:hover': {
        boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)'
      }
    },
    cardcontent: {
      textAlign: 'left',
      // padding: theme.spacing.unit * 3
    },    
  }),
);

export const SubjectInfo= ():JSX.Element=>{

  const classes = useStyles();
  const {reportInfoState, reportInfoDispatch} = React.useContext(ReportInfoContext);
  const {asmtSubjectName, subjectGender, 
    asmtSubjectBirthDate} = reportInfoState?.reportInformation;
  const {gestAgeDays, dueDate, subjectNotes} = reportInfoState?.uiInformation;// ?? {};
  // hack value because the date displayer needs the on change
  const [unusedValue, setUnusedValue] = React.useState<Date | null>(null);
  const [gestAgeString, setGestAgeString] = React.useState('');

  React.useEffect(() =>{
    if(gestAgeDays){
      const weekDays = calculateWeeksAndDaysFromDays(gestAgeDays);
      setGestAgeString(prettifyMonthDay(weekDays.weeks, weekDays.days, true));
      return;
    }else{
      if(asmtSubjectBirthDate){
        const weekDays = calculateGestAgeFromExpected(dueDate, asmtSubjectBirthDate);
        if(weekDays.totalDays){
          setGestAgeString(prettifyMonthDay(weekDays.weeks, weekDays.days, true));
        }
      }
    }
  },[asmtSubjectBirthDate,dueDate, gestAgeDays]);

  return (          
    <Card className={classes.card}>
      <CardContent className={classes.cardcontent}>
        <Typography
          color="secondary"
          gutterBottom
          variant={'h6'}
        >
        Subject Information
        </Typography>

        <form
          autoComplete="off"
          className={classes.root}
          noValidate
        >
          <div>
            <TextField
              autoFocus
              fullWidth
              id="subjectname"
              label="Name"
              margin="dense"
              type="text"
              value={asmtSubjectName}
            />
            <TextField
              helperText="Your baby's gender"
              id="gender"
              label="Gender"
              select
              value={subjectGender}
            >
              {genders.map(option => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div>
            <KeyboardDatePicker
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              format="MM/dd/yyyy"
              label="Date of birth"
              margin="normal"
              onChange={setUnusedValue}
              value={asmtSubjectBirthDate}
            />
            {!gestAgeDays&&
            <KeyboardDatePicker
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              format="MM/dd/yyyy"
              label="Due date"
              margin="normal"
              onChange={setUnusedValue}
              value={dueDate}
            />
            }
            <TextField
              fullWidth
              id="gestationalAge"
              label="Gestational Age"
              value = {gestAgeString}
            />
            {/* <WeekAndDayInput
              initialGestationAgeInDays={gestAgeToDisplay??0}
            /> */}
          </div>
          <div>
            <TextField
              InputLabelProps={{ shrink: true }}
              autoFocus
              fullWidth
              id="subjectnotes"
              label="Infant notes"
              margin="dense"
              // onChange={(event) => setDialogValue({ ...dialogValue, lastname: event.target.value })}
              style={{ width: '80%' }}
              type="text"
              value={subjectNotes}
            />
          </div>
        </form>
      </CardContent>
    </Card>
  );
};