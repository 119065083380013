import React from 'react';
import {
  Route,
  Switch,
  withRouter,
  Redirect,
} from 'react-router-dom';

import classnames from 'classnames';
// styles
import useStyles from './styles';
// components
import Header from '../Header';
import Sidebar from '../Sidebar';
import Footer from '../Footer';
// context
import { useLayoutState } from '../../context/LayoutContext';

import * as AppRoutes from '../../common/routeNames';
import Dashboard from '../../pages/dashboard';
import EmmaUserSettings from '../../pages/emmausersettings';
import VideoTasks from '../../pages/videotasks';
import DerivedDataStatus from '../../pages/deriveddatastatus';
import Documents from '../../pages/documents';
import EmmaVideos from '../../pages/emmavideos';
import OTVideos from '../../pages/otvideos';
import Assessments from '../../pages/assessments';
import {Management} from '../../pages/management';
import {AppSettings} from '../../pages/settings';
import {CloudMessaging} from '../../pages/cloudmessaging';

// the test page is useful, but it should be pulled to a different
// index. ie not in the main build.
import TestPage from '../../pages/testpage/TestPage';
import Administration from '../../pages/administration/Adminstration';

// This layout should be dependent on the user that is logged in.
// it should also matchish the sidebar layout
function Layout(props) {
  const classes = useStyles();
  // global
  const layoutState = useLayoutState();
  return (
    <div 
      className={classes.root}
    >
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route
              component={Dashboard}
              path={AppRoutes.DASHBOARD}
            />
            <Route
              exact
              path={AppRoutes.SETTINGS}
              render={() => <Redirect to={AppRoutes.EMMA_USER_SETINGS} />}
            />
            <Route
              component={Assessments}
              path={AppRoutes.ASSESSMENTS}
            />
            <Route
              component={EmmaUserSettings}
              path={AppRoutes.EMMA_USER_SETINGS}
            />
            <Route
              component={VideoTasks}
              path={AppRoutes.VIDEO_TASKS}
            />
            <Route
              component={DerivedDataStatus}
              path={AppRoutes.DERIVED_DATA_STATUS}
            />
            <Route
              component={CloudMessaging}
              path={AppRoutes.MESSAGES}
            />
            <Route
              component={Documents}
              path={AppRoutes.DOCUMENTATION}
            />
            <Route
              component={Administration}
              path={AppRoutes.ADMIN_TASKS}
            />
            <Route
              component={AppSettings}
              path={AppRoutes.APP_SETTINGS}
            />
            {/* <Route
              component={Management}
              path={AppRoutes.MANAGEMENT}
            /> */}
            <Route
              exact
              path={AppRoutes.VIDEOS}
              render={() => <Redirect to={AppRoutes.EMMA_VIDEOS} />}
            />
            <Route
              component={EmmaVideos}
              path={AppRoutes.EMMA_VIDEOS}
            />
            <Route
              component={OTVideos}
              path={AppRoutes.OT_VIDEOS}
            />
          </Switch>
        
          <Footer/>
        </div>
      </>
    </div>
  );
}
export default withRouter(Layout);